import React from "react";
import { UsePaginationInstanceProps, UsePaginationState } from "react-table";
import { IconButton } from "./TableStyles";
import { ReactComponent as FirstPageIcon } from "../../Assets/First page.svg";
import { ReactComponent as LastPageIcon } from "../../Assets/Last page.svg";
import { ReactComponent as NextPageIcon } from "../../Assets/Next Page.svg";
import { ReactComponent as PrevPageIcon } from "../../Assets/Prev Page.svg";
import "./paginationcss.css";

export const Pagination: React.FC<
	UsePaginationInstanceProps<object> & UsePaginationState<object>
> = ({
	pageOptions,
	pageIndex,
	gotoPage,
	canPreviousPage,
	canNextPage,
	previousPage,
	nextPage,
	pageCount,
}) => pageOptions.length > 1 ? (
	<nav className="dds__d-flex dds__flex-wrap dds__justify-content-lg-between dds__justify-content-around" role="navigation" aria-label="Pagination Navigation">
		<div className="dds__d-flex dds__flex-wrap">
			<span className="dds__mr-2">
				<label htmlFor="gotopage">Go to page:</label>{" "}
				<input
					id="gotopage"
					type="number"
					defaultValue={pageIndex + 1}
					onChange={e => {
						const page = e.target.value ? Number(e.target.value) - 1 : 0;
						gotoPage(page);
					}}
					style={{ width: "80px" }}
					min={1}
					max={pageOptions.length}
				/>
			</span>
			{/* <select
					value={pageSize}
					onChange={e => {
						setPageSize(Number(e.target.value))
					}}
				>
					{[10, 20, 30, 40, 50].map(pageSize => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select> */}
			<span>
				Page{" "}
				<strong>
					{pageIndex + 1} of {pageOptions.length}
				</strong>
				{" "}
			</span>
		</div>

		<div>
			<IconButton
				className="dds__mx-3"
				onClick={() => gotoPage(0)}
				disabled={!canPreviousPage}
				type="button"
				aria-label="Go to first page"
			>
				<FirstPageIcon
					aria-hidden="true"
					style={{ width: "80%" }}
					stroke={!canPreviousPage ? "lightgray" : ""}
				/>
				<span className="dds__sr-only">Go to first page</span>
			</IconButton>
			<IconButton
				className="dds__mx-3"
				onClick={() => previousPage()}
				disabled={!canPreviousPage}
				type="button"
				aria-label="Go to previous page"
			>
				<PrevPageIcon
					aria-hidden="true"
					style={{ width: "80%" }}
					stroke={!canPreviousPage ? "lightgray" : ""}
				/>
				<span className="dds__sr-only">Go to previous page</span>
			</IconButton>
			<IconButton
				className="dds__mx-3"
				onClick={() => nextPage()}
				disabled={!canNextPage}
				type="button"
				aria-label="Go to next page"
			>
				<NextPageIcon
					aria-hidden="true"
					style={{ width: "80%" }}
					stroke={!canNextPage ? "lightgray" : ""}
				/>
				<span className="dds__sr-only">Go to next page</span>
			</IconButton>
			<IconButton
				className="dds__mx-3"
				onClick={() => gotoPage(pageCount - 1)}
				disabled={!canNextPage}
				type="button"
				aria-label="Go to last page"
			>
				<LastPageIcon
					aria-hidden="true"
					style={{ width: "80%" }}
					stroke={!canNextPage ? "lightgray" : ""}
				/>
				<span className="dds__sr-only">Go to last page</span>
			</IconButton>
		</div>
	</nav>
) : (
			<></>
		);
