import { useCustomQuery } from "../../../hooks/useCustomQuery";
import { queryKeys } from "../../../react-query/constants";
import { fetchUserProfileImage } from "../API";

export const useUserImageAPI = (badgeId: string) => {
    return useCustomQuery([queryKeys.userImage], fetchUserProfileImage(badgeId), {
        select: data => {
            const blob = new Blob([data], { type: "image/png" });
            const url = URL.createObjectURL(blob);
            return url;
        },
        staleTime: Infinity,
        enabled: !!badgeId,
    });
};
