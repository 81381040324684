import styled, { keyframes } from "styled-components";
import banner from "../../../Assets/banner.svg";

export const ComponentWrapper = styled.div<{}>`
	background-color: #17319e;
`;
export const BgWrapper = styled.div<{}>`
	background-image: url(${banner});
	min-height: 150px;
	height: auto;
	width: 100%;
	background-size: cover;
	background-size: 50%;
	background-repeat: no-repeat;
	background-position: right center;
	flex-direction: row;
	display: flex;
	align-items: center;
	@media (max-width: 1023px) {
		// till lg
		background-image: none;
	}
`;

const typing = keyframes`
    0% { width: 0% }
    99% {width: 100%}
    100% { width: auto }
`;

// Animation for the cursor blinking effect
const blinkCursor = keyframes`
    0% { border-right-color: transparent }
    50% { border-right-color: transparent } // Adjust this color: ;
    100% { border-right-color: transparent }
`;

export const TypeWriterEffect = styled.div<{}>`
	white-space: nowrap;
	animation: ${typing} 2s steps(40, end) forwards,
		// Adjust duration as needed
		${blinkCursor} 1s step-end forwards; // Blinking cursor animation
	animation-delay: 0;
	/* set infinte animation */
	animation-iteration-count: 1;
	width: 0;
	overflow: hidden; // To ensure text is clipped as it "types" out
	border-right: 2px solid black; // Cursor effect
	display: block;
`;

