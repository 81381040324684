import LoadingSpinner from '../../../Common/LoadingSpinner';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import { pcversion } from '../../constants';
import DDSAccordian from '../../Accordian/DDSAccordian';
import NoRecordFound from '../../CardTable/NoRecordFound';
import { createAccordianID } from '../Asset Request Tracker Card/User';
import CardError from '../CardError';
import { usePcVersionAPI } from './usePcVersionAPI';
import sanitizeHtml from 'sanitize-html';

const columns = [{
	Header: "Asset Number",
	accessor: "model",
	Cell: ({ value }) => <>{value}</>,
	searchable: true,
	visible: false,

}, {
	Header: "Host name",
	accessor: "host",
	Cell: ({ value }) => <>{value}</>,
	searchable: true,
	visible: false,
}, {
	Header: "OS",
	accessor: "os",
	Cell: ({ value }) => <>{value}</>,
	searchable: true,
	visible: false,
}, {
	Header: "OS Build Version",
	accessor: "osBuildName",
	Cell: ({ value }) => <>OS Build Version: {value}</>,
	searchable: true,
	visible: false,
}, {
	Header: "alert",
	accessor: "alert",
	Cell: ({ value }) => <>{value}</>,
	searchable: false,
	visible: true,
	hidden: true
}, {
	Header: "Instructions",
	accessor: "actionItem",
	Cell: ({ value }) => <>
		<p
			className="dds__text-justify dds__break-word"
			dangerouslySetInnerHTML={{ __html: sanitizeHtml(value) }}
		></p>
	</>,
	searchable: false,
	visible: true,
}
]

const User: React.FC = () => {
	const { isLoading, data, isError } = usePcVersionAPI();

	if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
	if (isError) return <CardError />;

	if (data.length === 0)
		return <NoRecordFound />

	return (
		<DDSAccordian
			totalElements={data.length}
			data={data}
			isError={isError}
			isLoading={isLoading}
			columns={columns}
			accordianID={createAccordianID(pcversion)}
			sideWayTable={false}
		/>
	);
}

export default User