

const DDSSearch = ({ searchRef, searchText, setSearchText }) => {

	return (
		<div
			ref={searchRef}
			className="dds__search dds__mb-3"
			// id="search-487023617"
			data-dds="search"
			role="search"
		>
			<div className="dds__search__wrapper">
				<input
					type="search"
					className="dds__search__control"
					name="search-control-888441747"
					// id="search-control-888441747"
					aria-labelledby="search-label-888441747"
					placeholder="Search"
					value={searchText}
					onChange={event => {
						setSearchText(event.target.value);
					}}
					maxLength={35}
				/>
			</div>
		</div>
	)
}

export default DDSSearch