import React from 'react'
import LoadingSpinner from '../../../Common/LoadingSpinner';
import CardError from '../CardError';
import { cardColumns, eol } from '../../constants';
import { useEOLData } from './useEOLData';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import DDSAccordian from '../../Accordian/DDSAccordian';
import NoRecordFound from '../../CardTable/NoRecordFound';
import { createAccordianID } from '../Asset Request Tracker Card/User';

const columns: cardColumns = [
	{
		Header: "Service Tag",
		accessor: "servicetag",
		Cell: ({ value }) => <>{value.toUpperCase()}</>,
		searchable: true,
		visible: false,
	},
	{
		Header: "Product Name",
		accessor: "model",
		Cell: ({ value }) => <>{value}</>,
		searchable: true,
		visible: false,
	},
	{
		Header: "End Of Life",
		accessor: "endoflifeInDays",
		Cell: ({ value }) => {
			if (parseInt(value) < 0)
				value = value + " days overdue";
			else if (parseInt(value) === 0)
				value = "Today";
			else
				value = value + " days left";
			return <>{value}</>
		},
		searchable: true,
		visible: false,
	},
	{
		Header: "End Of Life Date",
		accessor: "endoflife",
		Cell: ({ value }) => <>{new Date(value).toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric'
		})}</>,
		searchable: true,
		visible: true,
	},
	{
		Header: "OS",
		accessor: "os",
		Cell: ({ value }) => <>{value}</>,
		searchable: true,
		visible: true,
	}
];

const User: React.FC = () => {
	const { isOrg, activeTab } = useDashboardContext();
	const { isLoading, error, data, isError } =
		useEOLData((!isOrg || (activeTab.category === 'all' && activeTab.name === eol)))

	if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
	if (error) return <CardError />;

	if (data.length === 0)
		return <NoRecordFound />

	return (
		<DDSAccordian
			totalElements={data.length}
			data={data}
			isError={isError}
			isLoading={isLoading}
			columns={columns}
			accordianID={createAccordianID(eol)}
		/>
	);

}

export default User