
const ToolbarButton: React.FC<{
	onClick: () => void,
	iconClass?: string,
}> = ({ onClick, iconClass, children }) => {
	return <button
		onClick={onClick}
		className="dds__button dds__button--md"
		type="button"
		style={{
			lineHeight: "2.0rem",
			color: "white",
			borderColor: "white",
			verticalAlign: "middle",
		}}
	>
		{iconClass && <i className={`dds__icon dds__button__icon--start ${iconClass}`} />}
		{children}
	</button>
}
export default ToolbarButton