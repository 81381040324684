import React, { useEffect, useRef } from "react";
import DropdownComponent from "../../../Common/DropdownComponent";
import { Dropdown } from "@dds/components";
import { SiteFilter } from "../AssetTrackerAdmin";
import useDateRangePicker from "../../../Common/DateRangePicker/useDateRangePicker";
import DateRangePicker from "../../../Common/DateRangePicker/DateRangePicker";
const FilterBar: React.FC<{
	siteList: string[];
	defaultFilter: SiteFilter;
	onApply: (filter: SiteFilter) => void;
	isFetching: boolean;
}> = ({ siteList, defaultFilter, onApply, isFetching }) => {
	const dropdownRef = useRef<HTMLDivElement>(null);
	const dropdownInstanceRef = useRef<any>(null);

	const sdRef = useRef<HTMLDivElement>(null);
	const edRef = useRef<HTMLDivElement>(null);

	const searchInputRef = useRef<any>();

	const {
		initialize: initializeDatePicker,
		setDateRange,
		getSelectedRange,
		clearDateRange
	} = useDateRangePicker(sdRef, edRef);

	useEffect(() => {
		if (dropdownRef.current === null) return;
		dropdownInstanceRef.current = new Dropdown(
			dropdownRef.current
		);
		initializeDatePicker("YYYY-MM-DD", new Date(), new Date());

		resetHandler();

		return () => {
			dropdownInstanceRef.current.dispose()
		}
	}, [dropdownRef]);

	const resetHandler = () => {
		console.log("default", defaultFilter);
		setDateRange(new Date(defaultFilter.startDate), new Date(defaultFilter.endDate));
		dropdownInstanceRef.current.selectOption(defaultFilter.site);
		searchInputRef.current.value = defaultFilter.searchAll;
	};

	const applyHandler = () => {
		const { startDate, endDate } = getSelectedRange()
		if (startDate === null || endDate === null) {
			clearDateRange();
		}
		let searchAll = searchInputRef.current.value;
		let site = dropdownInstanceRef.current.getSelection();
		let isDateRange = startDate && endDate ? true : false;
		console.log("Selection: ", startDate, endDate, searchAll, site);
		onApply({ startDate, endDate, searchAll, site, isDateRange });
	};

	return (
		<div className="dds__d-flex dds__justify-content-around dds__flex-wrap">
			{/* Dell Site - Dropdown*/}
			<DropdownComponent
				elementRef={dropdownRef} // To be fixed
				label="Dell Site"
				showLabel={true}
				placeholder="Select Dell Site"
				PopupList={
					<ul className="dds__dropdown__list" role="listbox" tabIndex={-1}>
						{siteList.length > 0 && (
							<>
								{siteList.map((site, index) => (
									<li key={index} className="dds__dropdown__item" role="none">
										<button
											className="dds__dropdown__item-option"
											role="option"
											data-selected={false}
											tabIndex={-1}
										>
											<span className="dds__dropdown__item-label">{site}</span>
										</button>
									</li>
								))}
							</>
						)}
					</ul>
				}
			/>
			{/* Search - Text Input with button */}
			<div className="dds__input-text__container">
				<label
					id="text-input-label-682745172"
					htmlFor="text-input-control-name-682745172"
				>
					RITM/TASK/Badge ID/Manager Email
				</label>
				<div className="dds__input-text__wrapper">
					<input
						type="text"
						className="dds__input-text dds__has__icon--start"
						name="text-input-control-name-682745172"
						id="text-input-control-682745172"
						aria-labelledby="text-input-label-682745172 text-input-helper-682745172"
						placeholder="Search"
						ref={searchInputRef}
					/>
					<i
						className="dds__icon dds__icon--search dds__input-text__icon--start"
						aria-hidden="true"
					></i>
					{/* <small id="text-input-helper-682745172" className="dds__input-text__helper">Helper Text</small> */}
					<div
						id="text-input-error-682745172"
						className="dds__invalid-feedback"
					>
						Error Text
					</div>
				</div>
			</div>
			{/* Start Date - Date Selector */}
			<div className="dds__d-flex">
				<DateRangePicker sdRef={sdRef} edRef={edRef} />
			</div>
			<div className="dds__d-flex dds__flex-wrap dds__justify-content-around dds__align-items-center dds__mt-4">
				<div className="dds__mr-2">
					<button
						onClick={applyHandler}
						role={"button"}
						className="dds__button dds__button--primary"
						disabled={isFetching}
					>
						Apply
					</button>
				</div>
				<div>
					<button
						role={"button"}
						className="dds__button dds__button--secondary"
						onClick={resetHandler}
						disabled={isFetching}
					>
						Reset
					</button>
				</div>
			</div>
		</div>
	);
};

export default FilterBar;
