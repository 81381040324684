import { useEffect, useRef } from 'react';
import DropdownComponent from '../../../Common/DropdownComponent'
import { Dropdown } from "@dds/components"

const SiteDropdown: React.FC<{
	id: string,
	selectedDellSites: React.MutableRefObject<any[]>,
	initialize: boolean,
	instanceRef: React.MutableRefObject<any>
}> = ({ id, selectedDellSites, initialize, instanceRef }) => {
	const dropdownRef = useRef<any>();
	useEffect(() => {
		// Initialize Dropdown role-dropdown
		if (initialize) {
			console.log(id + "init" + dropdownRef.current)
			instanceRef.current = new Dropdown(dropdownRef.current);
		} else {
			console.log("issue")
		}
		// return () => {
		// 	if (instanceRef.current)
		// 		instanceRef.current.dispose();
		// }
	}, [])
	return (
		<DropdownComponent
			label="Dell Site *"
			inputId={`dellsites-multiselect-dropdown-input-${id}`}
			popupId={`dellsites-multiselect-dropdown-popup-${id}`}
			inputName={`dellsites-multiselect-dropdown-name-${id}`}
			selection="multiple"
			elementRef={dropdownRef}
			PopupList={
				<ul className="dds__dropdown__list" role="listbox" tabIndex={-1}>
					{/* Get List from userinfo and populate */}
					{selectedDellSites.current.map((dellSite) => {
						return <li className="dds__dropdown__item" role="none" key={dellSite.siteName}>
							<button
								className="dds__dropdown__item-option" role="option" data-selected={dellSite.isSelected ? "true" : "false"} data-value={dellSite.siteName} tabIndex={-1}>
								<span className="dds__dropdown__item-label">{dellSite.siteName}</span>
							</button>
						</li>
					})}
				</ul>}
		// inputDisabled={inputDisabled}
		/>
	)
}

export default SiteDropdown