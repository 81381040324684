import { useState } from "react";
import EditAdminModal from "../../AdminModal/EditAdminModal";
import ToolbarButton from "./ToolbarButton";

const CreateAdminButton = () => {
	const [show, setShow] = useState(false);

	return <>
		<ToolbarButton onClick={() => {
			setShow(true);
		}} iconClass="dds__icon--user-add"
		>Create Admin</ToolbarButton>
		{show && (
			<EditAdminModal
				handleClose={() => {
					setShow(false);
				}}
				mode="new"
			></EditAdminModal>
		)}
	</>
}
export default CreateAdminButton