import { objectToQuery } from "../../../utils/querycreator";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const createTelemetryMeasures = (): object => ({
	url: baseUrl + "/api/Add/v1/TelemertyMeasures",
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});
export const updateTelemetryMeasures = (): object => ({
	url: baseUrl + "/api/Update/v1/UpdateTelemertyMeasures",
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const getMeasuresConfig = () => {
	return {
		url: baseUrl + "/api/Fetch/v1/TelemertyMeasures",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};
export const getSearchMeasuresConfig = (q:string) => {
	return {
		url: baseUrl + `/api/Fetch/v1/TelemertyMeasuresSearchValues?q=${q}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};
export const measureValuesQueryConfig = () => ({
    url: baseUrl + "/api/Fetch/v1/TelemertyMeasuresValues",
    method: "GET",
    headers: {
        "Content-Type": "application/json",
    },
});


export const DeleteMeasuresQueryConfig =  (id: number) => {
	return {
		url: baseUrl + `/api/v1/deleteTelemertyMeasures?id=${id}`,
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	}
}