import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import DDSTabs, { TabsData } from '../../../Common/Tabs/DDSTabs';
import Card from '../../Card/Card';
import { createTrigger, TriggerAction } from '../../Common/TriggerAction';
import { cardsTheme, vdi } from '../../constants';
import DDSDrawer from '../../Drawer/DDSDrawer';
import { userTab } from '../../DashboardPage/useMetaAPI';
import User from './User';
import { useVDIData } from './useVDIData';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import { useState, useEffect } from 'react';
import { useDashboardAPIs } from '../../DashboardPage/useDashboardAPIs';

const VdiCard: React.FC<{
	favouriteStatus: boolean, description: any, tabsMetaData: userTab, actionRequired: boolean
}> = ({
	favouriteStatus,
	description,
	tabsMetaData,
	actionRequired
}) => {
		const { isLoading: isUserLoading, error: userError, data: userData } = useVDIData();
		const { getUserRole } = useUserInfo();
		const role = getUserRole(vdi)
		const { isOrg } = useDashboardContext();
		const { data: metaData } = useDashboardAPIs();
		const [message, setMessage] = useState("View Details")

		useEffect(() => {
			if (!isOrg) {
				if (metaData && metaData.data[vdi]) {
					setMessage(metaData.data[vdi]);
				}
			}
		}, [metaData, isOrg]);

		useEffect(() => {
			if (!isOrg && !isUserLoading && !userError) {
				let temp = "No VDI Requests found"
				if (userData.total > 0)
					temp = `You have ${userData.total} VDI Requests`;
				setMessage(temp);
				// Add logic to update query data with message 
			}
		}, [isUserLoading, userData, userError, isOrg]);
		const tabDetails: TabsData = [{
			hidden: !tabsMetaData.user || !role.user,
			name: tabsMetaData.user,
			iconClass: "dds__icon--user",
			content: User,
			active: !isOrg,
			footerButtons: [
				{
					name: "Request a VDI",
					path: "https://vdi.dell.com/request",
					external: true,
					ariaLabel: "Open " + vdi + " dashboard in a new tab" // if external is true
				},
				{
					name: "Manage Requests",
					path: "https://vdi.dell.com",
					external: true,
					ariaLabel: "Open " + vdi + " dashboard in a new tab" // if external is true
				}
			]
		}]
		const triggerID = createTrigger(vdi)

		return <Card
			themeColor={cardsTheme[vdi].themeColor}
			name={vdi}
			ddsIconClass={cardsTheme[vdi].ddsIconClass}
			isFav={favouriteStatus}
			idName={vdi}
			description={description}
			isLoading={(message === "View Details" && !userError)}
			actionRequired={actionRequired}
		>
			<TriggerAction triggerID={triggerID}>
				{message}
			</TriggerAction>
			<DDSDrawer triggerID={triggerID} cardName={vdi}>
				<DDSTabs tabsMetaData={tabsMetaData} idName={vdi} tabsData={tabDetails} salt={vdi} />
			</DDSDrawer>
		</Card>


	}

export default VdiCard