import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from "react";
import { v4 as uuidv4 } from "uuid";
import {
	browserName,
	browserVersion,
	osName,
	osVersion,
	deviceType,
} from "react-device-detect";
import { useCustomMutation, useCustomQuery } from "../../hooks/useCustomQuery";
import { queryKeys } from "../../react-query/constants";
import { logDelllyticsEvents } from "./API";
import { useIsAuthenticated } from "@azure/msal-react";

const appVersion = process.env.REACT_APP_VERSION;
const delllyticsClientId = process.env.REACT_APP_DELLLYTICS_CLIENT_ID;

const AnalyticsContext = createContext<any>(null);

export type EventType = {
	additional_payload?: any; // Any data can be sent regardless of type
	event_action: string; // Event name
	event_id: number; // Event ID
	event_type: string; // Event type
	log_time: string; // UTC time like "2022-08-04T15:45:40.425"
	response_time?: number | string; // double - in seconds
	status_code?: number | string;
	status_message?: string;
};

const AnalyticsProvider = (props): any => {
	const BASE_URL = process.env.REACT_APP_EOL_BASE_URL;
	const CLIENT_ID = process.env.REACT_APP_DELLLYTICS_CLIENT_ID;
	const ANALYTICS_PROVIDER = "delllytics";

	const registerEndpoint = "/api/Add/v1/DellLyticsRegister";

	// const auth = useAuth();

	let eventsList: EventType[] = [];

	const getId = (): {
		user_id: string;
		device_id: string;
	} => {
		const temp = sessionStorage.getItem(ANALYTICS_PROVIDER);
		return typeof temp === "string" ? JSON.parse(temp) : {};
	};

	const {
		isLoading: isSaving,
		mutate: logAPI,
	} = useCustomMutation(logDelllyticsEvents(), {});

	const isRegistered = (): boolean => {
		if (sessionStorage.getItem(ANALYTICS_PROVIDER)) return true;
		return false;
	};

	const setSession = (dataObj: {
		user_id: string;
		device_id: string;
	}): void => {
		sessionStorage.setItem(ANALYTICS_PROVIDER, JSON.stringify(dataObj));
	};
	// useEffect(() => {
	// 	console.log("event iosSaving ", isSaving)
	// }, [isSaving])

	const getTimeDifference = (time1: string, time2: string) => { // utc time
		const date1 = new Date(time1);
		const date2 = new Date(time2);
		const diff = Math.abs(date2.getTime() - date1.getTime());
		return diff;
	}

	const addEventToQueue = (event: EventType) => {
		// Exception to avoid dual logging of same APi if it is called twice at the same time for caching like Dashboard cards
		if (eventsList[eventsList.length - 1]?.additional_payload?.pathname === event.additional_payload?.pathname &&
			getTimeDifference(eventsList[eventsList.length - 1]?.log_time, event.log_time) < 500) {
			return
		};
		eventsList.push(event);
	}

	const logEvents = useCallback(() => {
		let events: EventType[] = []
		events.push(...eventsList); // deep copy
		eventsList = [];
		// console.log("Logging", events)
		if (events.length > 0) {
			const body = {
				app_key: delllyticsClientId,
				app_version: appVersion,
				user_id: getId().user_id,
				device_id: getId().device_id,
				logs: events,
			};
			logAPI(body, {
				onError: error => {
					// reset the list
					eventsList.push(...events);
				},
			});
		}
	}, []);

	useEffect(() => {
		setInterval(() => {
			// console.log("interval")
			logEvents()
		}, 10000);
	}, [])

	const isAuthenticated = useIsAuthenticated();

	const { error, refetch } = useCustomQuery(
		[queryKeys.dellyticsReg],
		{
			url: BASE_URL + registerEndpoint,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				// access_token: auth.getIdToken() || "",
			},
			body: {
				device: {
					device_id: uuidv4(),
					platform: "web",
					device_os: osName,
					os_version: `${osName} ${osVersion}`,
					device_name: `${browserName} ${browserVersion}`,
				},
				application: {
					app_key: CLIENT_ID,
				},
			},
		},
		{
			enabled: isAuthenticated && !isRegistered(),
			select: data => {
				setSession(data.result);
				return data.result;
			},
		}
	);

	useEffect(() => {
		if (error) console.log(error);
	}, [error]);

	const analyticsContextValue = useMemo(
		() => ({
			isRegistered,
			addEventToQueue,
		}),
		[]
	);

	return (
		<AnalyticsContext.Provider value={analyticsContextValue} {...props}>
			{props.children}
		</AnalyticsContext.Provider>
	);
};

const useAnalytics = (): {
	isRegistered: () => boolean;
	addEventToQueue: (event: EventType) => void;
} => useContext(AnalyticsContext);

export { AnalyticsProvider, useAnalytics };
