import MobileDevicesLayout from "../../Layouts/MobileDevicesLayout";
import MobileDevicesOrgPage from "../../components/MobileDevices/Org/MobileDevicesOrgPage";

const mobileDevicesRoutes = [
	{
		path: "/mobiledevices/myorg",
		exact: true,
		layout: MobileDevicesLayout,
		name: "My Organization",
		component: MobileDevicesOrgPage,
	},
];
export default mobileDevicesRoutes;