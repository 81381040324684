import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
// import { AuthProvider } from "./store/auth/AuthContext";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./react-query/queryClient";
import "wicg-inert";
import "./index.css";


// Uncomment below lines to remove clg from production build
if (process.env.NODE_ENV === 'production') {
	console.log = () => { }
	console.error = () => { }
	console.debug = () => { }
} else {
	const axe = require('@axe-core/react');
	axe(React, ReactDOM, 1000);
}





// optional configuration
const options = {
	// you can also just use 'bottom center'
	position: positions.BOTTOM_CENTER,
	timeout: 5000,
	offset: '30px',
	// you can also just use 'scale'
	transition: transitions.SCALE
}

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
	<Router>
		{/* <ErrorBoundary FallbackComponent={Fallback} onError={() => { }}> */}
		<QueryClientProvider client={queryClient}>
			<AlertProvider template={AlertTemplate} {...options}>
				<MsalProvider instance={msalInstance}>
					{/* <AuthProvider> */}
					<Route render={props =>
						<App />
					} />
					{/* </AuthProvider> */}
				</MsalProvider>
			</AlertProvider>
		</QueryClientProvider>
		{/* </ErrorBoundary> */}
	</Router>
	,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
