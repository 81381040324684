import { objectToQuery } from "../../utils/querycreator";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const mobileDevicesTableConfig = (
	params: {
		page?: number;
		size?: number;
		q?: string;
		exportCsv?: boolean;
	},
	filters: {
		device: string[];
		carrier: string[];
		country: string[];
	}
) => {
	let endpoint = "/api/Fetch/v1/MobileDeviceRecords";

	return {
		url: baseUrl + endpoint + "?" + objectToQuery(params),
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: filters,
	};
};

export const mobileDeviceFilterConfig = () => {
	let endpoint = "/api/Fetch/v1/MobileDeviceFilter";

	return {
		url: baseUrl + endpoint,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const mobileDevicesStatsConfig = (
	filters: {
		device: string[];
		carrier: string[];
		country: string[];
	}
) => {
	let endpoint = "/api/Fetch/v1/MobileDeviceCount";

	return {
		url: baseUrl + endpoint,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: filters,
	};
};