import React, { useEffect, useState } from "react";
import { HeaderLabel, Main } from "./styles";
import BreadCrumb from "../components/Layout/BreadCrumb/BreadCrumb";
import AssetInventorySideNavigaton from "../components/Layout/SideNavbar/AssetInventorySideNavigaton";
import Header from "../components/Layout/Header/Header";
import Footer from "../components/Layout/Footer/Footer";
import { useUserInfo } from "../store/auth/UserInfoContext";
import { inventory } from "../components/Dashboardv3/constants";

const AssetInventoryLayout: React.FC<{
	children: JSX.Element;
	noNavbar: boolean | null;
	noFooter: boolean | null;
	crumbs: any[];
}> = ({ children, noNavbar, noFooter, crumbs }) => {
	const [userRole, setUserRole] = useState<{
		user: boolean,
		admin: boolean,
		superAdmin: boolean,
		leader: boolean
	}>({
		user: true,
		admin: false,
		superAdmin: false,
		leader: false
	});
	const user = useUserInfo();
	useEffect(() => {
		if (user) {
			const res = user?.getUserInfo();
			if (res.user_role && res.user_role[inventory]) {
				let role = res.user_role[inventory];
				setUserRole(role)
			}

		}
	}, [user]);
	// const sidenavRef = useRef<null | any>(null);
	// const [side, setSide] = useState<any>(null)
	return (
		<div className="dds__template--productivity">
			<Header />
			<AssetInventorySideNavigaton userType={userRole} />
			<Main>
				<div style={{
					position: 'relative',
					width: '100%'
				}}>
					<HeaderLabel bkgColor={'#021757'} />
				</div>
				<div className='dds__container--compact'>
					<BreadCrumb crumbs={crumbs} color="white" />
					{children}
				</div>

			</Main >
			<Footer />
		</div >
	)
}

export default AssetInventoryLayout;
