import styled from "styled-components";
export const AssistiveText = styled.span`
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  `
export const ResponsiveWrapper = styled.div`
	.react-grid-layout {
		position: relative;
		transition: height 200ms ease;
	}
	.react-grid-item.react-grid-placeholder {
		background: var(--gray-100);
		border: 1px dashed #B6B6B6;
		/* opacity: 1; */
		transition-duration: 100ms;
		z-index: 2;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		border-radius: 24px;
	}
	.react-grid-item.react-draggable-dragging {
		transition: none;
		z-index: 3;
		will-change: transform;
	}
	.react-grid-item.cssTransforms {
		transition-property: transform;
	}
	.react-grid-item {
		transition: all 200ms ease;
		transition-property: left, top;
	}
`;