import { ReactComponent as ManageUsersIcon } from "../../../../Assets/Manage Admin.svg"
import { IconButton } from "../../../Table/TableStyles"


const ModalHeader = ({ mode, handleClose }) => {
	return (
		<div className="dds__p-3" style={{
			fontSize: 24,
			fontWeight: 600,
		}}>
			<ManageUsersIcon />
			<span className='dds__pl-3'>
				{mode === 'edit' ? "Edit" : "Create"} Admin
			</span>
			<IconButton style={{
				float: 'right'
			}}
				onClick={handleClose}>
				<i
					style={{ fontSize: "1rem", color: 'var(--gray-600)' }}
					className="dds__icon dds__icon--close-x"

				></i>
			</IconButton>
		</div>
	)
}

export default ModalHeader
