import { ResponsiveContainer } from 'recharts';
import useContainerWidth from '../../../useContainerWidth';
import DDSBar from '../../../../Common/BarGraph/DDSBar';


const MobileDevicesGraph = ({ data }) => {
    const { mobileView } = useContainerWidth();

    return (
        <ResponsiveContainer width='100%' height={mobileView ? 220 : 140}>
            <div>
                <DDSBar result={data.result.deviceCount} total={data.result.total} />
            </div>

        </ResponsiveContainer>
    )
}

export default MobileDevicesGraph