import { cloneElement, useEffect, useState } from 'react'
import { WidthProvider, Responsive } from 'react-grid-layout';
import { useDashboardContext } from '../../../store/dashboard/DashboardContext';
import AssetRequestCard from '../Cards/Asset Request Tracker Card/AssetRequestCard';
import AssetInventoryCard from '../Cards/Asset Tracker Card/AssetInventoryCard';
import EolCard from '../Cards/End of Life Devices/EolCard';
import CvlCard from '../Cards/Security Threats on Your Computer/CvlCard';
import UnauthCard from '../Cards/Unauthorized Software/UnauthCard';
import VdiCard from '../Cards/VDI Request Management/VdiCard';
import PasswordManagementCard from '../Cards/Password Management/PasswordManagementCard';
import PCVersionCard from '../Cards/PC Version/PCVersionCard';
import { req_tracker, vdi, cvl, unauth, eol, inventory, activeType, passwordReset, mobiledevices, pcversion } from '../constants';
import MobileDevicesCard from '../Cards/Mobile Devices Card/MobileDevicesCard';
const ResponsiveGridLayout = WidthProvider(Responsive);
const ResponsiveLayout = ({ layout, breakpoints, layoutChangeHandler, data, order }) => {
	const { active, searchCards } = useDashboardContext();
	const [windowWidth, setWindowWidth] = useState<number>(window.screen.width);
	useEffect(() => {
		function handleResize() {
			setWindowWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [windowWidth]);

	// Determines which card to show in which tab and order
	const generateCard = (key: string, data: any, active: activeType, favouritedCards: { [key: string]: boolean }, orgCards: { [key: string]: boolean }, actionCards: { [key: string]: boolean }, order: string[]) => {
		const isHidden = (data && data.hidden && data.hidden[key] === true) || !key.toLowerCase().includes(searchCards.toLowerCase());
		if (
			!isHidden &&
			((active === 'all') ||
				(active === 'fav' && favouritedCards[key]) ||
				(active === 'org' && orgCards[key]) ||
				(active === 'action' && actionCards[key]))
		) {
			const index = order.indexOf(key);
			console.log(index)
			const tabIndex = index < 0 ? -1 : index + 1;
			const SearchClasses = searchCards.length > 0 ? 'dds__col--2  dds__col--md-12 dds__col--lg-6 dds__col--xl-4 dds__my-2' : ''

			switch (key) {
				case req_tracker:
					return (
						<div key={key} tabIndex={tabIndex} className={SearchClasses}>
							<AssetRequestCard
								favouriteStatus={favouritedCards[key]}
								description={data.description[key]}
								tabsMetaData={data.tabs[key]}
								actionRequired={searchCards.length > 0 ? false : data.actionCards[key]}
							/>
						</div>
					);
				case cvl[0]:
					return (
						<div key={key} tabIndex={tabIndex} className={SearchClasses}>
							<CvlCard
								favouriteStatus={favouritedCards[key]}
								description={data.description[key]}
								tabsMetaData={data.tabs[key]}
								actionRequired={searchCards.length > 0 ? false : data.actionCards[key]}
							/>
						</div>
					);
				case unauth:
					return (
						<div key={key} tabIndex={tabIndex} className={SearchClasses}>
							<UnauthCard
								favouriteStatus={favouritedCards[key]}
								description={data.description[key]}
								tabsMetaData={data.tabs[key]}
								actionRequired={searchCards.length > 0 ? false : data.actionCards[key]}
							/>
						</div>
					);
				case eol:
					return (
						<div key={key} tabIndex={tabIndex} className={SearchClasses}>
							<EolCard
								favouriteStatus={favouritedCards[key]}
								description={data.description[key]}
								tabsMetaData={data.tabs[key]}
								actionRequired={searchCards.length > 0 ? false : data.actionCards[key]}
							/>
						</div>
					);
				case passwordReset:
					return (
						<div key={key} tabIndex={tabIndex} className={SearchClasses}>
							<PasswordManagementCard
								favouriteStatus={favouritedCards[key]}
								description={data.description[key]}
								tabsMetaData={data.tabs[key]}
								actionRequired={searchCards.length > 0 ? false : data.actionCards[key]}
							/>
						</div>
					);
				case vdi:
					return (
						<div key={key} tabIndex={tabIndex} className={SearchClasses}>
							<VdiCard
								favouriteStatus={favouritedCards[key]}
								description={data.description[key]}
								tabsMetaData={data.tabs[key]}
								actionRequired={searchCards.length > 0 ? false : data.actionCards[key]}
							/>
						</div>
					);
				case inventory:
					return (
						<div key={key} tabIndex={tabIndex} className={SearchClasses}>
							<AssetInventoryCard
								favouriteStatus={favouritedCards[key]}
								description={data.description[key]}
								tabsMetaData={data.tabs[key]}
								actionRequired={searchCards.length > 0 ? false : data.actionCards[key]}
							/>
						</div>
					);
				case mobiledevices:
					return (
						<div key={key} tabIndex={tabIndex} className={SearchClasses}>
							<MobileDevicesCard
								favouriteStatus={favouritedCards[key]}
								description={data.description[key]}
								tabsMetaData={data.tabs[key]}
								actionRequired={searchCards.length > 0 ? false : data.actionCards[key]}
							/>
						</div>
					)
				case pcversion:
					return (
						<div key={key} tabIndex={tabIndex} className={SearchClasses}>
							<PCVersionCard
								favouriteStatus={favouritedCards[key]}
								description={data.description[key]}
								tabsMetaData={data.tabs[key]}
								actionRequired={searchCards.length > 0 ? false : data.actionCards[key]}
							/>
						</div>
					)
				default:
					return <></>;
			}
		} else {
			return <></>;
		}
	};
	type CardObject = {
		[key: string]: JSX.Element;
	}
	const [cards, setCards] = useState<CardObject>({});
	// Regenarate cards when active tab or search field changes
	useEffect(() => {
		if (active) {
			const activeCards = {
				[req_tracker]: generateCard(req_tracker, data, active, data.favouritedCards, data.orgCards, data.actionCards, order),
				[cvl[0]]: generateCard(cvl[0], data, active, data.favouritedCards, data.orgCards, data.actionCards, order),
				[unauth]: generateCard(unauth, data, active, data.favouritedCards, data.orgCards, data.actionCards, order),
				[eol]: generateCard(eol, data, active, data.favouritedCards, data.orgCards, data.actionCards, order),
				[vdi]: generateCard(vdi, data, active, data.favouritedCards, data.orgCards, data.actionCards, order),
				[inventory]: generateCard(inventory, data, active, data.favouritedCards, data.orgCards, data.actionCards, order),
				[passwordReset]: generateCard(passwordReset, data, active, data.favouritedCards, data.orgCards, data.actionCards, order),
				[mobiledevices]: generateCard(mobiledevices, data, active, data.favouritedCards, data.orgCards, data.actionCards, order),
				[pcversion]: generateCard(pcversion, data, active, data.favouritedCards, data.orgCards, data.actionCards, order),
			}
			// loop in activeCards object and remove empty cards
			for (const key in activeCards) {
				if (activeCards[key].type !== 'div') {
					delete activeCards[key]
				}
			}
			// find minimum tab index in activeCards
			let min = 1000;
			for (const key in activeCards) {
				if (activeCards[key].props.tabIndex < min) {
					min = activeCards[key].props.tabIndex
				}
			}
			// normalize tabIndex values to start from 0 by subtracting min
			for (const key in activeCards) {
				activeCards[key] = cloneElement(activeCards[key], {
					tabIndex: activeCards[key].props.tabIndex - min
				})
			}
			setCards(
				activeCards
			)

		}
	}, [active, order, data, searchCards]);
	const getTab = (active) => {
		switch (active) {
			case 'all': return 'My View'
			case 'fav': return 'Favourite Apps'
			case 'org': return 'My Org'
			case 'action': return 'My Actions'
		}
	}
	if (searchCards.length === 0) {
		return (
			<ResponsiveGridLayout
				className="layout"
				layouts={layout}
				breakpoints={breakpoints}
				cols={{ "2xl": 3, "xl": 3, lg: 2, md: 2, sm: 2, xs: 1, xxs: 1 }}
				rowHeight={120} // Use this to change card height
				style={{
					background: "#ffffff",
				}}
				isDraggable={active === 'fav'}
				allowOverlap={false}
				draggableHandle=".handle"
				useCSSTransforms={true}
				onLayoutChange={layoutChangeHandler}
				measureBeforeMount={true}
				isBounded={true}
				compactType={windowWidth < 1024 ? "vertical" : "horizontal"}
				containerPadding={[8, 16]}
				margin={[16, 16]}
			>
				{order.map(pointer => {
					return cards[pointer]
				})}

			</ResponsiveGridLayout>
		)
	}
	else
		return (
			(
				<div className="dds__d-flex dds__mt-4 dds__flex-column">
					<div>
						<h2 className="dds__text--bold dds__text--medium">
							Search results for "{searchCards}"
						</h2>
						<p className="dds__text--small">
							{Object.keys(cards).length} results found in "{getTab(active)}"
						</p>
					</div>
					{/* Create the same using Grid layout */}
					<div className=' dds__container-fluid--compact dds__p-0 layout'>
						<div className='dds__row dds__m-0'>
							{order.map(pointer => {
								return cards[pointer]
							})}
						</div>
					</div>
				</div>
			)
		)
}

export default ResponsiveLayout