import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';

// Custom hook for fuzzy searching
function useFuzzySearch(data, searchText, columns) {
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        if (searchText === "") {
            setFilteredData(data);
            return;
        }

        const keysToSearch = columns
            .filter(col => col.searchable)
            .map(col => col.accessor);

        const options = {
            keys: keysToSearch,
            includeScore: true,
            threshold: 0.2, //Higher values mean less fuzziness.
        };

        const fuse = new Fuse(data, options);
        const result = fuse.search(searchText);
        const filteredList = result.map(item => item.item);

        setFilteredData(filteredList);
    }, [data, searchText, columns]);

    return filteredData;
}

export default useFuzzySearch;