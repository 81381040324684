import BannerLeftComponent from "../DashboardBanner/LeftComponent/BannerLeftComponent";
import BannerRightComponent from "./RightComponent/bannerRightComponent";
import { ComponentWrapper, BgWrapper } from "../DashboardBanner/Styles";

const Banner = () => {
  return (
    <ComponentWrapper>
      <BgWrapper>
        <div className="dds__container dds__container--compact ">
          <div className="dds__d-flex dds__flex-wrap dds__flex-md-nowrap dds__justify-content-between">
            <BannerLeftComponent />
            <BannerRightComponent />
          </div>
        </div>
      </BgWrapper>
    </ComponentWrapper>
  );
};

export default Banner;
