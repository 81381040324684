import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getSearchMeasuresConfig } from '../../../TelemetryAPI';
import useAxios from '../../../../../../hooks/useAxios';
import LoadingSpinner from '../../../../../Common/LoadingSpinner'; // Example path to your LoadingSpinner component

const TelemetryMeasuresBox: React.FC<{
  mode: 'new' | 'edit';
  MeasureInput: string;
  setMeasureInput: React.Dispatch<React.SetStateAction<string>>;
  initialize?: boolean;
}> = ({ mode, MeasureInput, setMeasureInput, initialize = false }) => {
  const [measureList, setMeasureList] = useState<string[]>([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isLoading: fetchingMeasure, error: fetchMeasureError, sendRequest: fetchMeasureValues } = useAxios();

  // State to manage timeout for debouncing
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  // Effect to reset measureList when MeasureInput changes
  useEffect(() => {
    setMeasureList([]);
  }, [MeasureInput]);

  // Telemetry Measures input change handler with debounce
  const onMeasureInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setMeasureInput(input);
    setIsDropdownVisible(true);
    setIsLoading(true);

    // Clear previous timeout
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set new timeout for 2 seconds
    const timeoutId = setTimeout(() => {
      fetchMeasureValues(getSearchMeasuresConfig(input), data => {
        if (data && Array.isArray(data.result)) {
          setMeasureList(data.result);
          setIsDropdownVisible(true);
        }
        setIsLoading(false);
      });
    }, 2000);

    // Save timeout ID to state
    setDebounceTimeout(timeoutId);
  };

  const selectMeasureHandler = (measure: string) => {
    setMeasureInput(measure);
    setIsDropdownVisible(false);
  };

  const selectInputTextHandler = () => {
    setMeasureInput(MeasureInput);
    setIsDropdownVisible(false);
  };
  return (
    <div className="dds__input-text__container">
      <label htmlFor="Measure-field" className="dds__label">
        {mode === 'edit' ? 'Telemetry Measure' : 'Telemetry Measure'}
      </label>
      <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
        <input
          type="text"
          className="dds__input-text"
          style={{
            width: '100%',
            borderRadius: '14px',
          }}
          placeholder="Enter Telemetry Measure"
          maxLength={100}
          name="Measure-field"
          id="Measure-field"
          required
          aria-labelledby="Measure-label"
          onChange={onMeasureInputChange}
          value={MeasureInput}
        />
        {/* Dropdown for autofill options */}
        {isDropdownVisible && (
          <div
            id={`telemetry-measure-dropdown-${uuidv4()}`}
            className="dds__dropdown__popup"
            role="presentation"
            style={{
              position: 'absolute',
              top: 'calc(100% + 4px)', // Position below the input
              left: 0,
              zIndex: 1000,
              width: '100%',
              backgroundColor: '#fff',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: '4px',
            }}
          >
            <ul className="dds__dropdown__list" role="listbox" tabIndex={-1} style={{ maxHeight: '200px', overflowY: 'auto' }}>
              {measureList.map((measure, index) => (
                <li className="dds__dropdown__item" role="none" key={index}>
                  <button
                    onClick={() => selectMeasureHandler(measure)}
                    className="dds__dropdown__item-option"
                    role="option"
                    data-selected="false"
                    data-value={measure}
                    tabIndex={-1}
                    style={{
                      padding: '8px 12px',
                      display: 'block',
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    <span className="dds__dropdown__item-label">{measure}</span>
                  </button>
                </li>
              ))}
              {/* Option to select the current input */}
              <li className="dds__dropdown__item" role="none">
                <button
                  onClick={selectInputTextHandler}
                  className="dds__dropdown__item-option"
                  role="option"
                  data-selected="false"
                  data-value={MeasureInput}
                  tabIndex={-1}
                  style={{
                    padding: '8px 12px',
                    display: 'block',
                    width: '100%',
                    textAlign: 'left',
                    backgroundColor: '#f0f0f0',
                    borderTop: '1px solid #ddd',
                  }}
                >
                  <span className="dds__dropdown__item-label">"{MeasureInput}" | New Telemetry Measure</span>
                </button>
              </li>
            </ul>
          </div>
        )}
        {/* Loading spinner */}
        {isLoading && <LoadingSpinner size="md" />}
      </div>
    </div>
  );
};

export default TelemetryMeasuresBox;

