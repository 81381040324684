import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useCustomQuery } from '../../../../../hooks/useCustomQuery'
import { queryKeys } from '../../../../../react-query/constants'
import { myAssetsConfig, orgAssetsCountConfig } from '../../../../AssetInventory/API'
import LoadingSpinner from '../../../../Common/LoadingSpinner'
import CardError from '../../CardError'
import { CardTableWrapper } from '../../../CardTable/Styles'
import useContainerWidth from '../../../useContainerWidth'
import useTrackerLeaderData from './useTrackerLeaderData'
import { useDashboardContext } from '../../../../../store/dashboard/DashboardContext'
import { inventory } from '../../../constants'

const columns = [{
	Header: "New Devices",
	accessor: "newAssetsCount",
	Cell: ({ value }) => value
}, {
	Header: "Used Devices",
	accessor: "usedAssetsCount",
	Cell: ({ value }) => value
}, {
	Header: "IT Funded",
	accessor: "itFundedAssetsCount",
	Cell: ({ value }) => value
}, {
	Header: "Business Funded",
	accessor: "buFundedAssetsCount",
	Cell: ({ value }) => value
}]

const InventoryOrgTableStyle = styled.table`
	text-align: center;
	display: table;
	width: 100%;
	tr {
		border-top: 1px solid var(--gray-300);
		border-bottom: 1px solid var(--gray-300);
	}
	td, th {
		padding: 12px;
	}
	th {
		font-weight: 400
	}
	tbody tr:last-child {
		border: none
	}
`

const Pill = ({ label, data }) => {
	return <div className='dds__d-flex dds__flex-column dds__w-50 dds__p-3'>
		<label>{label}</label>
		<span>{data}</span>
	</div>
}

const Leader: React.FC = () => {
	const { isOrg, activeTab } = useDashboardContext();
	const { isLoading, error, data, refetch } = useTrackerLeaderData(isOrg);
	useEffect(() => {
		if (activeTab.name === inventory && !isOrg && activeTab.category === 'org')
			refetch()
	}, [activeTab])


	const { mobileView } = useContainerWidth();

	if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
	if (error) return <CardError />;
	return (
		<div className='dds__mx-4'>
			<CardTableWrapper className='dds__body-2 dds__py-2'>
				<div id='org-row1' className='dds__d-flex dds__justify-content-between dds__px-4 dds__py-2' style={{
					borderBottom: '1px solid var(--gray-300)'
				}}>
					<div className='dds__d-flex'>
						{!mobileView && <i
							style={{
								fontSize: "2rem",
							}}
							className={`dds__icon icon--totalAssets dds__mr-3`}
						/>}
						<label style={{
							color: 'var(--blue-800)',
							fontWeight: 500,
						}}>Total Assets</label>
					</div>
					<span style={{
						color: 'var(--blue-800)',
						fontWeight: 500,
					}}>{data[0].allAssetsCount}</span>

				</div>
				{!mobileView && <InventoryOrgTableStyle>
					<thead className="dds__body-2">
						<tr>

							{columns.map(col => (
								<th key={col.accessor}>{col.Header}</th>
							))}
						</tr>
					</thead>
					<tbody className="dds__body-3">
						<tr>
							{columns.map(col => (
								<td data-label={col.Header}>
									{data && <col.Cell value={data[0][col.accessor]} />}
								</td>
							))}
						</tr>

					</tbody>
				</InventoryOrgTableStyle>}
				{mobileView &&
					<div id='org-row2' className='dds__d-flex dds__flex-column dds__justify-content-between dds__text-center'>
						<div className='dds__d-flex'>
							<Pill label='New Devices' data={data[0].newAssetsCount} />
							<Pill label='Used Devices' data={data[0].usedAssetsCount} />
						</div>
						<div style={{
							borderBottom: '1px solid var(--gray-300)'
						}}></div>
						<div className='dds__d-flex'>
							<Pill label='IT Funded' data={data[0].itFundedAssetsCount} />
							<Pill label='Business Funded' data={data[0].buFundedAssetsCount} />
						</div>
					</div>}
			</CardTableWrapper>
		</div>
	)
}

export default Leader