import gif from '../../../Assets/gifs/favorite_white.gif'

const DummyFavoriteCard: React.FC = () => {
	return (
		<div style={{
			width: 444,
			height: 300,
			border: '1px dashed #B6B6B6',
			borderRadius: 24,
			color: 'var(--gray-700)'
		}} className='dds__d-flex dds__justify-content-center dds__flex-column dds__align-items-center'>
			<img src={gif} alt="Awesome" style={{ width: 240 }} />
			<strong className="dds__body-2">No favourite card added</strong>
			<p className='dds__body-2'>Add your favourite card here by pressing <i className='dds__icon dds__icon--heart' style={{
				verticalAlign: 'middle'
			}}></i> icon on card</p>

		</div>
	)
}

export default DummyFavoriteCard