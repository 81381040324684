
import ExportButton from "./ExportButton";
import SearchInput from "./SearchInput";
import { BlueToolbar } from "./Styles";

const ManageUsersToolbar = ({ CreateAdminButton }) => {

	return <>
		<div className="dds__w-100">
			<BlueToolbar>
				<div
					className="dds__w-100 dds__h-100 dds__d-inline-block"
					style={{
						verticalAlign: "middle",
						position: "relative",
					}}
				>
					<div className=" dds__position-absolute"
						style={{
							top: "13%",
							left: "1%",
						}}>
						<SearchInput />
					</div>

					<div className=" dds__position-absolute"
						style={{
							top: "13%",
							right: "1%"
						}}>
						<span className="dds__mr-2">
							<CreateAdminButton />
						</span>
						{/* <ExportButton /> */}
					</div>
				</div>
			</BlueToolbar>
		</div>
	</>
}

export default ManageUsersToolbar