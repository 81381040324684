import DropdownComponent from '../../../../../Common/DropdownComponent'

const CountryDropdown = ({ handleCountryChange, regions, checkDropdown, rowIndex }) => {
	console.log("regions", regions);

	return (
		<DropdownComponent
			inputId={`country-dropdown-input${rowIndex}`}
			popupId={`country-dropdown-popup${rowIndex}`}
			onInputClick={() => checkDropdown(`country-dropdown-popup${rowIndex}`)}
			PopupList={
				<ul className="dds__dropdown__list" role="listbox" tabIndex={-1}>
					{regions?.map((e, index) => {
						if (e.selected === -1 || e.selected === rowIndex) {
							return <li className="dds__dropdown__item" role="none" key={e.country}>
								<button onClick={() => handleCountryChange(e.country, rowIndex)} className="dds__dropdown__item-option" role="option" data-selected={e.selected === rowIndex ? true : false} data-value={e.country} tabIndex={-1}>
									<span className="dds__dropdown__item-label">{e.country}</span>
								</button>
							</li>
						}
					})}
				</ul>}
			label="Country"
		/>
	)
}
export default CountryDropdown