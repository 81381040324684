import styled from "styled-components";

const Wrapper = styled.div<{}>`
	@media (max-width: 767px) {
		width: 100%;
	}
`;

const SlidesWrapper = styled.div<{}>`
	background-color: #ffffff;
	color: #85133f;
	text-align: center;
	border-radius: 24px;
`;
const ButtonWrapper = styled.div<{}>`
	background-color: #ffffff;
	color: "#00227F";
	text-align: center;
	border-radius: 24px;
`;

export { SlidesWrapper, ButtonWrapper, Wrapper };
