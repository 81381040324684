import { useEffect, useState } from 'react'
import { useRegions } from '../../ManageUsersPage/RegionContext';

const useRows = () => {
	const {regions, setRegions} = useRegions()

	const [rows, setRows] = useState<{ value: number, operation: "add" | "delete" }>({ value: 1, operation: "add" });

	const [rowsLeft, setRowsLeft] = useState<number>(160)

	// add row
	const addRow = (rowIndex: number) => {
		let flag = 0
		regions.forEach(e => {
			if (e.selected === rowIndex) {
				e.stockrooms.forEach(stockroom => {
					if (stockroom.selected === true) {
						flag = 1
					}
				})
			}
		})
		if (flag === 1)
			setRows(row => { return { value: row.value + 1, operation: "add" } })
		else
			highlightStockroomInput(rowIndex)
	};

	// highlight input box if empty and user tries to add new row
	const highlightStockroomInput = (rowIndex: number) => {
		const el = document.getElementById(`stockroom-multiselect-dropdown-input${rowIndex}`)
		el?.setAttribute("style", "border-color:red")
		setTimeout(() => {
			el?.setAttribute("style", "border-color:#7e7e7e")
		}, 800)
	}

	// delete row
	const deleteRow = (rowIndex: number) => {
		setRegions(old =>
			old.map((e, i) => {
				if (e.selected === rowIndex) {
					return {
						...old[i],
						selected: -1,
						stockrooms: old[i].stockrooms.map(e => {
							return { name: e.name, selected: false }
						})
					}
				} else if (e.selected > rowIndex) {
					return {
						...old[i],
						selected: old[i].selected - 1,
					}
				}
				return e
			})
		)
		setRows(row => {
			return { value: row.value - 1, operation: "delete" }
		})
	}
	// Keep track of countries left to hide Add button when there are no countries left to select
	useEffect(() => {
		let totalCountriesLeft = regions.length
		regions.forEach(e => {
			if (e.selected !== -1)
				totalCountriesLeft--;
		})
		setRowsLeft(totalCountriesLeft);
		console.log("regions:", regions)
	}, [regions])
	return {
		rows,
		setRows,
		addRow,
		deleteRow,
		rowsLeft
	}
}
export default useRows;
