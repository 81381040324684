import { CardContnet, columnMapping } from "./styles";
import LoadingSpinner from "../../../Common/LoadingSpinner";
import useMobileDevicesStatsData from "../../useMobileDevicesStatsData";
import { MobileDeviceFilterObject } from "../MobileDevicesOrgContext";
import { useEffect, useState } from "react";

export type ListConfiguration = {
    deviceCount: {
        smartphones: number,
        featurephones: number,
        datacards: number,
        mifi: number,
        laptop: number,
        tablet: number,
        none: number,
        unknown: number
    }
}

const Card: React.FC<{
    selected: boolean,
    count: string | number,
    filters: any[] | null,
}> = ({ selected, filters }) => {


    const initialValues = {
        device: [''],
        carrier: [''],
        country: [''],
    };
    const [apiArgValues, setApiArgValues] = useState(initialValues);

    const { isLoading: isLoading, error: isError, data: leaderData, refetch } = useMobileDevicesStatsData(apiArgValues);

    useEffect(() => {
        if (filters) {
            console.log("StasticCard Filter Things", filters)
            setApiArgValues(filters[0]);
        }
        refetch();
    }, [filters, {} as MobileDeviceFilterObject])


    // As the user can click on the card to filter elements based on the category we need to make it accessible by keyboard
    // So we need to add a tabIndex to the card and when tab is pressed we need to add a focus outline to the card
    return (
        <>
            {isLoading && !isError && (
                <div
                    className="dds__mx-auto dds__text-center"
                    style={{
                        marginTop: 150,
                    }}
                >
                    <LoadingSpinner label="Loading Filters" />
                </div>
            )}
            {isError &&
                <div
                    className="dds__mx-auto dds__text-center"
                    style={{
                        marginTop: 150,
                    }}
                >
                    <h4 className="dds_h4">Unknown error occurred while loading data filters. Please try again later.</h4>
                </div>
            }
            {leaderData && (leaderData.result === null) &&
                <div
                    className="dds__mx-auto dds__text-center"
                    style={{
                        marginTop: 30,
                    }}
                >
                    <h4 className="dds_h4">No Record Found</h4>
                </div>
            }
            {leaderData && (leaderData.result !== null) &&
                <>
                    <CardContnet
                        selected={selected}
                        disabled={false}
                        tabIndex={0}
                        aria-label={"View " + "e"}
                    >

                        <div className="card-title">
                            <span className="dds__icon dds__icon--network-connected" style={{ color: '#0C32A4' }}></span>
                            <span className="title" style={{ color: '#0C32A4' }}> Total Connections</span>
                        </div>
                        <div className="card-count" style={{ color: '#0C32A4' }}>
                            <span className="count">{leaderData.result.total}</span>
                        </div>


                        {Object.entries(leaderData.result.deviceCount).map(([key, value]) => (
                            <>
                                <div className="card-title" >
                                    <span className={columnMapping[key].iconClass} style={{ color: columnMapping[key].fontColor }} ></span>
                                    <span className="title" style={{ color: columnMapping[key].fontColor }}> {columnMapping[key].name}</span>
                                </div>
                                <div className="card-count" style={{ color: columnMapping[key].fontColor }}>
                                    <span className="count" >{value}</span>
                                </div>
                            </>
                        ))}
                    </CardContnet>
                </>
            }
        </>
    );
};

export default Card;
