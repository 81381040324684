import ReactTooltip from "react-tooltip";

const columns: any[] = [
	{
		Header: "Assigned To",
		accessor: "ntid",
		show: false,
		disableFilters: true,
		disableSortBy: false,
		// width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", color: "var(--blue-800)" }}
					data-for="ntid_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="ntid_tip" />
			</>
		),
	},
	{
		Header: "Employee ID",
		accessor: "employeeId",
		disableFilters: true,
		disableSortBy: false,
		// width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", color: "var(--blue-800)" }}
					data-for="employeeId_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="employeeId_tip" />
			</>
		),
	},
	{
		Header: "Employee email",
		accessor: "emailId",
		disableFilters: true,
		disableSortBy: false,
		// width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", color: "var(--blue-800)" }}
					data-for="emailId_tip"
					data-tip={value}
				>
					{parseInt(value) === 0 ? "" : value}
				</p>
				<ReactTooltip id="emailId_tip" />
			</>
		),
	},
	{
		Header: "Service Tag",
		accessor: "asset_number",
		disableFilters: true,
		disableSortBy: false,
		// width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{
						display: "inline",
						color: "var(--blue-800)",
						fontWeight: 500,
					}}
					data-for="asset_number_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="asset_number_tip" />
			</>
		),
	},
	{
		Header: "Product Name",
		accessor: "product_name",
		disableFilters: true,
		disableSortBy: false,
		// width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", color: "var(--blue-800)" }}
					data-for="product_name_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="product_name_tip" />
			</>
		),
	},
	{
		Header: "Asset Location",
		accessor: "asset_location",
		disableFilters: true,
		disableSortBy: false,
		// width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", color: "var(--blue-800)" }}
					data-for="asset_location_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="asset_location_tip" />
			</>
		),
	},
	{
		Header: "Asset Condition",
		accessor: "asset_condition",
		disableFilters: true,
		disableSortBy: false,
		// width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", color: "var(--blue-800)" }}
					data-for="asset_condition_tip"
					data-tip={value}
				>
					NA
				</p>
				<ReactTooltip id="asset_condition_tip" />
			</>
		),
	},
	{
		Header: "Asset Funded by",
		accessor: "fundedby",
		disableFilters: true,
		disableSortBy: false,
		// width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", color: "var(--blue-800)" }}
					data-for="asset_funded_by_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="asset_funded_by_tip" />
			</>
		),
	},
	{
		Header: "Next Funding",
		accessor: "fundingOnEol",
		disableFilters: true,
		disableSortBy: false,
		// width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", color: "var(--blue-800)" }}
					data-for="fundingOnEol_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="fundingOnEol_tip" />
			</>
		),
	},
	{
		Header: "cost_center",
		accessor: "cost_center",
		disableFilters: true,
		disableSortBy: false,
		// width: 40,
		Cell: ({ value }) => (
			<>
				<p
					style={{
						display: "inline",
						color: "var(--blue-800)",
						fontWeight: 500,
					}}
					data-for="cost_center_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="cost_center_tip" />
			</>
		),
	},
	{
		Header: "Status",
		accessor: "status",
		disableFilters: true,
		disableSortBy: false,
		// width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", color: "var(--blue-800)" }}
					data-for="status_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="status_tip" />
			</>
		),
	},
	{
		Header: "End of Life",
		accessor: "eoldate",
		disableFilters: true,
		disableSortBy: false,
		// width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", color: "var(--blue-800)" }}
					data-for="eoldate_tip"
					data-tip={value}
				>
					{value} Days
				</p>
				<ReactTooltip id="eoldate_tip" />
			</>
		),
	},
];
export default columns;
