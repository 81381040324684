import styled from "styled-components";
export const CardWrapper = styled.div`
	background-color: #f5f6f7;
	border-radius: 10px;
	padding: 20px;
	min-height: 190px;
	height: auto;
	color: #000;
`;
export const CardTitle = styled.div`
	color: #000;
	font-weight: bold;
`;

export const CardContent = styled.div`
	display: flex;
	padding: 15px 0;
	align-items: flex-end;
	.filter-select {
		margin-right: 15px;

		.dds__select__wrapper {
			width: 180px;

			.dds__select__field {
				padding: 5px;
				height: 40px;
			}
		}
		.dds__dropdown__input-wrapper {
			.dds__dropdown__input-field {
				padding: 5px 10px;
				font-size: 14px;
			}
			button {
				padding: 5px;
				font-size: 14px;
				line-height: 1;

				&.dds__dropdown__chevron {
					top: 0.4rem;
					right: 0.4rem;
				}
			}
		}
	}
	.btn-apply {
		display: flex;
		/* align-items: center; */
		height: 35px;
	}
`;