import AccordianControls from "./AccordianControls";

interface IAccordionWrapperProps {
	enableControls: boolean;
	children: any;
	totalElements: number;
	labels: {
		expandLabel: string;
		collapseLabel: string;
	};
	elementRef: React.RefObject<HTMLDivElement>;
}

const AccordianWrapper: React.FC<IAccordionWrapperProps> = ({
	enableControls,
	children,
	totalElements,
	labels,
	elementRef,
}) => {
	return (
		<div
			ref={elementRef}
			role="region"
			// id="cvl-user-accordion"
			className="dds__accordion"
			data-dds="accordion"
			data-independent="true"
			data-sr-collapse-all={`${totalElements} items collapsed`}
			data-sr-expand-all={`${totalElements} items expanded`}
			data-sr-expand-item="item expanded."
			data-sr-collapse-item="item collapsed."
		>
			{enableControls && <AccordianControls {...labels} />}
			{children}
		</div>
	);
}
export default AccordianWrapper