import { StyledTroubleshooting } from "./Styles";

const Troubleshooting = (): JSX.Element => (
	<StyledTroubleshooting>
		<h4>Troubleshooting Steps:</h4>
		<ol>
			<li>
				Check your network connection: Make sure you're connected to the
				internet and try to visit another website to confirm your connection is
				working properly.
			</li>
			<li>
				Reload the page: Sometimes, refreshing the page can help fix the issue.
				Click the refresh button on your browser or press F5 on your keyboard.
			</li>
			<li>
				Open the link in a new tab: Try opening the link in a new tab or window
				to see if the issue is with your current tab or window.
			</li>
			<li>
				Clear your browser cookies: Sometimes, clearing your browser's cookies
				and cache can help fix the issue. Go to your browser settings and look
				for an option to clear your browsing data.
			</li>
			<li>
				Contact the administrator: If the issue persists after trying the above
				steps, please contact the administrator for further assistance.
			</li>
		</ol>
	</StyledTroubleshooting>
);

export default Troubleshooting