import styled from "styled-components";

export const HeaderLabel = styled.div<{ bkgColor }>`
	background-color: ${props => props.bkgColor};
	height: 200px;
	position: absolute;
	/* right: 0; */
	width: 100%;
	z-index: 0;
`;

export const Main = styled.main`
	background-color: #ffffff;
`