const columns = [
	{
		Header: "Asset Hostname",
		accessor: "assethostname",

	},
	{
		Header: "Service Tag",
		accessor: "servicetag",

	},
	{
		Header: "Email",
		accessor: "email",

	},
	{
		Header: "Last Comment Time",
		accessor: "comment_time",

	},
	{
		Header: "Last Comment Message",
		accessor: "lastComment",

		show: false,

	},
	{
		Header: "Incident(INC)",
		accessor: "snowTicket",

		editable: false,
	},
	{
		Header: "Assigned Admin",
		accessor: "assignadmin",

		overflow: "visible",

	},
	{
		Header: "Legacy co",
		accessor: "legacyco",

	},
	{
		Header: "User Name",
		accessor: "username",

	},
	{
		Header: "isexec",
		accessor: "isexec",


	},
	{
		Header: "isexecutiveadmin",
		accessor: "isexecutiveadmin",

	},
	{
		Header: "Sab Name",
		accessor: "sabname",

	},
	{
		Header: "Device Type",
		accessor: "devicetype",

	},
	{
		Header: "OS Build",
		accessor: "osbuild",


	},
	{
		Header: "OS Version",
		accessor: "osversion",

	},

	// {
	// 	Header: "Freedisk More Than 15%", // Changed to 15%
	// 	accessor: "freedisk_more_than_10percentage",
	// 	disableFilters: true,
	// 	disableSortBy: false,
	// 	width: 110,
	// 	Cell: ({ value }) => (
	// 		<>
	// 			<p
	// 				style={{ display: "inline", wordBreak: 'break-word' }}
	// 				data-for="freedisk_more_than_10percentage_tip"
	// 				data-tip={value}
	// 			>
	// 				{value}
	// 			</p>
	// 			<ReactTooltip id="freedisk_more_than_10percentage_tip" />
	// 		</>
	// 	),
	// },
	// {
	// 	Header: "Free Disk",
	// 	accessor: "freediskpercentage",
	// 	disableFilters: true,
	// 	disableSortBy: false,
	// 	width: 80,
	// 	Cell: ({ value }) => (
	// 		<>
	// 			<p
	// 				style={{ display: "inline", wordBreak: 'break-word' }}
	// 				data-for="freediskpercentage_tip"
	// 				data-tip={value}
	// 			>
	// 				{/* {(Math.round(value*100))+"%"} */}
	// 				{value ? `${value}%` : ""}
	// 			</p>
	// 			<ReactTooltip id="freediskpercentage_tip" />
	// 		</>
	// 	),
	// },
	{
		Header: "End of Life",
		accessor: "endoflife",

	},

	{
		Header: "Product",
		accessor: "product",

	},
	{
		Header: "Solution",
		accessor: "solution",

	},
	{
		Header: "Vulnerability Due Date",
		accessor: "due_date",

	},
	{
		Header: "Document",
		accessor: "document_link",

	},
	{
		Header: "L3",
		accessor: "l3",

	},
	{
		Header: "L4",
		accessor: "l4",

	},
	{
		Header: "L5",
		accessor: "l5",

	},
	{
		Header: "L6",
		accessor: "l6",

	},
	// {
	// 	Header: "Scanner Information",
	// 	accessor: "scanner_information",
	// 	disableFilters: true,
	// 	disableSortBy: true,
	// 	// disableExport: true,
	// 	width: 80,
	// 	Cell: ({ value }) =>
	// 		value ? (
	// 			<>
	// 				<p
	// 					style={{ display: "inline" }}
	// 					data-for="scanner_information_tip"
	// 					data-tip={value}
	// 				>
	// 					{value}
	// 				</p>
	// 				<ReactTooltip id="scanner_information_tip" />
	// 			</>
	// 		) : (
	// 			<p>{value}</p>
	// 		),
	// },
	// {
	// 	Header: "created_by",
	// 	accessor: "created_by",
	// 	disableFilters: true,
	// 	disableSortBy: true,
	// 	show: false,
	// 	//width: 110,
	// 	Cell: ({value}) => <><p style={{display:'inline'}} data-for='created_by_tip' data-tip={value}>{value}</p>
	// 	<ReactTooltip id="created_by_tip"/></>
	// },
	// {
	// 	Header: "created_date",
	// 	accessor: "created_date",
	// 	disableFilters: true,
	// 	disableSortBy: true,
	// 	show: false,
	// 	//width: 110,
	// 	Cell: ({value}) => <><p style={{display:'inline'}} data-for='created_date_tip' data-tip={value}>{value}</p>
	// 	<ReactTooltip id="created_date_tip"/></>
	// },
	// {
	// 	Header: "updated_by",
	// 	accessor: "updated_by",
	// 	disableFilters: true,
	// 	disableSortBy: true,
	// 	show: false,
	// 	//width: 110,
	// 	Cell: ({value}) => <><p style={{display:'inline'}} data-for='updated_by_tip' data-tip={value}>{value}</p>
	// 	<ReactTooltip id="updated_by_tip"/></>,

	// },
	// {
	// 	Header: "updated_date",
	// 	accessor: "updated_date",
	// 	disableFilters: true,
	// 	disableSortBy: true,
	// 	show: false,
	// 	//width: 110,
	// 	Cell: ({value}) => <><p style={{display:'inline'}} data-for='updated_date_tip' data-tip={value}>{value}</p>
	// 	<ReactTooltip id="updated_date_tip"/></>
	// },
];
export default columns;