import React from "react";
import { FallbackProps } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import { APIRequestFailedError, AuthError, PageNotFoundError, UnauthorizedError } from "../../ErrorStates";
// import { useAuth } from "../../store/auth/AuthContext";
import { ButtonGroupContainer, StyledHeader, Wrapper } from "./Styles";
import Troubleshooting from "./TroubleShooting";
import { useAuth } from "../../hooks/useAuth";



export const Header = ({ title, message }) => {
	return (
		<StyledHeader>
			<h1>{title}</h1>
			<h2>{message}</h2>
		</StyledHeader>
	);
};



export const ButtonGroup = ({ loginagain = false, tryagain = false, gotohome = false, resetErrorBoundary = () => { } }) => {
	const auth = useAuth();
	const history = useHistory();

	const resetLogin = (): void => {
		sessionStorage.clear();
		auth?.login();
		resetErrorBoundary();
	};

	const signout = (): void => {
		auth?.logout();
		resetErrorBoundary();
	};


	const gotoHome = (): void => {
		history.push("/");
		resetErrorBoundary();
	};
	return <ButtonGroupContainer>
		{loginagain && <button type="button" className="dds__button dds__button--md dds__button--primary dds__m-2" onClick={resetLogin}>Login again</button>}
		{tryagain && <button type="button" className="dds__button dds__button--md dds__button--primary dds__m-2" onClick={resetErrorBoundary}>Try again</button>}
		{gotohome && <button type="button" className={`dds__button dds__button--md ${tryagain ? 'dds__button--secondary' : 'dds__button--primary'} dds__m-2`} onClick={gotoHome}>Go to Home page</button>}
		<button type="button" className="dds__button dds__button--md dds__button--primary dds__m-2" onClick={signout}>Sign Out</button>

	</ButtonGroupContainer>
}

export const Fallback: React.FC<FallbackProps> = ({
	error,
	resetErrorBoundary,
}) => {

	// console.log("reset", resetErrorBoundary)
	// Use Event listener on Keyup to auto trigger resest boundary or login

	if (error instanceof AuthError) {
		return (
			<Wrapper>
				<Header title={error.title} message={error.message} />
				<ButtonGroup loginagain resetErrorBoundary={resetErrorBoundary} />
				<Troubleshooting />
			</Wrapper>
		);
	}
	if (error instanceof UnauthorizedError) {
		return (
			<Wrapper>
				<Header title={error.title} message={error.message} />
				<ButtonGroup loginagain gotohome resetErrorBoundary={resetErrorBoundary} />
				<Troubleshooting />
			</Wrapper>
		);
	}
	if (error instanceof PageNotFoundError) {
		return (
			<Wrapper>
				<Header title={error.title} message={error.message} />
				<ButtonGroup tryagain gotohome resetErrorBoundary={resetErrorBoundary} />
				<Troubleshooting />
			</Wrapper>
		);
	} if (error instanceof APIRequestFailedError) {
		return (
			<Wrapper>
				<Header title={'Something went wrong!'} message={error.statusCode === '500' ? `500: Internal Server Error, please try again later` : `${error.statusCode} : ${error.message}`} />
				<ButtonGroup tryagain gotohome resetErrorBoundary={resetErrorBoundary} />
				<Troubleshooting />
			</Wrapper>
		)
	}

	return (
		<Wrapper>
			<Header title={'Something went wrong!'} message={error.message} />
			<ButtonGroup tryagain gotohome resetErrorBoundary={resetErrorBoundary} />
			<Troubleshooting />
		</Wrapper>
	);
};
