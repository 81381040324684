import { matchSorter } from "match-sorter";
import React from "react";
import {
	FilterValue,
	IdType,
	Row,
	useAsyncDebounce,
	UseGlobalFiltersState,
} from "react-table";
import { IconButton } from "./TableStyles";
import { ReactComponent as SearchIcon } from "../../Assets/Search.svg";

// function fuzzyTextFilterFn(
// 	rows: Array<Row<object>>,
// 	id: IdType<object>,
// 	filterValue: FilterValue
// ) {
// 	return matchSorter(rows, filterValue, {
// 		keys: [(row: Row<object>) => row.values[id]],
// 	});
// }

// // Let the table remove the filter if the string is empty
// fuzzyTextFilterFn.autoRemove = (val: any) => !val;

export var GlobalSearchFilter = ({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
}: {
	preGlobalFilteredRows: Row<object>[];
	globalFilter: any;
	setGlobalFilter: (filterValue: any) => void;
}) => {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined);
	}, 500);

	return (
		<span className="dds__input-text__wrapper dds__input-text__wrapper--button dds__input-text__container--lg"
			style={{
				// lineHeight: "2rem",
			}}>
			{/* <label htmlFor="table-search-input">Search:</label> */}
			<label htmlFor='table-search-input'>
				<span className=" dds__sr-only">Search</span>
			</label>
			<input
				id="table-search-input"
				className="dds__input-text"
				style={{
					maxWidth: 300
				}}
				value={value || ""}
				onChange={e => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={`${count} records...`}
			/>
			<button
				style={{ minWidth: 50, backgroundColor: 'white' }}
				className="dds__button dds__button--secondary dds__button--md"
				type="button"
				aria-label="Search"
			// aria-hidden="true"
			// onClick={props.searchHandler}
			// disabled={props.isFetching}
			>
				<SearchIcon
					aria-hidden="true"
					style={{
						margin: 0,
						// marginLeft: "2.4rem",
						// marginTop: `${props.searchOnly ? "0.5rem" : "0.2rem"}`,
						// width: "1.5rem",
					}}
					className="dds__icon dds__button__icon--end"
				/>
				{/* <i style={{ fontSize: '1.2rem' }} className='dds__icon dds__button__icon--end dds__icon--arrow-right'></i> */}
				{/* <i style={{ fontSize: '1.2rem' }} className='dds__icon dds__icon--app-window-search dds__button__icon--end'></i> */}
			</button>

			{/* <IconButton
				style={{
					lineHeight: 2.5,
					height: `calc(${2.28125}rem + ${0.125}rem)`,
					borderRight: ".0625rem solid grey",
					borderTop: ".0625rem solid grey",
					borderBottom: ".0625rem solid grey",
					paddingBottom: 0,
					paddingTop: 0,
					margin: 0,
					cursor: "default",
					width: "17%",
				}}
			>
				<SearchIcon />
			</IconButton> */}
		</span>
	);
}
