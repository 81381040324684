import DefaultCell from "../../Table/ReusableTableCells/DefaultCell";
import DeleteAction from "./DeleteAction";
import RoleCell from "./RoleCell";

const columns: any[] = [
	{
		Header: "Name",
		accessor: "name",
		disableFilters: true,
		disableSortBy: false,
		width: 60,
		Cell: DefaultCell,
	},
	{
		Header: "Email ID",
		accessor: "email",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		Cell: DefaultCell,
	},
	{
		Header: "NT ID",
		accessor: "ntid",
		disableFilters: true,
		disableSortBy: false,
		width: 40,
		Cell: DefaultCell,
	},
	{
		Header: "Role",
		accessor: "role",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		Cell: RoleCell,
	},
	{
		Header: "Action",
		accessor: "action",
		disableFilters: true,
		disableSortBy: true,
		width: 20,
		Cell: DeleteAction,
	},
];
export default columns;