import React, { ChangeEventHandler, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useErrorHandler } from "react-error-boundary";
import { AuthError } from "../../ErrorStates";
// import useErrorAPI from "../../hooks/use-error-api";
import useAxios from "../../hooks/useAxios";
import { useCustomMutation } from "../../hooks/useCustomQuery";
import { useUserInfo } from "../../store/auth/UserInfoContext";
import { dateFormat } from "../../utils/dateFunctions";
import LoadingSpinner from "../Common/LoadingSpinner";
import { updateRecordEditableConfig2 } from "./API/api";

// Create an editable cell renderer
const EditableCell: React.FC<{
	value: any;
	row: any;
	column: any;
	statusList: string[];
	setData: any;
	updateStatusCell: (
		index: any,
		id: any,
		value: any,
		updateData: (requestConfig: any, applyData: any) => void
	) => { isUpdateLoading: boolean; updateError: string | null };
}> = ({
	value: initialValue,
	row,
	column: { width, id },
	updateStatusCell, // This is a custom function that we supplied to our table instance
	statusList,
	setData
}) => {
		// We need to keep and update the state of the cell normally
		const [value, setValue] = useState(initialValue);
		const alert = useAlert();
		const { mutate, isLoading: isUpdateLoading } = useCustomMutation(updateRecordEditableConfig2(), {});
		const user = useUserInfo();

		const onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> =
			async e => {
				setValue(e.target.value);
				// console.log("row", row.values, e.target.value)
				mutate({
					comments: row.values.comments,
					dispatch_pickup_eta: row.values.dispatch_pickup_eta,
					productive: row.values.productive,
					request_item_number: row.values.request_item_number,
					serial_number: row.values.serial_number,
					shiporsite: row.values.shiporsite,
					snow_city: row.values.snow_city,
					snow_country: row.values.snow_country,
					status: e.target.value, // Update
					task_number: row.values.task_number,
					tracking: row.values.tracking,
					updatedby: user.getUserInfo().email,
					updatedon: dateFormat(new Date(), "yyyy-mm-dd"),
				}, {
					onSuccess: (data) => {
						console.log("success", data);
						alert.show(`Successfully Changed status to ${e.target.value}`, {
							timeout: 2000, // custom timeout just for this one alert
							type: "success",
						});
						setData(old =>
							old.map((dataRow, index) => {
								if (index === row.index) {
									console.log("updating", old[index], "to", e.target.value)
									return {
										...old[index],
										status: e.target.value,
										updatedby: user.getUserInfo().email,
										updatedon: dateFormat(new Date(), "yyyy-mm-dd"),
									}
								}
								return dataRow
							})
						)
					},
					onError: (error) => {
						alert.show(`UNKNOWN ERROR OCCURRED WHILE UPDATING STATUS`, {
							type: "error",
							timeout: 0,
						});
					}
				})
			};

		// If the initialValue is changed external, sync it up with our state
		React.useEffect(() => {
			setValue(initialValue);
		}, [initialValue]);

		// if (isUpdateLoading)
		// 	return <LoadingSpinner size='md' />

		return statusList.length > 0 ? (
			<>
				<select disabled={isUpdateLoading} style={{ width: '100%', marginRight: isUpdateLoading ? '8px' : '0' }} value={value} onChange={onChange}>
					{value === "NA" || value === "" ? (
						<option value={value} key={value} selected>
							-- Select --
						</option>
					) : null}
					{statusList.map(status =>
					// console.log(status)
					(
						<option key={status} value={status}>
							{status}
						</option>
					)
					)}
				</select>
				{isUpdateLoading && <LoadingSpinner size='sm' />}
			</>
		) : (
			value
		);
	};

export default EditableCell;