import React, { useRef, useState, useEffect } from "react";
import { AppHeader } from "./styles.js";
import { Masthead } from "@dds-uicore/all";
// import { useAuth } from "../../../store/auth/AuthContext";
import { IUserInfo } from "../../../common/modules";
import dell_horizontal_logo from "../../../Assets/dell_horizontal_logo.png";
import dell_stacked_logo from "../../../Assets/dell_stacked_logo.png";
import { useHistory } from "react-router-dom";
import { useUserInfo } from "../../../store/auth/UserInfoContext";
import { useAnalytics } from "../../../store/analytics/AnalyticsContext";
import { eventsInfo } from "../../../store/analytics/eventsInfo";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useAuth } from "../../../hooks/useAuth";

const Header: React.FC<{ enableHamMenu?: boolean }> = ({
	enableHamMenu = true,
}) => {
	const dropdownRef = useRef<any>(null);
	// const auth = useAuth();
	const { logout } = useAuth();


	const isAuthenticated = useIsAuthenticated();
	const user = useUserInfo();
	const history = useHistory();
	const [userInfo, setUserInfo] = useState<IUserInfo>({} as IUserInfo);
	const { addEventToQueue } = useAnalytics();
	useEffect(() => {
		if (user && isAuthenticated) {
			const temp = user.getUserInfo();
			setUserInfo(temp);
		}
	}, [user]);

	useEffect(() => {
		// initialize the Masthead component
		[].forEach.call(
			document.querySelectorAll('[data-toggle="dds__msthd"]'),
			element => {
				new Masthead(element);
			}
		);
	}, []);
	const menuToggle = (e: any) => {
		console.log(e.target);
		e.target = document.getElementById("toggle-button");
		let clas = e.target.classList;
		if (e.target.childNodes !== null) clas = e.target.childNodes[0].classList;
		const open = `dds__icon--close-x`;
		const clos = `dds__icon--menu-closed`;
		if (clas.contains(open)) {
			clas.add(clos);
			clas.remove(open);
			const el: any = document.querySelector("#side-nav");
			el.inert = true;
		} else {
			clas.remove(clos);
			clas.add(open);
			const el: any = document.querySelector("#side-nav");
			el.inert = false;
		}
		// console.log("ref", sidenavRef.current)
		// @ts-ignore
		document.getElementById("side-nav").SideNav.toggle();
		// sidenavRef.toggle();
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setOpenDropdown(false);
		}
	};

	useEffect(() => {
		window.addEventListener('mousedown', handleClickOutside);
		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const [openDropdown, setOpenDropdown] = useState(false);

	const toggleDropdown = (event) => {
		event.stopPropagation();
		if (!openDropdown)
			addEventToQueue(eventsInfo["user header dropdown"]());

		setOpenDropdown(prev => !prev);
	};

	return (
		<AppHeader role={"banner"}>
			<div id="nav-container" className="">
				<div className="left">
					<div
						className={`hamMenu dds__mr-4 ${!enableHamMenu && "dds__d-none"}`}
					>
						<button
							className="dds__button dds__button__icon dds__button--secondary dds__button--lg  dds__border-0 dds__p-0"
							role="button"
							aria-label="Toggle menu"
							id="toggle-button"
							onClick={menuToggle}
						>
							<i
								aria-hidden={true}
								className="dds__icon dds__icon--menu-closed"
								style={{ fontSize: "1.3rem" }}
							></i>
						</button>
					</div>
					<h1><a
						href=""
						tabIndex={0}
						className="app-logo"
						role={"button"}
						onClick={e => {
							e.preventDefault();
							history.push("/");
						}}
						aria-label={"TrackIT home"}
					>
						<img
							alt="Dell logo"
							className="horizontal-logo"
							src={dell_horizontal_logo}
							height={20}
						/>
						<img
							alt="Dell logo"
							className="stacked-logo"
							src={dell_stacked_logo}
							height={30}
						/>
					</a>
					</h1>
				</div>

				<div className="right">
					{
						isAuthenticated && (
							// <button onClick={() => alert('ger')}>test</button>
							<>
								<button
									onClick={toggleDropdown}
									type="button"
									role={"button"}
									className="dds__ml-auto dropdown dds__button"
									aria-haspopup="true"
									aria-expanded={openDropdown}
									aria-controls="dropdown-content"
									ref={dropdownRef}
								>
									<i
										aria-hidden="true"
										className="dds__icon dds__icon--user dds__button__icon--start dds__mr-2"
										style={{
											fontSize: "0.85rem",
											// color: 'white'
										}}
									></i>
									{/* <div className="dropbtn"> */}
									{userInfo.userName}
									{/* </div> */}
									{openDropdown && (
										<div
											className="dropdown-content"
											id="dropdown-content"
											style={{
												marginTop: "7.7rem",
											}}
										>
											<div className="user-name">{userInfo.userName}</div>
											<div className="job-title">{userInfo.jobTitle}</div>
										</div>
									)}

								</button>
								<button className="dds__button dds__button--md dds__button-primary" onClick={() => logout("redirect")}>
									Sign Out
								</button>
							</>
						)
					}
				</div>
			</div>
		</AppHeader>
	);
};

export default Header;
