import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Breadcrumb as BC } from "@dds/components"
import { useEffect } from "react";
import { Crumb } from "../../../models/crumb-type";

const BreadCrumbWrapper = styled.nav<{ color }>`
	padding: 16px 0 16px 0;
	/* Padding defined in  https://www.delldesignsystem.com/components/breadcrumb/ */
	.dds__breadcrumb__item {
		z-index: 1;
		a {
			color: ${props => props.color};
		}
		&::after {
			color: ${props => props.color};
		}
	}
`;

const BreadCrumb: React.FC<{ crumbs: Crumb[], color?: string }> = ({ crumbs, color = "" }) => {

	useEffect(() => {
		const element = document.getElementById("breadcrumb");
		new BC(element);
	}, [])


	if (crumbs.length === 2 && crumbs[1].path === "/callback") {
		return null;
	}
	const result =
		crumbs.length <= 1 ? null : (
			<BreadCrumbWrapper color={color} data-dds="breadcrumb" aria-label="breadcrumb" className="breadcrumb-nav" id="breadcrumb">
				{/* <div className="dds__row">
						<div className="dds__col-9 dds__col-md-10"> */}
				<ol style={{ marginBottom: 0 }} className="dds__breadcrumb">
					{crumbs.map(({ name, path }, key) => {
						if (+key === 0) {
							return (
								<li key={key} className="dds__breadcrumb__item">
									<NavLink to={path}>
										<span className="dds__sr-only">{name}</span>
										<i
											id="homeicon"
											role="icon"
											className="dds__icon dds__icon--home"
											aria-hidden="true"
										/>
									</NavLink>
								</li>
							);
						}
						return (
							<li key={key} className="dds__breadcrumb__item">
								<NavLink to={path}>{name}</NavLink>
							</li>
						);
					})}
				</ol>
				{/* </div> */}
				{/* {crumbs.length - 2 >= 0 && (
							<div className="dds__col-3 dds__col-md-2 dds__d-flex dds__flex-row dds__justify-content-end">
								<div
									style={{
										padding: "0.5rem 0",
									}}
									className="dds__breadcrumb-item"
								>
									<NavLink
										className="btn-back"
										to={crumbs[crumbs.length - 2]["path"]}
										aria-current="page"
										onClick={() => { }}
									>
										<i
											style={{
												fontSize: "0.6rem",
												fontWeight: 400,
											}}
											id=""
											role="icon"
											className="dds__icon dds__icon--chevron-left"
											aria-hidden="true"
										/>{" "}
										Back
									</NavLink>
								</div>
							</div>
						)} */}
				{/* </div> */}
			</BreadCrumbWrapper>
		);

	return result;
};

export default BreadCrumb;
