import React from 'react'

const DropdownComponent: React.FC<{
	elementRef?: any // ref can not be used - replaced with elementRef
	inputId?: string,
	popupId?: string,
	inputName?: string
	onInputClick?: any,
	PopupList: any,
	label: string,
	selection?: "single" | "multiple",
	inputDisabled?: any,
	placeholder?: string,
	showLabel?: boolean,
	inputWidth?: string
}> = ({
	elementRef,
	label,
	selection = "single",
	PopupList,
	inputId = `${label.toLowerCase()}${selection === "multiple" ? "-multiselect" : ""}-dropdown-input`,
	popupId = `${label.toLowerCase()}${selection === "multiple" ? "-multiselect" : ""}-dropdown-popup`,
	inputName = `${label.toLowerCase()}${selection === "multiple" ? "-multiselect" : ""}-dropdown-name`,
	onInputClick,
	inputDisabled,
	placeholder,
	showLabel = true,
	inputWidth = 'initial'
}) => {

		return (
			<div
				ref={elementRef}
				className="dds__dropdown" //dds__dropdown--md
				data-dds="dropdown"
				id={`${label.toLowerCase()}-dropdown${Math.random()}`}
				data-selection={selection}
				data-select-all-label="Select all"
				style={{
					width: inputWidth
				}}
			>
				<div className="dds__dropdown__input-container">
					<label id={`country-dropdown-label${Math.random()}`} style={{ display: showLabel ? 'block' : 'none' }} htmlFor={inputId}>{label}</label>
					<div
						className="dds__dropdown__input-wrapper"

						role="combobox"
						aria-haspopup="listbox"
						aria-controls={popupId}
						aria-expanded="false"
						onClick={onInputClick}
					>
						<input
							id={inputId}
							name={inputName}
							type="text"
							className="dds__dropdown__input-field"
							aria-labelledby={`${label.toLowerCase()}${selection === "multiple" ? "-multiselect" : ""}-dropdown-label ${label.toLowerCase()}${selection === "multiple" ? "-multiselect" : ""}-dropdown-helper`}
							disabled={inputDisabled}
							placeholder={placeholder}
						/>
						<div id={`${label.toLowerCase()}${selection === "multiple" ? "-multiselect" : ""}-dropdown-feedback${Math.random()}`} className="dds__invalid-feedback"></div>
					</div>
				</div>
				<div id={popupId} className="dds__dropdown__popup dds__dropdown__popup--hidden" role="presentation" tabIndex={-1}
					style={{
						maxHeight: '15rem',
						//width: 'auto',
						//minWidth: '-webkit-fill-available'

						// to make it take up space 
						// position: 'initial'

						// to make it centered
						// position: 'fixed',
						// top: '30%',
						// left: '20%',
						// maxWidth: '400px'
					}}
				>
					{PopupList}
					{/* <PopupList /> */}
				</div>
			</div>
		)
	}

export default DropdownComponent