import { activeType, cardsTheme } from "../constants";

const ButtonList: React.FC<{
	items: string[],
	active: activeType,
	cardName: string,
	closeDrawer: () => void,
	openCardDrawer: (item: string, active: activeType) => void
}> = ({ items, active, cardName, closeDrawer, openCardDrawer }) => (
	<>
		{items.map((item, index) => (
			<div key={index}>
				<button
					disabled={item === cardName}
					className="dds__button dds__button--tertiary"
					type="button"
					style={{ color: `var(--${cardsTheme[item].themeColor}-800)`, width: "100%", justifyContent: "flex-start" }}
					onClick={() => {
						closeDrawer();
						openCardDrawer(item, active);
					}}
				>
					<span className={`dds__icon ${cardsTheme[item]?.ddsIconClass} dds__button__icon--start dds__mr-2`} aria-hidden="true"></span>
					{item}
				</button>
			</div>
		))}
	</>
);

export default ButtonList;