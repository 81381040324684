import React, { useState } from 'react'
import DropdownComponent from '../../../../Common/DropdownComponent'

const CountryDropdown = ({ countryList }) => {
	const [disable, setDisable] = useState<boolean>(false);
	const setDisabled = () => {
		setDisable(true);
		setTimeout(() => {
			setDisable(false)
		}, 300)
	}
	return (
		<DropdownComponent
			selection="multiple"
			inputDisabled={disable}
			PopupList={
				<ul
					className="dds__dropdown__list"
					role="listbox"
					tabIndex={-1}
				>
					{Object.keys(countryList).length > 0 && (
						<>
							{Object.keys(countryList).map(key => (
								<li
									key={key}
									className="dds__dropdown__item"
									role="none"
								>
									<button
										className="dds__dropdown__item-option"
										role="option"
										data-value={key}
										data-selected={"false"}
										// (filterdObj.countries && filterdObj.countries.includes(key)) ? "true" : "false"
										tabIndex={-1}
										onClick={setDisabled}
										disabled={disable}
									>
										<span className="dds__dropdown__item-label">
											{key}
										</span>
									</button>
								</li>
							))}
						</>
					)}
				</ul>
			} label={"Country"} />
	)
}
export default CountryDropdown