import ReactTooltip from "react-tooltip";

export const columns = [
	{
		Header: "Status",
		accessor: "status",
		disableFilters: true,
		disableSortBy: true,
		width: 250,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="summary_item_tip"
					data-tip={value}
				>
					<b>{value}</b>
				</p>
				<ReactTooltip id="summary_item_tip" />
			</>
		),
	},
	{
		Header: "Description",
		accessor: "description",
		disableFilters: true,
		disableSortBy: true,
		// width: 110,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="summary_task_recieved_count_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="summary_task_recieved_count_tip" />
			</>
		),
	},
	{
		Header: "SW count",
		accessor: "sw_count",
		disableFilters: true,
		disableSortBy: true,
		// width: 110,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="summary_task_rejected_count_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="summary_task_rejected_count_tip" />
			</>
		),
	},
	{
		Header: "Percentage",
		accessor: "percentage",
		disableFilters: true,
		disableSortBy: true,
		// width: 110,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="summary_problem_count_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="summary_problem_count_tip" />
			</>
		),
	},
];
