import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useCallback } from 'react';
import { loginRequest } from '../authConfig';
import { IUserProfile } from '../common/modules';

export const useAuth = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticatedx = useIsAuthenticated();

  const isAuthenticated = (): boolean => {
    return isAuthenticatedx;
  }

  const isLoggingIn = (): boolean => {
    return inProgress == "login";
  }

  const login = (loginType = "redirect") => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch(e => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch(e => {
        console.log(e);
      });
    }
  }
  const logout = (logoutType = "redirect") => {
	if (logoutType === "popup") {
		instance.logoutPopup({
			postLogoutRedirectUri: "/",
			mainWindowRedirectUri: "/"
		});
	} else if (logoutType === "redirect") {
		instance.logoutRedirect({
			postLogoutRedirectUri: "/logout",
		});
	}
}

  const getAccessToken = async (): Promise<string | null> => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      });
      return response.accessToken;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getIdToken = async (): Promise<string | null> => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      });
      return response.idToken;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getProfile = useCallback((): IUserProfile => {
    return {} as IUserProfile;
  }, [getIdToken])	

  return {
    isAuthenticated,
    isLoggingIn,
    login,
	  logout,
    getAccessToken,
    getIdToken,
    getProfile
  };
};