import React from "react";
import { FallbackProps } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import { AuthError, APIRequestFailedError } from "../../ErrorStates";
import { useAuth } from "../../hooks/useAuth";

const ComponentFallback: React.FC<FallbackProps> = ({
	error,
	resetErrorBoundary,
}) => {
	const auth = useAuth();
	const history = useHistory();

	const resetLogin = (): void => {
		sessionStorage.clear();
		auth.login();
		resetErrorBoundary();
	};
	const signout = (): void => {
		auth.logout();
		resetErrorBoundary();
	};


	const gotoHome = (): void => {
		history.push("/");
		resetErrorBoundary();
	};

	if (error instanceof AuthError) {
		// Provide Login form here
		return (
			<div role="alert" style={{ textAlign: "center", marginTop: 100 }}>
				<h3>{error.title}</h3>
				<pre>{error.message}</pre>
				<button type="button" onClick={resetLogin}>Try Login again?</button>
				<button type="button" onClick={signout}>Sign Out</button>
			</div>
		);
	}

	return (
		<div role="alert" style={{ textAlign: "center", marginTop: 100 }}>
			<h3>Something went wrong:</h3>
			<pre>{error instanceof APIRequestFailedError ? `Error ${error.statusCode}: ` : ""}
				{error instanceof APIRequestFailedError && error.statusCode === '500' ? "Internal Server Error, please try again later" : error.message}</pre>
			<button type="button" className="dds__button dds__button--sm dds__button--secondary dds__m-2" onClick={resetErrorBoundary}>Try again</button>
			{/* <button className="dds__button dds__button--sm dds__button--secondary dds__m-2" onClick={gotoHome}>Go to Home page</button> */}
		</div>
	);
};

export default ComponentFallback;