// Greeting message based on user local time
export const getGreetingMessage = (name: string) => {
	const today = new Date();
	const curHr = today.getHours();

	if (curHr < 12) {
		return `Good Morning! ${name}`;
	} else if (curHr < 18) {
		return `Good Afternoon! ${name}`;
	} else {
		return `Good Evening! ${name}`;
	}
};
