import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import DDSTabs, { TabsData } from '../../../Common/Tabs/DDSTabs';
import Card from '../../Card/Card';
import { createTrigger, TriggerAction } from '../../Common/TriggerAction';
import { cardsTheme, eol } from '../../constants';
import DDSDrawer from '../../Drawer/DDSDrawer';
import { userTab } from '../../DashboardPage/useMetaAPI';
import Leader from './Leader/Leader';
import { useEOLData } from './useEOLData';
import User from './User';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import useEolOrgData from './Leader/useEolOrgData';
import { useDashboardAPIs } from '../../DashboardPage/useDashboardAPIs';
import { useState, useEffect } from 'react';

const EolCard: React.FC<{
	favouriteStatus: boolean, description: any, tabsMetaData: userTab, actionRequired: boolean
}> = ({
	favouriteStatus,
	description,
	tabsMetaData,
	actionRequired
}) => {
		const { isOrg } = useDashboardContext();
		const { isLoading: isUserLoading, data: userData, error: userError } = useEOLData(!isOrg);
		const { isLoading: isLeaderLoading, data: leaderData, error: leaderError } = useEolOrgData(isOrg);
		const { getUserRole } = useUserInfo();
		const role = getUserRole(eol);

		const { data: metaData } = useDashboardAPIs();
		const [message, setMessage] = useState("View Details")

		useEffect(() => {
			if (!isOrg) {
				if (metaData && metaData.data[eol]) {
					setMessage(metaData.data[eol]);
				}
			}
		}, [metaData, isOrg]);

		useEffect(() => {
			if (isOrg && !isLeaderLoading && !leaderError) {
				const totalDevices: number = Number.parseInt(leaderData.chartLabels[0][1]);
				if (totalDevices === 0)
					setMessage('No Devices EOL in Your Org');
				else if (totalDevices === 1)
					setMessage('1 Device EOL in Your Org');
				else
					setMessage(totalDevices + ' Devices EOL in Your Org');
			} else if (!isOrg && !isUserLoading && !userError) {
				let temp = ""
				if (userData.length > 0)
					if (userData[0].endoflifeInDays < 0)
						temp = 'EOL Passed: See Details';
					else
						temp = `EOL in ${userData[0].endoflifeInDays} days: Details`
				else if (userData.length === 0)
					temp = 'No Devices found';
				setMessage(temp);
				// Add logic to update query data with message 
			}
		}, [isUserLoading, isLeaderLoading, userData, leaderData, userError, leaderError, isOrg]);

		const tabDetails: TabsData = [{
			hidden: !tabsMetaData.user || !role.user,
			name: tabsMetaData.user,
			iconClass: "dds__icon--user",
			content: User,
			active: !isOrg,
			footerButtons: [
				{
					name: "Request a device",
					path: "https://dell.service-now.com/sp?id=sc_category",
					external: true,
					ariaLabel: "Open My IT portal to place a request for a new device"
				}
			]
		},
		{
			hidden: !tabsMetaData.leader || !role.leader,
			name: tabsMetaData.leader,
			active: isOrg,
			iconClass: "dds__icon--user-group",
			content: Leader,
			footerButtons: [
				{
					name: "Visit Dashboard",
					path: "https://ebipowerbi.dell.com/Reports/powerbi/TMXDataStr/Dell%20Device%20Ecosystem",
					external: true,
					ariaLabel: "Open " + eol + " Organization page"
				}
			]
		}
		]
		const triggerID = createTrigger(eol);

		return <Card
			themeColor={cardsTheme[eol].themeColor}
			name={eol}
			ddsIconClass={cardsTheme[eol].ddsIconClass}
			isFav={favouriteStatus}
			idName={eol}
			description={description}
			isLoading={isOrg ? isLeaderLoading : (message === "View Details" && !userError)}
			actionRequired={actionRequired}
		>
			<TriggerAction triggerID={triggerID}>
				{message}
			</TriggerAction>
			<DDSDrawer triggerID={triggerID} cardName={eol}>
				<DDSTabs tabsMetaData={tabsMetaData} idName={eol} tabsData={tabDetails} salt={eol} />
			</DDSDrawer>
		</Card>

	}

export default EolCard
