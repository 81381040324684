import { useEffect } from 'react'
import { queryKeys } from '../../../react-query/constants'
import { PageTitle } from '../../AssetInventory/Admin/styles'
import { ManageUsersContextProvider } from '../../AssetInventory/ManageUsers/ManageUsersPage/ManageUsersContext'
import { ManageUsersTable } from '../../AssetInventory/ManageUsers/ManageUsersPage/ManageUsersTable'
import { getARTAdminsList } from '../API/api'
import CreateAdminButton from './CreateAdminButton'
import columns from "./DataModel"


const ManageUsersPage = () => {
	useEffect(() => {
		document.title = "Asset Request Tracker | Manage Users"
	}, [])
	return (
		<div className="dds__container-fluid dds__px-0">
			<PageTitle>Manage Administrator</PageTitle>
			<ManageUsersContextProvider apiConfig={{ keyName: queryKeys.ARTAdminList, getAdminsConfig: getARTAdminsList }}>
				<ManageUsersTable columns={columns} CreateAdminButton={CreateAdminButton} />
			</ManageUsersContextProvider>
		</div>
	)
}

export default ManageUsersPage