import { useEffect, useState } from "react";
import { ResponsiveContainer, PieChart, Tooltip, Pie, Cell, Label, Legend } from "recharts";
import useContainerWidth from "../../../useContainerWidth";

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
	if (active) {
		return (
			<div
				style={{
					background: 'rgba(255, 122, 89, .5)',
					padding: '5px 5px 0px 5px',
					borderRadius: 5
				}}
				className="custom-tooltip">
				<label>{`${payload[0].name} : ${payload[0].value}`}</label>
			</div>
		);
	}
	return null
}


export const InventoryAdminChart: React.FC<{
	COLORS: string[],
	chartData: { name: string, value: number }[]
}> = ({ COLORS, chartData }) => {
	const { mobileView } = useContainerWidth();


	return <div className="" style={{ height: mobileView ? 190 : 120, width: mobileView ? '100%' : 400 }} >
		<ResponsiveContainer width='100%' height='100%'>
			<PieChart>
				<Tooltip content={<CustomTooltip />} />
				<Pie data={chartData} dataKey="value" outerRadius={55} innerRadius={39} cx="50%" paddingAngle={5}
					cy="50%" fill="green" >{chartData.map((entry, index) => (
						<Cell
							key={`cell-${index}`}
							fill={COLORS[index % COLORS.length]}
						/>
					))}
					<Label fontSize={11} style={{ transform: 'translateY(-10px)' }} value="Total Assets" position="center" ></Label>
					{/* <Label fontSize={13} style={{transform: 'translateY(0px)'}} value="(In Stock)" position="center" ></Label> */}
					<Label fontSize={11} style={{ transform: 'translateY(+15px)' }} value={chartData[0].value + chartData[1].value} position="center" ></Label>
				</Pie>
				<Legend align={mobileView ? 'center' : 'right'} verticalAlign={mobileView ? 'bottom' : 'middle'} layout="vertical" style={{
					marginLeft: 120
				}} className='dds__ml-5' />
				{/* layout="vertical" align="right" verticalAlign="middle" color="black"  Add these props to Legend for left vertical alignment*/}
			</PieChart>
		</ResponsiveContainer>
	</div>

}