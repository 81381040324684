interface IAccordionBodyWrapperProps {
	contentId: string;
	triggerId: string;
	children: any;
}

const AccordianBodyWrapper: React.FC<IAccordionBodyWrapperProps> = ({
	contentId,
	triggerId,
	children,
}) => {
	return (
		<div
			id={contentId}
			className="dds__accordion__content"
			role="region"
			aria-labelledby={triggerId}
		>
			<div className="dds__accordion__body">{children}</div>
		</div>
	);
};
export default AccordianBodyWrapper;