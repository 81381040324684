/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */

export const objectToQuery = (queryObject: object = {}): string => {
	const params = new URLSearchParams();
	const entries = Object.entries(queryObject);
	
	for (const [key, value] of entries) {
		if (value !== "" && value !== null)
			params.append(key,value)
	}

	return params.toString();
}