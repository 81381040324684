/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from "react";
import { Row } from "react-table";
import styled from "styled-components";
import { SubmitHandler, useForm } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import { useAlert } from "react-alert";
import { useErrorHandler } from "react-error-boundary";
import { APIRequestFailedError, AuthError } from "../../../ErrorStates";
import { EditablexpandCell } from "./Expand";
// import useErrorAPI from "../../../hooks/use-error-api";
import useAxios from "../../../hooks/useAxios";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { useCustomMutation } from "../../../hooks/useCustomQuery";
import { updateRecordEditableConfig2 } from "../API/api";
import { useUserInfo } from "../../../store/auth/UserInfoContext";
import { dateFormat } from "../../../utils/dateFunctions";

const OverflowWrap = styled.div`
	.overflowWrap {
		overflow-wrap: normal;
	}
`;

export const ShippingDetails: React.FC<{
	data: EditablexpandCell[];
	row: Row<object>;
	setData: any;
	updatedon: string;
	updatedby: string;
	allowEdit?: boolean;
}> = ({
	data,
	row,
	setData,
	updatedon,
	updatedby,
	allowEdit = false,
}) => {
		const [edit, setEdit] = useState(false);
		const alert = useAlert();

		// const { index } = row;
		const [initialValue, setInitialValue] = useState<any>(); // initial Value
		// const [updatedData, setUpdatedData] = useState(data);
		// const [updateInfo, setupdateInfo] = useState("updatedon: "+updatedon+"\n"+"updatedby: "+updatedby)
		// const handleError = useErrorHandler();
		// const { sendErrorLog } = useErrorAPI();

		const { mutate, isLoading } = useCustomMutation(updateRecordEditableConfig2(), {});
		const user = useUserInfo();
		type Inputs = any;

		const {
			register,
			handleSubmit,
			watch,
			formState: { errors },
			reset,
		} = useForm<Inputs>();

		// const {id} = column
		useEffect(() => {
			// Store initial Value here
			const temp: any = {};
			data.map(cell => {
				temp[`${cell.id}`] = cell.value;
			});
			setInitialValue(temp);
		}, [data]);

		// useEffect(() => {
		// 	if (error) {
		// 		// revert changes to default
		// 		reset(initialValue);

		// 		if (error instanceof AuthError) handleError(error);
		// 		else if (error instanceof APIRequestFailedError) {
		// 			if (error.statusCode === "203")
		// 				alert.show(`Data not updated - Serial Number already exists`, {
		// 					type: "error",
		// 				});
		// 		}
		// 		else {
		// 			alert.show(`Error occurred - Data not updated \n${error}`, {
		// 				type: "error",
		// 			});
		// 			sendErrorLog(error, "Data update Error");
		// 		}
		// 	}
		// }, [alert, error, handleError, initialValue, reset, sendErrorLog]);

		const editHandler = () => {
			setEdit(true);
		};
		const cancelHandler = () => {
			setEdit(false);
		};

		const saveHandler: SubmitHandler<Inputs> = (formData: any) => {
			const mergedData: any = {};
			const email: string = user.getUserInfo().email;
			const date: string = dateFormat(new Date(), "yyyy-mm-dd")
			row.allCells.map(cell => {
				if (cell.column.id in formData) {
					mergedData[cell.column.id] = formData[cell.column.id];
					// alert(cell.column.id);
				} else if (cell.column.id !== "expander")
					mergedData[cell.column.id] = cell.value;
			});
			mergedData['updatedby'] = email
			mergedData['updatedon'] = date

			mutate(mergedData, {
				onSuccess: (data) => {
					console.log("success", data);
					alert.show(`Successfully updated Shipping details for ${mergedData.task_number}`, {
						timeout: 2000, // custom timeout just for this one alert
						type: "success",
					});
					console.log("merged Data", mergedData)
					setData(old =>
						old.map((dataRow, index) => {
							if (index === row.index) {
								console.log("updating", old[index], "to", mergedData)
								return mergedData
							}
							return dataRow
						})
					)
				},
				onError: (error: any) => {
					console.log("error", error)
					reset(initialValue);
					setEdit(true)
					if (error.statusCode === "203")
						alert.show(`Data not updated - Serial Number already exists`, {
							type: "error",
							timeout: 0
						});
					else
						alert.show(`Unknown Error Occurred while updating shipping details`, {
							type: "error",
							timeout: 0,
						});
				}
			})

			// updateShippingDetails(index, mergedData, updateData); // here merged data represent a complete row

			setEdit(false);
		};
		// useEffect(() => {
		// 	if (error) setEdit(true);
		// }, [error]);

		// useEffect(() => {
		// 	setupdateInfo("updatedon: "+updatedon+"\n"+"updatedby: "+updatedby);
		// }, [updatedby,updatedon])

		return (
			<div>
				<div style={{
					marginBottom: 8
				}}>
					<h4 style={{ display: "inline" }} className="dds__font-weight-bold dds__mr-2">
						Shipping Details
					</h4>
					{/* {updateInfo} */}
					{/* <i className="dds__icon dds__icon--alert-info-cir dds__tooltip-icon" data-toggle="dds__tooltip"  aria-label="information"></button> */}
					<p
						data-for="shipping_details_header_tip"
						style={{ display: "inline" }}
						data-multiline
						data-tip={`Updated On: ${updatedon}<br/>Updated By: ${updatedby}`}
					>
						<i style={{ color: 'var(--blue-600)' }} className="dds__icon dds__icon--alert-info-cir" />
					</p>
					<ReactTooltip id="shipping_details_header_tip" />
				</div>
				<form
					onSubmit={handleSubmit(saveHandler)}
					className="dds__container-fluid"
				>
					{data.map(cell => (
						<OverflowWrap>
							<div className="dds__row">
								<div className="dds__col-1">
									<label htmlFor="">{cell.Header}</label>
								</div>
								<span style={{}} className="overflowWrap dds__col-1 dds__input-text__container--sm">
									{edit &&
										(cell.editable ? (
											cell.editable_type === "options" ? (
												cell.options_data && cell.options_data.length > 0 ? (
													<select
														{...register(cell.id)}
														defaultValue={cell.value}
														className="dds__input-text  dds__mb-1"
													>
														{cell.value === "NA" || cell.value === "" ? (
															<option
																value={cell.value}
																key={cell.value}
																selected
															>
																--Select--
															</option>
														) : null}
														{cell.options_data
															? cell.options_data.map(option => (
																<option value={option} key={option}>
																	{option}
																</option>
															))
															: null}
													</select>
												) : (
													<>
														{cell.value}
														<p
															data-for={`${cell.Header}_tip`}
															style={{ display: "inline" }}
															data-multiline
															data-tip="Please contact admin. No options found."
														>
															<i
																style={{ color: "#FFCC00" }}
																className="dds__icon dds__icon--alert-info-cir"
															/>
														</p>
														<ReactTooltip id={`${cell.Header}_tip`} />
													</>
												)
											) : cell.editable_type === "text" ? (
												<input
													{...register(cell.id)}
													className="dds__input-text dds__mb-1"
													type={cell.editable_type}
													defaultValue={cell.value}
												/>
											) : (
												<input
													{...register(cell.id)}
													className="dds__input-text dds__mb-1"
													type={cell.editable_type}
													defaultValue={cell.value}
												/>
											)
										) : (
											cell.value
										))}
									{!edit && cell.value}
								</span>
							</div>
						</OverflowWrap>
					))}
					{allowEdit && (
						<div className="dds__row">
							<div className="dds__col-1">
								{!edit && !isLoading && (
									<>
										<button
											type="button"
											className="dds__button dds__button--sm dds__button--primary dds__m-1"
											onClick={editHandler}
										>
											Edit
										</button>
										<button
											type="button"
											hidden
											className="dds__button dds__button--sm dds__button--primary dds__m-1"
											onClick={editHandler}
											disabled
										>
											Notify Manager
										</button>
									</>
								)}
								{edit && !isLoading && (
									<button
										type="button"
										className="dds__button dds__button--sm dds__button--secondary dds__m-1"
										onClick={cancelHandler}
									>
										Cancel
									</button>
								)}
							</div>
							<div className="dds__col-1">
								{edit && !isLoading && (
									<button
										type="submit"
										className="dds__button dds__button--sm dds__button--primary dds__m-1"
									>
										Save
									</button>
								)}
								{isLoading && <LoadingSpinner size='md' />}
							</div>
						</div>
					)}
				</form>
			</div>
		);
	};
