import { useEffect, useState } from "react";
import { queryKeys } from "../../../react-query/constants";
import { useQueryClient } from "@tanstack/react-query";
import { activeType, breakpointsInfo } from "../constants";
import { useDashboardAPIs } from "./useDashboardAPIs";
import { useAnalytics } from "../../../store/analytics/AnalyticsContext";
import { eventsInfo } from "../../../store/analytics/eventsInfo";
import { useDashboardContext } from "../../../store/dashboard/DashboardContext";
import { createTrigger } from "../Common/TriggerAction";

const useResponsiveDragLayout = () => {
	const [layout, setLayout] = useState({});
	const queryClient = useQueryClient();
	const { isMetaDataLoading, dataError, data, rearrangeFav, rearrangeAll } =
		useDashboardAPIs();

	const { active, setActive, setSearchCards } = useDashboardContext();
	const { addEventToQueue } = useAnalytics();

	const openCardDrawer = (key: string, activeSection: activeType = "action") => {
        // Get the button by its id
        // Check if the button exists and then click it
        if (active === activeSection) {
			setSearchCards("");
			setTimeout(() => {
                let buttonToClick = document.getElementById(createTrigger(key));
                buttonToClick?.click();
            }, 500); // as 500ms is the time it takes to close the drawer with animation
        } else {
            // switch active to action and click
            handleActiveChange(activeSection);
            setTimeout(() => {
                let buttonToClick = document.getElementById(createTrigger(key));
                buttonToClick?.click();
            }, 500); // as 500ms is the time it takes to close the drawer with animation

        }
    }

	// TODO: Add Delllytics Events for new states
	const handleActiveChange = (newActive: activeType) => {
		if (newActive === "all") addEventToQueue(eventsInfo.viewAllApps());
		else if (newActive === "fav")
			addEventToQueue(eventsInfo.viewFavouriteApps());
		else if (newActive === "action")
			addEventToQueue(eventsInfo.viewMyActionsApps());
		else if (newActive === "org") addEventToQueue(eventsInfo.viewMyOrgApps());
		setActive(newActive);
	};
	// Change layout when active changes
	useEffect(() => {
		if (data && active !== undefined) {
			// Update active in metadata
			queryClient.setQueryData([queryKeys.metaData], (prevData: any) => {
				return {
					...prevData,
					result: {
						...prevData.result,
						active: active,
					},
				};
			});
			createLayoutFromActive(data, active);
		}
	}, [active]);

	useEffect(() => {
		// set favourite layout if user has favourited cards
		if (!isMetaDataLoading) {
			// check type of data.active is of type activeType
			if (data.active) {
				setActive(data.active);
			} else if (data.totalFavourite > 0) {
				setActive("fav");
			} else {
				setActive("all");
			}
		}
	}, [isMetaDataLoading]);

	// Common function to generate valid coordinates for layout
	const generateValidCoordinates = (
		totalCards: number,
		maxCardsInRow: number
	) => {
		let y = 0;
		const validCoordinates: { x: number; y: number; occupied: boolean }[] = [];
		console.log("check total cards", totalCards, maxCardsInRow);
		while (totalCards > 0) {
			for (let i = 0; i < maxCardsInRow && totalCards > 0; i++) {
				validCoordinates.push({ x: i, y: y, occupied: false });
				totalCards--;
			}
			y++;
		}

		return validCoordinates;
	};

	const createLayoutFromActive = (data: any, active: string) => {
		if (active === "fav") createLayout(data.orderFavouritedCards, true);
		else if (active === "all") createLayout(data.order, true);
		else if (active === "org") createLayout(data.orderOrgCards, true);
		else createLayout(data.orderUserActions, true);
		// return layout;
	};

	// If there is any layout related change inside the responsive container
	const layoutChangeHandler = (currentLayout, allLayout) => {
		// Check if new layout is valid
		console.log("width here");
		// Window width - can't use useContainerWidth as layout class is not defined before this function call
		const containerWidth =
			document.getElementsByClassName("layout")[0]!.clientWidth;
		let windowSize = "xxs";
		let maxCardsInRow = 1;
		console.log("container width", containerWidth);

		// Find breakpoint for corresponding width
		Object.entries(breakpointsInfo).forEach(([key, details]) => {
			if (containerWidth >= details.min && containerWidth < details.max) {
				windowSize = key;
				maxCardsInRow = details.maxCardsInRow;
			}
		});

		// Generate valid coordinates
		console.log("Total cards: ", data.total);
		console.log("Max cards in a row: ", maxCardsInRow);
		let totalCards = 0;
		if (active === "all") totalCards = data.total;
		else if (active === "fav") totalCards = data.totalFavourite;
		else if (active === "org") totalCards = data.totalOrgCards;
		else totalCards = data.totalUserActions;
		let validCoordinates: { x: number; y: number; occupied: boolean }[] =
			generateValidCoordinates(totalCards, maxCardsInRow);

		// if a card belongs to an invalid coordinate, swap with empty valid coordinate
		let invalidCardIndex: number | null = null;
		for (let i = 0; i < currentLayout.length; i++) {
			let x = currentLayout[i].x;
			let y = currentLayout[i].y;
			let valid = false;
			for (let j = 0; j < validCoordinates.length; j++) {
				if (validCoordinates[j].x === x && validCoordinates[j].y === y) {
					validCoordinates[j].occupied = true;
					valid = true;
				}
				if (valid) break;
			}
			if (!valid) {
				invalidCardIndex = i;
			}
		}
		console.log(
			"Valid coordinates with occupancy",
			JSON.stringify(validCoordinates)
		);
		// Find unoccupied correct coordinate & swap the invalid card
		if (invalidCardIndex !== null) {
			console.log("Invalid Card: ", currentLayout[invalidCardIndex]);
			for (let i = 0; i < validCoordinates.length; i++) {
				if (!validCoordinates[i].occupied) {
					validCoordinates[i].occupied = true;
					console.log(currentLayout);
					currentLayout[invalidCardIndex] = {
						...currentLayout[invalidCardIndex],
						x: validCoordinates[i].x,
						y: validCoordinates[i].y,
					};
					console.log(currentLayout);

					break;
				}
			}
		} else {
			console.log("No invalid card found");
		}

		// currentLayout contains correct layout for windowSize
		// Find order of cards, recreate layout for different windowSizes

		allLayout[windowSize] = currentLayout;
		// setLayout(allLayout);

		// Change the layout order for other screen sizes as well
		let order: string[] = [];
		for (let i = 0; i < validCoordinates.length; i++) {
			for (let j = 0; j < currentLayout.length; j++) {
				if (
					validCoordinates[i].x === currentLayout[j].x &&
					validCoordinates[i].y === currentLayout[j].y
				) {
					order.push(currentLayout[j].i);
				}
			}
		}

		console.log("order: ", order);

		createLayout(order, false);

		// setLayout(oldLayout)
		console.log(currentLayout);
		console.log("allLayout", allLayout);
	};

	const createLayout = (order: string[], init: boolean): void => {
		let newLayout: {
			[breakpoint: string]: {
				i: string;
				x: number;
				y: number;
				w: number;
				h: number;
				static: boolean;
			}[];
		} = {};
		let validCoordinates: {
			[breakpoint: string]: {
				x: number;
				y: number;
				occupied: boolean;
			}[];
		} = {};
		console.log("New order", order);
		Object.entries(breakpointsInfo).forEach(([breakpoint, details]) => {
			let totalCards = 0;
			if (active === "all") totalCards = data.total;
			else if (active === "fav") totalCards = data.totalFavourite;
			else if (active === "org") totalCards = data.totalOrgCards;
			else totalCards = data.totalUserActions;
			let maxCardsInRow = details.maxCardsInRow;
			validCoordinates[breakpoint] = generateValidCoordinates(
				totalCards,
				maxCardsInRow
			);
		});
		console.log("check", validCoordinates);
		Object.entries(validCoordinates).forEach(([breakpoint, coordinates]) => {
			newLayout[breakpoint] = [];
			order.forEach((i, index) => {
				newLayout[breakpoint].push({
					i: i,
					x: coordinates[index].x,
					y: coordinates[index].y,
					w: 1,
					h: 1,
					static: false,
				});
			});
		});
		console.log("new Layout", newLayout);
		setLayout(newLayout);

		if (!init) {
			// Update Cache
			queryClient.setQueryData([queryKeys.metaData], (prevData: any) => {
				console.log("Updating order: ", order);
				console.log(" order active", active);
				if (active === "fav") {
					console.log("New fav order:", order);
					console.log("Old fav order:", data.orderFavouritedCards);
					let change = false;
					if (order.length !== data.orderFavouritedCards.length) change = true;
					if (change === false) {
						for (let i = 0; i < order.length; i++) {
							if (order[i] !== data.orderFavouritedCards[i] || change) {
								console.log(
									"mismatch fav",
									order[i],
									data.orderFavouritedCards[i]
								);
								rearrangeFav({
									cards: order,
								});
								change = true;
								break;
							}
							if (change) break;
						}
					}

					if (change)
						return {
							...prevData,
							result: {
								...prevData.result,
								orderFavouritedCards: order,
							},
						};
				} else if (active === "all") {
					console.log("New order:", order);
					console.log("Old order:", data.order);
					let change = false;
					if (order.length !== data.order.length) change = true;
					if (change === false) {
						for (let i = 0; i < order.length; i++) {
							if (order[i] !== data.order[i]) {
								console.log("mismatch", order[i], data.order[i]);
								// rearrangeAll({
								// 	cards: order,
								// });
								change = true;
								break;
							}
							if (change) break;
						}
					}
					if (change) {
						// Implement Tab Index
						console.log(order);
						return {
							...prevData,
							result: {
								...prevData.result,
								order: order,
							},
						};
					}
				}
			});
		}
	};
	return {
		handleActiveChange,
		layoutChangeHandler,
		layout,
		openCardDrawer,
	};
};

export default useResponsiveDragLayout;
