import React, {
	MouseEventHandler,
	useEffect,
	useState,
} from "react";
import * as XLSX from "xlsx";
import { useAlert } from "react-alert";
import { useErrorHandler } from "react-error-boundary";
import Modal from "../Common/Modal/Modal";
import { addMultipleVulnerabilityRecordsConfig } from "./API";
// import { useAuth } from "../../store/auth/AuthContext";
import { AuthError, LOST_TOKEN } from "../../ErrorStates";
import ProgressBar from "../Common/ProgressBar/ProgressBar";
import { fetchFunction, useCustomQuery } from "../../hooks/useCustomQuery";
import { useQueries } from "@tanstack/react-query";
import { queryKeys } from "../../react-query/constants";
import LoadingSpinner from "../Common/LoadingSpinner";
import { useAuth } from "../../hooks/useAuth";

//TODO: AccessTOKEN not there  - as the component is not being used - msal fix not applied yet
const UploadModal: React.FC<{ onClose: () => void; onSuccess: () => void }> =
	({ onClose, onSuccess }) => {
		const [selectedFile, setSelectedFile] = useState<File>();
		const [error, setError] = useState<null | string>(null);
		const [isProcessing, setIsProcessing] = useState(false);
		const [progress, setProgress] = useState(0);
		const [uploadError, setUploadError] = useState<boolean>(false);
		const auth = useAuth();
		const alert = useAlert();
		const handleError = useErrorHandler();
		const [successfullyUploaded, setSuccessfullyUploaded] =
			useState<boolean>(false);
		useEffect(() => {
			if (uploadError) {
				setError("Unknown error occurred in the middle of upload, please restart upload");
				alert.show(`Unknown error occurred in the middle of upload, please restart upload`, {
					timeout: 2000, // custom timeout just for this one alert
					type: "error",
				});
			}
		}, [alert, uploadError]);

		const onFileChange = (e): void => {
			const file = e.target.files[0];
			setSuccessfullyUploaded(false);
			if (file) {
				const fileExtension = file.name
					.split(".")
				[file.name.split(".").length - 1].toLowerCase();
				if (fileExtension !== "xlsx" && fileExtension !== "xlsb") {
					setError(
						"File Extension not supported. Please upload file with xlsx/xlsb extension."
					);
					setSelectedFile(undefined);
				} else if (file.size > 70 * 1024 * 1024) {
					setError(
						"File size should be less 70MB"
					);
					setSelectedFile(undefined);
				} else {
					setSelectedFile(e.target.files[0]);
					setError(null);
				}
			} else setSelectedFile(undefined);
		};

		const transformData = (file: any): Promise<{ records: any[], solutions: any, updateDate: any }> => new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e: any) => {
				const wb = XLSX.read(e.target.result, {
					type: "binary",
					cellDates: true,
					dateNF: "dd-mm-yyyy",
				});
				const ws = wb.Sheets["All Device"];
				const ws2 = wb.Sheets.Document;
				const ws3 = wb.Sheets["Update Date"];

				const parsedData = XLSX.utils.sheet_to_json(ws, { raw: false });
				if (parsedData.length === 0) {
					reject(new Error("File is either too large or there are no records present.\n <5,00,000 records supported for .xlsx files\n <3,00,000 records supported for .xlsb files"));
					return
				}

				const parsedData2 = XLSX.utils.sheet_to_json(ws2, { raw: false });

				const parsedData3 = XLSX.utils.sheet_to_json(ws3, { raw: false });
				// console.log({ "records": parsedData })
				resolve({
					records: parsedData,
					solutions: parsedData2,
					updateDate: parsedData3[0],
				});
			};

			reader.onerror = function (event) {
				console.error(new Error(`File could not be read! Code ${event}`));
				setError("Unknown error occurred while parsing the file.");
				setIsProcessing(false);
				reject(new Error("File could not be read!"));
			};

			reader.readAsBinaryString(file);
		});

		const [fileData, setFileData] = useState<{
			data: {
				records: any[],
				updateDate: any,
				solutions: any,
			},
			totalChunks: number,
			chunkSize: number
		}>();
		const firstChunkSize = 100;
		const lastChunkSize = 100;
		const accessToken = "";//TODO: auth?.getAccessToken();

		const [firstChunkSent, setFirstChunkSent] = useState(false)

		let startingIndex = 0;
		let endIndex = firstChunkSize;

		const { isFetching: firstChunkLoading, isError: firstChunkError, isPaused: firstChunkPaused } = useCustomQuery([queryKeys.cvlchunk, 0],
			addMultipleVulnerabilityRecordsConfig(accessToken ? accessToken : "", fileData ? {
				...fileData.data,
				records: fileData.data.records.slice(startingIndex, Math.min(endIndex, fileData.data.records.length))
			} : {}, fileData ? fileData.totalChunks > 1 : false, 0, 0),
			{
				enabled: !!fileData && fileData.totalChunks > 0,
				staleTime: 0,
				cacheTime: 0,
				refetchOnMount: false,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				onSuccess: () => {
					console.log("success first")
					setFirstChunkSent(true);
					if (fileData!.totalChunks === 1) {
						onSuccess();
						setSuccessfullyUploaded(true);
						setProgress(100);
					} else {
						setProgress(5);
					}
				}
			}
		)



		const midChunks = useQueries({
			queries: fileData && fileData.totalChunks > 2 ? [...Array(fileData.totalChunks - 2)].map((e, index) => {

				if (index === 0) {
					startingIndex = firstChunkSize;
				}
				else {
					startingIndex = endIndex;
				}
				endIndex = Math.min(startingIndex + fileData.chunkSize, fileData.data.records.length - lastChunkSize)

				console.log("data start", startingIndex, "data endIndex", endIndex);
				let slicedRecords = fileData.data.records.slice(startingIndex, endIndex);
				console.log("data records", slicedRecords)

				return {
					queryKey: [queryKeys.cvlchunk, index + 1],
					queryFn: () =>
						fetchFunction(addMultipleVulnerabilityRecordsConfig(
							accessToken ? accessToken : "",
							{
								...fileData.data,
								records: slicedRecords
							}, true, 1, index + 1),
							{
								Authorization: `${accessToken!.replace(/\n|\r/g, '')}`,
								"Content-Type": "application/json",
							},
							auth
						)
					,
					enabled: firstChunkSent,
					staleTime: 0,
					cacheTime: 0,
					refetchOnMount: false,
					refetchOnWindowFocus: false,
					refetchOnReconnect: false,
					onSuccess: () => {
						console.log("mid")
					},
				}
			}) : []
		})

		const [midChunksSent, setMidChunksSent] = useState(false);
		const [midChunksLoading, setMidChunksLoading] = useState(false);
		const [midChunksError, setMidChunksError] = useState(false);
		const [midChunksPaused, setMidChunksPaused] = useState(false)
		useEffect(() => {
			console.log(midChunks)
			let isSuccess = false;
			let isLoading = false;
			let isError = false;
			let isPaused = false;
			let progress = 0;
			let totalSuccess = 0;
			midChunks.forEach((chunk, i) => {
				if (chunk.isSuccess) totalSuccess++;
				else isSuccess = false;
				isLoading = chunk.isLoading || isLoading; // any loading
				isError = chunk.isError || isError; // any error
				isPaused = chunk.isPaused || isPaused;
			});
			setMidChunksLoading(isLoading);
			if (fileData && fileData.totalChunks === 2)
				isSuccess = true;
			if (fileData && totalSuccess === midChunks.length)
				isSuccess = true;
			setMidChunksSent(isSuccess);
			setMidChunksError(isError);
			if (totalSuccess > 0) {
				progress = totalSuccess * 100 / midChunks.length;
				console.log("progress", progress)
				setProgress(progress)
			}

		}, [midChunks, fileData])




		const { isFetching: lastChunkLoading, isError: lastChunkError, isPaused: lastChunkPaused } = useCustomQuery([queryKeys.cvlchunk, fileData?.totalChunks ? fileData?.totalChunks - 1 : null],
			addMultipleVulnerabilityRecordsConfig(accessToken ? accessToken : "", fileData ? {
				...fileData.data,
				records: fileData.data.records.slice(endIndex, fileData.data.records.length) // (fileData.totalChunks - 1) * chunkSize
			} : {}, true, 2, fileData ? fileData.totalChunks - 1 : 10000000),
			{
				enabled: !!fileData && fileData.totalChunks > 1 && firstChunkSent && midChunksSent,
				staleTime: 0,
				cacheTime: 0,
				refetchOnMount: false,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				onSuccess: () => {
					console.log("success last")
					onSuccess();
					setSuccessfullyUploaded(true);
				}
			}
		)

		const [isLoading, setIsLoading] = useState(false);
		useEffect(() => {
			console.log(firstChunkLoading, midChunksLoading, lastChunkLoading)
			setIsLoading(firstChunkLoading || midChunksLoading || lastChunkLoading)

		}, [firstChunkLoading, midChunksLoading, lastChunkLoading])
		useEffect(() => {
			setUploadError(lastChunkError || midChunksError || firstChunkError)
		}, [lastChunkError, firstChunkError, midChunksError])

		const [isPaused, setIsPaused] = useState(false);
		useEffect(() => {
			setIsPaused(firstChunkPaused || midChunksPaused || lastChunkPaused)
		}, [firstChunkPaused, midChunksPaused, lastChunkPaused])
		const uploadHandler: MouseEventHandler = async () => {
			setIsProcessing(true);
			setFirstChunkSent(false);
			setFileData(undefined);
			transformData(selectedFile).then(async (data: { records: any[], solutions: any, updateDate: any }) => {
				setIsProcessing(false);
				if (accessToken) {
					let totalChunks = 1;
					if (data.records.length > firstChunkSize)
						totalChunks += 1;
					let chunkSize = Math.min(60000, Math.ceil((data.records.length - firstChunkSize - lastChunkSize) / 6));
					if (data.records.length > firstChunkSize + lastChunkSize)
						totalChunks += Math.ceil((data.records.length - firstChunkSize - lastChunkSize) / chunkSize);
					console.log("totalChunks", totalChunks)
					setFileData({
						totalChunks: totalChunks,
						chunkSize: chunkSize,
						data: data
					})
					console.log("uploading total ", totalChunks > 1, totalChunks)
				} else handleError(new AuthError(LOST_TOKEN));
			}).catch(err => {
				setIsProcessing(false);
				console.log("err", err.message)
				setError(err.message)
			});
		};

		return (
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			<Modal classNames="dds__p-3" onClose={!isLoading ? onClose : () => { }}>
				<h3 className="dds__h3">Upload RENBE Product Vulnerability file</h3>
				<ul>
					<li>Supported File Extension: xlsx, xlsb</li>
					<li>Excel file must have a sheet with name &quot;All Device&quot;(Optional sheets: Document, Update Date)</li>
					<li>It must have a header row</li>
					<li>Max file size: 70MB</li>
					<li>.xlsx supports upto 5,00,000 records</li>
					<li>.xlsb supports upto 3,00,000 records</li>
				</ul>
				<div>
					<input
						type="file"
						className="dds__form-upload" // Add File upload classes from DDS2
						data-toggle="dds__form-upload"
						name="upload"
						id="uploadInput"
						tabIndex={-1}
						required
						onChange={onFileChange}
						disabled={isLoading}
					/>

					{selectedFile && (
						<div>
							<h4 className="dds__h4">File Details</h4>
							<p>
								<b>File Name:</b> {selectedFile.name}
							</p>
							<p>
								<b>File Size:</b>{" "}
								{(Math.round(selectedFile.size * 100) / (1024 * 1024 * 100)).toFixed(2)} MB
							</p>
							<p>
								<b>Last Modified:</b> {`${new Date(selectedFile.lastModified)}`}
							</p>
						</div>
					)}

					<button
						type="button"
						onClick={uploadHandler}
						disabled={!(selectedFile && !isLoading) || successfullyUploaded || isPaused}
						className="dds__button dds__button--primary"
					>
						Upload
					</button>

					{isProcessing && !isLoading && (
						<div className="dds__mx-auto dds__text-center">
							<LoadingSpinner size="md" />
							<b>Processing Data, please wait</b>
						</div>
					)}

					{isLoading && !isProcessing && (
						<div className="dds__mx-auto dds__text-center">
							<LoadingSpinner size="md" />
							<div className="dds__my-2">
								<ProgressBar progress={Math.floor(progress)} maxWidth={300} textColor={"white"} bgColor={"#ececed"} fgColor={"#006bbd"} />
							</div>
							<b>Uploading Data, please wait</b>
						</div>
					)}

					{successfullyUploaded && (
						<div className="dds__mx-auto dds__text-center">
							<p>
								<b>Successfully Uploaded</b>
							</p>
							<button
								type="button"
								className="dds__button dds__button--secondary"
								onClick={onClose}
							>
								Close
							</button>
						</div>
					)}
					{isPaused && <div className="dds__mx-auto dds__text-center">
						<p>
							<pre>Upload in progress, waiting for internet connection...</pre>
						</p>
					</div>}

					{error && (
						<div className="dds__mx-auto dds__text-center">
							<p>
								<b>Error</b>
							</p>
							<pre>{error}</pre>
						</div>
					)}
				</div>
			</Modal>
		);
	};
export default UploadModal;
