import { ColumnInstance } from "react-table";

const ColumnWidthAdjust: React.FC<{
	allColumns: ColumnInstance<object>[];
	resetResizing: any;
}> = ({ allColumns, resetResizing }) => (
	<>
		{allColumns.map((column) => column.canResize && column.isVisible ? (
			<div
				style={{ display: "inline-block" }}
				key={`${column.id}_div_width_input`}
				className="dds__m-2"
			>
				<label htmlFor={`${column.id}_width_input`}>
					{column.Header} :{" "}
					{column.width !== undefined
						? parseInt(`${column.width}`, 10) <=
							parseInt(`${column.maxWidth}`, 10) &&
							parseInt(`${column.width}`, 10) >= parseInt(`${column.minWidth}`, 10)
							? parseInt(`${column.width}`, 10)
							: parseInt(`${column.width}`, 10) >=
								parseInt(`${column.maxWidth}`, 10)
								? parseInt(`${column.maxWidth}`, 10)
								: parseInt(`${column.minWidth}`, 10)
						: "Error"}
				</label>

				{/* {console.log(column.getResizerProps)} */}
				<div style={{ display: "inline" }} {...column.getResizerProps()}>
					<input
						type="range"
						min={column.minWidth}
						max={column.maxWidth}
						id={`${column.id}_width_input`}
						value={column.width}
					/>
				</div>
			</div>
		) : null)}
		<button
			type="button"
			className="dds__button dds__button--md dds__button--primary dds__m-1"
			onClick={resetResizing}
		>
			Reset Resizing
		</button>
	</>
);

// const ColumnWidthAdjust:React.FC<{
// 	hideColWidthTool:() => void
// 	allColumns: ColumnInstance<Object>[],
// 	resetResizing: any
// }> = (props) => {
// 	return(
// 		<Modal onClose={props.hideColWidthTool}>
// 			{props.allColumns.map(column => {
// 				return column.canResize && column.isVisible ? (
// 					<div key={column.id + "_div_width_input"} className="dds__m-2">
// 						<label htmlFor={column.id + "_width_input"}>{column.Header} : {
// 							column.width !== undefined
// 								? parseInt(column.width + "") <= parseInt(column.maxWidth + "") &&
// 									parseInt(column.width + "") >= parseInt(column.minWidth + "") ?
// 									column.width : parseInt(column.width + "") >= parseInt(column.maxWidth + "") ?
// 										column.maxWidth : column.minWidth
// 								: "Error"
// 						}</label>

// 						{/* {console.log(column.getResizerProps)} */}
// 						<div {...column.getResizerProps()}>
// 							<input type="range" min={column.minWidth} max={column.maxWidth} id={column.id + "_width_input"} value={column.width}></input>
// 						</div>
// 					</div>
// 				) : null
// 			})}
// 			<button className="dds__btn dds__btn-primary dds__m-1" onClick={props.resetResizing}>Reset Resizing</button>
// 		</Modal>
// 	)
// }

export default ColumnWidthAdjust;
