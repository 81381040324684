import React, { useEffect, useState } from "react";
import BreadCrumb from "../components/Layout/BreadCrumb/BreadCrumb";
import Footer from "../components/Layout/Footer/Footer";
import Header from "../components/Layout/Header/Header";
import CvlSideNavigation from "../components/Layout/SideNavbar/CvlSideNavigation";
import { Main } from "./styles";
import { useUserInfo } from "../store/auth/UserInfoContext";
import { cvl } from "../components/Dashboardv3/constants";

const CvlLayout: React.FC<{
	children: JSX.Element;
	noNavbar: boolean | null;
	noFooter: boolean | null;
	crumbs: any[];
}> = ({ children, noNavbar, noFooter, crumbs }) => {
	const [userRole, setUserRole] = useState<{
		user: boolean,
		admin: boolean,
		superAdmin: boolean,
		leader: boolean
	}>({
		user: true,
		admin: false,
		superAdmin: false,
		leader: false
	});
	const user = useUserInfo();
	useEffect(() => {
		if (user) {
			const res = user?.getUserInfo();
			if (res.user_role && res.user_role[cvl[0]]) {
				let role = res.user_role[cvl[0]];
				setUserRole(role)
			}

		}
	}, [user]);
	return (
		<div className="dds__template--productivity">
			<Header />
			<CvlSideNavigation userType={userRole} />
			<Main>
				<div className='dds__container--compact'>
					<BreadCrumb crumbs={crumbs} />
					{children}
				</div>
			</Main>
			<Footer />
		</div>
	)
}

export default CvlLayout;
