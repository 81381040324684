import React, { useEffect, useState } from 'react'
import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import DDSTabs, { TabsData } from '../../../Common/Tabs/DDSTabs';
import useUserVulnerabilityData from '../../../VulnerabilityPortal/useUserVulnerabilityData';
import Card from '../../Card/Card';
import { createTrigger, TriggerAction } from '../../Common/TriggerAction';
import { cardsTheme, cvl } from '../../constants';
import DDSDrawer from '../../Drawer/DDSDrawer';
import { userTab } from '../../DashboardPage/useMetaAPI';
import User from './User';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import { useDashboardAPIs } from '../../DashboardPage/useDashboardAPIs';

const CvlCard: React.FC<{
	favouriteStatus: boolean, description: any, tabsMetaData: userTab, actionRequired: boolean
}> = ({
	favouriteStatus,
	description,
	tabsMetaData,
	actionRequired
}) => {
		const { isLoading: isUserLoading, error: userError, data: userData } = useUserVulnerabilityData();
		const { getUserRole } = useUserInfo();
		const { isOrg } = useDashboardContext();

		const role = getUserRole(cvl[0]);

		const { data: metaData } = useDashboardAPIs();
		const [message, setMessage] = useState("View Details")

		useEffect(() => {
			if (!isOrg) {
				if (metaData && metaData.data[cvl[0]]) {
					setMessage(metaData.data[cvl[0]]);
				}
			}
		}, [metaData, isOrg]);

		useEffect(() => {
			if (!isOrg && !isUserLoading && !userError) {
				let temp = "No Threats Detected: All Clear"
				if (userData.length > 0)
					temp = userData.length + ' Security Threats Detected';
				setMessage(temp);
				// Add logic to update query data with message 
			}
		}, [isUserLoading, userData, userError, isOrg]);

		const tabDetails: TabsData = [{
			hidden: !tabsMetaData.user || !role.user,
			name: tabsMetaData.user,
			iconClass: "dds__icon--user",
			active: !isOrg,
			content: User,
			footerButtons: [
				{
					name: "Visit Dashboard",
					path: "/vulnerability-portal",
					external: false,
					ariaLabel: "Visit Vulnerability Portal"
				}
			]
		}]
		const triggerID = createTrigger(cvl[0])


		return <Card
			themeColor={cardsTheme[cvl[0]].themeColor}
			name={
				document.getElementsByClassName("layout")[0]?.clientWidth >
					1000
					? cvl[0]
					: cvl[1]
			}
			ddsIconClass={cardsTheme[cvl[0]].ddsIconClass}
			isFav={favouriteStatus}
			idName={cvl[0]}
			description={description}
			isLoading={(message === "View Details" && !userError)}
			actionRequired={actionRequired}
		>
			<TriggerAction triggerID={triggerID}>
				{message}
			</TriggerAction>
			<DDSDrawer triggerID={triggerID} cardName={cvl[0]}>
				<DDSTabs tabsMetaData={tabsMetaData} idName={cvl[0]} tabsData={tabDetails} salt={cvl[0]} />
			</DDSDrawer>
		</Card>
	}

export default CvlCard