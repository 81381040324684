import React from 'react'
import DropdownComponent from '../../../../Common/DropdownComponent';

const StatusDropdown = ({ assetStatusList }) => {
	return (
		<DropdownComponent
			label="Status"
			PopupList={
				<ul
					className="dds__dropdown__list"
					role="listbox"
					tabIndex={-1}
				>
					{Object.keys(assetStatusList).length > 0 && (
						<>
							{Object.keys(assetStatusList).map(function (key) {
								return (
									<li
										key={key}
										className="dds__dropdown__item"
										role="none"
									>
										<button
											className="dds__dropdown__item-option"
											role="option"
											data-selected="false"
											tabIndex={-1}
										>
											<span className="dds__dropdown__item-label">
												{key}
											</span>
										</button>
									</li>
								);
							})}
						</>
					)}
				</ul>
			}
		/>
	)
}
export default StatusDropdown;
