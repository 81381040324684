import { createContext, useContext, useEffect, useRef, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { assetConditionListConfig, assetFundedListConfig, assetStatusListConfig, stockroomListConfig } from "../API";
import { AuthError } from "../../../ErrorStates";
import { useErrorHandler } from "react-error-boundary";

export const MyAdminContext = createContext<any>(null);

export type FilterObject = {
	assetCondition: string,
	countries: string[],
	fundedBy: string,
	status: string,
	stockrooms: string[],
	sub_status_list: string[],
}

export const useSetFilterStore = () => {
	const { pathname } = window.location;

	const { isLoading: isCountryLoading,
		error: fetchCountryError,
		sendRequest: fetchCountryData } = useAxios();

	const { isLoading: isStatusListLoading,
		error: fetchStatusListError,
		sendRequest: fetchStatusData } = useAxios();

	const { isLoading: isFundListLoading,
		error: fetchFundError,
		sendRequest: fetchFundData } = useAxios();

	const { isLoading: isConditionListLoading,
		error: fetchConditionError,
		sendRequest: fetchConditionData } = useAxios();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(isCountryLoading || isStatusListLoading || isFundListLoading || isConditionListLoading)
	}, [isCountryLoading, isStatusListLoading, isFundListLoading, isConditionListLoading])

	const [fetchError, setFetchError] = useState<boolean>(false);

	const handleError = useErrorHandler();
	useEffect(() => {
		if (fetchCountryError || fetchStatusListError || fetchFundError || fetchConditionError) {
			console.log(fetchCountryError)
			if (fetchCountryError instanceof AuthError)
				handleError(fetchCountryError)
			else if (fetchStatusListError instanceof AuthError)
				handleError(fetchStatusListError)
			else if (fetchFundError instanceof AuthError)
				handleError(fetchFundError)
			else if (fetchConditionError instanceof AuthError)
				handleError(fetchConditionError)
			else
				setFetchError(true);
		}
	}, [fetchCountryError, fetchStatusListError, fetchFundError, fetchConditionError])


	const [countryList, setCountryList] = useState<{
		[key: string]: string
	}>();
	const [assetStatusList, setAssetStatusList] = useState<{
		[key: string]: string
	}>();
	const [fundedByList, setFundedByList] = useState<{
		fundedbyid: number,
		fundedby: string
	}[]>();
	const [assetDropdownList, setAssetDropdownList] = useState<{
		conditionid: number,
		conditionvalue: string
	}[]>();

	const getCountryStockroomsList = () => {
		if (!countryList)
			fetchCountryData(stockroomListConfig({
				isAllStockrooms: false
			}), data => {
				let temp = data.stockrooms;
				let res: any = {};
				temp.forEach(element => {
					let key = element.countryName;
					let value: string[] = [];
					element.stockRooms.forEach((e, i) => {
						value.push(e.name)
					})
					res[key] = value; // key[0].toUpperCase() + key.substring(1) - removed to support lowercase and uppercase country names
				});
				setCountryList(res);
			});
	};
	const getAssetStatusList = () => {
		if (!assetStatusList)
			fetchStatusData(assetStatusListConfig(), data => {
				setAssetStatusList(JSON.parse(data.assetStatusList));
			});
	};
	const getFundedByList = () => {
		if (!fundedByList)
			fetchFundData(assetFundedListConfig(), data => {
				setFundedByList(data.assetFundedByList);
			});
	};
	const getAssertConditionList = () => {
		if (!assetDropdownList)
			fetchConditionData(assetConditionListConfig(), data => {
				setAssetDropdownList(data.assetConditionList);
			});
	};
	useEffect(() => {
		if (/assetinventory\/admin/i.test(pathname)) {
			console.log("fetching ", pathname)
			getCountryStockroomsList();
			getAssetStatusList();
			getFundedByList();
			getAssertConditionList();
		}
	}, [pathname])


	/* Setting data */
	let preFilterdObj = useRef({} as FilterObject);
	const setPreFilterdObj = (appliedFilter: FilterObject) => {
		preFilterdObj.current = appliedFilter;
	}

	return {
		isLoadingFilters: isLoading,
		fetchError: fetchError,

		countryList: countryList,
		assetStatusList: assetStatusList,
		fundedByList: fundedByList,
		assetDropdownList: assetDropdownList,
		// stockroomList: stockroomList,
		// // setMultiCountryList: setMultiCountryList,
		// subStatusList: subStatusList,
		// // setSubStatusList: setSubStatusList
		// showSelectedFilter: showSelectedFilter,
		preFilterdObj: preFilterdObj.current,
		setPreFilterdObj: setPreFilterdObj
		// onSubStatusList: onSubStatusList

	}

}


export const useAdminFilters = () => {
	const context = useContext(MyAdminContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}