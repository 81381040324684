import styled from "styled-components";

export const FooterElement = styled.footer`
	border: 1px solid #d5dce1;
	border-left: 0;
	box-shadow: 0px 1px 0px #ffffff;
	font-size: 12px;
	fontweight: 400;
	min-height: 55px;
	letter-spacing: 0;
	text-align: left;
	color: rgb(99; 99; 99);
	display: flex;
	align-items: center;
	// background-color: rgb(240, 240, 240);
	ul {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		gap: 3rem;
		a {
			color: rgb(99, 99, 99);
			text-decoration: none;
			:hover {
				text-decoration: underline;
			}
		}
	}
	@media only screen and (max-width: 767px) {
		ul {
			flex-direction: column;
			gap: 1rem;
		}
		min-height: 160px;
	}
`;
