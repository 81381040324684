import { useContext } from "react";
import { ReactComponent as SearchIcon } from "../../../../../Assets/Search.svg";
import { ManageUsersContext } from "../ManageUsersContext";

const SearchInput = () => {
	const { filterData } = useContext(ManageUsersContext)
	const filterHandler = (e) => {
		filterData(e.target.value)
	}
	return <div
		className="dds__input-text__wrapper dds__input-text__container--lg dds__input-text__wrapper--button"
		style={{
			width: 400
		}}
	>
		<input
			// style={{
			// 	lineHeight: '2.2rem'
			// }}
			// disabled={props.isFetching}
			// onChange={props.searchInputChangeHandler}
			// onKeyPress={e => e.key === "Enter" && props.searchHandler()}
			type="text"
			className="dds__input-text"
			placeholder="Search"
			onChange={filterHandler}
		/>

		<button
			style={{ minWidth: 50, backgroundColor: "white" }}
			className="dds__button dds__button--secondary dds__button--md"
			type="button"
		// onClick={props.searchHandler}
		// disabled={props.isFetching}
		>
			<SearchIcon
				style={{
					margin: 0,
					// marginLeft: "2.4rem",
					// marginTop: `${props.searchOnly ? "0.5rem" : "0.2rem"}`,
					// width: "1.5rem",
				}}
				className="dds__icon dds__button__icon--end"
			/>
		</button>
	</div>
}
export default SearchInput