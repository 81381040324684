import React, {
	ChangeEventHandler,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { ErrorBoundary, useErrorHandler } from "react-error-boundary";
import { useAlert } from "react-alert";
import {
	fetchAdminListConfig,
	fetchAllVulnerabilityRecordsConfig,
	fetchVulnerabilityRecordsBySearchConfig,
	fetchVulnerabilityRecordsConfig,
} from "./API";
import {
	AuthError,
} from "../../ErrorStates";
import columns from "./DataModel";
import UploadModal from "./UploadModal";
import "./portalcss.css";
import ServerTable from "../ServerTable/ServerTable";
import ComponentFallback from "../Fallback/ComponentFallback";
import { ReactComponent as HeaderIcon } from "../../Assets/Announcement.svg"
import useAxios from "../../hooks/useAxios";
import VulnerabilityHeaderInfo from "./HeaderBar/VulnerabilityHeaderInfo";
import { useUserInfo } from "../../store/auth/UserInfoContext";

const VulnerabilityPortal: React.FC = () => {

	/*
	TODO: Refactor-> fetchAdmins, getUserRole, constants, Toolbar
	*/

	useEffect(() => {
		document.title = "Security Threats | Admin"
	}, [])

	const {
		isLoading: adminListLoading,
		error: adminListError,
		sendRequest: fetchAdminAPIRequest,
	} = useAxios();

	const user = useUserInfo();
	const [userRole, setUserRole] = useState<string>("user");
	const handleError = useErrorHandler();
	const searchRef = useRef<any>("");
	const [uploadModalVisible, setuploadModalVisible] = useState(false);
	const [lastClicked, setLastClicked] = useState<string>("");
	const [disableSearch, setDisableSearch] = useState(true);
	const [apiArgs, setApiArgs] = useState<any[] | null>(null)

	const FETCH_ALL_RECORDS = "All Records";
	const FETCH_YOUR_RECORDS = "My Records";
	const FETCH_BY_REFERENCE = "Fetch By Reference";

	const alert = useAlert();

	useEffect(() => {
		if (user) {
			const res = user?.getUserInfo();
			if (res.userRole && res.userRole.clientRole) {
				let role = (res.userRole.clientRole || "user");
				if (res.is_CVL_Leader && role === "user")
					role = "leader"
				setUserRole(role)
			}

		}
	}, [user]);


	const [adminList, setAdminList] = useState<any[]>([]);

	const fetchAdmins = useCallback(() => {

		fetchAdminAPIRequest(
			fetchAdminListConfig(),
			(dataObj => {
				setAdminList([
					{
						name: "",
						email: "",
						ntid: "",
						badgeid: ""
					},
					...dataObj.admins
				])
			})
		);

	}, [fetchAdminAPIRequest]);

	useEffect(() => {
		if (adminListError && userRole !== "user")
			alert.show("Assign admin functionality not available, please try again later!", {
				type: "error",
				timeout: 0
			})
	}, [adminListError, alert, userRole])



	const [reload, setReload] = useState<boolean>(false);

	const fetchCurrentUserRecords = useCallback(() => {
		if (lastClicked === FETCH_YOUR_RECORDS)
			setReload(true)
		else
			setLastClicked(FETCH_YOUR_RECORDS);
	}, [lastClicked]);

	const searchHandler = useCallback(() => {
		if (lastClicked === FETCH_BY_REFERENCE) {
			fetchAllRecordsHandler();
			setTimeout(() => {
				setLastClicked(FETCH_BY_REFERENCE)
			}, 300)

			// setReload(true)
		} else
			setLastClicked(FETCH_BY_REFERENCE);

		setApiArgs([{
			reference: searchRef.current.value.trim()
		}])
	}, [lastClicked]);

	const fetchAllRecordsHandler = useCallback(() => {

		if (lastClicked === FETCH_ALL_RECORDS)
			setReload(true)
		else
			setLastClicked(FETCH_ALL_RECORDS);

	}, [lastClicked]);

	const showUploadModal = (): void => {
		setuploadModalVisible(true);
	};

	const hideUploadModal = (): void => {
		setuploadModalVisible(false);
	};

	const uploadSuccessHandler = (): void => {
		fetchAllRecordsHandler();
	};

	const SearchInputChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
		if (e.target.value === "")
			setDisableSearch(true)
		else
			setDisableSearch(false);
	}

	useEffect(() => {
		fetchAllRecordsHandler();
		fetchAdmins();
	}, []); // No dependency - or remove useCallback from respective functions


	return (
		<div>
			<div className="dds__border dds__py-3 dds__px-4 dds__mb-3">
				<VulnerabilityHeaderInfo heading="Client Vulnerability Lookup" />

				<div className="dds__d-flex dds__flex-wrap dds__justify-content-around">
					<div className="dds__d-flex dds__flex-grow-1 dds__my-2">
						{/* <label htmlFor="">Search by hostname/ email/ service tag</label> */}
						<input
							placeholder="Search by Hostname/ User Name/ Email/ Service Tag/ Product"
							className="dds__input-text dds__mr-2"
							type="text"
							ref={searchRef}
							onChange={SearchInputChangeHandler}
							onKeyPress={e => e.key === "Enter" && searchHandler()}
							style={{
								fontSize: '1rem',
								width: '100%',
								maxWidth: 600
							}}
						/>

						<button
							type="button"
							onClick={searchHandler}
							disabled={disableSearch}
							// style={{ width: "100%" }}
							className="dds__button dds__button--primary dds__mr-md-4"
						>
							Search
							{lastClicked === FETCH_BY_REFERENCE && (
								<i className="dds__icon dds__icon--refresh-spin dds__button__icon--end dds__icon" aria-hidden="true" style={{
									fontSize: '1rem'
								}}></i>
							)}
						</button>
						{/* <div className="content-to-hide">
							<hr
								style={{
									width: 0.1,
									height: "3.2rem",
									backgroundColor: "gray",
									margin: 0,
									padding: 0,
								}}
							/>
						</div> */}
					</div>
					<div className="dds__d-flex dds__flex-wrap dds__my-2">
						{/* <div					>
						<button
							type="button"
							onClick={fetchCurrentUserRecords}
							// style={{ width: "100%" }}
							className="dds__button dds__button--secondary"
						>
							{FETCH_YOUR_RECORDS}
							{lastClicked === FETCH_YOUR_RECORDS && (
								<svg style={{ height: 12, width: 12, marginLeft: 5 }}>
									<use xlinkHref="#dds__refresh-spin" />
								</svg>
							)}
						</button>
					</div> */}

						{(userRole === "superadmin" || userRole === "admin") && (
							<>
								<div>
									<button
										type="button"
										onClick={fetchAllRecordsHandler}
										// style={{ width: "100%" }}
										className="dds__button dds__button--secondary dds__text-truncate dds__mr-4"
									>
										{FETCH_ALL_RECORDS}
										{lastClicked === FETCH_ALL_RECORDS && (
											<i className="dds__icon dds__icon--refresh-spin dds__button__icon--end dds__icon" aria-hidden="true" style={{
												fontSize: '1rem'
											}}></i>
										)}
									</button>
								</div>
								{/* {userRole === "superadmin" && <div >
									<button
										type="button"
										onClick={showUploadModal}
										// style={{ width: "100%" }}
										className="dds__button dds__button--secondary dds__text-truncate"
									>
										Upload File
									</button>
								</div>} */}
							</>
						)}
					</div>
				</div>
			</div>

			<div className="dds__pb-5">
				{/* Server table here */}
				<ErrorBoundary
					onError={err => {
						if (err instanceof AuthError) {
							handleError(new AuthError())
						}
					}}
					FallbackComponent={ComponentFallback}
				>
					{/* TODO: Make Server table compatible for instance and expand props and expand component */}
					{lastClicked === FETCH_YOUR_RECORDS && <ServerTable
						columns={columns}
						apiConfig={
							fetchVulnerabilityRecordsConfig
						}
						apiArgs={null}
						Icon={HeaderIcon}
						heading="Vulnerability Report"
						searchHeader="Search"
						instanceProps={{
							tableRole: "user",
							userRole,
							adminList
						}}
						reload={reload}
						setReload={setReload}
						disableHeading
					/>}
					{lastClicked === FETCH_ALL_RECORDS && <ServerTable
						columns={columns}
						apiConfig={
							fetchAllVulnerabilityRecordsConfig
						}
						apiArgs={null}
						Icon={HeaderIcon}
						heading="Vulnerability Report"
						searchHeader="Search"
						instanceProps={{
							tableRole: "admin",
							userRole,
							adminList
						}}
						defaultComplex
						reload={reload}
						setReload={setReload}
						disableHeading
					/>}
					{lastClicked === FETCH_BY_REFERENCE && <ServerTable
						columns={columns}
						apiConfig={
							fetchVulnerabilityRecordsBySearchConfig
						}
						apiArgs={apiArgs}
						Icon={HeaderIcon}
						heading="Vulnerability Report"
						searchHeader="Search"
						instanceProps={{
							tableRole: "search",
							userRole,
							adminList
						}}
						reload={reload}
						setReload={setReload}
						disableHeading
						activateSearch={false}
					/>}
				</ErrorBoundary>
			</div>
			{uploadModalVisible && (
				<UploadModal
					onSuccess={uploadSuccessHandler}
					onClose={hideUploadModal}
				/>
			)}
		</div>
	);
};

export default VulnerabilityPortal;
