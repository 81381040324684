import styled from "styled-components";

export const CardWrapper = styled.div<{ bgColor }>`
	border-radius: 24px;
    width: 520px;
	background-color: ${props => props.bgColor ? props.bgColor : 'white'};
	padding: 24px;
`;

export const Title = styled.h6`
  color: var(--blue-700);
`;

export const ExpirationText = styled.span`
  color: #636363;
`;