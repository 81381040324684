import { color } from "d3-color";
import ReactTooltip from "react-tooltip";
import sanitizeHtml from 'sanitize-html';
import DefaultCell from "../Table/ReusableTableCells/DefaultCell";
import CommentsCell from "./CommentsCell";
import EditableSelectCell from "./EditableSelectCell";
import EditableTextCell from "./EditableTextCell";


const columns = [
	{
		Header: "Asset Hostname",
		accessor: "assethostname",
		disableFilters: true,
		disableSortBy: false,
		width: 95,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word', color: "var(--blue-600)" }}
					data-for="assethostname_tip"
					data-tip={value}
				>
					<b>{value}</b>
				</p>
				<ReactTooltip id="assethostname_tip" />
			</>
		),
	},
	{
		Header: "Service Tag",
		accessor: "servicetag",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="servicetag_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="servicetag_tip" />
			</>
		),
	},
	{
		Header: "Email",
		accessor: "email",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline", wordBreak: 'break-word' }} data-for="email_tip" data-tip={value}>
					{value}
				</p>
				<ReactTooltip id="email_tip" />
			</>
		),
	},
	{
		Header: "Comments",
		accessor: "total_comments",
		disableFilters: true,
		disableSortBy: true,
		width: 80,
		Cell: CommentsCell,
		editable: false,
		show: false,
	},
	{
		Header: "Last Comment Time",
		accessor: "comment_time",
		disableFilters: true,
		disableSortBy: false,
		width: 90,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="commenttime_tip"
					data-tip={value ? new Date(value).toLocaleString() : value}
				>
					{value ? new Date(value).toLocaleString() : value}
				</p>
				<ReactTooltip id="commenttime_tip" />
			</>
		),
		editable: false,
		show: false,
	},
	{
		Header: "Last Comment Message",
		accessor: "lastComment",
		disableFilters: true,
		disableSortBy: true,
		show: false,
		width: 90,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="commentmsg_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="commentmsg_tip" />
			</>
		),
		editable: false,
	},
	{
		Header: "Incident(INC)",
		accessor: "snowTicket",
		disableFilters: true,
		disableSortBy: true,
		width: 135,
		Cell: EditableTextCell,
		editable: false,
		show: false,
	},
	{
		Header: "Assigned Admin",
		accessor: "assignadmin",
		disableFilters: true,
		disableSortBy: true,
		overflow: "visible",
		width: 140,
		Cell: EditableSelectCell,
		editable: false,
		show: false,
	},
	{
		Header: "Legacy co",
		accessor: "legacyco",
		disableFilters: true,
		disableSortBy: false,
		show: false,
		width: 50,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="legacyco_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="legacyco_tip" />
			</>
		),
	},
	{
		Header: "User Name",
		accessor: "username",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="username_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="username_tip" />
			</>
		),
	},
	{
		Header: "Manager",
		accessor: "manager",
		disableFilters: true,
		disableSortBy: false,
		show: false,
		width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="manager_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="manager_tip" />
			</>
		),
	},
	{
		Header: "Manager Email",
		accessor: "manager_email",
		disableFilters: true,
		disableSortBy: false,
		show: false,
		width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="manager_email_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="manager_email_tip" />
			</>
		),
	},
	{
		Header: "isexec",
		accessor: "isexec",
		disableFilters: true,
		disableSortBy: false,
		show: false,
		width: 50,
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline", wordBreak: 'break-word' }} data-for="isexec_tip" data-tip={value}>
					{value}
				</p>
				<ReactTooltip id="isexec_tip" />
			</>
		),
	},
	{
		Header: "isexecutiveadmin",
		accessor: "isexecutiveadmin",
		disableFilters: true,
		disableSortBy: false,
		show: false,
		width: 50,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="isexecutiveadmin_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="isexecutiveadmin_tip" />
			</>
		),
	},
	{
		Header: "Sab Name",
		accessor: "sabname",
		disableFilters: true,
		disableSortBy: false,
		show: false,
		width: 70,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="sabname_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="sabname_tip" />
			</>
		),
	},
	{
		Header: "Device Type",
		accessor: "devicetype",
		disableFilters: true,
		disableSortBy: false,
		width: 70,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="devicetype_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="devicetype_tip" />
			</>
		),
	},
	{
		Header: "OS Build",
		accessor: "osbuild",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="osbuild_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="osbuild_tip" />
			</>
		),
	},
	{
		Header: "OS Version",
		accessor: "osversion",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="osversion_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="osversion_tip" />
			</>
		),
	},

	// {
	// 	Header: "Freedisk More Than 15%", // Changed to 15%
	// 	accessor: "freedisk_more_than_10percentage",
	// 	disableFilters: true,
	// 	disableSortBy: false,
	// 	width: 110,
	// 	Cell: ({ value }) => (
	// 		<>
	// 			<p
	// 				style={{ display: "inline", wordBreak: 'break-word' }}
	// 				data-for="freedisk_more_than_10percentage_tip"
	// 				data-tip={value}
	// 			>
	// 				{value}
	// 			</p>
	// 			<ReactTooltip id="freedisk_more_than_10percentage_tip" />
	// 		</>
	// 	),
	// },
	// {
	// 	Header: "Free Disk",
	// 	accessor: "freediskpercentage",
	// 	disableFilters: true,
	// 	disableSortBy: false,
	// 	width: 80,
	// 	Cell: ({ value }) => (
	// 		<>
	// 			<p
	// 				style={{ display: "inline", wordBreak: 'break-word' }}
	// 				data-for="freediskpercentage_tip"
	// 				data-tip={value}
	// 			>
	// 				{/* {(Math.round(value*100))+"%"} */}
	// 				{value ? `${value}%` : ""}
	// 			</p>
	// 			<ReactTooltip id="freediskpercentage_tip" />
	// 		</>
	// 	),
	// },
	{
		Header: "End of Life",
		accessor: "endoflife",
		disableFilters: true,
		disableSortBy: false,
		show: false,
		width: 70,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word' }}
					data-for="endoflife_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="endoflife_tip" />
			</>
		),
	},

	{
		Header: "Product",
		accessor: "product",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word', color: "red" }}
					data-for="product_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="product_tip" />
			</>
		),
	},
	{
		Header: "Solution",
		accessor: "solution",
		disableFilters: true,
		disableSortBy: false,
		width: 120,
		Cell: ({ value }) => (
			<>
				<div
					style={{ display: "inline", wordBreak: 'break-word', color: "green" }}
					data-for="solution_tip"
					data-tip={value}
					data-html={true}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: sanitizeHtml(value) }}
				/>
				<ReactTooltip html id="solution_tip" />
			</>
		),
	},
	{
		Header: "Vulnerability Due Date",
		accessor: "due_date",
		disableFilters: true,
		disableSortBy: false,
		width: 90,
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline", wordBreak: 'break-word', fontWeight: 'bold' }}
					data-for="due_date_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="due_date_tip" />
			</>
		),
	},
	{
		Header: "Document",
		accessor: "document_link",
		disableFilters: true,
		disableSortBy: true,
		width: 80,
		Cell: ({ value }) =>
			value ? (
				<>
					<p
						style={{ display: "inline", wordBreak: 'break-word' }}
						data-for="document_link_tip"
						data-tip={value}
					>
						<a rel="noreferrer" href={value} target="_blank">
							Click here
						</a>
					</p>
					<ReactTooltip id="document_link_tip" />
				</>
			) : (
				<p>{value}</p>
			),
	},
	{
		Header: "Scanner Information",
		accessor: "scanner_information",
		disableFilters: true,
		disableSortBy: true,
		// disableExport: true,
		width: 80,
		Cell: ({ value }) =>
			value ? (
				<>
					<p
						style={{ display: "inline" }}
						data-for="scanner_information_tip"
						data-tip={value}
					>
						{value}
					</p>
					<ReactTooltip id="scanner_information_tip" />
				</>
			) : (
				<p>{value}</p>
			),
	},
	{
		Header: "user Status",
		accessor: "userStatus",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "Last Seen",
		accessor: "last_seen",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "Found Date",
		accessor: "found_date",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "Vuln Product Version",
		accessor: "version",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "Vuln Path",
		accessor: "path",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "Country",
		accessor: "country",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "Region",
		accessor: "region",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "L3",
		accessor: "l3",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "L4",
		accessor: "l4",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "L5",
		accessor: "l5",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "L6",
		accessor: "l6",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "Manager IsExec",
		accessor: "managerIsExec",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "Asset Status",
		accessor: "assetStatus",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
	{
		Header: "Block State",
		accessor: "blockState",
		disableFilters: true,
		disableSortBy: false,
		width: 80,
		show: false,
		Cell: (props) => DefaultCell({ ...props, color: '#0e0e0e' }),
	},
];
export default columns;