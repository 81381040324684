import ReactTooltip from "react-tooltip";
import DefaultCell from "../../Table/ReusableTableCells/DefaultCell";
import ActionColumn from "./ActionColumn/ActionColumn";
import FloatingPillColumn from "./FloatingPillColumns";

const columns: any[] = [
	{
		Header: "Employee Name",
		accessor: "name",
		disableFilters: true,
		disableSortBy: false,
		Cell: DefaultCell,
	},
	{
		Header: "Employee email",
		accessor: "email",
		disableFilters: true,
		disableSortBy: false,
		Cell: DefaultCell,
	},
	{
		Header: "NT ID",
		accessor: "ntid",
		show: false,
		disableFilters: true,
		disableSortBy: false,
		Cell: DefaultCell,
	},
	{
		Header: "Badge ID",
		accessor: "badgeid",
		show: false,
		disableFilters: true,
		disableSortBy: false,
		Cell: DefaultCell,
	},
	{
		Header: "Site Access",
		accessor: "dellSites",
		disableFilters: true,
		disableSortBy: true,
		width: 140,
		Cell: FloatingPillColumn
	},
	{
		Header: "Role",
		accessor: "admin",
		disableFilters: true,
		disableSortBy: false,
		Cell: ({ value, color = "var(--blue-800)", ...props }) => {
			return <div style={{
				display: 'flex',
				alignItems: 'center',
				height: '100%'
			}}>
				<p
					style={{ display: "inline", color: color, wordBreak: 'break-word', textTransform: "capitalize" }}
					data-for={`${props.column.id}_${props.row.id}_tip`}
					data-tip={value === "1" || value === 1 ? 'admin' : 'superadmin'}
				>
					{value === "1" || value === 1 ? 'Admin' : 'Superadmin'}
				</p>
				<ReactTooltip id={`${props.column.id}_${props.row.id}_tip`} />
			</div>
		}
	},
	{
		Header: "Superadmin",
		accessor: "superadmin",
		show: false,
		disableFilters: true,
		disableSortBy: false,
		Cell: DefaultCell,
	},
	{
		Header: "Action",
		accessor: "action",
		disableFilters: true,
		disableSortBy: true,
		width: 70,
		Cell: ActionColumn,
	},
];
export default columns;