import { useEffect, useRef, useState } from "react";
// import { FileInput, Notification } from "@dds/components";
// import * as XLSX from "xlsx";
import DateRangePicker from "../../Common/DateRangePicker/DateRangePicker";
import useDateRangePicker from "../../Common/DateRangePicker/useDateRangePicker";
// import DDSModal from "../../Common/Modalv2/DDSModal";
import ServerTable from "../../ServerTable/ServerTable";
import { addBlockedDevicesConfig, fetchBlockedDevicesConfig } from "../API";
import columns from "./DataModel";
// import { useCustomMutation } from "../../../hooks/useCustomQuery";
// import LoadingSpinner from "../../Common/LoadingSpinner";
// import { useUserInfo } from "../../../store/auth/UserInfoContext";
// import { cvl } from "../../Dashboardv3/constants";

type BlockedDevicesFilter = {
	startDate: string;
	endDate: string;
	isDateRange: boolean;
};
// Server table with Filter
const BlockedDevices = () => {
	const sdRef = useRef<HTMLDivElement>(null);
	const edRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		document.title = "Security Threats | Blocked Devices"
	}, [])

	// const fileInputRef = useRef<HTMLDivElement>(null);
	// const fileInputInstanceRef = useRef<any>();

	const [filter, setFilter] = useState<BlockedDevicesFilter>({
		startDate: "",
		endDate: "",
		isDateRange: false,
	}); // set type
	const [reload, setReload] = useState<boolean>(false);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [tableError, setTableError] = useState<Error | null>(null);
	// const [selectedFile, setSelectedFile] = useState<File>();

	// const userRole = useUserInfo().getUserRole(cvl[0]);

	const {
		initialize: initializeDatePicker,
		getSelectedRange,
		clearDateRange,
	} = useDateRangePicker(sdRef, edRef);

	let modalInstanceRef = useRef<any>();
	const heading = "Blocked Devices";

	useEffect(() => {
		if (filter) {
			console.log("filter set to", filter);
			setReload(true);
		}
	}, [filter]);

	useEffect(() => {
		initializeDatePicker("YYYY-MM-DD", new Date(), new Date());
	}, []);

	// const removeSelectedFile = () => {
	// 	setSelectedFile(undefined);
	// };

	// useEffect(() => {
	// 	fileInputInstanceRef.current = FileInput(fileInputRef.current, {
	// 		maxFileCount: 1,
	// 		maxFileSize: 30000000, // restricts to 30KB instead of 30MB
	// 		acceptedFileTypes: [".xlsx", ".xlsb"], // Not showing error when different file type is selected
	// 	});
	// 	addEventListener("ddsFileInputDeletedEvent", removeSelectedFile);

	// 	return () => {
	// 		fileInputInstanceRef.current.dispose();
	// 		removeEventListener("ddsFileInputDeletedEvent", removeSelectedFile);
	// 	};
	// }, []);

	const applyHandler = () => {
		const { startDate, endDate } = getSelectedRange();
		if (startDate === null || endDate === null) {
			clearDateRange();
		}
		let isDateRange = startDate && endDate ? true : false;
		console.log({
			startDate,
			endDate,
			isDateRange,
		});
		setFilter({
			startDate,
			endDate,
			isDateRange,
		});
	};
	const resetHandler = () => {
		clearDateRange();
	};

	// const openUploadModal = () => {
	// 	if (modalInstanceRef.current) modalInstanceRef.current.open();
	// };

	// const onFileChange = (e): void => {
	// 	const file = e.target.files[0];
	// 	// setSuccessfullyUploaded(false);
	// 	if (file) {
	// 		const fileExtension = file.name
	// 			.split(".")
	// 		[file.name.split(".").length - 1].toLowerCase();
	// 		if (fileExtension !== "xlsx" && fileExtension !== "xlsb") {
	// 			fileInputInstanceRef.current.setState(
	// 				0,
	// 				"error",
	// 				"File Extension not supported."
	// 			);

	// 			removeSelectedFile();
	// 		} else if (file.size > 30 * 1024 * 1024) {
	// 			fileInputInstanceRef.current.setState(
	// 				0,
	// 				"error",
	// 				"File size should be less than 30MB"
	// 			);

	// 			removeSelectedFile();
	// 		} else {
	// 			setSelectedFile(e.target.files[0]);
	// 		}
	// 	} else {
	// 		setSelectedFile(undefined);
	// 	}
	// };

	// const transformData = (file: any): Promise<{ cvlBlockedDevices: any[] }> =>
	// 	new Promise((resolve, reject) => {
	// 		const reader = new FileReader();
	// 		reader.onload = (e: any) => {
	// 			const wb = XLSX.read(e.target.result, {
	// 				type: "binary",
	// 				cellDates: true,
	// 				dateNF: "dd-mm-yyyy",
	// 			});
	// 			const ws = wb.Sheets["Blocked Devices"];

	// 			const parsedData = XLSX.utils.sheet_to_json(ws, { raw: false });
	// 			if (parsedData.length === 0) {
	// 				removeSelectedFile();
	// 				reject(
	// 					new Error(
	// 						"File is either too large or there are no records present.\n <5,00,000 records supported for .xlsx files\n <3,00,000 records supported for .xlsb files"
	// 					)
	// 				);
	// 				return;
	// 			}

	// 			resolve({
	// 				cvlBlockedDevices: parsedData,
	// 			});
	// 		};

	// 		reader.onerror = function (event) {
	// 			console.error(new Error(`File could not be read! Code ${event}`));
	// 			// setError("Unknown error occurred while parsing the file.");
	// 			setIsProcessing(false);
	// 			fileInputInstanceRef.current.setState(
	// 				0,
	// 				"error",
	// 				"Unknown error occurred while parsing the file."
	// 			);
	// 			removeSelectedFile();
	// 			reject(new Error("Unknown error occurred while parsing the file."));
	// 		};

	// 		reader.readAsBinaryString(file);
	// 	});

	// const { mutate, isLoading: isUploading } = useCustomMutation(
	// 	addBlockedDevicesConfig(),
	// 	{}
	// );
	// const [isProcessing, setIsProcessing] = useState<boolean>(false);

	// const uploadFile = () => {
	// 	fileInputInstanceRef.current.setState(0, "uploading", "Processing data");
	// 	setIsProcessing(true);
	// 	transformData(selectedFile).then(data => {
	// 		setIsProcessing(false);
	// 		console.log(data);
	// 		fileInputInstanceRef.current.setState(0, "uploading", "Uploading data");
	// 		mutate(data, {
	// 			onSuccess: data => {
	// 				console.log("Successfully uploaded");
	// 				// fileInputInstanceRef.current.setState(0, 'success', 'Successfully uploaded new records') // Bug with the message - removed temporarily
	// 				fileInputInstanceRef.current.setState(0, "rest", "");
	// 				modalInstanceRef.current.close();
	// 				setReload(true);
	// 				let notifData = {
	// 					titleIcon: "",
	// 					closeIcon: false,
	// 					title: "Successfully Uploaded",
	// 					messageBody: "The file has been successfully uploaded",
	// 					timeStamp: new Date().toDateString(),
	// 					primaryActionText: "Close",
	// 					secondaryAction: openUploadModal,
	// 					secondaryActionText: "Upload more",
	// 					notificationCount: 1,
	// 					titleIconType: "font-icon",
	// 				};

	// 				new Notification(notifData);
	// 				// setReload(true);
	// 			},
	// 			onError: error => {
	// 				console.log("Not uploaded");
	// 				const msg = "Error" + error + " occurred while uploading";
	// 				fileInputInstanceRef.current.setState(0, "error", msg);

	// 				let notifData = {
	// 					titleIcon: "",
	// 					closeIcon: false,
	// 					title: "Upload failed",
	// 					messageBody: "Unknown error occurred while uploading data",
	// 					timeStamp: new Date().toDateString(),
	// 					primaryActionText: "Close",
	// 					secondaryAction: openUploadModal,
	// 					secondaryActionText: "Retry",
	// 					notificationCount: 1,
	// 					titleIconType: "font-icon",
	// 				};

	// 				new Notification(notifData);
	// 			},
	// 		});
	// 	});
	// };

	// const showNotification = useCallback(() => {
	// 	console.log("closed");
	// 	console.log("Isuploading", isUploading);
	// 	console.log("IsProcessing", isProcessing);
	// 	if (isUploading || isProcessing) {
	// 		console.log("triggered");
	// 		const data = {
	// 			titleIcon: "",
	// 			closeIcon: false,
	// 			title: isUploading ? "Uploading Data" : "Processing Data",
	// 			messageBody: "Please wait while the upload finishes",
	// 			timeStamp: new Date().toDateString(),
	// 			primaryActionText: "Close",
	// 			secondaryActionText: "Open Upload Modal",
	// 			secondaryAction: openUploadModal,
	// 			notificationCount: 1,
	// 			titleIconType: "font-icon",
	// 		};

	// 		new Notification(data);
	// 	}
	// }, [isUploading, isProcessing]);

	return (
		<div>
			{/* Header */}
			<div className="dds__border dds__py-3 dds__px-4 dds__mb-3">
				<div className="dds__d-flex dds__flex-wrap dds__justify-content-between dds__mb-2 ">
					<h4
						className=" dds__h4 dds__font-weight-normal dds__d-inline "
						style={{
							color: "var(--blue-800)",
						}}
					>
						{heading}
					</h4>
					<div className="dds__d-flex dds__align-items-center dds__flex-wrap">
						<div
							className="dds__d-flex dds__px-3 dds__py-2 dds__mr-3 dds__mb-2"
							style={{
								background: "var(--gray-100)",
								border: "1px solid var(--gray-500)",
								borderRadius: 4,
							}}
						>
							<div className="dds__d-flex">
								<DateRangePicker sdRef={sdRef} edRef={edRef} />
							</div>
							<div className="dds__d-flex dds__mt-4 dds__flex-wrap dds__align-content-end">
								<div className="dds__mx-2">
									<button
										onClick={applyHandler}
										role={"button"}
										className="dds__button dds__button--primary"
										disabled={tableLoading}
									>
										Apply
									</button>
								</div>
								<div>
									<button
										role={"button"}
										className="dds__button dds__button--secondary"
										onClick={resetHandler}
										disabled={tableLoading}
									>
										Reset
									</button>
								</div>
							</div>
						</div>
						{/* {userRole.superAdmin && <div className="dds__mb-2">
							<button
								role={"button"}
								className="dds__button dds__button--secondary"
								onClick={openUploadModal}
							>
								{isProcessing || isUploading ? (
									<><LoadingSpinner size="md" ></LoadingSpinner><span className="dds__ml-2 dds__d-inline">Uploading...</span></>
								) : (
									<><i className="dds__icon dds__icon--user-add dds__button__icon--start" />Upload Records</>
								)}

							</button>
						</div>} */}
					</div>
				</div>
				{/* Table */}
				{filter && (
					<ServerTable
						apiArgs={[filter]} // add filters
						apiConfig={fetchBlockedDevicesConfig}
						columns={columns}
						searchHeader="Search"
						heading="Blocked Devices"
						tableBorder={false}
						disableHeading
						reload={reload}
						setReload={setReload}
						setTableLoadingState={setTableLoading}
						setTableErrorState={setTableError}
						disableColumnFunctions
					/>
				)}
			</div>
			{/* <DDSModal
				themeColor={"black"}
				modalInstanceRef={modalInstanceRef}
				classes={"dds__rounded"}
				id={"infoModal"}
				title={"Upload Data"}
				// onClose={showNotification}
				footerButtons={[
					{
						text: "Upload",
						handleFooterButtonClick: uploadFile,
						class: "dds__button--primary",
						disabled: (selectedFile && !isUploading && !isProcessing) ? false : true,
					},
					{
						text: "Close",
						handleFooterButtonClick: () => { },
						class: "dds__button--secondary",
						close: true,
					},
				]}
			>
				<p></p>

				<div
					ref={fileInputRef}
					className="dds__file-input"
					data-dds="file-input"
				>
					<label
						id="file-input-label-605313477"
						className="dds__file-input__label"
						htmlFor="file-input-control-name-605313477"
					>
						Select Excel file with blocked devices data
					</label>
					<small
						id="file-input-helper-605313477"
						className="dds__file-input__helper-text"
					>
						*.xlsx, *.xlsb
					</small>
					<input
						aria-hidden="true"
						id="file-input-control-605313477"
						type="file"
						name="file-input-control-name-605313477"
						className="dds__file-input__control"
						accept=".xlsx, .xlsb"
						multiple={false}
						onChange={onFileChange}
					/>
					<button
						className="dds__button dds__button--secondary dds__button--md dds__file-input__button"
						aria-describedby="file-input-label-605313477 file-input-helper-605313477 upload-file-attributes"
					>
						<i className="dds__icon dds__icon--upload dds__button__icon--start" />
						Browse files
					</button>
					<div
						id="file-input-error-605313477"
						aria-live="polite"
						className="dds__error-text"
						style={{
							display: "none",
						}}
					>
						<i
							className="dds__icon dds__icon--alert-notice dds__error-text__icon"
							aria-hidden="true"
						></i>
						Error Text
					</div>
				</div>

				<hr></hr>
				<ul id="upload-file-attributes">
					<li>Supported File Extension: xlsx, xlsb</li>
					<li>Excel file must have a sheet with name "Blocked Devices"</li>
					<li>It must have a header row</li>
					<li><a download href="https://dell.sharepoint.com/sites/ITTracker/_layouts/15/download.aspx?SourceUrl=https://dell.sharepoint.com/sites/ITTracker/Shared%20Documents/General/Templates/BlockDevice_Template.xlsx">Download</a> Excel template</li>
					<li>Max file size: 30MB</li>

				</ul>
				<hr></hr>
			</DDSModal> */}
		</div>
	);
};

export default BlockedDevices;
