import FilterChip from './FilterChip';

const AppliedFilterBar = ({ dropdownList, resetFilters }) => {
	type dropdownVariable<T> = {
		controller: any,
		value: T,
		length: number
	}

	let country: dropdownVariable<string[]> = {
		controller: dropdownList[0],
		value: dropdownList[0].getSelection(),
		length: dropdownList[0].getSelection().length
	};

	let stockrooms: dropdownVariable<string[]> = {
		controller: dropdownList[1],
		value: dropdownList[1].getSelection(),
		length: dropdownList[1].getSelection().length
	};

	let condition: dropdownVariable<string> = {
		controller: dropdownList[2],
		value: dropdownList[2].getSelection(),
		length: dropdownList[2].getSelection().length > 0 ? 1 : 0
	};

	let fundedBy: dropdownVariable<string> = {
		controller: dropdownList[3],
		value: dropdownList[3].getSelection(),
		length: dropdownList[3].getSelection().length > 0 ? 1 : 0
	};

	let status: dropdownVariable<string> = {
		controller: dropdownList[4],
		value: dropdownList[4].getSelection(),
		length: dropdownList[4].getSelection().length > 0 ? 1 : 0
	};

	let substatus: dropdownVariable<string[]> = {
		controller: dropdownList[5],
		value: dropdownList[5].getSelection(),
		length: dropdownList[5].getSelection().length
	};

	const length = (country.length + stockrooms.length + condition.length + fundedBy.length + status.length + substatus.length);

	return (
		<div>
			<span>
				Filter Result({length})
			</span>
			{country.length > 0 &&
				<FilterChip listItem={country.value} controller={country.controller} />
			}
			{stockrooms.length > 0 &&
				<FilterChip listItem={stockrooms.value} controller={stockrooms.controller} />
			}
			{condition.length > 0 &&
				<FilterChip listItem={[condition.value]} controller={condition.controller} />
			}
			{fundedBy.length > 0 &&
				<FilterChip listItem={[fundedBy.value]} controller={fundedBy.controller} />
			}
			{status.length > 0 &&
				<FilterChip listItem={[status.value]} controller={status.controller} />
			}
			{substatus.length > 0 &&
				<FilterChip listItem={substatus.value} controller={substatus.controller} />
			}

			{length > 0 &&
				<button
					onClick={resetFilters}
					className="dds__button dds__button--tertiary dds__button--sm">
					Reset
				</button>}


		</div>
	)
}

export default AppliedFilterBar