import { objectToQuery } from "../../../utils/querycreator";

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const fetchCvlAdminListConfig = (): object => ({
	url: `${baseUrl}/api/Fetch/v1/Admins`,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

export const addCvlAdminConfig = (): object => ({
	url: `${baseUrl}/api/Access/v1/AddAdmin`,
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
	// body: body
});

export const deleteCvlAdminConfig = (userId: string): object => ({
	url:
		`${baseUrl}/api/Access/v1/DeleteAdmin?` + objectToQuery({ userId: userId }),
	method: "DELETE",
	headers: {
		"Content-Type": "application/json",
	},
	// body: body
});

export const updateCvlAdminConfig = (): object => ({
	url: `${baseUrl}/api/Access/v1/UpdateAdmin`,
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
	// body: body
});

// body: {
// 	isSuperadmin: boolean;
// 	userid: string;
// }
