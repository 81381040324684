import { Crumb } from "../models/crumb-type";

const getCrumbs = (props: any, routes: any[]): Crumb[] => {
	return routes
		// Get all routes that contain the current one.
		.filter(({ path }) => props.match.path.includes(path))
		// Swap out any dynamic routes with their param values.
		// E.g. "/pizza/:pizzaId" will become "/pizza/1"
		.map(({ path, name }) => ({
			path: Object.keys(props.match.params).length
				? Object.keys(props.match.params).reduce(
					(path, param) =>
						path.replace(
							`:${param}`,
							props.match.params[param]
						),
					path
				)
				: path,
			name,
			// ...rest
		}));
}
export default getCrumbs