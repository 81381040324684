/* eslint-disable import/prefer-default-export */
import React, { FC, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
// import { useAuth } from "./AuthContext";

interface IState {
	message?: string;
}

const UnAuthorized: FC = () => {
	const location = useLocation();
	const auth = useAuth();
	const message = (location.state as IState).message
		? (location.state as IState).message
		: ("");

	const resetLogin = (): void => {
		sessionStorage.clear();
		auth?.login();
	};

	return (
		<div role="alert" style={{ textAlign: "center", marginTop: 100 }}>
			<h3>Unauthorized</h3>
			<pre>{`${message}`}</pre>
			<button type="button" className="dds__button dds__button--sm dds__button--primary dds__m-2" onClick={resetLogin}>Try Login again?</button>

			<div style={{ width: "fit-content", margin: "auto", textAlign: 'left' }}>
				<br />
				<h4>Troubleshooting: </h4>
				<ol>
					<li>
						Make sure that you are connected to the internal network using VPN or
						Tunnel
					</li>
					<li>Reload the page</li>
					<li>Open the link in new tab</li>
					<li>Clear browser cookies or restart the browser</li>
					<li>Please contact Admin if the problem persists</li>
				</ol>
			</div>
		</div>
	);
}
export default UnAuthorized
