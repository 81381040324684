import { PageTitle } from "../../Admin/styles";
import { RegionContextProvider } from "./RegionContext";
import { ManageUsersContextProvider } from "./ManageUsersContext";
import { ManageUsersTable } from "./ManageUsersTable";
import { getAdminsConfig } from "../../API";
import { queryKeys } from "../../../../react-query/constants";
import columns from '../DataModel/DataModel'
import CreateAdminButton from "./Toolbar/CreateAdminButton";
import { useEffect } from "react";

const ManageUsersPage: React.FC = () => {
	useEffect(() => {
		document.title = "Asset Inventory | Manage Users"
	}, [])
	return (
		<div className="dds__container-fluid dds__px-0">
			<PageTitle>Manage Administrator</PageTitle>
			<RegionContextProvider>
				<ManageUsersContextProvider apiConfig={{ keyName: queryKeys.inventoryadminlist, getAdminsConfig: getAdminsConfig }}>
					<ManageUsersTable CreateAdminButton={CreateAdminButton} columns={columns} />
				</ManageUsersContextProvider>
			</RegionContextProvider>
		</div>
	);
};

export default ManageUsersPage;