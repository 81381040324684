import Modal from '../../../Common/Modal/Modal';
import { IconButton } from '../../../Table/TableStyles';

type AssetViewProp = {
    trigger: boolean;
    leaderName: string;
    setTriggerAssetView: any;
    children?: any;
};

const AssetView = (props: AssetViewProp) => {
    return (props.trigger) ? (
        <Modal classNames='' onClose={() => { props.setTriggerAssetView(false) }} style={{ width: '75%', left: '15%', height: '84%', focus }}>
            {<div className="dds__pt-3 dds__px-3" style={{
                position: 'sticky',
                top: 0,
                background: 'white',
                zIndex: 999
            }}>
                <div className="dds__mb-2">
                    <h3 style={{
                        color: 'var(--blue-700)'
                    }} className="dds__d-inline dds__h3 ">{'Total Asset Details'}</h3>
                    <IconButton style={{
                        float: 'right'
                    }}
                        onClick={() => props.setTriggerAssetView(false)}>
                        <i
                            style={{ fontSize: "1rem", color: 'var(--gray-600)' }}
                            className="dds__icon dds__icon--close-x"
                            tabIndex={0}
                            role="button"
                            aria-label='Close'
                        ></i>
                    </IconButton>

                </div>
            </div>}
            {props.children}
        </Modal>
    ) : null;
}

export default AssetView;