import { useHistory } from "react-router-dom";
import { useAnalytics } from "../../../store/analytics/AnalyticsContext";
import { eventsInfo } from "../../../store/analytics/eventsInfo";

const useRedirectHandler = () => {
    const history = useHistory();
    const { addEventToQueue } = useAnalytics();
    const redirectHandler = (buttonInfo: { name: string, path: string, external: boolean }) => {
        const additionalPayload = buttonInfo
        addEventToQueue({
            ...eventsInfo.redirectDashboardButton(),
            additional_payload: additionalPayload
        })
        if (buttonInfo.external)
            window.open(buttonInfo.path)
        else {
            // Fix for Direct push from drawer element as overlay classes are not auto removed
            // remove class dds__drawer__overlay--overflow-hidden from body if present
            document.body.classList.remove("dds__drawer__overlay--overflow-hidden");
            history.push(buttonInfo.path);
        }
    }
    return { redirectHandler }
}

export default useRedirectHandler;