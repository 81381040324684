import React, { useState } from 'react'
import DropdownComponent from '../../../../Common/DropdownComponent'

const CarrierDropdown = ({ carrier }) => {
    return (
        <DropdownComponent
            selection='multiple'
            label="Carrier"
            PopupList={
                <ul
                    className="dds__dropdown__list"
                    role="listbox"
                    tabIndex={-1}
                >
                    {Object.keys(carrier).length > 0 && (
                        <>
                            {carrier.map((value, index) => (
                                <li
                                    key={index}
                                    className="dds__dropdown__item"
                                    role="none"
                                >
                                    <button
                                        className="dds__dropdown__item-option"
                                        role="option"
                                        data-selected="false"
                                        tabIndex={-1}
                                    >
                                        <span className="dds__dropdown__item-label">
                                            {value}
                                        </span>
                                    </button>
                                </li>
                            ))}

                        </>
                    )}
                </ul>
            }
        />
    )
}
export default CarrierDropdown