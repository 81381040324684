import DropdownComponent from "../../../../../Common/DropdownComponent"

const StockroomDropdown = ({ regions, checkDropdown, rowIndex, inputDisabled }) => {
	return (
		<DropdownComponent
			label="Stockrooms"
			inputId={`stockroom-multiselect-dropdown-input${rowIndex}`}
			popupId={`stockroom-multiselect-dropdown-popup${rowIndex}`}
			inputName={`stockroom-multiselect-dropdown-name${rowIndex}`}
			selection="multiple"
			PopupList={
				<ul className="dds__dropdown__list" role="listbox" tabIndex={-1}>
					{regions?.map((e) => {
						if (e.selected === rowIndex) {
							return e.stockrooms.map(stockroom => {
								return <li className="dds__dropdown__item" role="none" key={stockroom.name}>
									<button
										// onClick={() => handleStockroomChange(stockroom.name, !stockroom.selected, rowIndex)}
										className="dds__dropdown__item-option" role="option" data-selected={stockroom.selected ? "true" : "false"} data-value={stockroom.name} tabIndex={-1}>
										<span className="dds__dropdown__item-label">{stockroom.name}</span>
									</button>
								</li>
							})
						}
					})}

				</ul>}
			onInputClick={() => checkDropdown(`stockroom-multiselect-dropdown-popup${rowIndex}`)}
			inputDisabled={inputDisabled}
		/>
	)
}
export default StockroomDropdown
