import React from 'react'
import LoadingSpinner from '../../../../Common/LoadingSpinner';
import CardError from '../../CardError';
import MobileDevicesGraph from './MobileDevicesGraph';
import useMobileDevicesStatsData from '../../../../MobileDevices/useMobileDevicesStatsData';

const Leader: React.FC = () => {
    const { isLoading, error, data } = useMobileDevicesStatsData({
        device: [""],
        carrier: [""],
        country: [""]
    })

    if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
    if (error) return <CardError />;
    return (
        <div>
            <MobileDevicesGraph data={data} />
        </div >
    )
}

export default Leader