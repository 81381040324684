import React from "react";
import LoadingSpinner from "../../../Common/LoadingSpinner";
import useUserVulnerabilityData from "../../../VulnerabilityPortal/useUserVulnerabilityData";
import CardError from "../CardError";
import CardText from "../../CardText.tsx/CardText";
import { NumberWrapper } from "../../CardText.tsx/Styles";
import sanitizeHtml from "sanitize-html";
import { cardColumns, cvl } from "../../constants";
import DDSAccordian from "../../Accordian/DDSAccordian";
import { createAccordianID } from "../Asset Request Tracker Card/User";
import useRefreshDateApi from "../../../VulnerabilityPortal/useRefreshDateApi";
const columns: cardColumns = [
	{
		Header: "Host name",
		accessor: "assethostname",
		Cell: ({ value }) => {
			return <>Device name: {value}</>;
		},
		searchable: true,
		visible: false,
	},
	{
		Header: "Threat",
		accessor: "product",
		Cell: ({ value }) => {
			return (
				<p className="dds__text-justify dds__body-2 dds__pr-md-4">
					A recent scan has determined you have an active security threat on{" "}
					<strong>{value}</strong> application
				</p>
			);
		},
		searchable: true,
		visible: true,
	},
	{
		Header: "Instructions",
		accessor: "model",
		Cell: ({ value, row: obj }) => {
			return (
				<>
					<p
						className="dds__text-justify dds__body-2 dds__pr-md-4 dds__break-word"
						dangerouslySetInnerHTML={{ __html: sanitizeHtml(obj.solution) }}
					></p>
					{obj.document_link && obj.document_link.length > 0 && (
						<p className="dds__text-justify dds__body-2 dds__pr-md-4">
							<a rel="noreferrer" href={obj.document_link} target="_blank">
								Click here
							</a>{" "}
							for more details
						</p>
					)}
					{/* {obj.due_date && <p className="dds__text-justify dds__body-2 dds__pr-md-4">
					Please ensure you take the above actions before <strong style={{
						color: 'var(--red-600)'
					}}>{obj.due_date}</strong>, or risk your PC being blocked from the Dell network
				</p>} */}
				</>
			);
		},
		searchable: false,
		visible: true,
	},
];
const User: React.FC = () => {
	const { isLoading, error, data, isError } = useUserVulnerabilityData();
	const {
		isLoading: refreshDateLoading,
		error: refreshDateError,
		data: refreshDate,
	} = useRefreshDateApi();

	if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
	if (error) return <CardError />;

	const total = data.length;

	let issueMessage;
	if (total > 0) {
		const date = new Date(data[0].due_date);
		if (date instanceof Date && !Number.isNaN(date.valueOf()))
			issueMessage = (
				<div>
					<NumberWrapper>{total}</NumberWrapper> security threat
					{total > 1 && "s"} were found on your devices , please take action
					before {total > 0 && new Date(data[0].due_date).toLocaleDateString()}
				</div>
			);
		else
			issueMessage = (
				<div>
					<NumberWrapper>{total}</NumberWrapper> security threat
					{total > 1 && "s"} were found on your devices.
				</div>
			);
	}
	const congratsMessage = (
		<div>You don't have any security threats on your devices</div>
	);

	return (
		<>
			<div style={{ textAlign: 'right', marginTop: "1.5rem" }}>
				{!refreshDateLoading && !refreshDateError && (
					<span className="dds__text-muted">
						Last updated on {refreshDate}
					</span>
				)}
			</div>
			{total === 0 ? (
				<CardText
					total={total}
					issueMessage={issueMessage}
					congratsMessage={congratsMessage}
				/>
			) : (
				<DDSAccordian
					totalElements={data.length}
					data={data}
					isError={isError}
					isLoading={isLoading}
					columns={columns}
					accordianID={createAccordianID(cvl[0])}
					sideWayTable={false}
				/>
			)}
		</>
	);
};

export default User;
