import React from "react"
import { AppHeader } from "../components/Layout/Header/styles";
import dell_horizontal_logo from "../Assets/dell_horizontal_logo.png";
import dell_stacked_logo from "../Assets/dell_stacked_logo.png";


const LogoutHeader: React.FC<{
}> = ({ }) => {

    return (
        <AppHeader role={"banner"}>
            <div id="nav-container">
                <div className="left">
                    <h1 className="app-logo">
                        <img
                            alt="Dell logo"
                            className="horizontal-logo"
                            src={dell_horizontal_logo}
                            height={20}
                        />
                        <img
                            alt="Dell logo"
                            className="stacked-logo"
                            src={dell_stacked_logo}
                            height={30}
                        />
                    </h1>
                    <span style={{ color: "white", marginLeft: "7px" }}>  |  TrackIT</span>
                </div>

            </div>
        </AppHeader>
    )
}

export default LogoutHeader;