import { UseTableCellProps } from "react-table";
import ReactTooltip from "react-tooltip";
import EditableCell from "./EditableCell";
import { dateBetweenFilterFn, DateRangeColumnFilter, SelectColumnFilter } from "../Table/Filters";

import dds_arrow_tri_right from "../../Assets/dds_icons/dds__arrow-tri-right.png";
import dds_arrow_tri_down from "../../Assets/dds_icons/dds__arrow-tri-down.png";
import { ExpandColumnController } from "./ExpandController";

/*
editable attribute logic hasn't been added yet

Columns with shippingDetails category are being treated as editable for now
*/

export type Asset = any;

export const mainColumns = [
	{
		Header: "TASK",
		accessor: "task_number",
		disableFilters: true,
		disableSortBy: true,
		width: 110,
		expandCategory: "ProductDetails",
		Cell: ({ row, value }) => (
			<>
				<p style={{ display: "inline" }} data-for={`task_tip_${row.id}`} data-tip={value}>
					<a
						rel="noreferrer"
						href={`https://dell.service-now.com/textsearch.do?sysparm_search=${value}`}
						target="_blank"
					>
						{" "}
						{value}{" "}
					</a>
				</p>
				<ReactTooltip id={`task_tip_${row.id}`} />
			</>
		),
	},
	{
		Header: "Task date",
		accessor: "created_date",
		Filter: DateRangeColumnFilter,
		filter: dateBetweenFilterFn,
		width: 100,
		expandCategory: "ProductDetails",
		Cell: ({ row, value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for={`taskdate_${row.id}`}
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id={`taskdate_${row.id}`} />
			</>
		),
	},
	{
		Header: "Badge ID",
		accessor: "dell_employee_id",
		disableFilters: true,
		disableSortBy: true,
		width: 80,
		show: false,
		expandCategory: "EmployeeDetails",
		Cell: ({ row, value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for={`badgeid_${row.id}`}
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id={`badgeid_${row.id}`} />
			</>
		),
	},
	{
		Header: "Emp Name",
		accessor: "employee_name",
		disableFilters: true,
		disableSortBy: true,
		width: 80,
		show: false,
		expandCategory: "EmployeeDetails",
		Cell: ({ row, value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for={`empname_${row.id}`}
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id={`empname_${row.id}`} />
			</>
		),
	},
	{
		Header: "Product",
		accessor: "product",
		width: 150,
		expandCategory: "ProductDetails",
		Cell: ({ row, value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for={`product_${row.id}`}
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id={`product_${row.id}`} />
			</>
		),
	},
	{
		Header: "Serial number",
		accessor: "serial_number",
		disableFilters: true,
		disableSortBy: true,
		width: 80,
		show: false,
		expandCategory: "ShippingDetails",
		editable: false,
		editable_type: "text",
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline" }} data-for="sno_tip" data-tip={value}>
					{value}
				</p>
				<ReactTooltip id="sno_tip" />
			</>
		),
	},
	{
		Header: "Item",
		accessor: "item",
		Filter: SelectColumnFilter,
		filter: "includes",
		width: 130,
		expandCategory: "ProductDetails",
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline" }} data-for="item_tip" data-tip={value}>
					{value}
				</p>
				<ReactTooltip id="item_tip" />
			</>
		),
	},
	{
		Header: "Reason for req",
		accessor: "reason_for_request",
		disableFilters: true,
		disableSortBy: true,
		width: 200,
		show: false,
		expandCategory: "ProductDetails",
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline" }} data-for="reqres_tip" data-tip={value}>
					{value}
				</p>
				<ReactTooltip id="reqres_tip" />
			</>
		),
	},
	{
		Header: "City",
		accessor: "city",
		disableFilters: true,
		width: 80,
		expandCategory: "EmployeeDetails",
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline" }} data-for="city_tip" data-tip={value}>
					{value}
				</p>
				<ReactTooltip id="city_tip" />
			</>
		),
	},
	{
		Header: "State",
		accessor: "state",
		disableFilters: true,
		width: 100,
		show: false,
		expandCategory: "EmployeeDetails",
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline" }} data-for="state_tip" data-tip={value}>
					{value}
				</p>
				<ReactTooltip id="state_tip" />
			</>
		),
	},
	{
		Header: "DOJ",
		accessor: "new_hire_start_date",
		disableFilters: true,
		width: 100,
		show: false,
		expandCategory: "EmployeeDetails",
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline" }} data-for="doj_tip" data-tip={value}>
					{value}
				</p>
				<ReactTooltip id="doj_tip" />
			</>
		),
	},
	{
		Header: "Cost center",
		accessor: "cost_center_number",
		disableFilters: true,
		width: 80,
		show: false,
		expandCategory: "ProductDetails",
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline" }} data-for="cost_tip" data-tip={value}>
					{value}
				</p>
				<ReactTooltip id="cost_tip" />
			</>
		),
	},
	{
		Header: "Ship or site",
		accessor: "shiporsite",
		Filter: SelectColumnFilter,
		filter: "includes",
		width: 60,
		expandCategory: "ShippingDetails",
		editable: false,
		show: false,
		editable_type: "options",
		options_data: ["NA", "Ship", "Site"],
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline" }} data-for="ss_tip" data-tip={value}>
					{value}
				</p>
				<ReactTooltip id="ss_tip" />
			</>
		),
	},
	{
		Header: "Status",
		accessor: "status",
		Filter: SelectColumnFilter,
		filter: "includes",
		width: 80,
		expandCategory: "ShippingDetails",
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline" }} data-for="status_tip" data-tip={value}>
					{value}
				</p>
				<ReactTooltip id="status_tip" />
			</>
		),
		editable: false,
		editable_type: "options",
		options_data: [], // fetched and added in AssetTracker component
	},
	{
		Header: "Service Now Status",
		accessor: "snow_status",
		disableFilters: true,
		disableSortBy: true,
		show: false,
		expandCategory: "ProductDetails",
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="snowstatus_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="snowstatus_tip" />
			</>
		),

		// Cell: (value: any) => {
		// 	return <span >
		// 		{value === "complete" ?
		// 			<span style={{backgroundColor:'green'}}>{value}</span> :
		// 			<span style={{fontSize:200}}>{value}</span>
		// 		}
		// 	</span>
		// },
	},
	{
		Header: "Dispatch/ Pickup ETA",
		accessor: "dispatch_pickup_eta",
		disableFilters: true,
		disableSortBy: true,
		width: 90,
		expandCategory: "ShippingDetails",
		editable: false,
		editable_type: "date",
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline" }} data-for="eta_tip" data-tip={value}>
					{value}
				</p>
				<ReactTooltip id="eta_tip" />
			</>
		),
		// Filter: DateRangeColumnFilter,
		// filter: 'dateBetween'
	},
];

export const expandColumns = [
	{
		Header: "RITM Number",
		accessor: "request_item_number",
		disableFilters: true,
		disableSortBy: true,
		show: false,
		expandCategory: "ProductDetails",
		Cell: ({ value }) => (
			<>
				<p style={{ display: "inline" }} data-for="ritm_tip" data-tip={value}>
					<a
						rel="noreferrer"
						href={`https://dell.service-now.com/textsearch.do?sysparm_search=${value}`}
						target="_blank"
					>
						{value}
					</a>
				</p>
				<ReactTooltip id="ritm_tip" />
			</>
		),
	},

	{
		Header: "Address",
		accessor: "address",
		disableFilters: true,
		disableSortBy: true,
		show: false,
		expandCategory: "EmployeeDetails",
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="address_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="address_tip" />
			</>
		),
	},
	{
		Header: "DHL Tracking Number",
		accessor: "tracking", // dhltracking
		disableFilters: true,
		disableSortBy: true,
		show: false,
		expandCategory: "ShippingDetails",
		editable: false,
		editable_type: "text",
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="tracking_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="tracking_tip" />
			</>
		),
	},
	{
		Header: "Employee email",
		accessor: "emp_email",
		disableFilters: true,
		disableSortBy: true,
		show: false,
		expandCategory: "EmployeeDetails",
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="empemail_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="empemail_tip" />
			</>
		),
	},
	{
		Header: "Comments",
		accessor: "comments",
		disableFilters: true,
		disableSortBy: true,
		show: false,
		expandCategory: "ShippingDetails",
		editable: false,
		editable_type: "text",
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="comments_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="comments_tip" />
			</>
		),
	},
	{
		Header: "Productive",
		accessor: "productive",
		disableFilters: true,
		disableSortBy: true,
		show: false,
		expandCategory: "ShippingDetails",
		editable: false,
		editable_type: "options",
		options_data: [], // fetched and added in AssetTracker component
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="productive_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="productive_tip" />
			</>
		),
	},
	{
		Header: "Manager email",
		accessor: "manager_email", // what_is_your_manager_email
		disableFilters: true,
		disableSortBy: true,
		show: false,
		expandCategory: "EmployeeDetails",
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="mgremail_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="mgremail_tip" />
			</>
		),
	},
	{
		Header: "Updated on",
		accessor: "updatedon",
		disableFilters: true,
		disableSortBy: true,
		show: false,
		expandCategory: "ShippingDetails",
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="updatedon_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="updatedon_tip" />
			</>
		),
	},
	{
		Header: "Updated by",
		accessor: "updatedby",
		disableFilters: true,
		disableSortBy: true,
		show: false,
		expandCategory: "ShippingDetails",
		Cell: ({ value }) => (
			<>
				<p
					style={{ display: "inline" }}
					data-for="updatedby_tip"
					data-tip={value}
				>
					{value}
				</p>
				<ReactTooltip id="updatedby_tip" />
			</>
		),
	},
];

export const columns = [
	ExpandColumnController,
	...mainColumns,
	...expandColumns,
];
