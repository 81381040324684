import React from "react";
import { FooterElement } from "./styles";

const Footer = () => (
  <FooterElement>
    <nav className="dds__container">
      <ul>
        <li>
          Copyright{' '}
          <span aria-hidden="true">©</span>{' '}
          <span>{new Date().getFullYear()} Dell technologies</span>
        </li>
        <li>
          <a href="https://www.dell.com/learn/in/en/incorp1/site-terms-of-use" target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>
        </li>
        <li>
          <a href="https://www.dell.com/learn/us/en/uscorp1/policies-privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </li>
      </ul>

    </nav>
  </FooterElement>
);


export default Footer;
