import { useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { useAlert } from "react-alert";
import ReactTooltip from "react-tooltip";
import { useCustomMutation } from "../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";
import LoadingSpinner from "../../../Common/LoadingSpinner";
import DDSModal from "../../../Common/Modalv2/DDSModal";
import { IconButton } from "../../../Table/TableStyles";
import { deleteARTAdminConfig } from "../../API/api";

const DeleteAction = ({ row }) => {
	const alert = useAlert();
	const queryClient = useQueryClient();
	let modalInstanceRef = useRef<any>();

	const deleteSuccessHandler = data => {
		alert.show(
			`Successfully removed user ${row.values.name} from the admin group`,
			{
				type: "success",
				timeout: 5000,
			}
		);
		queryClient.setQueryData([queryKeys.ARTAdminList], data);
		// queryClient.invalidateQueries([queryKeys.ARTAdminList])
	};

	const deleteErrorHandler = () => {
		alert.show("Unknown error occurred while deleting the record", {
			type: "error",
			timeout: 10000,
		});
	};

	const { isLoading: isDeleting, mutate: deleteRecord } = useCustomMutation(deleteARTAdminConfig(row.values.ntid), {
		onSuccess: deleteSuccessHandler,
		onError: deleteErrorHandler
	});
	// {
	// 	"admin": "string",
	// 	"dellSites": [
	// 	  {
	// 		"isDefault": true,
	// 		"siteName": "string"
	// 	  }
	// 	],
	// 	"ntid": "string",
	// 	"superadmin": "string"
	//   }
	const deleteRecordHandler = () => {
		deleteRecord(null) // pass row here
		closeConfirmationModal();
	}

	const openConfirmationModal = () => {
		modalInstanceRef.current.open();
	};
	const closeConfirmationModal = () => {
		modalInstanceRef.current.close();
	};

	return (
		<>
			<IconButton
				className="dds__button dds__button--secondary"
				data-for="deleteTicketTip"
				data-tip="Delete"
				style={{ marginTop: 0, marginBottom: 0 }}
				onClick={openConfirmationModal}
				disabled={isDeleting}
				aria-label={`Delete admin`}
			>
				{isDeleting && <LoadingSpinner size="md" />}
				{!isDeleting && (
					<i
						style={{ fontSize: "1.1rem", color: "red" }}
						className="dds__icon dds__icon--trash"
						aria-hidden="true"
					/>
				)}
				<ReactTooltip id="deleteTicketTip" />
			</IconButton>
			<DDSModal
				themeColor={"black"}
				iconClass={"dds__icon--trash"}
				modalInstanceRef={modalInstanceRef}
				classes={"dds__rounded"}
				id={"infoModal"}
				title={"Confirm Deletion"}
				footerButtons={[
					{
						class: "dds__button--secondary",
						text: "Cancel",
						handleFooterButtonClick: closeConfirmationModal,
					},
					{
						class: "dds__button--destructive",
						text: "Confirm Delete",
						handleFooterButtonClick: deleteRecordHandler,
					},
				]}
			>
				<div
					style={{
						color: "var(--gray-800)",
					}}
				>
					Are you sure you want to remove {row.values.name} (Badge ID:{" "}
					{row.values.badgeid}) from the admin group. This action can not be
					undone.
				</div>
			</DDSModal>
		</>
	);
};
export default DeleteAction;
