import AdminPage from "../../components/AssetInventory/Admin/AdminPage";
import ManageUsersPage from "../../components/AssetInventory/ManageUsers/ManageUsersPage/ManageUsersPage";
import MyAssetsPage from "../../components/AssetInventory/MyAssets/MyAssetsPage";
import OrgAssetsPage from "../../components/AssetInventory/Org/OrgAssetsPage";
import AssetInventoryLayout from "../../Layouts/AssetInventoryLayout";
import LeaderBoardPage from "../../components/AssetInventory/LeaderBoard/AssetInventoryLeaderBoardPage";
import TelemetryMeasuresPage from "../../components/AssetInventory/TelemetryMeasures/TelemetryMeasuresPage";
import routes from "./routers";

const inventoryRoutes = [
	{
		path: "/assetinventory",
		exact: true,
		layout: AssetInventoryLayout,
		name: "My Assets",
		component: MyAssetsPage,
	},
	{
		path: "/assetinventory/admin",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Admin",
		component: AdminPage,
	},
	{
		path: "/assetinventory/myorg",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Org Assets",
		component: OrgAssetsPage,
	},
	{
		path: "/assetinventory/leaderboard",
		exact: true,
		layout: AssetInventoryLayout,
		name: "LeaderBoard - L3",
		component: LeaderBoardPage,
	},
	{
		path: "/assetinventory/leaderboard/:l3_manager",
		layout: AssetInventoryLayout,
		exact: true,
		name: "L4",
		component: LeaderBoardPage,
	},
	{
		path: "/assetinventory/leaderboard/:l3_manager/:l4_manager",
		layout: AssetInventoryLayout,
		exact: true,
		name: "L5",
		component: LeaderBoardPage,
	},
	{
		path: "/assetinventory/leaderboard/:l3_manager/:l4_manager/:l5_manager",
		layout: AssetInventoryLayout,
		exact: true,
		name: "L6",
		component: LeaderBoardPage,
	},
	{
		path: "/assetinventory/leaderboard/:l3_manager/:l4_manager/:l5_manager/:l6_manager",
		layout: AssetInventoryLayout,
		exact: true,
		name: "L7",
		component: LeaderBoardPage,
	},
	{
		path: "/assetinventory/leaderboard/:l3_manager/:l4_manager/:l5_manager/:l6_manager/:l7_manager",
		layout: AssetInventoryLayout,
		exact: true,
		name: "L8",
		component: LeaderBoardPage,
	},
	{
		path: "/assetinventory/manageusers",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Manage Users",
		component: ManageUsersPage,
	},
	{
		path: "/assetinventory/telemetrymeasures",
		exact: true,
		layout: AssetInventoryLayout,
		name: "Telemetry Measures",
		component: TelemetryMeasuresPage,
	},
];
export default inventoryRoutes;
