import styled from "styled-components";

export const StatCardWrapper = styled.div`
    .statCardTable {
        width: 700px;
        background-color: #D9F5FD;
        border-radius: 24px;
        margin-left: auto;
        margin-bottom: 24px;
    }
    .statCardElement {
        color: #0E0E0E;
        font-size: 16 px;
        text-align: center;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .firstColStat {
        border-top-left-radius: 24px;
    }

    .lastColStat {
        border-top-right-radius: 24px;
    }

    th.statCardElement {
        background-color: #94DCF7;
        box-shadow: inset 0px -1px 0px #7E7E7E, inset -1px 0px 0px #E1E1E1;
    }

    @media screen and (max-width:481px) {
    .statCardTable {
        display: block;
        width: 90vw;
    }

    .statCardTable thead {
        display: none;
    }

    .statCardTable tbody,
    .statCardTable tr,
    .statCardTable td {
        display: block;
        width: 100%;
    }

    .statCardTable td {
        text-align: right;
        padding-left: 15%;
        padding-right: 5%;
        position: relative;
    }

    .statCardTable td::before {
        content: attr(id);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 5%;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
    }
}

@media screen and (max-width:830px) and (min-width:481px) {
    .statCardTable {
        display: block;
        width: 75vw;
    }

    .statCardTable thead {
        display: none;
    }

    .statCardTable tbody,
    .statCardTable tr,
    .statCardTable td {
        display: block;
        width: 100%;
    }

    .statCardTable td {
        text-align: right;
        padding-left: 15%;
        padding-right: 5%;
        position: relative;
    }

    .statCardTable td::before {
        content: attr(id);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 5%;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
    }
}
`;