import ReactDOM from "react-dom";
import { BackdropWrapper, ModalWrapper } from "./styles";

const Backdrop: React.FC<{ onClose: () => void }> = ({ onClose }) => <BackdropWrapper id="modal-backdrop" onClick={onClose} />;

const ModalOverlay: React.FC<{ classNames?: string, style?: any }> = ({ classNames, children, style }) => (
	<ModalWrapper className={classNames} id="modal-overlay" style={style}>
		<div className="">{children}</div>
	</ModalWrapper>
);

let portalElement;
if (document.getElementById("overlays"))
	portalElement = document.getElementById("overlays")
else {
	const el = document.createElement("div")
	el.setAttribute('id', 'overlays')
	document.body.appendChild(el)
	portalElement = el
}


const Modal: React.FC<{ onClose: () => void, classNames?: string, style?: any }> = ({ onClose, classNames, children, style }) => (
	<>
		{portalElement
			? ReactDOM.createPortal(
				<Backdrop onClose={onClose} />,
				portalElement
			)
			: null}
		{portalElement
			? ReactDOM.createPortal(
				<ModalOverlay classNames={classNames} style={style}>{children}</ModalOverlay>,
				portalElement
			)
			: null}
	</>
);

export default Modal;