import { createContext, useContext, useEffect, useRef, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { mobileDeviceFilterConfig } from "../API";
import { AuthError } from "../../../ErrorStates";
import { useErrorHandler } from "react-error-boundary";

export const MobileDeviceLeaderContext = createContext<any>(null);

export type MobileDeviceFilterObject = {
    device: string[],
    carrier: string[],
    country: string[],
}

export const useSetMobileFilterStore = () => {
    const { pathname } = window.location;

    const { isLoading: isFilterListLoading,
        error: fetchFilterListError,
        sendRequest: fetchFilterData } = useAxios();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(isFilterListLoading)
    }, [isFilterListLoading])

    const [fetchError, setFetchError] = useState<boolean>(false);

    const handleError = useErrorHandler();
    useEffect(() => {
        if (fetchFilterListError) {
            console.log(fetchFilterListError)
            if (fetchFilterListError instanceof AuthError)
                handleError(fetchFilterListError)
            else
                setFetchError(true);
        }
    }, [fetchFilterListError])

    const [filterList, setFilterList] = useState<{
        [key: string]: string
    }>();


    const getFilterList = () => {
        if (!filterList)
            fetchFilterData(mobileDeviceFilterConfig(), data => {
                console.log("Filter Data::::::", data.result);
                setFilterList(data.result);
            });
    };
    useEffect(() => {
        if (/mobiledevices\/myorg/i.test(pathname)) {
            console.log("fetching ", pathname)
            getFilterList();
        }
    }, [pathname])


    return {
        isLoadingFilters: isLoading,
        fetchError: fetchError,
        filterList: filterList,
    }

}

export const useMobileDevicesLeaderFilters = () => {
    const context = useContext(MobileDeviceLeaderContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
}