import React from "react";
import styled from "styled-components";
import { expandCell } from "./Expand";

const OverflowWrap = styled.div`
	.overflowWrap {
		overflow-wrap: normal;
	}
`;

export const EmployeeDetails: React.FC<{ data: expandCell[] }> = ({ data }) => (
	<div>
		<h4 className="dds__font-weight-bold">Employee Details</h4>
		{data.map(cell => (
			<OverflowWrap>
				<div className="dds__container-fluid">
					<div className="dds__row">
						<div className="dds__col-1">{cell.Header} :</div>

						<span style={{ wordBreak: 'break-word' }} className="overflowWrap dds__col-1">
							{cell.value}
						</span>
					</div>
				</div>
			</OverflowWrap>
		))}
	</div>
);
