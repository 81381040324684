
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuid } from 'uuid';
// import { useState, useEffect } from 'react';
import { useCustomQuery } from '../../../../../../hooks/useCustomQuery';
import { queryKeys } from '../../../../../../react-query/constants';
import { measureValuesQueryConfig } from '../../../TelemetryAPI';

const MeasureValue: React.FC<{
    mode: 'new' | 'edit',
    selectedMeasureValue: string,
    setSelectedMeasureValue: React.Dispatch<React.SetStateAction<string>>
    initialize?: boolean
}> = ({
    mode,
    selectedMeasureValue,
    setSelectedMeasureValue,
    initialize = false,
}) => {
    const [availableMeasureValues, setAvailableMeasureValues] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);

    // Use the useCustomQuery hook to fetch measure values
    const { data: measureValuesResponse, error: queryError, isLoading: queryLoading } = useCustomQuery(
        [[queryKeys.measureValues]],
        measureValuesQueryConfig(),
        {}
    );

    // Effect to handle loading state
    useEffect(() => {
        setLoading(queryLoading);
    }, [queryLoading]);

    // Effect to handle API response and error
    useEffect(() => {
        if (queryError) {
            setError('Failed to fetch measure values. Please try again.');
        } else if (measureValuesResponse && Array.isArray(measureValuesResponse.result)) {
            setAvailableMeasureValues(measureValuesResponse.result); // Set available measure values directly from 'result' array
            setError(null);
        }
        // } else {
        //     setError('Unexpected response format.');
        // }
    }, [measureValuesResponse, queryError]);

    return (
        <div className="measure-value-field">
            <span>Measure Value:</span>
            <div className="radio-container">
                {isLoading && <p className="loading-message">Loading measure values...</p>}
                {error && <p className="error-message">{error}</p>}
                {!isLoading && availableMeasureValues.map((value) => (
                    <label key={uuid()}>
                        <input
                            style={{ marginRight: '5px' }}
                            type="radio"
                            name="measure-value"
                            value={value}
                            checked={selectedMeasureValue === value}
                            onChange={() => setSelectedMeasureValue(value)}
                        />
                        {value}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default MeasureValue;

