import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useHistory } from "react-router-dom";
import { dateFormat } from "../../../utils/dateFunctions";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { columns, mainColumns, expandColumns } from "./../DataModel";
import useATProductiveList from "./useATProductiveList";
import useATStatusListAPI from "./useATStatusListAPI";
import { Expand } from "./../Expand/Expand";
import ServerTable from "../../ServerTable/ServerTable";
import { fetchBySiteConfig } from "../API/api";
import { useAlert } from "react-alert";
import { useUserInfo } from "../../../store/auth/UserInfoContext";
import { PageTitle } from "../../AssetInventory/Admin/styles";
import FilterBarWrapper from "../FilterBarWrapper";
import FilterBar from "./FilterBar/FilterBar";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../react-query/constants";
export type SiteFilter = {
	endDate: string;
	isDateRange: boolean;
	searchAll: string;
	site: string;
	startDate: string;
}
// Server table with filters
const AssetTrackerAdmin = () => {
	useEffect(() => {
		document.title = "Asset Request Tracker | Admin"
	}, [])

	const columnsList = useRef<any>(columns);
	const { isLoading: isStatusLoading, data: statusList } = useATStatusListAPI();
	const { isLoading: isProductiveLoading, data: productiveList } =
		useATProductiveList();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const history = useHistory();
	const alert = useAlert();
	const user = useUserInfo();
	const [siteList, setSiteList] = useState<string[]>([]);
	const [reload, setReload] = useState<boolean>(false);
	const [filter, setFilter] = useState<SiteFilter>();
	const [defaultFilter, setDefaultFilter] = useState<SiteFilter>();
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [tableError, setTableError] = useState<Error | null>(null);

	const today = useMemo(() => new Date(), []);

	const TEMP = 0;
	const lastMonth = new Date(
		today.getFullYear(),
		today.getMonth() - 1 - TEMP,
		today.getDate()
	);

	useEffect(() => {
		if (filter) {
			console.log('filter set to', filter)
			setReload(true);
		}
	}, [filter]);

	useEffect(() => {
		if (user) {
			const res = user?.getUserInfo();
			if (res && res.userRole && res.userRole.role) {
				let defaultSite = res.userRole.arrDellSites[0].siteName;
				const temp: string[] = [];
				res.userRole.arrDellSites.forEach(obj => {
					temp.push(obj.siteName);
					if (obj.isDefault === true) {
						defaultSite = obj.siteName
					}
				});
				setSiteList(temp);
				const defaultFilterValue = {
					site: defaultSite,
					searchAll: "",
					isDateRange: true,
					startDate: dateFormat(lastMonth, 'yyyy-mm-dd'),
					endDate: dateFormat(today, 'yyyy-mm-dd'),
				}
				setDefaultFilter(defaultFilterValue)
				setFilter(defaultFilterValue)
			}
		}
	}, [user]);

	useEffect(() => {
		if (statusList && statusList.length > 0)
			columnsList.current = columnsList.current.map(col => {
				if (col.Header === "Status") {
					return {
						...col,
						options_data: statusList,
					};
				}
				return col;
			});
	}, [statusList]);

	useEffect(() => {
		if (productiveList && productiveList.length > 0)
			columnsList.current = columnsList.current.map(col => {
				if (col.Header === "Productive") {
					return {
						...col,
						options_data: productiveList,
					};
				}
				return col;
			});
	}, [productiveList]);

	useEffect(() => {
		setIsLoading(isStatusLoading || isProductiveLoading);
	}, [isProductiveLoading, isStatusLoading]);


	const queryClient = useQueryClient();
	const fetchHandler = (newFilter: SiteFilter): void => {
		let arrDellSites: { siteName: string, isDefault: boolean }[] = [];
		for (let i = 0; i < siteList.length; i++) {
			arrDellSites.push({
				siteName: siteList[i],
				isDefault: siteList[i] === newFilter.site ? true : false
			})
		}
		queryClient.setQueryData([queryKeys.userinfo], (prevData: any) => {
			return {
				...prevData,
				user: {
					...prevData.user,
					userRole: {
						...prevData.user.userRole,
						arrDellSites: arrDellSites
					}
				}
			}
		});
		setFilter(newFilter)
	};

	if (isLoading)
		return (
			<div className="dds__mt-5">
				<LoadingSpinner size="xl" />
			</div>
		);

	return (
		<div className="dds__container-fluid dds__px-0">
			<div>
				<div className="dds__pb-2 dds__mb-2">
					<PageTitle display="inline">
						Administrator - Requests Tracker
					</PageTitle>
				</div>

				{defaultFilter !== null && siteList !== null && (
					<FilterBarWrapper>
						<FilterBar
							onApply={fetchHandler}
							siteList={siteList}
							defaultFilter={defaultFilter!}
							isFetching={tableLoading}
						/>
					</FilterBarWrapper>
				)}
			</div>

			<div className="dds__mt-3">
				{isLoading && (
					<div className="dds__mt-3 dds__mx-auto dds__text-center">
						<LoadingSpinner size="lg" label="Fetching" />
					</div>
				)}
				{!isLoading && filter && (
					<>
						<ServerTable
							apiArgs={[filter]} // add filters
							apiConfig={fetchBySiteConfig}
							columns={columnsList.current}
							searchHeader="Search"
							heading="Asset Request Tracker - Admin"
							tableBorder={false}
							disableHeading
							reload={reload}
							setReload={setReload}
							setTableLoadingState={setTableLoading}
							setTableErrorState={setTableError}
							instanceProps={{
								statusList,
							}}
							canExpand
							ExpandComponent={Expand}
							expandProps={{
								allowEdit: true,
							}}
						/>
					</>
				)}

				{/* {!isLoading && assetData.length === 0 && !error && !loadingTable && (
					<p>No data Found. Please Try Again.</p>
				)}
				{error}
				{(region === null || region.length === 0) && (
					<p>
						You don&apos;t have access to any region. Please contact your manager to
						request access.
					</p>
				)} */}
			</div>
		</div>
	);
};

export default AssetTrackerAdmin;
