import React from "react";
import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";
import { fetchUserRecordsConfig } from "../../../AssetTracker/API/api";
import LoadingSpinner from "../../../Common/LoadingSpinner";
import CardError from "../CardError";
import DDSAccordian from "../../Accordian/DDSAccordian";
import { req_tracker } from "../../constants";
import NoRecordFound from "../../CardTable/NoRecordFound";

const columns = [
	{
		Header: "Request Item Number",
		accessor: "request_item_number",
		Cell: ({ value }) => <>{value}</>,
		searchable: true,
		visible: false,
	},
	{
		Header: "Task Number",
		accessor: "task_number",
		Cell: ({ value }) => <>{value}</>,
		searchable: true,
		visible: true,
	},
	{
		Header: "Date",
		accessor: "created_date",
		Cell: ({ value }) => <>{value}</>,
		searchable: true,
		visible: true,
	},
	{
		Header: "Item",
		accessor: "item",
		Cell: ({ value }) => <>{value}</>,
		searchable: true,
		visible: true,
	},
	{
		Header: "Product",
		accessor: "product",
		Cell: ({ value }) => <>{value}</>,
		searchable: true,
		visible: false,
	},
	{
		Header: "City",
		accessor: "city",
		Cell: ({ value }) => <>{value}</>,
		searchable: true,
		visible: true,
	},
	{
		Header: "Status",
		accessor: "snow_status",
		Cell: ({ value }) => {
			const getStatusColor = (value: string) => {
				switch (value) {
					case "denied":
						return "var(--pink-600)";
					case "pending":
						return "var(--red-600)";
					case "rejected":
						return "var(--orange-600)";
					default:
						return "var(--green-600)";
				}
			};

			return <span className="dds__text-capitalize" style={{
				color: getStatusColor(value)
			}}>
				{value}
			</span>
		},
		searchable: true,
		visible: false,
	},
];
export const createAccordianID = (name: string) => {
	return `accordian-${name.replace(/\s/g, "")}`
}
const User: React.FC = () => {
	const { isLoading, data, isError } = useCustomQuery(
		[queryKeys.ARTUserCard],
		fetchUserRecordsConfig(),
	);

	if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
	if (isError) return <CardError />;

	if (data.records.length === 0)
		return <NoRecordFound />

	return (
		<DDSAccordian
			totalElements={data.length}
			data={data.records}
			isError={isError}
			isLoading={isLoading}
			columns={columns}
			accordianID={createAccordianID(req_tracker)}
		/>
	);
};

export default User;
