import styled from "styled-components";

export const IconButton = styled.button`
	border: 0;
	padding: 5;
	background-color: transparent;
	cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

export const Styles = styled.div`
	padding-left: 0.9375rem;
	padding-right: 0.9375rem;
	${"" /* These styles are suggested for the table fill all available space in its containing element */
	}
	display: block;
	${"" /* These styles are required for a horizontaly scrollable table overflow */
	}

	overflow-y: auto;

	.table {
		border-spacing: 0;
		color: 'var(--gray-900)';
		display: table; /* Since DDS2.7^ changes display to grid, switching back to table display which was there is DDS1.6 */
		/* border: 1px solid lightgray; */

		.thead {
			${"" /* These styles are required for a scrollable body to align with the header properly */
	}
			overflow-y: auto;
			overflow-x: hidden;
			/* color: 'var(--blue-600)';
			border-radius: 10px; */
		}

		.tbody {
			${"" /* These styles are required for a scrollable table body */}
			overflow-y: visible;
			overflow-x: hidden;
			padding-bottom: 1rem;
		}

		.tr {
			:last-child {
				.td {
					/* border-bottom: 0; */
				}
			}
			:nth-child(even) {
				/* background-color: var(--blue-100); */
				background-color: var(--gray-100);
			}
			:hover .resizer {
				background: #d1d1d4;
			}
			border-bottom: 0px;
		}
		.th {
			/* background-color: var(--blue-500); */ 
			/* color: white; */
			
		}

		.th,
		.td {
			margin: 0;
			padding: 0.5rem;
			border-right: 1px solid #E1E1E1;
			overflow: hidden;
			line-height: 1.25rem;
			border-collapse: inherit;

			${"" /* In this example we use an absolutely position resizer,
       so this is required. */
	}
			position: relative;

			:last-child {
				border-right: 0;
			}

			.resizer {
				right: 0;

				width: 3px;
				height: 75%;
				margin-top: 5px;
				margin-bottom: 30px;
				position: absolute;
				top: 0;
				z-index: 1;
				${"" /* prevents from scrolling while dragging on touch devices */}
				touch-action :none;

				&.isResizing {
					background: #969393;
				}
			}
		}
	}
`;
