import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useErrorHandler } from "react-error-boundary";
import ReactTooltip from "react-tooltip";
import { APIRequestFailedError, AuthError, LOST_TOKEN } from "../../ErrorStates";
import useAxios from "../../hooks/useAxios";
import LoadingSpinner from "../Common/LoadingSpinner";
import { IconButton } from "../Table/TableStyles";
import { addSnowTicketConfig } from "./API";
import { useAuth } from "../../hooks/useAuth";


// Create an editable cell renderer
const EditableTextCell: React.FC<{
	value: string;
	row: any;
	column: any;
	tableRole: string;
	userRole: string;
	setData: any;
}> = ({
	value: initialValue,
	row,
	column: { width, id },
	tableRole,
	userRole,
	setData

}) => {
		/*
		TODO: react tooltip id fix
		*/
		const [ticket, setTicket] = useState<string>(initialValue);
		const [editing, setEditing] = useState<boolean>(false);
		const auth = useAuth();
		const alert = useAlert();
		const handleError = useErrorHandler();

		const { isLoading, error: addError, sendRequest } = useAxios();

		const saveTicketSuccessHAndler = (dataObj: any): void => {
			if (dataObj.snowTicket.ticket === "")
				alert.show("Incident number removed successfully", {
					type: "success",
					timeout: 2000
				});
			else
				alert.show("Incident number successfully added", {
					type: "success",
					timeout: 2000
				});
			setEditing(false);

			setData(old =>
				old.map((dataRow, index) => {
					if (index === row.index) {
						return {
							...old[index],
							snowTicket: dataObj.snowTicket.ticket
						}
					}
					return dataRow
				})
			)
		}

		const saveTicketHandler = async (): Promise<void> => {
			// Validation: Either empty ticket or more than 5 length starting with INC
			if (!(ticket.length === 0 || (ticket.length > 5 && ticket.slice(0, 3).toLowerCase() === "inc"))) {
				alert.show("Please enter correct Incident ticket", {
					type: "error",
					timeout: 2000
				})
				return;
			}

			const accessToken = await auth?.getAccessToken();
			if (accessToken)
				sendRequest(
					addSnowTicketConfig(accessToken, {
						assethostname: row.values.assethostname,
						email: row.values.email,
						ticket,
						product: row.values.product,
						servicetag: row.values.servicetag,
						username: row.values.username
					}),
					saveTicketSuccessHAndler
				)
			else
				handleError(new AuthError(LOST_TOKEN));
		}

		useEffect(() => {
			if (addError) {
				if (addError instanceof APIRequestFailedError) {
					if (addError.statusCode === "201") {
						alert.show("Server error, please try again later", {
							type: "error",
							timeout: 5000
						})
					}
					else
						handleError(addError)
				} else if (addError instanceof AuthError) {
					handleError(addError)
				} else {
					alert.show("Connection error, please check your connection and try again", {
						type: "error",
						timeout: 5000
					})
				}
			}
		}, [handleError, addError, alert])



		const valueLink = <>
			<p style={{ display: "inline", textTransform: 'uppercase' }} data-for="snowTicket_tip" data-tip={initialValue}>
				<a
					rel="noreferrer"
					href={`https://dell.service-now.com/textsearch.do?sysparm_search=${initialValue}`}
					target="_blank"
				>
					{" "}
					{initialValue}{" "}
				</a>
			</p>
			<ReactTooltip id="snowTicket_tip" />
		</>
		if (userRole === "user" && tableRole === "search")
			// eslint-disable-next-line react/jsx-no-useless-fragment
			return valueLink

		return <span style={{ wordBreak: 'break-word' }}>
			{!editing && valueLink}
			{editing &&
				<div className="dds__d-block">
					<input
						type="text"
						className="dds__w-100"
						value={ticket}
						onChange={(e) => setTicket(e.target.value.trim())}
						onKeyPress={e => e.key === "Enter" && saveTicketHandler()}
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus

					/>
				</div>
			}
			{!editing && <IconButton
				className="dds__button dds__button--secondary"
				data-for="editTicketTip"
				data-tip="Edit"
				style={{ marginTop: 0, marginBottom: 0 }}
				onClick={() => setEditing(true)}

			>
				<i style={{ fontSize: '1.1rem' }} className="dds__icon dds__icon--pencil" aria-hidden="true" />
				<ReactTooltip id="editTicketTip" />

			</IconButton>}

			{editing && !isLoading && <div className="dds__d-block">
				<IconButton
					className="dds__button dds__button--secondary"
					data-for="saveTicketTip"
					data-tip="Save"
					style={{ marginTop: 0, marginBottom: 0 }}
					onClick={saveTicketHandler}
					disabled={isLoading}

				>
					<>
						<i style={{ fontSize: '1.1rem' }} className="dds__icon dds__icon--pencil-check" aria-hidden="true" />
						<ReactTooltip id="saveTicketTip" /></>


				</IconButton>

				{!isLoading && <IconButton
					className="dds__button dds__button--secondary"
					data-for="cancelTicketTip"
					data-tip="Cancel"
					style={{ marginTop: 0, marginBottom: 0 }}
					onClick={() => setEditing(false)}
					disabled={isLoading}

				>
					<i style={{ fontSize: '1.1rem' }} className="dds__icon dds__icon--pencil-remove" aria-hidden="true" />
					<ReactTooltip id="cancelTicketTip" />

				</IconButton>}

			</div>}
			{isLoading &&
				<div className="dds__mt-1"><LoadingSpinner size="md" />
				</div>
			}

		</span>
	};

export default EditableTextCell