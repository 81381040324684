import React, { useEffect, useRef } from "react";
import { DatePicker } from "@dds/components";

const useDateRangePicker = (sdRef: React.MutableRefObject<any>, edRef: React.MutableRefObject<any>) => {
	const sdInstanceRef = useRef<any>(null);
	const edInstanceRef = useRef<any>(null);
	
	const initialize = (format: string, maxStartDate, maxEndDate) => {
		try{
			sdInstanceRef.current = new DatePicker(sdRef.current, {
				format: format,
			});
			edInstanceRef.current = new DatePicker(edRef.current, {
				format: format,
			});
	
			sdInstanceRef.current.setEndDate(maxStartDate);
			edInstanceRef.current.setEndDate(maxEndDate);
	
			edRef.current!.addEventListener("focusout", preventInvalidFeedback)
			sdRef.current!.addEventListener("focusout", preventInvalidFeedback)

			return true;
		}catch(err){
			return false;
		}
		
	}

	const preventInvalidFeedback = () => {
		document.querySelectorAll('[data-dds="date-picker"]').forEach(el => {
			console.log("here", el);
			el.classList.remove(`dds__date-picker--invalid`);
		});
	}

	const getSelectedRange = () => {
		let startDate = sdInstanceRef.current.getDate().formattedDate;
		let endDate = edInstanceRef.current.getDate().formattedDate;
		return {startDate, endDate}
	}

	const setDateRange = (startDate, endDate) => {
		sdInstanceRef.current.setDate(startDate);
		edInstanceRef.current.setDate(endDate);
	}

	const clearDateRange = () => {
		const elements = document.getElementsByClassName('dds__date-picker__input');
		for (var i = 0; i < elements.length; i++) {
			(<HTMLInputElement>elements[i]).value = '';
		}
	}

	// Dispose element on removal
	useEffect(() => {
		return () => {
			edRef.current!.removeEventListener("focusout", preventInvalidFeedback)
			sdRef.current!.removeEventListener("focusout", preventInvalidFeedback)
			sdInstanceRef.current.dispose();
			edInstanceRef.current.dispose();
		}
		
	}, []);
  return (
	{
		initialize,
		getSelectedRange,
		setDateRange,
		clearDateRange
	}
  )
}

export default useDateRangePicker