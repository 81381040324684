import React, { useEffect } from 'react'
import { Tag } from '@dds/components'

const FilterChip = ({ listItem, controller }) => {
	useEffect(() => {
		console.log("init", listItem);
		[].forEach.call(document.querySelectorAll('[data-dds="tag"]'), function (element) {
			new Tag(element);
		});
	}, [listItem]);

	useEffect(() => {
		addEventListener("ddsTagDismissEvent", clr)
		return function cleanupListener() {
			removeEventListener('ddsDropdownSelectionChangeEvent', clr)
		}
	}, [])
	const clr = (e) => {
		console.log(e.target.innerText);
		clearSelection(e.target.innerText);
	}

	const clearSelection = (item: string) => {
		controller.deselectOption(item);
	}
	return (
		<>
			{listItem.map(item => (
				<span key={item} className="result-item">
					<div data-dds="tag" className="dds__tag dds__tag--sm dds__ml-2" data-dismiss="true" data-sr-dismiss="dismiss">
						<button type='button'>
							{item}
						</button>
					</div>
				</span>
			))}
		</>
	);
};

export default FilterChip