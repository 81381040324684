import React, { useEffect, useRef } from "react";
import useAnnouncementsAPI from "../AnnouncementBanner/useAnnouncementsAPI";
import { activeType, cvl, eol, inventory, req_tracker, unauth, vdi } from "../constants";
import { ToolbarIcon, ToolbarLink, ToolbarWrapper } from "./Styles";
import { Popover, Search } from "@dds/components";
import AnnouncementCarousel from "../AnnouncementBanner/AnnouncementCarousel";
import { useDashboardContext } from "../../../store/dashboard/DashboardContext";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { useAnalytics } from "../../../store/analytics/AnalyticsContext";
import { eventsInfo } from "../../../store/analytics/eventsInfo";

const DashboardToolbar: React.FC<{
	totalApps: number;
	totalFavourite: number;
	totalUserActions: number;
	totalOrgCards: number;
	handleActiveChange: (active: activeType) => void;
}> = ({ totalApps, totalFavourite, handleActiveChange, totalUserActions, totalOrgCards }) => {
	const { data, isLoading, isError } = useAnnouncementsAPI();
	const popoverRef = useRef<HTMLDivElement>(null);
	const popoverInstanceRef = useRef<any>(null);

	const searchInstanceRef = useRef<any>(null);
	const searchRef = useRef<HTMLDivElement>(null);
	const { active, isOrg, activeAPICalls, setSearchCards, searchCards } = useDashboardContext();
	const { addEventToQueue } = useAnalytics();
	useEffect(() => {
		popoverInstanceRef.current = new Popover(
			popoverRef.current,
			{
				placement: "bottom",
				size: "md",
				closeOnScroll: false,
			}
		);
		() => {
			popoverInstanceRef.current?.dispose();
		};
	}, []);
	useEffect(() => {
		searchInstanceRef.current = new Search(
			searchRef.current,
			{
				srSearchButtonText: "Search button",
				srClearButtonText: "Clear search button"
			}
		);
		searchRef.current?.addEventListener("ddsSearchClearEvent", () => {
			setSearchCards("");

		}); searchRef.current?.addEventListener("ddsSearchSubmitEvent", (e: any) => {
			// console.log(e.detail.query)
			setSearchCards(e.detail.query);
		});
		() => {
			searchInstanceRef.current?.dispose();
		};
	}, []);

	useEffect(() => {
		// debounce seach cards for 1 second
		const timeoutId = setTimeout(() => {
			if (searchCards.length > 0)
				addEventToQueue({
					...eventsInfo["search Card Dashboard"](),
					additional_payload: {
						search: searchCards,
						active: active
					}
				})
		}, 1000);
		return () => clearTimeout(timeoutId);
	}, [searchCards])
	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchCards(e.target.value);
	};

	return (
		<ToolbarWrapper >
			<div className="dds__mb-2 dds__d-flex dds__justify-content-between dds__h-50" style={{
				borderBottom: '1px solid #e6e1e1',
			}}>
				<div className="dds__d-flex">
					<ToolbarLink
						isActive={active === "all"}
						href=""
						className="dds__align-self-center dds__ml-4"
						onClick={e => {
							e.preventDefault();
							handleActiveChange("all");
						}}
					>
						<ToolbarIcon
							className="dds__icon dds__icon--dashboard dds__mr-2"
							aria-hidden="true"
						></ToolbarIcon>
						<span className=" dds__d-none dds__d-lg-inline">My View  (
							{totalApps})</span>
					</ToolbarLink>
					{/* Check if leader and then show this option */}
					{totalOrgCards > 0 && <ToolbarLink
						isActive={isOrg}
						href=""
						className="dds__align-self-center dds__ml-4"
						onClick={e => {
							e.preventDefault();
							handleActiveChange("org");
						}}
					>
						<ToolbarIcon
							className="dds__icon dds__mr-2 dds__icon--network-vert"
							aria-hidden="true"
						></ToolbarIcon>
						<span className=" dds__d-none dds__d-lg-inline">My Org  (
							{totalOrgCards})</span>
					</ToolbarLink>}
					{/* Check is user has any pending actions */}
					<ToolbarLink
						isActive={active === "action"}
						href=""
						className="dds__align-self-center dds__ml-4"
						onClick={e => {
							e.preventDefault();
							handleActiveChange("action");
						}}
					>
						<ToolbarIcon
							className="dds__icon dds__icon--alert-check-cir dds__mr-2"
							aria-hidden="true"
						></ToolbarIcon>
						<span className=" dds__d-none dds__d-lg-inline">My Actions  (
							{totalUserActions})</span>
					</ToolbarLink>
					<ToolbarLink
						isActive={active === "fav"}
						href=""
						className="dds__align-self-center dds__ml-4"
						onClick={e => {
							e.preventDefault();
							handleActiveChange("fav");
						}}
						style={{
							display: 'inherit'
						}}
					>
						{activeAPICalls > 0 ? <div className="dds__mr-2"><LoadingSpinner size="sm" /></div> : <ToolbarIcon
							className={`dds__icon ${totalFavourite > 0 ? ' dds__icon--heart-filled ' : ' dds__icon--heart '} dds__mr-2`}
							aria-hidden="true"
						></ToolbarIcon>}
						<span className=" dds__d-none dds__d-lg-inline">{activeAPICalls > 0 ? "Saving Changes" : "Favourite Apps (" +
							totalFavourite + ")"}</span>
					</ToolbarLink>
				</div>
				<div className="dds__d-flex dds__mr-4">
					{/* <ToolbarLink
					isActive={false}
					href=""
					className="dds__align-self-center dds__ml-4"
					onClick={e => {
						e.preventDefault();
						handleActiveChange("fav");
					}}
				>
					<ToolbarIcon
						className="dds__icon dds__icon--view-row dds__mr-2"
						aria-hidden="true"
					></ToolbarIcon>
					<span className=" dds__d-none dds__d-lg-inline">List View </span>
				</ToolbarLink> */}
					<div className="dds__align-self-center dds__ml-4">
						<ToolbarLink
							id="popover-trigger-460004928"
							isActive={false}
							href=""
							onClick={e => {
								e.preventDefault();
							}}
						>
							<ToolbarIcon
								className="dds__icon icon--announcement dds__mr-2"
								aria-hidden="true"
							></ToolbarIcon>
							<span className=" dds__d-none dds__d-lg-inline">Announcements{" "}
								{data?.total ? `(${data.total})` : null}</span>
						</ToolbarLink>
						<div
							ref={popoverRef}
							id="popover-259352430"
							data-trigger="#popover-trigger-460004928"
							data-dds="popover"
							className="dds__popover"
							role="dialog"
							data-placement="bottom-end"
							aria-labelledby="popover-title-675402058"
							style={{
								maxHeight: "100vh",
								minHeight: '100px'
							}}
						>
							<div className="dds__popover__content">
								<div className="dds__popover__header">
									<h6
										id="popover-title-675402058"
										className="dds__popover__headline"
									>
										Announcements
									</h6>
								</div>
								<div className="dds__popover__body" style={{
									overflowY: 'hidden'
								}}>
									<AnnouncementCarousel
										data={data}
										isLoading={isLoading}
										isError={isError}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="dds__mx-4">
				{/* Add Search Cards input field */}
				<div style={{
					maxWidth: 320
				}} ref={searchRef} className="dds__search dds__search--sm" id="search-cards-input" data-dds="search" role="search">
					<div className="dds__search__wrapper">
						<input
							type="search"
							className="dds__search__control"
							placeholder="Search cards"
							name="search-control"
							id="search-control"
							aria-label="Search cards"
							autoComplete="on"
							// list="cards"
							onChange={handleSearchChange}
							value={searchCards}
							maxLength={35}
						/>
						{/* <datalist id="cards">
							<option value={req_tracker} />
							<option value={inventory} />
							<option value={cvl[0]} />
							<option value={vdi} />
							<option value={unauth} />
							<option value={eol} />
						</datalist> */}
					</div>
				</div>
			</div>
		</ToolbarWrapper>
	);

};

export default DashboardToolbar;
