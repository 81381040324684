import { useUserInfo } from "../../../../store/auth/UserInfoContext";
import DeleteAction from "./DeleteAction";
import EditAction from "./EditAction";

const ActionColumn = ({ value, row, setData }) => {
	const userNTID = useUserInfo().getUserInfo().ntId;
	const disabled = (userNTID + "").toLowerCase() === (row.values['ntid'] + "").toLowerCase()
	if (disabled)
		return <><EditAction row={row} /></>
	return (
		<div>
			<EditAction row={row} />
			<DeleteAction row={row} />
		</div>

	)
}

export default ActionColumn