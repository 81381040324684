import React, { useEffect } from "react";
import { Main } from "./styles";
import Header from "../components/Layout/Header/Header";
import Footer from "../components/Layout/Footer/Footer";
import { FavouriteContextProvider } from "../components/Dashboardv3/FavouriteContext/FavouriteContext";


const DashboardLayout: React.FC<{
	children: JSX.Element;
	crumbs: any[];
}> = ({ children, crumbs }) => {


	return <div className="dds__template--productivity">
		<Header enableHamMenu={false} />

		<Main>
			<FavouriteContextProvider>
				{children}
			</FavouriteContextProvider>
		</Main>
		<Footer />
	</div>
};

export default DashboardLayout;
