import React from 'react'
import LoadingSpinner from "../../../Common/LoadingSpinner";
import CardError from "../CardError";
import { useVDIData } from "./useVDIData";
import NoRecordFound from '../../CardTable/NoRecordFound';
import { cardColumns, vdi } from '../../constants';
import DDSAccordian from '../../Accordian/DDSAccordian';
import { createAccordianID } from '../Asset Request Tracker Card/User';

const columns: cardColumns = [
	{
		Header: "VDI Type",
		accessor: "vdi_type",
		Cell: ({ value }) => {
			return <>{value.length > 47 ? `${value.substring(0, 44)}...` : value}</>;
		},
		searchable: true,
		visible: false,
	},
	{
		Header: "Status",
		accessor: "status",
		Cell: ({ value }) => {
			const getStatusColor = (value: string) => {
				switch (value) {
					case "denied":
						return "var(--pink-600)";
					case "pending":
						return "var(--red-600)";
					case "rejected":
						return "var(--orange-600)";
					default:
						return "var(--green-600)";
				}
			};
			return <span className="dds__text-capitalize" style={{
				color: getStatusColor(value)
			}}>
				{value}
			</span>
		},
		searchable: true,
		visible: true,
	},
];

const User: React.FC = () => {
	const { isLoading, data, error, isError } = useVDIData();


	if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
	if (error) return <CardError />;

	if (data.records.length === 0) return <NoRecordFound />;

	return (
		<DDSAccordian
			totalElements={data.records.length}
			data={data.records}
			isError={isError}
			isLoading={isLoading}
			columns={columns}
			accordianID={createAccordianID(vdi)}
		/>
	);
};

export default User;
