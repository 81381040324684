import { useQueryClient } from '@tanstack/react-query';
import { useContext, useMemo } from 'react'
import { createContext } from 'react'
import { queryKeys } from '../../../react-query/constants';
import { eventsInfo } from '../../../store/analytics/eventsInfo';
import useFavAPI from './useFavAPI';

const FavouriteContext = createContext<any>(null);

export const FavouriteContextProvider = ({ children }) => {
	const queryClient = useQueryClient();


	const {
		mutate: fav
	} = useFavAPI(true);
	const {
		mutate: unfav
	} = useFavAPI(false);

	const toggleFavourite = (name: string) => {
		queryClient.setQueryData([queryKeys.metaData], (prevData: any) => {
			console.log(prevData.result)
			if (prevData.result.favouritedCards[name]) { // remove from fav
				const orderFavouritedCards = [...prevData.result.orderFavouritedCards]
				orderFavouritedCards.splice(orderFavouritedCards.indexOf(name), 1)

				const favouritedCards = { ...prevData.result.favouritedCards }
				favouritedCards[name] = false;

				const totalFavourite = prevData.result.totalFavourite - 1;

				// Call API parallely
				unfav({
					cardname: name
				});

				console.log({
					...prevData,
					result: {
						...prevData.result,
						totalFavourite,
						orderFavouritedCards,
						favouritedCards
					}
				})

				return {

					result: {
						...prevData.result,
						totalFavourite,
						orderFavouritedCards,
						favouritedCards
					}
				};
			} else { // add to fav
				const orderFavouritedCards = [...prevData.result.orderFavouritedCards]
				orderFavouritedCards.push(name)

				const favouritedCards = { ...prevData.result.favouritedCards }
				favouritedCards[name] = true;

				const totalFavourite = prevData.result.totalFavourite + 1;

				// Call API parallely
				fav({
					cardname: name
				})

				return {
					// ...prevData,
					result: {
						...prevData.result,
						totalFavourite,
						orderFavouritedCards,
						favouritedCards
					}
				};
			}
		})

	}

	const value = useMemo(() => {
		return { toggleFavourite: toggleFavourite }
	}, [])

	return <FavouriteContext.Provider value={value}>{children}</FavouriteContext.Provider>

}

export const useFavouriteAPI = () => {
	const context = useContext(FavouriteContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}