import ReactTooltip from "react-tooltip";

const columnValue: any[] = [
    {
        Header: "Employee Number",
        accessor: "employeeNumber",
        disableFilters: true,
        disableSortBy: true,
        // width: 80,
        Cell: ({ value }) => (
            <>
                <p
                    style={{ display: "inline", color: "var(--blue-800)" }}
                    data-for="employeeNumber_tip"
                    data-tip={value}
                >
                    {value}
                </p>
                <ReactTooltip id="employeeNumber_tip" />
            </>
        ),
    },
    {
        Header: "Personnel Name",
        accessor: "personnelName",
        disableFilters: true,
        disableSortBy: true,
        // width: 80,
        Cell: ({ value }) => (
            <>
                <p
                    style={{ display: "inline", color: "var(--blue-800)" }}
                    data-for="personnelName_tip"
                    data-tip={value}
                >
                    {value}
                </p>
                <ReactTooltip id="personnelName_tip" />
            </>
        ),
    },
    {
        Header: "Email Address",
        accessor: "email",
        disableFilters: true,
        disableSortBy: true,
        // width: 80,
        Cell: ({ value }) => (
            <>
                <p
                    style={{ display: "inline", color: "var(--blue-800)" }}
                    data-for="email_tip"
                    data-tip={value}
                >
                    {parseInt(value) === 0 ? "" : value}
                </p>
                <ReactTooltip id="email_tip" />
            </>
        ),
    },
    {
        Header: "Employee Status",
        accessor: "status",
        disableFilters: true,
        disableSortBy: true,
        // width: 80,
        Cell: ({ value }) => (
            <>
                <p
                    style={{ display: "inline", color: "var(--blue-800)", fontWeight: 500 }}
                    data-for="status_tip"
                    data-tip={value}
                >
                    {value}
                </p>
                <ReactTooltip id="status_tip" />
            </>
        ),
    },
    {
        Header: "Manager Name",
        accessor: "managerName",
        disableFilters: true,
        disableSortBy: true,
        // width: 80,
        Cell: ({ value }) => (
            <>
                <p
                    style={{ display: "inline", color: "var(--blue-800)" }}
                    data-for="managerName_tip"
                    data-tip={value}
                >
                    {value}
                </p>
                <ReactTooltip id="managerName_tip" />
            </>
        ),
    },
    {
        Header: "Carrier",
        accessor: "carrier",
        disableFilters: true,
        disableSortBy: true,
        // width: 80,
        Cell: ({ value }) => (
            <>
                <p
                    style={{ display: "inline", color: "var(--blue-800)" }}
                    data-for="carrier_tip"
                    data-tip={value}
                >
                    {value}
                </p>
                <ReactTooltip id="carrier_tip" />
            </>
        ),
    },
    {
        Header: "Device Type",
        accessor: "deviceTypeName",
        disableFilters: true,
        disableSortBy: true,
        // width: 80,
        Cell: ({ value }) => (
            <>
                <p
                    style={{ display: "inline", color: "var(--blue-800)" }}
                    data-for="deviceTypeName_tip"
                    data-tip={value}
                >
                    {value}
                </p>
                <ReactTooltip id="deviceTypeName_tip" />
            </>
        ),
    },
    {
        Header: "Device Model",
        accessor: "deviceModelName",
        disableFilters: true,
        disableSortBy: true,
        // width: 80,
        Cell: ({ value }) => (
            <>
                <p
                    style={{ display: "inline", color: "var(--blue-800)" }}
                    data-for="deviceModelName_tip"
                    data-tip={value}
                >
                    {value}
                </p>
                <ReactTooltip id="deviceModelName_tip" />
            </>
        ),
    },
    {
        Header: "Mobile Number(International)",
        accessor: "mobileNumber",
        disableFilters: true,
        disableSortBy: true,
        // width: 80,
        Cell: ({ value }) => (
            <>
                <p
                    style={{ display: "inline", color: "var(--blue-800)" }}
                    data-for="mobileNumber_tip"
                    data-tip={value}
                >
                    {value}
                </p>
                <ReactTooltip id="mobileNumber_tip" />
            </>
        ),
    },
    {
        Header: "Country",
        accessor: "country",
        disableFilters: true,
        disableSortBy: true,
        // width: 40,
        Cell: ({ value }) => (
            <>
                <p
                    style={{ display: "inline", color: "var(--blue-800)", fontWeight: 500 }}
                    data-for="country_tip"
                    data-tip={value}
                >
                    {value}
                </p>
                <ReactTooltip id="country_tip" />

            </>
        ),
    },

];
export default columnValue;