import { useQueryClient } from "@tanstack/react-query";
import { useAlert } from "react-alert";
import { useCustomMutation } from "../../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../../react-query/constants";
import { updateCreateAdminConfig } from "../../../API";

const useSaveData = (mode, handleClose) => {
	const queryClient = useQueryClient();

	// Save success
	const saveSuccessHandler = () => {
		alert.show("Saved", {
			type: "success",
			timeout: 5000,
		});
		handleClose();
		queryClient.invalidateQueries([queryKeys.inventoryadminlist]);
	};

	//To Save data
	const {
		isLoading: isSaving,
		error: saveError,
		isError: isSaveError,
		mutate: saveAPI,
	} = useCustomMutation(updateCreateAdminConfig(mode), {
		onSuccess: saveSuccessHandler,
		onError: error => {
			console.error(error.response.data.message);
			alert.show(
				error.response.data.message
					? error.response.data.message
					: "Unknown error occurred while saving, please try again later",
				{
					type: "error",
					timeout: 5000,
				}
			);
		},
	});

	const alert = useAlert();
	// Function to call save API with all the data
	const saveData = ({ mode, regions, ...props }) => {
		console.log(props);
		let temp: any[] = [];
		let stockrooms: {
			country: string;
			stockroom: string;
		}[] = [];
		regions.forEach(region => {
			if (region.selected !== -1) {
				temp = [];
				console.log(region.country);
				region.stockrooms.forEach(stockroom => {
					if (stockroom.selected) {
						stockrooms.push({
							stockroom: stockroom.stockroom,
							country: stockroom.country,
						});
						temp.push(stockroom.name);
					}
				});
				console.log(temp);
			}
		});
		if (stockrooms.length > 0) {
			const body = {
				...props,
				stockrooms,
			};
			console.log(body);

			saveAPI(body);
		} else {
			alert.show("Please select at least one stockroom to proceed", {
				type: "error",
				timeout: 5000,
			});
		}
	};

	return { isSaving, saveData };
};

export default useSaveData;
