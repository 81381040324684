import { useState } from "react";
import { ArrowContainer, Popover } from "react-tiny-popover";
import ButtonList from "./ButtonList";
import useMetaAPI from "../DashboardPage/useMetaAPI";
import { activeType } from "../constants";

const QuickViewPopover: React.FC<{ cardName: string, active: activeType | undefined, closeDrawer: () => void, openCardDrawer: (item: string, active: activeType) => void }> = ({ cardName, active, closeDrawer, openCardDrawer }) => {
	const { data: metaData } = useMetaAPI();
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	return (
		<Popover
			containerStyle={{ zIndex: "99999", maxWidth: "400px" }}
			isOpen={isPopoverOpen}
			positions={['bottom', 'left']}
			padding={0}
			reposition={true}
			onClickOutside={() => setIsPopoverOpen(false)}
			content={({ position, childRect, popoverRect }) => (
				<ArrowContainer
					position={position}
					childRect={childRect}
					popoverRect={popoverRect}
					arrowColor={'gray'}
					arrowSize={12}
					arrowStyle={{ opacity: 1 }}
					className='popover-arrow-container'
					arrowClassName='popover-arrow'
				>
					<div
						className="dds__elevation-3"
						style={{ backgroundColor: "white", border: "1px solid var(--gray-200)" }}
					>
						<ButtonList
							items={active === "all" ? metaData?.order : active === "fav" ? metaData?.orderFavouritedCards : active === "action" ? metaData?.orderUserActions : metaData?.orderOrgCards}
							active={active ? active : "all"}
							cardName={cardName}
							closeDrawer={closeDrawer}
							openCardDrawer={openCardDrawer}
						/>
					</div>
				</ArrowContainer>
			)}
		>
			<button onClick={() => setIsPopoverOpen(curr => !curr)} className="dds__button" type="button">
				Quick View
				<span className="dds__ml-2 dds__icon dds__icon--dashboard dds__button__icon--end" aria-hidden="true"></span>
			</button>
		</Popover>
	);
};

export default QuickViewPopover;