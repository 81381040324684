import React, { useEffect } from 'react';
import { PageTitle } from '../Admin/styles';
import { TelemetryMeasuresTable } from './TelemetryMeasuresTable';
import columns from './DataModel/DataModel';
import AddMeasuresButton from './Toolbar/AddMeasuresButton';
import { queryKeys } from '../../../react-query/constants';
import { TableContextProvider } from './TableContext';
import { getMeasuresConfig } from './TelemetryAPI';

const TelemetryMeasuresPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Asset Inventory | Telemetry Measures';
  }, []);

  return (
    <div className="dds__container-fluid dds__px-0">
      <PageTitle>Telemetry Measures</PageTitle>
      <TableContextProvider apiConfig={{ keyName: queryKeys.TelemetryMeasuresList, getMeasuresConfig }}>
        <TelemetryMeasuresTable AddMeasuresButton={AddMeasuresButton} columns={columns} />
      </TableContextProvider>
    </div>
  );
};

export default TelemetryMeasuresPage;

