import styled from "styled-components";

export const LogoutBody = styled.div<{}>`
width: 100%;
height: 100px;
`;

export const LogoutContentWrapper = styled.div<{}>`
  position: absolute;
  align-items: center;
  width: 100%;
  height: 85vh;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .icon_lg {
    font-size: 65px;
    color: grey;
  }
  
  .txt_header {
    margin-top: 15px;
    color: #6E6E6E
  }

  .txt_sub_header {
    margin-bottom: 25px;
    width: max-content;
    color: #6E6E6E
  }

`;

export const Logoutbackground = styled.div`
  width: 30%;
  min-height: 85vh;
  background-size: cover;
  float: right;
  background-repeat: no-repeat;
  background-position: center;
`;




