import { useAlert } from "react-alert";
import { useCustomMutation } from "../../../hooks/useCustomQuery";
import { useDashboardContext } from "../../../store/dashboard/DashboardContext";
import { favCardConfig } from "../API";

const useFavAPI = (favourite: boolean) => {
	const alert = useAlert();
	const { addAPICall, removeAPICall } = useDashboardContext();

	const { mutate } = useCustomMutation(favCardConfig(favourite), {
		onSuccess: removeAPICall,
		onMutate: addAPICall,
		onError: error => {
			removeAPICall();
			console.error(error);
			alert.show("Some changes could not be saved", {
				type: "error",
				timeout: 1800,
			});
		},
	});

	return { mutate };
};

export default useFavAPI;
