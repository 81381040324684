import { useState, useEffect } from "react";

const useContainerWidth = () => {
	const containerWidth =
		document.getElementsByClassName("layout")[0]!.clientWidth;

	const [mobileView, setMobileView] = useState(false);
	useEffect(() => {
		// Create hook for container width
		console.log(containerWidth);
		if (containerWidth > 446) setMobileView(false);
		else setMobileView(true);
	}, [containerWidth]);
	return {
		containerWidth,
		mobileView,
	};
};
export default useContainerWidth;
