import styled from "styled-components";

export const CardTableWrapper = styled.div`
	background: var(--gray-100);
	border: 1px solid #e1e1e1;
	border-radius: 10px;
`;

export const ModalTableWrapper = styled.div`
	@media (min-width: 600px) {
		background: var(--gray-100);
		border: 1px solid #e1e1e1;
		border-radius: 10px;
	}
	`

export const ModalTableStyle = styled.table`
	text-align: center;
	display: table;
	width: 100%;
	tr {
		border-bottom: 1px solid var(--gray-300);
	}
	td,
	th {
		padding: 12px;
	}
	th {
		font-weight: 500;
	}
	th:last-child {
		text-align: left;
	}
	tr td:last-child {
		text-align: left;
	}
	th:first-child {
		text-align: left;
	}
	tr td:first-child {
		text-align: left;
	}
	/* td[data-type="more"] {
		text-align: center !important;
	} */
	/* tbody tr:last-child {
	border: none
} */
	
	@media (max-width: 599px) {
		tr {
			margin-top: 8px;
			background: var(--gray-100);
			border: 1px solid #e1e1e1;
			border-radius: 10px;
		}
		thead {
			display: none;
		}
		tbody,
		tr,
		td {
			display: block;
			width: 100%;
			text-align: left;
			position: relative;
		}
		td::before {
			content: attr(data-label);
			font-weight: 500;
			position: relative;
			padding-bottom: 4px;
			text-align: left;
			display: block;
		}
		td {
			border-bottom: 1px solid var(--gray-300);
			padding-left: 12px;
		}
		/* td[data-type="more"] {
			text-align: center;
		} */
		tbody td:last-child {
			border: none;
		}
	}
`;

export const CardTableStyle = styled.table`
	text-align: center;
	display: table;
	width: 100%;
	tr {
		border-bottom: 1px solid var(--gray-300);
	}
	td,
	th {
		padding: 12px;
		vertical-align: top;
	}
	th:last-child {
		text-align: left;
	}
	tr td:last-child {
		text-align: left;
	}
	th:first-child {
		text-align: left;
	}
	tr td:first-child {
		text-align: left;
	}
	td[data-type="more"] {
		text-align: center !important;
	}
	tbody tr:last-child {
		border: none;
	}
	@media (max-width: 479px) {
		thead {
			display: none;
		}
		tbody,
		tr,
		td {
			display: block;
			width: 100%;
			text-align: left;
			position: relative;
		}
		td::before {
			content: attr(data-label);
			position: relative;
			padding-bottom: 4px;
			text-align: left;
			display: block;
			color: #0063B8;
			font-weight: bold;
		}
		td {
			border-bottom: 1px solid var(--gray-300);
			padding-left: 12px;
		}
		td[data-type="more"] {
			text-align: center;
		}
		tbody td:last-child {
			border: none;
		}
	}
`;
