import styled, { keyframes } from "styled-components";

const progress = keyframes`
	from {
		width: 0;
	}
`

export const ProgressBackground = styled.div<{ bgColor, height, maxWidth }>`
	background: ${props => props.bgColor};
	height: ${props => props.height}px;
	width: 100%;
	max-width: ${props => props.maxWidth}px;
	position: relative;
	border-radius: 5px;
	display: inline-block;
`

export const ProgressForeground = styled.div<{ fgColor, progress, textColor }>`
	background: ${props => props.fgColor};
	color: ${props => props.textColor};
	/* box-shadow: 0 3px 3px -5px #F2709C, 0 2px 5px #F2709C; */
	border-Radius: 5px;
	height: 100%;
	width: ${props => props.progress}%;
	opacity: 1;
	transition: 1s ease 0.3s;
	align-items: center;
	text-align: center;
	animation: ${progress} 1500ms ease-in 1;
`