import React, { useEffect, useState } from 'react'
import { useDashboardContext } from '../../../../../store/dashboard/DashboardContext';
import LoadingSpinner from '../../../../Common/LoadingSpinner';
import { inventory } from '../../../constants';
import CardError from '../../CardError';
import { InventoryAdminChart } from './InventoryAdminChart';
import { useInventoryCardAdminData } from './useInventoryCardAdminData';

const Admin: React.FC = () => {
	const { activeTab } = useDashboardContext();
	const { isLoading, error, data } = useInventoryCardAdminData(activeTab.name === inventory && activeTab.category === 'admin')
	const [chartData, setChartData] = useState<{ name: string, value: number }[]>()

	const chartInputHandler = (e) => {
		if (e.target.value === "stock")
			setChartData(data.dataInStock);
		else {
			setChartData(data.dataAssignedDevices);
		}
	}
	useEffect(() => {
		if (data)
			setChartData(data.dataInStock)
	}, [data])


	const COLORS = ["var(--yellow-300)", "var(--blue-800)"];

	if (error) return <CardError />;
	if (isLoading || !chartData) return <LoadingSpinner size="lg"></LoadingSpinner>;

	return (
		<div className="dds__row">

			<fieldset className="dds__w-100 dds__fieldset dds__radio-button-group dds__d-flex dds__justify-content-center dds__justify-content-md-end dds__my-0 dds__py-0 dds__mx-4">
				<div className="dds__radio-button dds__radio-button--sm">
					<input
						className="dds__radio-button__input"
						type="radio"
						name="radiobutton-group-934739391"
						id="radiobutton-463560461"
						value="stock"
						onChange={chartInputHandler}
						defaultChecked
					/>
					<label className="dds__radio-button__label" htmlFor="radiobutton-463560461">In Stock: {data.dataInStock.reduce((a, b) => a + (b['value'] || 0), 0)}</label>
				</div>
				<div className="dds__radio-button dds__radio-button--sm">
					<input
						className="dds__radio-button__input"
						type="radio"
						name="radiobutton-group-934739391"
						id="radiobutton-804118618"
						value="assigned"
						onChange={chartInputHandler}
					/>
					<label className="dds__radio-button__label" htmlFor="radiobutton-804118618">Assigned Devices: {data.dataAssignedDevices.reduce((a, b) => a + (b['value'] || 0), 0)}</label>
				</div>

			</fieldset>
			<InventoryAdminChart COLORS={COLORS} chartData={chartData} />
		</div>
	)
}

export default Admin