import { objectToQuery } from "../../../utils/querycreator"

const baseUrl = process.env.REACT_APP_BASE_URL

export const searchLeadersConfig = (
	params: {
		page?: number
		size?: number
		q?: string
		exportCsv?: boolean
		sort: string
	}):object => ({
		url: `${baseUrl}/api/Fetch/v1/SearchByLeaderCVLSummary?${objectToQuery(params)}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})

	export const productListConfig = (
		params:{
			leader:string,
			level:string
		}
	):object => ({
		url: `${baseUrl}/api/Fetch/v1/LeaderProductWiseCVLCount?${objectToQuery(params)}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
		
	})

	export const leaderCVLDetailsConfig = (
		params:{
			leader:string,
			level:string
		}
	):object => ({
		url: `${baseUrl}/api/Fetch/v1/LeaderCVLDetails?${objectToQuery(params)}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
		
	})

	export const overallProductListConfig = (
		
	):object => ({
		url: `${baseUrl}/api/Fetch/v1/OverallProductWiseCVLCount`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
		
	})

export const cvlStatCountConfig = ():object => ({
		url: `${baseUrl}/api/Fetch/v1/CVLCount`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})

// export const getLeaderBoardConfig = (
// 		params: {
// 			page?: number
// 			size?: number
// 			q?: string
// 			exportCsv?: boolean
// 			sort: string
// 		},
	
// 		details?: boolean,
		
// 		l3name?: string,
// 		l4name?: string,
// 		l5name?: string,

// 	) => {
// 		let endpoint = "/api/leaders"
// 		if (l3name) {
// 			endpoint += "/" + l3name
// 			if (l4name) {
// 				endpoint += "/" + l4name
// 				if (l5name) {
// 					endpoint += "/" + l5name
// 				}
// 			}
// 		}
// 		if (details) endpoint += "/details"
	
// 		return {
// 			url: baseUrl + endpoint + objectToQuery(params),
// 			method: "POST",
// 			headers: {
// 				"Content-Type": "application/json",
// 			},
// 			// body: body,
// 		}
// 	}
const decodeHashUriComponent = (text: string) => {
	const decodedUriComponent = decodeURIComponent(text);
	const b64decoding = window.atob(decodedUriComponent);
	return b64decoding;
}


export const fetchLeaderboardFilterList = () => {
	const endpoint = "/api/Fetch/v2/cvlFilter";
	return {
		url: baseUrl + endpoint,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
}


export const getLeaderBoardConfig = (
	params: {
		page?: number
		size?: number
		q?: string
		exportCsv?: boolean
		sort: string
	},

	// details?: boolean,
	
	// l3name?: string,
	// l4name?: string,
	// l5name?: string,
	urlParams?:{
		l3_manager?: string
		l4_manager?: string
		l5_manager?: string
	},
	filter?:{
		regions: string[],
		countries: string[],
		products: string[],
	}

) => {
	let endpoint = "/api/Fetch/v2/L3LeaderCVLSummary"
	if(urlParams?.l3_manager || urlParams?.l4_manager || urlParams?.l5_manager){
		endpoint = "/api/Fetch/v2/SubLeaderCVLSummary"
		if (urlParams?.l5_manager) {
			params['level'] = 'l5'
			params['sublevel'] = 'l6'
			params['leader'] = decodeHashUriComponent(urlParams.l5_manager)
		}else if(urlParams?.l4_manager){
			params['level'] = 'l4'
			params['sublevel'] = 'l5'
			params['leader'] = decodeHashUriComponent(urlParams.l4_manager)
		}else if(urlParams?.l3_manager){
			params['level'] = 'l3'
			params['sublevel'] = 'l4'
			params['leader'] = decodeHashUriComponent(urlParams.l3_manager)
		}
	}	
	
	
	//if (details) endpoint += "/details"

	return {
		url: baseUrl + endpoint + "?"+objectToQuery(params),
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: filter,
	}
}