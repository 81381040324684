import { Main } from "./styles";
import Footer from "../components/Layout/Footer/Footer";
import React, { Children } from "react";
import LogoutHeader from "./LogoutHeader";

/**
 *
 * This class refers to application Logout session, the user will be redirected to the login
 * page after clicking the logout action button.
 * @param props
 * @returns logout layout
 */
const LogoutLayout: React.FC<{
	children: JSX.Element;
	crumbs: any[];
}> = ({ children, crumbs }) => {
	return (
		<div className="dds__template--productivity">
			<LogoutHeader />
			<Main>{children}</Main>
			<Footer />
		</div>
	);
};

export default LogoutLayout;
