import { inventory } from "../../Dashboardv3/constants";
import { SideNavigation } from "./SideNavigation";

const AssetInventorySideNavigaton: React.FC<{
	userType: {
		user: boolean,
		admin: boolean,
		superAdmin: boolean,
		leader: boolean
	},
}> = ({ userType }) => {
	const determineLinks = () => {
		const links = [
			{ show: true, path: '/', name: 'Home', iconClass: 'dds__icon--home', cascadingLink: false },
			{ show: true, path: '/assetinventory', name: 'My Assets', iconClass: 'dds__icon--user', cascadingLink: false },
			{ show: userType.leader || userType.admin || userType.superAdmin, path: '/assetinventory/leaderboard', name: 'Leaderboard', iconClass: 'dds__icon--user-groups', cascadingLink: true },
			{ show: userType.leader, path: '/assetinventory/myorg', name: 'My Organization Assets', iconClass: 'dds__icon--user-groups', cascadingLink: false },
			{ show: userType.admin || userType.superAdmin, path: '/assetinventory/admin', name: 'Administrator', iconClass: 'custom_admin_icon', cascadingLink: false },
			{ show: userType.superAdmin, path: '/assetinventory/manageusers', name: 'Manage Users', iconClass: 'dds__icon--gear-wrench', cascadingLink: false },
			{ show: userType.superAdmin, path: '/assetinventory/telemetrymeasures', name: 'Telemetry Measures', iconClass: 'dds__icon--diagnostic', cascadingLink: false }
		];

		return links.filter(link => link.show);
	}

	return (
		<SideNavigation portalName={inventory} links={determineLinks()} />)
};

export default AssetInventorySideNavigaton;
