import { useEffect } from "react";
import { useAlert } from "react-alert";
import useAxios from "../../../hooks/useAxios";
import { downloadData } from "../../../utils/downloadData";
import columns from "./ExportColumns";
import { leaderCVLDetailsConfig } from "./API";
import { ReactComponent as ExportIcon } from "../../../Assets/ExportCSV blue.svg"
import LoadingSpinner from "../../Common/LoadingSpinner";

const ExportButton: React.FC<{ leader: string, level: string }> = ({ leader, level }) => {
	const {
		isLoading: isExporting,
		error: isExportError,
		sendRequest: exportData,
	} = useAxios();

	const alert = useAlert();

	const exportDataHandler = () => {
		exportData(leaderCVLDetailsConfig({
			leader, level
		}), (dataObj) => {
			downloadData(dataObj, columns, `CVL Data(${level.toUpperCase()}) - ${leader}`)
		})
	}
	useEffect(() => {
		if (isExportError)
			alert.show(`Failed to export data for ${leader}. Please try again later.`, {
				type: 'error',
				timeout: 5000
			})
	}, [isExportError])

	return <button
		disabled={isExporting}
		type="button"
		onClick={() => exportDataHandler()
		}
		className="dds__button dds__button--sm dds__button--secondary dds__ml-2 dds__mr-1 dds__mb-1"
	>
		{!isExporting && <>
			<ExportIcon style={{ marginRight: 10 }} />
			Export
		</>}
		{isExporting && (
			<>
				<LoadingSpinner size='sm' />
				<span className="dds__ml-2">Exporting</span>
			</>
		)}
	</button>
}

export default ExportButton