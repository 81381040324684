import { Fragment, useEffect, useRef } from "react";
import DDSModal from "../../Common/Modalv2/DDSModal";
import { Tooltip } from "@dds/components";
import { CardWrapper, CardHeader, IconButtonWrapper } from "./Styles";
import { useFavouriteAPI } from "../FavouriteContext/FavouriteContext";
import { useAnalytics } from "../../../store/analytics/AnalyticsContext";
import { eventsInfo } from "../../../store/analytics/eventsInfo";
import { useDashboardContext } from "../../../store/dashboard/DashboardContext";

const Card: React.FC<{
	themeColor: string,
	name: string,
	idName: string,
	ddsIconClass: string,
	isFav: boolean,
	description: string,
	isLoading: boolean,
	actionRequired: boolean,
}> = ({
	themeColor,
	name,
	idName,
	ddsIconClass,
	isFav,
	description,
	isLoading,
	children,
	actionRequired = false
}) => {
		let modalInstanceRef = useRef<any>();

		const { active } = useDashboardContext();

		const { toggleFavourite } = useFavouriteAPI();

		const { addEventToQueue } = useAnalytics();

		const handleFavorite = () => {
			addEventToQueue({
				...eventsInfo[idName + " toggleFavourite"](),
				additional_payload: isFav ? "unfavourite" : "favourite"
			})
			toggleFavourite(idName);
		};

		const infoPopoverHandler = () => {
			if (modalInstanceRef.current) {
				console.log(idName + " info button")
				addEventToQueue(eventsInfo[idName + " info button"]())
				modalInstanceRef.current.open();
			}
		};
		const id = name.split(" ").join("");
		useEffect(() => {
			const element = document.getElementById(`tooltip-${id}`);
			Tooltip(element);
			console.log("Action REqired", actionRequired, idName
			)
		}, []);

		return (
			<Fragment>
				<CardWrapper className="dds__border " isLoading={isLoading} actionRequired={actionRequired}>
					<div className="card-border action-border"></div>
					<CardHeader
						backgroundColor={`white`}
						className="dds__d-flex dds__justify-content-between dds__px-3"
					>
						{active === 'fav' ? <button type="button" style={{
							border: 0,
							backgroundColor: 'transparent',
							padding: 0,
							justifyContent: 'start'
						}}
							className={`dds__d-flex dda__w-100 dds__flex-grow-1 handle dds__button`}
							aria-describedby="operation"
							onClick={() => {
								console.log("Dragging", idName)
								addEventToQueue({
									...eventsInfo["Drag and Drop favourite apps"](),
									additional_payload: {
										cardName: idName
									}
								})
							}}>
							<div tabIndex={-1} className="dds__d-none dds__d-sm-flex dds__align-self-center dds__mx-2" aria-hidden="true">
								<i
									style={{
										color: `var(--${themeColor}-800)`,
										fontSize: "1.8rem",
									}}
									className={`dds__icon ${ddsIconClass}`}
									aria-hidden="true"
								/>
							</div>
							<div
								className="dds__h6 dds__align-self-center"
								style={{
									color: `var(--${themeColor}-800)`,
									margin: 0,
								}}
							>
								{name}
							</div>
						</button> :
							<p style={{
								border: 0,
								margin: 0,
								backgroundColor: 'transparent',
								padding: 0,
								justifyContent: 'start'
							}}
								className="dds__d-flex dda__w-100 dds__flex-grow-1 dds__align-items-end" >
								<div tabIndex={-1} className="dds__d-none dds__d-sm-flex dds__align-self-center dds__mx-2" aria-hidden="true">
									<i
										style={{
											color: `var(--${themeColor}-800)`,
											fontSize: "1.8rem",
										}}
										className={`dds__icon ${ddsIconClass}`}
										aria-hidden="true"
									/>
								</div>
								<div
									className="dds__h6 dds__align-self-center"
									style={{
										color: `var(--${themeColor}-800)`,
										margin: 0
									}}
								>
									{name}
								</div>
							</p>
						}

						<div className="dds__align-self-center">
							<IconButtonWrapper
								aria-label={isFav ? `remove ${name} from favourites` : `add ${name} to favourites`}
								role={'button'}
								// aria-label="Toggle favourite"
								onClick={handleFavorite}
								className="dds__button dds__button--secondary dds__button--md  dds__border-0 dds__p-0"
							>
								<i
									style={{
										color: "#17319E",
										fontSize: "1.2rem",
									}}
									className={` dds__icon ${isFav ? "dds__icon--heart-filled" : "dds__icon--heart"
										}`}
									id={`tooltip-trigger-${id}`}
									aria-describedby={`tooltip-${id}`}
								/>
								<div
									id={`tooltip-${id}`}
									data-trigger={`#tooltip-trigger-${id}`}
									data-dds="tooltip"
									className="dds__tooltip"
									role="tooltip"
								>
									<div className="dds__tooltip__body">Toggle Favourite</div>
								</div>
							</IconButtonWrapper>
							<IconButtonWrapper
								role={'button'}
								aria-label={`${name} information`}
								onClick={infoPopoverHandler}
								className=" dds__button dds__button--secondary dds__button--md  dds__border-0 dds__mx-2 dds__mx-md-3 dds__p-0"
							>
								<i
									style={{
										color: "var(--" + themeColor + "-800)",
										fontSize: "1.2rem",
									}}
									className=" dds__icon dds__icon--alert-info-cir"
									id={`tooltip-info-trigger-${id}`}
									aria-describedby={`tooltip-info-${id}`}
								/>
								<div
									id={`tooltip-info-${id}`}
									data-trigger={`#tooltip-info-trigger-${id}`}
									data-dds="tooltip"
									className="dds__tooltip"
									role="tooltip"
								>
									<div className="dds__tooltip__body">View card description</div>
								</div>
							</IconButtonWrapper>
						</div>
					</CardHeader>
					<div className="dds__d-flex dds__justify-content-center dds__align-items-center" style={{
						height: 60,
					}}>{children}</div>
				</CardWrapper>
				<DDSModal
					themeColor={themeColor}
					iconClass={ddsIconClass}
					modalInstanceRef={modalInstanceRef}
					classes={"dds__rounded"}
					id={name.replace(/\s/g, "")}
					title={name}
					footerButtons={[]}
				>
					{description}
				</DDSModal>
			</Fragment>
		);
	};

export default Card;
