// This is a custom filter UI for selecting
// a unique option from a list

import { useMemo } from "react";
import { FilterProps, FilterValue, IdType, Row } from "react-table";
import { Asset } from "../AssetTracker/DataModel";

export const SelectColumnFilter = ({
	column: { filterValue, setFilter, preFilteredRows, id },
}: FilterProps<Asset>): JSX.Element => {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = useMemo(() => {
		const options = new Set<any>();
		preFilteredRows.forEach(row => {
			options.add(row.values[id]);
		});
		return [...Array.from(options.values())];
	}, [id, preFilteredRows]);

	// Render a multi-select box
	return (
		<select
			className="dds__input-text"
			value={filterValue}
			onChange={e => {
				setFilter(e.target.value || undefined);
			}}
		>
			<option value="">All</option>
			{options.map((option, i) => (
				<option key={i} value={option}>
					{option}
				</option>
			))}
		</select>
	);
};

export const DefaultColumnFilter = ({
	column: { filterValue, preFilteredRows, setFilter },
}: FilterProps<Asset>) => {
	const count = preFilteredRows.length;
	// return null

	return (
		<input
			className="dds__input-text"
			value={filterValue || ""}
			onChange={e => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
			placeholder={`Search ${count} records...`}
		/>
	);
}

export const DateRangeColumnFilter = ({
	column: { filterValue = [], preFilteredRows, setFilter, id },
}: FilterProps<Asset>) => {
	const [min, max] = useMemo(() => {
		let min = new Date(99999999999999);
		let max = new Date(0);
		if (preFilteredRows.length) {
			const fetched_created_date = String(preFilteredRows[0].values[id]).split(
				"-"
			);
			min = new Date(
				parseInt(fetched_created_date[0], 10),
				parseInt(fetched_created_date[1], 10) - 1,
				parseInt(fetched_created_date[2], 10)
			);
			min = new Date(
				parseInt(fetched_created_date[0], 10),
				parseInt(fetched_created_date[1], 10) - 1,
				parseInt(fetched_created_date[2], 10)
			);
		}
		// let min = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0)
		// let max = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0)
		preFilteredRows.forEach(row => {
			const temp = String(row.values[id]).split("-");
			const rowDate = new Date(
				parseInt(temp[0], 10),
				parseInt(temp[1], 10) - 1,
				parseInt(temp[2], 10)
			);

			min = rowDate <= min ? rowDate : min;
			max = rowDate >= max ? rowDate : max;
		});
		return [min, max];
	}, [id, preFilteredRows]);
	// console.log("min max")
	// console.log(min)
	// console.log(min.toISOString().slice(0,10))
	// console.log(min.getFullYear()+"-"+(min.getMonth()+1>9?(min.getMonth()+1):"0"+(min.getMonth()+1))+"-"+(min.getDate()>9?min.getDate():"0"+min.getDate()))

	// console.log(max)
	// console.log(max.toISOString().slice(0,10))
	// console.log(max.getFullYear()+"-"+(max.getMonth()+1>9?(max.getMonth()+1):"0"+(max.getMonth()+1))+"-"+(max.getDate()>9?max.getDate():"0"+max.getDate()))

	return (
		<div className="dds__row">
			<div className="dds__col-md-4 ">
				<label htmlFor="">Start Date: </label>
				<input
					className="dds__input-text"
					style={{ display: "inline", width: "160px" }}
					min={
						`${min.getFullYear()
						}-${min.getMonth() + 1 > 9
							? min.getMonth() + 1
							: `0${min.getMonth() + 1}`
						}-${min.getDate() > 9 ? min.getDate() : `0${min.getDate()}`}`
					}
					max={
						`${max.getFullYear()
						}-${max.getMonth() + 1 > 9
							? max.getMonth() + 1
							: `0${max.getMonth() + 1}`
						}-${max.getDate() > 9 ? max.getDate() : `0${max.getDate()}`}`
					}
					onChange={e => {
						const val = e.target.value;
						setFilter((old = []) => {
							if (
								(val === undefined || val === null || val === "") &&
								(old[1] === undefined || old[1] === null)
							)
								return undefined;
							return [val || undefined, old[1]];
						});
					}}
					type="date"
					value={filterValue[0] || ""}
				/>
			</div>
			{/* {' to '} */}
			<div className="dds__col-md-4 ">
				<label htmlFor="cars">End Date: </label>
				<input
					className="dds__input-text"
					style={{ display: "inline", width: "160px" }}
					min={
						`${min.getFullYear()
						}-${min.getMonth() + 1 > 9
							? min.getMonth() + 1
							: `0${min.getMonth() + 1}`
						}-${min.getDate() > 9 ? min.getDate() : `0${min.getDate()}`}`
					}
					max={
						`${max.getFullYear()
						}-${max.getMonth() + 1 > 9
							? max.getMonth() + 1
							: `0${max.getMonth() + 1}`
						}-${max.getDate() > 9 ? max.getDate() : `0${max.getDate()}`}`
					}
					onChange={e => {
						const val = e.target.value;
						setFilter((old: any = []) => {
							console.log(val);
							console.log(old[0]);
							if (
								(val === undefined || val === null || val === "") &&
								(old[0] === undefined || old[0] === null)
							)
								return undefined;
							return [old[0], val || undefined];
						});
					}}
					type="date"
					value={filterValue[1]?.slice(0, 10) || ""}
				/>
			</div>
		</div>
	);
};

export const dateBetweenFilterFn = (
	rows: Array<Row>,
	id: IdType<any>,
	filterValues: FilterValue
) => {
	// The value received here is by converting min and max to ISO String, who's format is YYYY-MM-DD
	let temp1: any;
	let temp2: any;
	let sd: any;
	let ed: any;
	console.log(filterValues);

	if (filterValues[0]) {
		temp1 = String(filterValues[0]).split("-");
		// console.log(temp1);
		sd = new Date(
			parseInt(temp1[0], 10),
			parseInt(temp1[1], 10) - 1,
			parseInt(temp1[2], 10)
		);
	}
	if (filterValues[1]) {
		temp2 = String(filterValues[1]).split("-");
		// console.log(temp2);
		ed = new Date(
			parseInt(temp2[0], 10),
			parseInt(temp2[1], 10) - 1,
			parseInt(temp2[2], 10)
		);
	}
	// let sd = filterValues[0] ? new Date(parseInt(temp1[2]), parseInt(temp1[1])-1, parseInt(temp1[0])) : undefined
	// let ed = filterValues[1] ? new Date(filterValues[1]) : undefined
	// console.log("sded")
	// console.log(sd + "    " + ed);
	if (ed || sd) {
		return rows.filter(r => {
			const temp3 = r.values[id].split("-");
			// console.log(temp3)

			const time = new Date(temp3[0], temp3[1] - 1, temp3[2]); // yyyy-mm-dd

			if (ed && sd) {
				return time >= sd && time <= ed;
			} if (sd) {
				return time >= sd;
			} if (ed) {
				return time <= ed;
			}
		});
	}
	return rows;

};
