import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";
import { fetchVDIDashboardConfig } from "../API";
// const sampleData = {
// 	records: [{
// 		vdi_type:'VDI',
// 		emp_id:'123456',
// 		status:'Active',
// 	}],
// 	total: 1,
// };
export const useVDIData = () => {
	return useCustomQuery([queryKeys.VDICard], fetchVDIDashboardConfig(), {
		select: data => 
		// sampleData
		data.result,
	});
};
