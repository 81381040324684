import { createContext, useContext, useEffect, useState } from "react";
import LoadingSpinner from "../../Common/LoadingSpinner";
import useCvlAdminList from "./useCvlAdminList";

const ManageCvlAdminContext = createContext<any>(null);

export const ManageCvlAdminContextProvider = ({ children }) => {
	const [error, setError] = useState<string>();
	const {
		isLoading: isDataLoading,
		error: dataError,
		data
	}: {
		isLoading: boolean,
		error: any,
		data: any
	} = useCvlAdminList();
	useEffect(() => {
		if (dataError) {
			if (dataError.response && dataError.response.status >= 500) {
				setError(
					"500 Internal Server Error: Sorry, something went wrong on our end. We are working to fix the issue as soon as possible. Please try again later."
				);
			} else
				setError(
					"Data is not loading at the moment. Please check your connection and try again."
				);
		}
	}, [dataError]);
	return (
		<ManageCvlAdminContext.Provider value={{
			data
		}}>
			{isDataLoading && !error && (
				<div
					className="dds__mx-auto dds__text-center"
					style={{
						marginTop: 150,
					}}
				>
					<LoadingSpinner label="Fetching admin list" />
				</div>
			)}
			{!isDataLoading && !dataError && children}
			{dataError && (
				<p
					className="dds__mx-auto dds__text-center"
					style={{
						marginTop: 150,
					}}
				>
					{error}
				</p>
			)}
		</ManageCvlAdminContext.Provider>

	)
}

export const useCvlAdminListAPI = () => {
	const context = useContext(ManageCvlAdminContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}