import { ResponsiveContainer, AreaChart, XAxis, Tooltip, Area } from 'recharts';
import useContainerWidth from '../../../useContainerWidth';

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
	if (active) {
		return (
			<div
				style={{
					background: 'rgba(255, 122, 89, .5)',
					padding: '5px 5px 0px 5px',
					borderRadius: 5
				}}
				className="custom-tooltip">
				<p className="label"
				>{`${label} : ${payload[0].value}`}</p>
			</div>
		);
	}
	return null
}

const EOLLeaderChart = ({ data }) => {
	const { mobileView } = useContainerWidth();

	return (
		<ResponsiveContainer width='100%' height={mobileView ? 220 : 140}>
			<AreaChart
				data={data?.chartInfo}
				margin={{
					top: 10,
					right: 0,
					left: 0,
					bottom: 0
				}}
			>
				<XAxis interval="preserveStartEnd" dataKey="qtrName" stroke="var(--berry-300)" />
				<Tooltip content={<CustomTooltip />} />
				<Area dataKey="eolCount" fillOpacity={1} stroke="var(--red-700)" fill="var(--red-100)" />
			</AreaChart>
		</ResponsiveContainer>
	)
}

export default EOLLeaderChart