import { useCustomQuery } from "../../../hooks/useCustomQuery";
import { queryKeys } from "../../../react-query/constants";
import { fetchAnnouncementsConfig } from "../Cards/API";

type Records = {
	title: string;
	content: string;
	announcement_id: number;
};

type Result = {
	records: Records;
	total: number;
};
const useAnnouncementsAPI = () => {
	return useCustomQuery([queryKeys.announcements], fetchAnnouncementsConfig(), {
		select: (data): Result => {
			return data.result;
		},
		// enabled: false,
	});
};

export default useAnnouncementsAPI;
