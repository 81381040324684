import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert';
import { useErrorHandler } from 'react-error-boundary';
import { AuthError, APIRequestFailedError } from '../../ErrorStates';
import useAxios from '../../hooks/useAxios';
import LoadingSpinner from '../Common/LoadingSpinner';
import Modal from '../Common/Modal/Modal'
import { IconButton } from '../Table/TableStyles';
import { addAdminCommentConfig, addUserCommentConfig, fetchCommentsConfig } from './API';
import { useAuth } from '../../hooks/useAuth';
import { useUserInfo } from '../../store/auth/UserInfoContext';

const CommentsCellModal: React.FC<{
	commentsPanelOpen: any,
	setCommentsPanelOpen: any,
	initialValue: any,
	row: any,
	tableRole: string,
	userRole: string,
	setData: any

}> = ({ commentsPanelOpen, setCommentsPanelOpen, initialValue, row, tableRole, userRole, setData }) => {

	// We need to keep and update the state of the cell normally
	const [value, setValue] = useState<object[]>([]);
	// console.log(initialValue);





	// If the initialValue is changed external, sync it up with our state
	// useEffect(() => {
	// 	if (initialValue)
	// 		setValue(JSON.parse(initialValue));
	// 	else
	// 		fetchCommentsHandler();
	// }, [initialValue]);

	const alert = useAlert();
	const handleError = useErrorHandler();
	// const { sendErrorLog } = useErrorAPI();
	const auth = useAuth();
	const user = useUserInfo();
	const [loggedInUserID, setloggedInUserID] = useState("")
	const [loggedInUserRole, setloggedInUserRole] = useState("")
	const [validationErrorMessage, setValidationErrorMessage] = useState<string | null>(null);
	const [commentInput, setCommentInput] = useState("")

	useEffect(() => {
		if (user && user?.getUserInfo().userName) {
			setloggedInUserID(user?.getUserInfo().userName.toLowerCase())
		}

		console.log(loggedInUserID)
	}, [user])

	const {
		isLoading: isFetchingComments,
		error: fetchError,
		sendRequest: fetchComments,
	} = useAxios();

	const {
		isLoading: isAddingcomment,
		error: addError,
		sendRequest: addComment,
	} = useAxios();

	const fetchCommentsHandler = async () => {
		// if (!initialValue) {
		const accessToken = await auth?.getAccessToken();
		if (accessToken) {
			fetchComments(fetchCommentsConfig(accessToken, {
				assethostname: row.values.assethostname,
				email: row.values.email,
				product: row.values.product,
				servicetag: row.values.servicetag,
				username: row.values.username
			}), (dataObj: any) => {
				setValue(dataObj.comments)
			})
		}
		else
			handleError(new AuthError("Auth Error"))
		// }
	}

	useEffect(() => {
		fetchCommentsHandler();
	}, [])

	const [fetchErrorMessage, setFetchErrorMessage] = useState<string | null>(null)

	useEffect(() => {
		if (fetchError) {
			// if it comes here, we know data state wouldn't have been updated because that function only gets called when response is 200
			// setValue(initialValue); //rolling back changes
			if (fetchError instanceof APIRequestFailedError) {
				if (fetchError.statusCode !== "201") {
					handleError(fetchError);
				} else {
					setFetchErrorMessage("No comments present");
				}
			} else if (fetchError instanceof AuthError) {
				handleError(fetchError);
			} else {
				setFetchErrorMessage(
					"Data is not loading at the moment. Please check your connection and try again."
				);
			}
		}
	}, [fetchError, handleError]);


	useEffect(() => {
		if (addError) {
			if (addError instanceof APIRequestFailedError) {
				setValidationErrorMessage("Unknwon error occurred, please try again later");
			}
			else if (addError instanceof AuthError) {
				setValidationErrorMessage("You are not authorized to perform this action");
			} else {
				setValidationErrorMessage("Please check your connection and try again.");
			}
		}

	}, [handleError, initialValue, addError]);

	const addCommentSuccessHandler = (dataObj: any): void => {
		setFetchErrorMessage(null)
		setValue(dataObj.comments)
		console.log("add comments return ", dataObj.comments)
		setCommentInput("");

		// setData(old => old)

		setData(old =>
			old.map((dataRow, index) => {
				if (index === row.index) {
					return {
						...old[index],
						total_comments: dataObj.total_comments,
						comment_time: dataObj.comments[0].comment_time
					}
				}
				return dataRow
			})
		)
	}


	const addCommentHandler: any = async () => {
		if (commentInput.length === 0)
			setValidationErrorMessage("Please enter your comment")
		else {
			const accessToken = await auth?.getAccessToken();
			if (accessToken) {
				setFetchErrorMessage(null)
				if (tableRole === "user") {
					addComment(addUserCommentConfig(accessToken, {
						assethostname: row.values.assethostname,
						email: row.values.email,
						message: commentInput,
						product: row.values.product,
						servicetag: row.values.servicetag,
						username: row.values.username
					}), addCommentSuccessHandler)
				}
				else if (tableRole === "admin") {
					addComment(addAdminCommentConfig(accessToken, {
						assethostname: row.values.assethostname,
						email: row.values.email,
						message: commentInput,
						product: row.values.product,
						servicetag: row.values.servicetag,
						username: row.values.username
					}), addCommentSuccessHandler)
				}
				else if (tableRole === "search" && (userRole === "admin" || userRole === "superadmin")) {
					addComment(addAdminCommentConfig(accessToken, {
						assethostname: row.values.assethostname,
						email: row.values.email,
						message: commentInput,
						product: row.values.product,
						servicetag: row.values.servicetag,
						username: row.values.username
					}), addCommentSuccessHandler)
				}

			}
			else
				handleError(new AuthError("Auth Error"))
		}
	}

	const commentInputChangeHandler = (e): void => {
		const text = e.target.value;
		setCommentInput(text)
		if (text.length > 3000) {
			const diff = text.length - 3000;
			setValidationErrorMessage(`Comment size should be less than 3000 characters. Please remove ${diff} characters.`)
			return;
		}
		if (validationErrorMessage)
			setValidationErrorMessage(null)
	}

	const formatDate = (date: string): string => {
		const dateObj = new Date(Date.parse(date))
		return `${dateObj.toLocaleDateString()} ${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`
	}

	useEffect(() => {
		console.log("error", fetchErrorMessage)
	}, [fetchErrorMessage])

	return commentsPanelOpen && <Modal classNames='dds__p-3' onClose={() => { setCommentsPanelOpen(false) }}>
		<div id="comment-header" >
			<div className="dds__mb-3">
				<h3 className="dds__h3 dds__d-inline">Comments</h3>
				<div style={{
					float: 'right'
				}}>
					<IconButton
						onClick={() => { setCommentsPanelOpen(false) }}
						className="dds__button dds__button--secondary dds__w-100"
						data-for="commentTip"
						data-tip="Comment panel"
					// ref={clickMeButtonRef}
					>
						<i style={{ fontSize: '1rem' }} className={`dds__icon dds__icon--close-x`} aria-hidden="true" />
						<span className=' dds__sr-only'>Close comments</span>
						{/* <ReactTooltip id="commentTip" /> */}
					</IconButton>

				</div>
			</div>

			<div
				style={{
					background: "var(--blue-900)",
					color: "white",

				}}
				className='dds__p-1 '
			>
				<p>Product: {row.values.product}</p>
				<p>Hostname: {row.values.assethostname.length > 0 && <>({row.values.assethostname})</>}</p>
			</div>
		</div>
		{!(tableRole === "search" && (userRole === "user")) && <div id="comment-input-body">
			<div className="dds__my-2">
				<div className="dds__row">
					<div
						className=" dds__w-100 dds__col-12 dds__col-sm-12 dds__col-md-12 dds__input-text__wrapper dds__input-text__wrapper--button dds__input-text__container--sm  "
						style={{
							lineHeight: `${"2.5rem"}`,
							padding: "0.5rem 1rem",
						}}
					>
						<textarea
							style={{
								maxWidth: `100%`,
								resize: "none",
								wordWrap: "break-word",
								lineHeight: 1

							}}
							disabled={isAddingcomment}
							onChange={commentInputChangeHandler}
							// onKeyPress={e => e.key === "Enter" && addCommentHandler()}
							rows={2}
							value={commentInput}
							// maxLength={3000}
							required
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus

							className="dds__input-text"
							placeholder="Enter comment"
						/>

						<button
							style={{ minWidth: 50 }}
							className="dds__button dds__button--primary dds__button--md"
							type="button"
							onClick={addCommentHandler}
							disabled={isAddingcomment || isFetchingComments || (!!validationErrorMessage)}
						>
							Send
						</button>
					</div>
					{validationErrorMessage &&
						<div style={{ color: 'red' }} id="error-text">
							{validationErrorMessage}
						</div>}


				</div>
			</div>

		</div>}
		<div id="comment-body">
			{(isFetchingComments || isAddingcomment) && <LoadingSpinner size='md' />}
			{!isFetchingComments && !fetchErrorMessage && <div>
				{value.length > 0 && value.map((obj: any) => <div style={{
					// textAlign: obj.commented_by_username.toLowerCase() === loggedInUserID ? "right" : "left"
					// textAlign: "left"
				}}
					className="dds__my-2"
				>
					<div style={{
						color: obj.role === "admin" ? "var(--green-700)" : "var(--blue-500)",
					}}>
						{obj.commented_by} {obj.role === "admin" && <>(Admin)</>}
						<div
							style={{
								// float: obj.commented_by_username.toLowerCase() === loggedInUserID ? "left" : "right"
								float: "right"
							}}
							className=" dds__d-inline">
							{obj.comment_time ? formatDate(obj.comment_time) : ""}
						</div>
					</div>
					<div style={{
						// textAlign: "left",
						// width: "50%",
						// float: obj.commented_by_username.toLowerCase() === loggedInUserID ? "left" : "right"
						// float: "right"
					}}>
						{obj.message.split('\n').map((item, i) => <p style={{ wordBreak: "break-all" }} key={i}>{item}</p>)}
					</div>
				</div>)}
			</div>}
			{fetchErrorMessage && !isAddingcomment && !isFetchingComments && <p>{fetchErrorMessage}</p>}

		</div>

	</Modal>

}
export default CommentsCellModal;