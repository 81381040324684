import { FC, useEffect } from 'react'
import { Progress, loadURLSVGs } from "@dds-uicore/all";
import { ProgressBackground, ProgressForeground } from './Styles';

const ProgressBar: FC<{
	progress: number,
	bgColor?: string,
	textColor?: string,
	fgColor?: string,
	showPercentage?: boolean,
	maxWidth?: number,
	height?: number
}> = ({ progress,
	bgColor = 'white',
	textColor = 'black',
	fgColor = 'blue',
	showPercentage = true,
	maxWidth,
	height = 20
}) => progress >= 0 && progress <= 100 ?
			<ProgressBackground id="container" bgColor={bgColor} height={height} maxWidth={maxWidth}>
				<ProgressForeground id="filler" fgColor={fgColor} progress={progress} textColor={textColor}>
					{showPercentage && <span>{progress}%</span>}
				</ProgressForeground>
			</ProgressBackground>
			:
			<span>Invalid progress</span>




// DDS Progress bar - not working as desired
// const ProgressBar: FC = () => {
// 	useEffect(() => {
// 		loadURLSVGs(["svgs/dds__check.svg", "svgs/dds__arrow-tri-solid-right.svg"]);

// 		[].forEach.call(document.querySelectorAll('[data-toggle="dds__progress"]'),
// 			(element) => {
// 				// eslint-disable-next-line no-new
// 				new Progress(element, {
// 					showText: false,
// 					timetoComplete: 1000
// 				});
// 			});
// 	}, [])

// 	return (
// 		<div className='dds__progress-bar'>
// 			<div
// 				className="dds__progress-bar-standard"
// 				role="progressbar"
// 				style={{ width: '50%' }}
// 				aria-valuenow="50"
// 				aria-valuemin="0"
// 				aria-valuemax="100"
// 				data-toggle="dds__progress"
// 			/>
// 		</div>
// 	)
// }
export default ProgressBar;