const LoadingSpinner: React.FC<{
	size?: 'xl' | 'lg' | 'md' | 'sm',
	label?: string
}> = ({
	size = 'xl',
	label
}) => {
		return (
			<div data-testid="loading-indicator" className={`dds__loading-indicator dds__loading-indicator--${size}`}>
				{label && <div data-testid="loading-indicator-label" className="dds__loading-indicator__label">{label}</div>}
				<div className="dds__loading-indicator__spinner"></div>
			</div>
		)
	}

export default LoadingSpinner