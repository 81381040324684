import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, LabelList } from 'recharts';

const DDSBar: React.FC<{
    result: { name: string; count: number; }[];
    total: number;
}> = ({ result, total }) => {


    const [chartData, setChartData] = useState<any>([]);

    const columnMapping = {
        smartphones: {
            title: "Smart Phone",
            fontColor: '#1282D6',
        },
        featurephones: {
            title: "Feature Phone",
            fontColor: '#A64600',
        },
        datacards: {
            title: "Data Card",
            fontColor: '#7D2E00',
        },
        mifi: {
            title: "MiFi",
            fontColor: '#0B7C84',
        },
        laptop: {
            title: "Laptop",
            fontColor: '#002A58',
        },
        tablet: {
            title: "Tablet",
            fontColor: '#C96100',
        },
        none: {
            title: "None",
            fontColor: '#044E52',
        },
        unknown: {
            title: "Unknown",
            fontColor: '#CB548D',
        }
    };

    useEffect(() => {
        // Process data for the chart here
        const resultArray = Object.entries(result).map(([name, count]) => ({
            name, count, title: columnMapping[name]?.title || "Unknown",
            fontColor: columnMapping[name]?.fontColor || '#CB548D'
        }));
        console.log("Result Array---", resultArray)
        setChartData(resultArray);
    }, [result]);

    const colors = ['#1282D6', '#A64600', '#A8396F', '#002A58', '#C96100', '#636363'];

    return (
        <div style={{ textAlign: 'left', border: '1px solid #E1E1E1', borderRadius: '2px', padding: '15px' }}>
            <h5 className="dds__font-weight-bold" style={{ color: '#0C32A4' }}>Total Connections: {total}</h5>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={chartData.map((entry, index) => ({ ...entry, fill: entry.fontColor }))}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid horizontal={true} vertical={false} />
                    <XAxis dataKey="title" />
                    <YAxis />
                    <Bar dataKey="count" fill="#5C6BC0">
                        <LabelList dataKey="count" position="top" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default DDSBar;
