import { useAlert } from 'react-alert';
import { useCustomQuery } from '../../../hooks/useCustomQuery';
import { queryKeys } from '../../../react-query/constants';
import { fetchProductiveConfig } from '../API/api';

const useATProductiveList = () => {
	const alert = useAlert();
	return useCustomQuery([queryKeys.ATProductiveList], fetchProductiveConfig(), {
		select: (list) => {
			const temp: string[] = [];
			// eslint-disable-next-line camelcase
			list.productiveList.forEach((productive: { productive_name: string }) => {
				temp.push(productive.productive_name);
			});
			return temp;
		},
		onError: () => {
			alert.show(
				"Error occurred while fetching Productive options:\n Editing Productive won't be available. Please contact admin or try refreshing the page",
				{
					timeout: 0, // custom timeout just for this one alert
					type: "error",
				}
			);
		}
	});
}

export default useATProductiveList