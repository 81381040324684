import { useEffect, useState } from 'react'
import ClientTable from '../../ClientTable/ClientTable';
import CVLCreateAdminModal from './CVLCreateAdminModal';
import columns from './DataModel';
import { useCvlAdminListAPI } from './ManageCvlAdminContext'

const ManageAdminPage = () => {
  const { data } = useCvlAdminListAPI();
  const [show, setShow] = useState(false);


  return (
    <>
      <div className='dds__d-flex dds__justify-content-between dds__flex-wrap dds__border dds__py-3 dds__px-4 dds__mb-3'>
        <div>
          <h4 className="dds__h4 dds__font-weight-normal dds__d-inline" style={{
            color: 'var(--blue-800)'
          }}>
            Manage Users
          </h4>
        </div>
        <div>
          <button onClick={() => setShow(true)} className='dds__button dds__button--md dds__button--secondary '>
            Create Admin
          </button>
        </div>
      </div>
      <div className='dds__border dds__px-4 dds__py-3 dds__mb-4'>
        <ClientTable
          data={data}
          columns={columns}
          skipStateReset={false}
          activateSearch
          defaultComplex={false}
          disableColumnFunctions
        />
      </div>
      {show && <CVLCreateAdminModal handleClose={() => setShow(false)} />}
    </>
  )
}

export default ManageAdminPage