import React, {
	useEffect, useState,
} from "react";
import columns from "./DataModel";
import "./portalcss.css";
import VulnerabilityHeaderInfo from "./HeaderBar/VulnerabilityHeaderInfo";
import useUserVulnerabilityData from "./useUserVulnerabilityData";
import LoadingSpinner from "../Common/LoadingSpinner";
import { Accordion, Popover } from "@dds/components"
import sanitizeHtml from 'sanitize-html';
import CommentsCell from "./CommentsCell";
import { queryKeys } from "../../react-query/constants";
import { useQueryClient } from "@tanstack/react-query";

const VulnerabilityPortalUser: React.FC = () => {

	const { data, isLoading, error, isError, refetch } = useUserVulnerabilityData();
	const [threatCount, setThreatCount] = useState<{
		[id: string]: number
	}>({})

	// change document title when we are at this page /vulnerability-portal
	useEffect(() => {
		document.title = "Security Threats"
	}, [window.location.pathname])
	useEffect(() => {
		if (data) {
			if (data.length > 0) {
				const accordian = document.getElementById("cvl-user-accordion");
				const accordionController = new Accordion(accordian);
				accordionController.expand && accordionController.expand(0);

			}

			for (let i = 0; i < data.length; i++) {
				let popover = document.getElementById(`popover-${i}`);
				new Popover(popover, { placement: "top-start" });
			}

			let temp = {};
			data.forEach(obj => {
				temp[obj.assethostname] = temp[obj.assethostname] ? temp[obj.assethostname] + 1 : 1
				console.log(temp)
			});
			setThreatCount(temp);
		}
	}, [data])

	const queryClient = useQueryClient()
	const setData = (func) => {
		let newData = func(data)
		const state = queryClient.getQueryState([queryKeys.cvluser])
		if (state)
			queryClient.setQueryData([queryKeys.cvluser], { records: newData, records_found: newData.length })
		else
			refetch()
	}

	if (isLoading)
		return (
			<div className="dds__mt-5">
				<LoadingSpinner size="xl" />
			</div>
		)




	return (
		<div>
			<div className="dds__border dds__py-3 dds__px-4 dds__mb-3">
				<VulnerabilityHeaderInfo heading="Security Threats On Your Computer" />
				<div className=" dds__text-center dds__pb-3">
					{Object.keys(threatCount).length > 0 ?
						Object.entries(threatCount).map(([key, value]) =>
							<p className=" dds__body-1" key={key}>{value} security threats found on your device<span className="dds__font-weight-normal"> ({key})</span><br /></p>
						) :
						isError ? <div>
							<i className="dds__icon dds__icon--alert-error" style={{
								fontSize: '2.2rem',
								color: 'red'
							}}></i>
							<h3 className="dds__h3">Unknown error occurred</h3>
							<p className="dds__body-1">Please try again later</p>
						</div> : <div>
							<i className="dds__icon dds__icon--thumb-up" style={{
								fontSize: '2.2rem',
								color: '#3BA755'
							}}></i>
							<h3 className="dds__h3">Congratulations!</h3>
							<p className="dds__body-1">You don't have any security threats on your devices</p>
						</div>
					}
				</div>
			</div>

			{!isError && data.length > 0 && <div className="dds__pb-5">
				<div
					role="region"
					id="cvl-user-accordion"
					className="dds__accordion"
					data-dds="accordion"
					data-independent="true"
					data-sr-collapse-all={`${data.length} items collapsed`}
					data-sr-expand-all={`${data.length} items expanded`}
					data-sr-expand-item="item expanded."
					data-sr-collapse-item="item collapsed."
				>
					<div className="dds__accordion__control">
						<button type="button" className="dds__accordion__control__expand" aria-disabled="false" aria-label="Expand All Threat information">Expand All</button>
						<button type="button" className="dds__accordion__control__collapse" aria-disabled="false" aria-label="Collapse All Threat information">Collapse All</button>
					</div>
					{data.map((obj, index) => <div className="dds__accordion__item" key={obj.assethostname + obj.product}>
						<h5 className="dds__accordion__heading">
							<button
								type="button"
								id={`trigger-basic-item${index}`}
								className="dds__accordion__button"
								aria-expanded="false"
								aria-controls={`content-basic-item${index}`}
							>
								Threat {index + 1} of {data.length} (Device name: {obj.assethostname})
							</button>
						</h5>
						<div id={`content-basic-item${index}`} className="dds__accordion__content" role="region" aria-labelledby={`trigger-basic-item${index}`}>
							<div className="dds__accordion__body">
								<div className=" dds__container-fluid dds__p-4" style={{
									backgroundColor: "var(--gray-100)",
									borderColor: "var(--gray-300)"
								}}>
									<div className="dds__row">
										<div className="dds__col--1 dds__col--md-4">
											<h5 className=" dds__h5 dds__font-weight-normal" style={{
												color: "var(--blue-800)",
											}}>Threat</h5>
											<p className="dds__text-justify dds__body-2 dds__pr-md-4">A recent scan has determined you have an active security threat on <strong>{obj.product}</strong> application</p>
										</div>
										<div className="dds__col--1 dds__col--md-8">
											<div className="dds__row">
												<h5 className="dds__h5 dds__font-weight-normal" style={{
													color: "var(--blue-800)",
												}}>Instructions</h5>
											</div>
											<div className="dds__row">
												<p
													className="dds__text-justify dds__body-2 dds__pr-md-4 dds__break-word"
													dangerouslySetInnerHTML={{ __html: sanitizeHtml(obj.solution) }}
												>

												</p>
												{obj.document_link && obj.document_link.length > 0 && <p className="dds__text-justify dds__body-2 dds__pr-md-4">
													<a rel="noreferrer" href={obj.document_link} target="_blank">
														Click here
													</a> for more details
												</p>}
												{/* {obj.due_date && <p className="dds__text-justify dds__body-2 dds__pr-md-4">
													Please ensure you take the above actions before <strong style={{
														color: 'var(--red-600)'
													}}>{obj.due_date}</strong>, or risk your PC being blocked from the Dell network
												</p>} */}
											</div>
											<hr />
											<div className="dds__row">
												<div className="dds__col--2 dds__col--md-6">
													<p className="dds__body-2--smaller dds__d-inline dds__p-3 dds__p-md-0" style={{
														color: "var(--blue-600)"
													}}
														aria-hidden="true"
													>Message Administrator</p>
													<CommentsCell value={obj.total_comments} setData={setData} tableRole="user" userRole="user" column={columns} row={{ values: obj, index: index }} maxWidth={false} />
												</div>
												{obj.scanner_information && obj.scanner_information.length > 0 && <div className="dds__col--2 dds__col--md-6">
													<button id={`popover-trigger-${index}`}
														className="dds__button dds__button--secondary dds__body-2--smaller dds__d-inline" style={{
															color: "var(--blue-600)",
															border: 0
														}}>View Scanner Information</button>

												</div>}

											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							id={`popover-${index}`}
							data-trigger={`#popover-trigger-${index}`}
							data-dds="popover"
							className=" dds__popover dds__popover--lg"
							role="dialog"
							data-placement="top"
							aria-labelledby={`popover-title-${index}`}
							style={{
								padding: 0
							}}
						>
							<div className="dds__popover__content">
								<div className="dds__popover__header">
									<h6 id={`popover-title-${index}`} className="dds__popover__headline">Scanner information</h6>
								</div>
								<div className="dds__popover__body">{obj.scanner_information}</div>
							</div>
						</div>
					</div>)}
				</div>
				{/* <ErrorBoundary
					onError={err => {
						if (err instanceof AuthError) {
							handleError(new AuthError())
						}
					}}
					FallbackComponent={ComponentFallback}
				>
					<div
						style={{
							marginTop: 10,
						}}
					>
						{!isLoading && !isError && <ClientTable
							data={data}
							columns={columns}
							skipStateReset={false}
							activateFilters={false}
							activateExportAllData={true}
							activateExportCurrentView={false}
							activateSearch
							defaultComplex={false}
							instanceProps={{
								tableRole: "user",
								userRole: "user",
								adminList: []
							}}
						/>}
					</div>
				</ErrorBoundary> */}
			</div>}
		</div>
	);
};

export default VulnerabilityPortalUser;
