import React, {
	useEffect,
	useState,
} from "react";
import { PageTitle } from "./styles";
import FilterCard from "./FilterCard/FilterCard";
import ServerTable from "../../ServerTable/ServerTable";
import { adminTableConfig } from "../API";
import columns from "../DataModel";
import { FilterObject, useAdminFilters } from "./AdminContext";
import LoadingSpinner from "../../Common/LoadingSpinner";

const AdminPage: React.FC = () => {
	useEffect(() => {
		document.title = "Asset Inventory | Admin"
	}, [])
	const {
		isLoadingFilters: isLoadingFilters,
		fetchError: filterFetchError,
		setPreFilterdObj: setPreFilterdObj,
	} = useAdminFilters();

	const [reload, setReload] = useState<boolean>(false);
	const [filter, setFilter] = useState<FilterObject>();
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [tableError, setTableError] = useState<Error | null>(null)
	useEffect(() => {
		if (filter) {
			setReload(true);
			setPreFilterdObj(filter);
		}
	}, [filter])


	return (
		<>
			<div className="dds__container-fluid dds__px-0">
				<PageTitle>Assets Inventory Administrator</PageTitle>
				{isLoadingFilters && !filterFetchError && (
					<div
						className="dds__mx-auto dds__text-center"
						style={{
							marginTop: 150,
						}}
					>
						<LoadingSpinner label="Loading Filters" />
						{/* <h4 className="dds_h4">Loading Filters</h4> */}
					</div>
				)}
				{filterFetchError && <div
					className="dds__mx-auto dds__text-center"
					style={{
						marginTop: 150,
					}}
				>
					<h4 className="dds_h4">Unknown error occurred while loading data filters. Please try again later.</h4>
				</div>}
				{!isLoadingFilters && !filterFetchError && <>
					<FilterCard applyFilterObj={setFilter} loading={tableLoading} error={tableError} />
					{filter && <ServerTable
						apiArgs={[filter]} // add filters
						apiConfig={adminTableConfig}
						columns={columns}
						searchHeader="Search by Asset No/Model Name/Emp ID"
						heading="Inventory records - Admin"
						tableBorder={false}
						disableHeading
						reload={reload}
						setReload={setReload}
						setTableLoadingState={setTableLoading}
						setTableErrorState={setTableError}
					// activateFilters={false}
					// FilterComponent={FilterCard}
					// defaultFilter={{ "countries": ["Malaysia", "India"], "stockrooms": ["Dell APJ Malaysia AP1"], "assetCondition": "", "fundedBy": "", "status": "", "sub_status_list": [] }}
					/>}
				</>}
			</div>
		</>
	);
};

export default AdminPage;
