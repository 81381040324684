import { useCustomQuery } from "../../hooks/useCustomQuery";
import { queryKeys } from "../../react-query/constants";
import { orgAssetsConfig } from "../AssetInventory/API";
import { mobileDevicesStatsConfig } from "./API";

//hook that takes in filter object and returns data

// note: filter with empty object returns all data,
// empty arrays should not be passed
const useMobileDevicesStatsData = (filters: {
	device: string[];
	carrier: string[];
	country: string[];
}) => {

	return useCustomQuery([queryKeys.MDStats, filters],
		mobileDevicesStatsConfig(filters),
		{
			select: (data) => {

				return data;
			},
		}
	);
}

export default useMobileDevicesStatsData;