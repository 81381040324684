import { useCustomQuery } from "../../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../../react-query/constants";
import { fetchEolOrgConfig } from "../../API";

const useEolOrgData = (enabled: boolean = true) =>
	useCustomQuery([queryKeys.eoladmin], fetchEolOrgConfig(), {
		select: data => {
			// data = { "status": "Success", "code": 200, "message": "EOL ORG Quarters info  fetched Successfully", "result": { "isLeader": true, "orgEOLAlreadyExpired": "1000", "orgEOLLastYearLabel": "EOL FY22", "qtrlyOrgEolInfoList": [{ "qtrName": "Q4FY23", "position": 0, "eolCount": 5 }, { "qtrName": "Q1FY24", "position": 1, "eolCount": 0 }, { "qtrName": "Q2FY24", "position": 2, "eolCount": 10 }, { "qtrName": "Q2FY24", "position": 2, "eolCount": 12 }] } }
			let temp: any = {};
			if (
				data.result.qtrlyOrgEolInfoList &&
				data.result.orgEOLLastYearLabel &&
				data.result.orgEOLAlreadyExpired
			) {
				temp.chartInfo = data.result.qtrlyOrgEolInfoList;
				temp.chartLabels = [
					[data.result.orgEOLLastYearLabel, data.result.orgEOLAlreadyExpired],
				];
			}
			return temp;
		},
		enabled: enabled,
	});

export default useEolOrgData;
