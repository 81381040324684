import React from 'react';
import LoadingSpinner from '../../../Common/LoadingSpinner';
import CardError from '../CardError';
import CardText from '../../CardText.tsx/CardText';
import { NumberWrapper } from '../../CardText.tsx/Styles';
import { useUnauthAPI } from './useUnauthAPI';
import { cardColumns, unauth } from '../../constants';
import DDSAccordian from '../../Accordian/DDSAccordian';
import { createAccordianID } from '../Asset Request Tracker Card/User';

const columns: cardColumns = [
	{
		Header: "Software Name",
		accessor: "software_name",
		Cell: ({ value }) => <>{value}</>,
		searchable: true,
		visible: false,
	},
	{
		Header: "Version",
		accessor: "version",
		Cell: ({ value }) => <>{value}</>,
		searchable: true,
		visible: false,
	},
	{
		Header: "Hostname",
		accessor: "device_hostname",
		Cell: ({ value }) => <>{value}</>,
		searchable: true,
		visible: true,
	},
	{
		Header: "Status",
		accessor: "status",
		Cell: ({ value }) => <>{value}</>,
		searchable: true,
		visible: true,
	},
	// {
	// 	Header: "Action",
	// 	accessor: "action",
	// 	Cell: ({ value = '', row }) => {
	// 		const {status} = row;

	// 	},
	// 	searchable: true,
	// 	visible: true,
	// },
];

const User: React.FC = () => {
	const { isLoading, data, error, isError } = useUnauthAPI()
	const issueMessage = <div>You have <NumberWrapper>{data}</NumberWrapper> unauthorized software{data > 1 && 's'} in your devices</div>
	const congratsMessage = <div>You don't have any unauthorized softwares on your devices</div>

	if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
	if (error) return <CardError />;
	if (data.length === 0)
		return <CardText total={data.length} issueMessage={issueMessage} congratsMessage={congratsMessage} />
	return (
		<DDSAccordian
			totalElements={data.length}
			data={data}
			isError={isError}
			isLoading={isLoading}
			columns={columns}
			accordianID={createAccordianID(unauth)}
		/>
	);
}

export default User