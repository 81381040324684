import { useEffect, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary';
import { APIRequestFailedError, AuthError } from '../../../ErrorStates';
import useAxios from '../../../hooks/useAxios';
import ClientTable from '../../ClientTable/ClientTable';
import LoadingSpinner from '../../Common/LoadingSpinner';
import Modal from '../../Common/Modal/Modal';
import { IconButton } from '../../Table/TableStyles';
import { overallProductListConfig } from './API';
import StatCard from './Cards/StatCard';
import { useCVLLeaderboardCardData } from './CVLLeaderboardContext';

const CardHeader = () => {
	const { isCardsLoading, cardDataError, cardStatistic } = useCVLLeaderboardCardData();


	const [overallProductListVisible, setOverallProductListVisible] = useState(false);
	const [overallProductList, setOverallProductList] = useState<any[]>([]);

	const handleError = useErrorHandler()


	const {
		isLoading: overallProductListLoading,
		error: overallProductListError,
		sendRequest: fetchOverallProductList,
	} = useAxios();


	const [overallProductListErrorMessage, setOverallProductListErrorMessage] = useState<string>()

	const openOverallProductDetails = () => {
		setOverallProductListVisible(true)
		fetchOverallProductList(overallProductListConfig(), data => {
			setOverallProductList(data.records)
		})
	}

	useEffect(() => {
		if (overallProductListError) {
			if (overallProductListError instanceof APIRequestFailedError) {
				if (overallProductListError.statusCode === "201")
					setOverallProductListErrorMessage("No Record Found")
				else
					handleError(overallProductListError)
			} else if (overallProductListError instanceof AuthError) {
				handleError(overallProductListError)
			} else {
				setOverallProductListErrorMessage(
					"Data is not loading at the moment. Please check your connection and try again."
				)
			}
		}
	}, [overallProductListError])

	const productListColumns = [
		{
			Header: "Product",
			accessor: "product",
			disableFilters: true,
			disableSortBy: false,
			// width: 80,
			Cell: ({ value }) => (
				<>
					<p
						style={{ display: "inline", color: "var(--red-700)" }}
						data-for="product_modal_tip"
						data-tip={value}
					>
						{value}
					</p>
					{/* <ReactTooltip id="product_modal_tip" /> */}
				</>
			),
		},
		{
			Header: "Client Vulnerability Count",
			accessor: "cvlcount",
			disableFilters: true,
			disableSortBy: false,
			// width: 80,
			Cell: ({ value }) => (
				<>
					<p
						style={{ display: "inline" }}
						data-for="cvlcount_modal_tip"
						data-tip={value}
					>
						{new Number(value).toLocaleString()}
					</p>
					{/* <ReactTooltip id="cvlcount_modal_tip" /> */}
				</>
			),
		}
	]

	return (
		<>
			<div className="dds__col-2 dds__col--md-4 dds__mb-1">
				<StatCard isError={cardDataError} isLoading={isCardsLoading} bgColor="var(--green-600)" count={cardStatistic?.allCVLCount} heading="Overall Client Vulnerability Count" />
			</div>
			<div className="dds__col-2 dds__col--md-4 dds__mb-1">
				<StatCard onClickDetailButton={openOverallProductDetails} detailButton isError={cardDataError} isLoading={isCardsLoading} bgColor="var(--berry-700)" count={cardStatistic?.allProductCount} heading="Overall Products Count" />
			</div>
			<div className="dds__col-2 dds__col--md-4 dds__mb-1">
				<StatCard isError={cardDataError} isLoading={isCardsLoading} bgColor="var(--light-blue-700)" count={cardStatistic?.unassignedCVLCount} heading="Unassigned Client Vulnerability Count" />
			</div>

			{overallProductListVisible && <Modal classNames="" onClose={() => { setOverallProductListVisible(false) }}>
				<div className="dds__pt-3 dds__px-3" style={{
					position: 'sticky',
					top: 0,
					background: 'white',
					zIndex: 999
				}}>
					<div className="dds__mb-2">
						<h3 style={{
							color: 'var(--blue-700)'
						}} className="dds__d-inline dds__h3 ">{'Overall Products Details'}</h3>
						<IconButton style={{
							float: 'right'
						}}
							onClick={() => setOverallProductListVisible(false)}>
							<i
								style={{ fontSize: "1rem", color: 'var(--gray-600)' }}
								className="dds__icon dds__icon--close-x"

							></i>
						</IconButton>

					</div>
					{/* <div id='productModalSubheading'>
					<p style={{
						fontSize: 18,
						color: 'var(--gray-600)'
					}}>Client Vulnerability Product Details</p>
				</div> */}
				</div>
				{overallProductListLoading && !overallProductListError && <div className="dds__mt-3 dds__mx-auto dds__text-center">
					<LoadingSpinner size='md' />
				</div>}
				{!overallProductListLoading && !overallProductListError && overallProductList && <div className="dds__px-3">
					<ClientTable
						data={overallProductList}
						columns={productListColumns}
						skipStateReset={false}
						activateExportAllData
						activateSearch
						activateFilters={false}
						disablePagination
						disableColumnFunctions
					/>
					{/* {productList?.records.map((val) => {
					return val.product + " " + val.cvlcount
				})} */}
				</div>}
				{overallProductListError && <p className="dds__px-3">{overallProductListErrorMessage}</p>}
			</Modal>}
		</>
	)
}
export default CardHeader;