import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { queryKeys } from '../../../react-query/constants'
import { fetchAdminListConfig } from '../API'

const useCvlAdminList = () => {
  return useCustomQuery([queryKeys.cvladminlist], fetchAdminListConfig(), {
	select: (adminData) => {
		if(adminData.admins)
			return adminData.admins
		return []
	},
	staleTime: 30000
  })
}

export default useCvlAdminList