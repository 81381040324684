import styled from "styled-components";

export const AppHeader = styled.header`
	background: var(--blue-600);
	z-index: 10;
	/* box-shadow: rgb(225 225 225) 0px 0.125rem 0px; */
	padding-left: 2rem;
	padding-right: 1rem;

	@media only screen and (min-width: 480px) {
		.hamMenu {
			display: none;
		}
	}
	.hamMenu button {
		color: white;
		:hover {
			opacity: 0.8;
			background-color: transparent !important;
		}
		:active,
		:focus {
			background-color: transparent !important;
			opacity: 1;
		}
	}

	#nav-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #fff;
		height: 3.5rem;
		width: 100%;
	}

	.left {
		display: flex;
		align-items: center;
		.app-logo {
			@media only screen and (max-width: 480px) {
				.horizontal-logo {
					display: none;
				}
			}
			@media only screen and (min-width: 480px) {
				.stacked-logo {
					display: none;
				}
			}
		}
	}

	.right {
		display: flex;

		svg {
			height: 14px;
			width: 14px;
			margin-right: 8px;
			path {
				fill: #fff;
			}
		}
	}

	.dropdown {
		background-color: transparent;
		border: 0;
		color: white;
		float: right;
		overflow: hidden;
		/* margin-right: 30px; */
	}

	/* Dropdown button */
	.dropdown .dropbtn {
		font-size: 16px;
		border: none;
		outline: none;
		color: white;
		padding: 14px 0px 14px 14px;
		background-color: inherit;
		font-family: inherit; /* Important for vertical align on mobile phones */
		margin: 0; /* Important for vertical align on mobile phones */
	}

	.navbar .dropdown:hover .dropbtn {
		text-decoration: underline;
	}

	/* Dropdown content (hidden by default) */
	.dropdown-content {
		/* display: none; */
		position: absolute;
		background-color: white;
		color: black;
		min-width: 140px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
		padding: 10px;

		.job-title {
			font-size: 11px;
		}
	}

	/* .dropdown:hover .dropdown-content {
		display: block;
	} */
`;
