import DefaultLayout from "../../Layouts/DefaultLayout";
import Callback from "../../store/auth/Callback";
import UnAuthorized from "../../store/auth/UnAuthorized";
import ErrorLayout from "../../Layouts/ErrorLayout";
// import { PageNotFoundError } from "./ErrorStates";
import { PageNotFound } from "../../components/Fallback/PageNotFound";
import LogoutLayout from "../../Layouts/LogoutLayout";
import LogoutComponent from "../../Layouts/LogoutComponent";

export const logoutRoute = {
	path: "/logout",
	name: "Logout",
	component: LogoutComponent,
	exact: true,
	layout: LogoutLayout,
};
const fixedroutes = [
	logoutRoute,
	{
		path: "/callback",
		name: "Callback",
		component: Callback,
		exact: true,
		layout: ErrorLayout,
	},
	{
		path: "/unauthorized",
		name: "Unauthorized",
		component: UnAuthorized,
		exact: true,
		layout: ErrorLayout,
	},
	{
		path: "*",
		name: "404",
		component: PageNotFound,
		layout: ErrorLayout,
	},

];
export default fixedroutes;
