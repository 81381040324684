import React, { useState } from "react";
import { IconButton } from "../Table/TableStyles";
import CommentsCellModal from "./CommentsCellModal";

// Create an editable cell renderer
const CommentsCell: React.FC<{
	value: any;
	row: any;
	column: any;
	tableRole: string;
	userRole: string;
	setData: any;
	maxWidth?: boolean
}> = ({
	value: initialValue,
	row,
	column: { width, id },
	tableRole,
	userRole,
	setData,
	maxWidth = true

}) => {
		const [commentsPanelOpen, setCommentsPanelOpen] = useState<boolean>(false); //

		return <>
			{/* <Popover
			containerStyle={{ zIndex: "2" }}
			isOpen={commentsPanelOpem}
			positions={["bottom", "right", "left", "top"]}
			padding={10}
			onClickOutside={() => setCommentsPanelOpem(false)}
			//ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
			content={({ position, childRect, popoverRect }) => (
				<ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
					position={position}
					childRect={childRect}
					popoverRect={popoverRect}
					arrowColor={"white"}
					arrowSize={10}
					arrowStyle={{ opacity: 1 }}
					className="popover-arrow-container"
					arrowClassName="popover-arrow"
				>
					<div
						style={{
							backgroundColor: "white",
							opacity: 1,
							boxShadow:
								"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
							overflowY: "auto",
							minWidth: '300px',
							minHeight: '300px',
							maxHeight: '400px',
							overflow: 'auto'

						}}
					>
						{row.values["product"]} ({row.values["assethostname"]})
					</div>
				</ArrowContainer>
			)}
		>
			</Popover> */}
			<IconButton
				onClick={() => { setCommentsPanelOpen(true) }}
				className={`dds__button dds__button--secondary ${maxWidth ? 'dds__w-100' : ''}`}
				data-for="commentTip"
				data-tip="Comment panel"
				aria-label="Message Administrator"
			// ref={clickMeButtonRef}
			>
				<i style={{ fontSize: '1.8rem' }} className={`dds__icon dds__button__icon--start ${true ? "dds__icon--conversation" : "dds__icon--comment"}`} aria-hidden="true" />
				{initialValue}
				{/* <ReactTooltip id="commentTip" /> */}
			</IconButton>

			{commentsPanelOpen && <CommentsCellModal
				setCommentsPanelOpen={setCommentsPanelOpen}
				commentsPanelOpen={commentsPanelOpen}
				initialValue={initialValue} // Changed initial value to date now
				row={row}
				tableRole={tableRole}
				userRole={userRole}
				setData={setData}

			/>}






		</>
	};

export default CommentsCell