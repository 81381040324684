import React, { useEffect, useState } from "react";
import { CardContent, CardTitle, CardWrapper } from "./styles";
import { Dropdown } from "@dds/components";
import { FilterObject, useAdminFilters } from "../AdminContext";
import CountryDropdown from "./Dropdowns/CountryDropdown";
import { StockroomsDropdown } from "./Dropdowns/StockroomsDropdown";
import AssetConditionDropdown from "./Dropdowns/AssetConditionDropdown";
import FundedByDropdown from "./Dropdowns/FundedByDropdown";
import StatusDropdown from "./Dropdowns/StatusDropdown";
import SubStatusDropdown from "./Dropdowns/SubStatusDropdown";
import AppliedFilterBar from "./AppliedFilterBar/AppliedFilterBar";

const FilterCard: React.FC<{
	applyFilterObj: React.Dispatch<any>;
	loading: boolean;
	error: Error | null;
}> = ({ applyFilterObj, loading, error }) => {
	// Initializing and tracking dropdown controllers
	const [dropdownList, setDropdownList] = useState<any[]>([]);
	useEffect(() => {
		if (dropdownList.length < 6) {
			document.querySelectorAll('[data-dds="dropdown"]').forEach(element => {
				let el = new Dropdown(element);
				setDropdownList(list => [...list, el])
			});
			console.log("checking init")
		}
	}, []);


	const {
		isLoadingFilters: isLoading,
		countryList: countryList,
		assetStatusList: assetStatusList,
		fundedByList: fundedByList,
		assetDropdownList: assetDropdownList,
		// stockroomList: stockroomList,
		// subStatusList: subStatusList,
		// selectedFilterObj: selectedFilterObj,
		// setSelectedFilterObj
		// onSubStatusList: onSubStatusList
		preFilterdObj: preFilterdObj,
	} = useAdminFilters();

	const [stockroomList, setStockroomList] = useState<any>([]);
	const [subStatusList, setSubStatusList] = useState<any>([]);
	const [selectedFilterObj, setSelectedFilterObj] = useState<FilterObject>({} as FilterObject);


	useEffect(() => {
		if (countryList && dropdownList.length === 6) {
			if (Object.keys(preFilterdObj).length !== 0 && Object.keys(selectedFilterObj).length === 0) {
				loadPreFilteredObj();
			} else {
				loadDefaultCountryList();
			}
		}

	}, [dropdownList, countryList])


	const loadPreFilteredObj = () => {
		console.log("selecting checking pre filtered ", preFilterdObj)
		applyFilterObj(preFilterdObj);
		setSelectedFilterObj(preFilterdObj);
		let country: any = dropdownList[0];
		preFilterdObj.countries.forEach(e => country.selectOption(e));

		let temp: string[] = [];
		preFilterdObj.countries.forEach(e => temp.push(e))
		let selectedStockRoomList: any = [];
		temp.forEach(item => {
			selectedStockRoomList.push(...countryList[item]);
		});
		setStockroomList(selectedStockRoomList);

		let stockrooms: any = dropdownList[1]
		setTimeout(() => {
			stockrooms.clearSelection();
			preFilterdObj.stockrooms.forEach(element => {
				console.log("selecting ", element)
				stockrooms.selectOption(element);
			});
		}, 500);

		let condition: any = dropdownList[2];
		condition.selectOption(preFilterdObj.assetCondition);
		let fundedBy: any = dropdownList[3];
		fundedBy.selectOption(preFilterdObj.fundedBy);
		let status: any = dropdownList[4];
		if (preFilterdObj.status.length > 0) {
			status.selectOption(preFilterdObj.status);
			setSubStatusList(assetStatusList[preFilterdObj.status])
		} else
			setSubStatusList([]);
		let substatus: any = dropdownList[5];
		setTimeout(() => {
			if (preFilterdObj.sub_status_list.length > 0)
				preFilterdObj.sub_status_list.forEach(element => {
					console.log("selecting ", element)
					substatus.selectOption(element);
				});
		}, 500);
	}

	const loadDefaultCountryList = () => {
		console.log("checking loading default")
		let temp: string[] = []
		Object.keys(countryList).forEach(country => temp.push(country))

		let selectedStockRoomList: string[] = [];
		if (countryList)
			temp.forEach(item => {
				selectedStockRoomList.push(...countryList[item]);
			});
		console.log("selected stockroom list", selectedStockRoomList)
		setStockroomList(selectedStockRoomList);


		let filterResult = {
			countries: temp,
			stockrooms: selectedStockRoomList,
			assetCondition: '',
			fundedBy: '',
			status: '',
			sub_status_list: [],
		};
		setSelectedFilterObj(filterResult);
		applyFilterObj(filterResult);
		dropdownList[0].selectAllOptions();
		setTimeout(() => {
			console.log("selecting all")
			dropdownList[1].selectAllOptions();
		}, 500)

	}


	useEffect(() => {
		if (dropdownList.length === 6) {
			function handleDropdown(e: any) {
				// console.log("Event trigger", e);
				let countries: string[] = dropdownList[0].getSelection();

				let condition: string = dropdownList[2].getSelection();
				let fundedBy: string = dropdownList[3].getSelection();
				let status: string = dropdownList[4].getSelection();

				let selectedStockRoomList: any = [];
				countries.forEach(item => {
					selectedStockRoomList.push(...countryList[item]);
				});

				const deselectStockrooms = () => {
					let removedCountry: string[] = [];
					let flag: number = 0;
					for (let i = 0; i < selectedFilterObj!.countries.length; i++) {
						flag = 0;

						for (let j = 0; j < countries.length; j++) {
							if (selectedFilterObj!.countries[i] === countries[j]) {
								flag = 1;
							}
						}
						if (flag === 0) {
							removedCountry.push(selectedFilterObj!.countries[i]);
						}
					}
					console.log("removed", removedCountry)
					removeEventListener('ddsDropdownSelectionChangeEvent', handleDropdown);
					for (let k = 0; k < removedCountry.length; k++) {
						let stockroomsToBeRemoved: string[] = countryList[removedCountry[k]];
						console.log("removing", stockroomsToBeRemoved)
						stockroomsToBeRemoved.forEach(stockroom => {
							dropdownList[1].deselectOption(stockroom);
						})
					}
					addEventListener("ddsDropdownSelectionChangeEvent", handleDropdown);
				}
				const selectStockrooms = () => {
					let addedCountry: string[] = [];
					let flag: number = 0;
					for (let i = 0; i < countries.length; i++) {
						flag = 0;
						if (selectedFilterObj)
							for (let j = 0; j < selectedFilterObj!.countries.length; j++) {
								if (selectedFilterObj!.countries[j] === countries[i]) {
									flag = 1;
								}
							}
						if (flag === 0) {
							addedCountry.push(countries[i]);
						}
						console.log("added", addedCountry)
						removeEventListener('ddsDropdownSelectionChangeEvent', handleDropdown);
						setTimeout(
							() => {
								for (let k = 0; k < addedCountry.length; k++) {
									let stockroomsToBeAdded: string[] = countryList[addedCountry[k]];
									console.log("adding", stockroomsToBeAdded)
									stockroomsToBeAdded.forEach(stockroom => {
										dropdownList[1].selectOption(stockroom);
									})
								}
							}, 300)
						addEventListener("ddsDropdownSelectionChangeEvent", handleDropdown);
					}
				}
				if (selectedFilterObj.countries) {
					if (selectedFilterObj!.countries.length > countries.length) //  if removed
						deselectStockrooms()
				}

				setStockroomList(selectedStockRoomList);
				if (!selectedFilterObj || selectedFilterObj.countries.length < countries.length) {
					selectStockrooms();
				}
				let stockrooms: string[] = dropdownList[1].getSelection();


				for (let i = 0; i < selectedStockRoomList.length; i++) {

				}

				if (status.length > 0) {
					setSubStatusList(assetStatusList[status])
				}
				else {
					setSubStatusList([]);
				}

				if (selectedFilterObj && selectedFilterObj.status !== status) {
					console.log("triggered", selectedFilterObj.status, "status ", status)
					removeEventListener('ddsDropdownSelectionChangeEvent', handleDropdown);
					dropdownList[5].clearSelection();
					addEventListener("ddsDropdownSelectionChangeEvent", handleDropdown);
				}
				let substatus: string[] = dropdownList[5].getSelection();

				let filterResult: FilterObject = {
					countries,
					stockrooms,
					assetCondition: condition,
					fundedBy: fundedBy,
					status: status,
					sub_status_list: substatus,
				};
				setSelectedFilterObj(filterResult);

			}

			addEventListener("ddsDropdownSelectionChangeEvent", handleDropdown);
			return function cleanupListener() {
				removeEventListener('ddsDropdownSelectionChangeEvent', handleDropdown)
			}
		}
	}, [dropdownList, selectedFilterObj]);

	const resetFilters = () => {
		if (dropdownList.length === 6) {
			for (let i = 2; i < 6; i++) {
				dropdownList[i].clearSelection();
			}
			loadDefaultCountryList();
		}
	}

	const ApplyFilterResult = () => {
		console.log(selectedFilterObj)
		applyFilterObj(selectedFilterObj);
	};
	const isDisabled = (): boolean => {
		return loading || isLoading || ((selectedFilterObj && selectedFilterObj.countries && selectedFilterObj.countries.length === 0) || (selectedFilterObj && selectedFilterObj.stockrooms && selectedFilterObj.stockrooms.length === 0))
	}

	return (
		<>
			<CardWrapper>
				<CardTitle>Filters</CardTitle>
				{!isLoading && countryList
					&& assetStatusList && fundedByList
					&& assetDropdownList
					&& <CardContent>
						<div className="filter-select">
							<CountryDropdown countryList={countryList} />
						</div>
						<div className="filter-select">
							<StockroomsDropdown stockroomList={stockroomList} />
						</div>
						<div className="filter-select">
							<AssetConditionDropdown assetDropdownList={assetDropdownList} />
						</div>
						<div className="filter-select">
							<FundedByDropdown fundedByList={fundedByList} />
						</div>
						<div className="filter-select">
							<StatusDropdown assetStatusList={assetStatusList} />
						</div>

						<div className="filter-select">
							<SubStatusDropdown subStatusList={subStatusList} />
						</div>
						<div className="btn-apply">
							<button
								disabled={isDisabled()}
								type="button"
								className="dds__button dds__button--primary dds__button--sm"
								id="btn_apply"
								onClick={() => ApplyFilterResult()}
							>
								Apply
							</button>
						</div>
					</CardContent>}
				{dropdownList.length === 6 && <AppliedFilterBar dropdownList={dropdownList} resetFilters={resetFilters} />}
			</CardWrapper>
		</>
	);
};

export default FilterCard;