const FilterBarWrapper = ({ children }) => {
	return (
		<div style={{
			background: 'white',
			border: '1px solid #D2D2D2',
			borderRadius: 2,
			width: '100%'
		}} className="dds__px-4 dds__py-2">{children}</div>
	)
}

export default FilterBarWrapper