import React, {
	ChangeEventHandler,
	MouseEventHandler,
	useCallback,
	useEffect,
	useState,
} from "react"
import { useErrorHandler } from "react-error-boundary"
// import XLSX from "xlsx"
// import { useAuth } from "../../store/auth/AuthContext"
import { APIRequestFailedError, AuthError } from "../../ErrorStates"
import Table from "../Table/Table"
import { IUserInfo } from "../../common/modules"
// import { apiConfig } from "../API";
import { Pagination } from "./Pagination"
import useAxios from "../../hooks/useAxios"
import { downloadData } from "../../utils/downloadData"
import { useUserInfo } from "../../store/auth/UserInfoContext"

// let timeout: any = null;

const ServerTable: React.FC<{
	heading: string
	subHeading?: any
	columns: any[]
	apiConfig: any
	apiArgs: any[] | null
	disableHeading?: boolean
	disableToolbar?: boolean
	pageSize?: number
	compactPagination?: boolean

	Icon?: React.FC<any>
	searchOnly?: boolean
	searchHeader?: string

	instanceProps?: any
	defaultComplex?: boolean

	reload?: boolean
	setReload?: React.Dispatch<React.SetStateAction<boolean>>

	setTableLoadingState?: React.Dispatch<React.SetStateAction<boolean>>,
	setTableErrorState?: React.Dispatch<React.SetStateAction<Error | null>>

	tableBorder?: boolean

	disableColumnFunctions?: boolean

	activateFilters?: boolean
	FilterComponent?: React.FC<{ setFilterObj: React.Dispatch<any>, loading: boolean, error: any }>
	defaultFilter?: object

	activateSearch?: boolean

	expandProps?: object;
	canExpand?: boolean;
	disableHeaderRow?: boolean;
	ExpandComponent?: React.FC<any>;
}> = ({
	heading,
	columns,
	apiConfig,
	Icon,
	apiArgs,
	reload,
	setReload,
	tableBorder = true,
	activateFilters,
	FilterComponent,
	defaultFilter,
	setTableLoadingState,
	setTableErrorState,
	activateSearch = true,
	canExpand = false,
	expandProps = {},
	ExpandComponent = () => <></>,
	...props
}) => {

		let instancePropsExtended: any = {}

		let expandPropsExtended: any = {}


		const user = useUserInfo();
		const handleError = useErrorHandler()

		const [data, setData] = useState<any[]>([])

		useEffect(() => {
			console.log(data)
		}, [data])
		const [error, setError] = useState<string | null>(null) // To store error message
		const [loadingTable, setLoadingTable] = useState<boolean>(false) // After fetching, we load data in the state

		if (props.instanceProps) {
			instancePropsExtended = props.instanceProps
			instancePropsExtended.setData = setData;
		}

		if (expandProps) {
			expandPropsExtended = expandProps
			expandPropsExtended.setData = setData;
		}

		const [isCmplx, setIsCmplx] = useState(!!props.defaultComplex)

		const {
			isLoading: isFetching,
			error: fetchError,
			sendRequest: fetchRecords,
		} = useAxios()
		const {
			isLoading: isExporting,
			error: exportError,
			sendRequest: exportRecords,
		} = useAxios()

		useEffect(() => {
			if (setTableLoadingState)
				setTableLoadingState(isFetching);
		}, [isFetching])
		useEffect(() => {
			if (setTableErrorState)
				setTableErrorState(fetchError)
		}, [fetchError])

		const [searchQuery, setSearchQuery] = useState<string>("")
		const [searchResults, setSearchResults] = useState<boolean>(false)

		const [pageIndex, setPageIndex] = useState<number>(0)
		const [pageSize, setPageSize] = useState<number>(
			props.pageSize ? props.pageSize : 10
		)

		const [totalRecords, setTotalRecords] = useState<number>(0)
		const [totalPage, setTotalPage] = useState<number>(0)

		const [sortQuery, setSortQuery] = useState<string>("")

		const [sortCol, setSortCol] = useState<string>("")
		const [sortDirection, setSortDirection] = useState<string>("")

		const [filters, setFilters] = useState<any>(defaultFilter); // To be passed to the filter component


		const transformData = useCallback(
			(dataObj: any) => {
				setLoadingTable(true)
				if (dataObj['payload']) {
					if (dataObj['payload'][0]) {
						dataObj = dataObj['payload']['0']
					}
					else {
						dataObj["records_found"] = 0;
					}
				}


				setTotalRecords(dataObj["records_found"])

				if (dataObj["records_found"] === 0) {
					setError("No Record Found")
					setLoadingTable(false)
					return
				}
				const loadedData: any = []
				for (const record in dataObj.records) { // records used in TrackIT ; data used in unauth
					const data: any = {}
					Object.values(columns).forEach(val => {
						const { accessor } = val
						if (dataObj.records[record][accessor] != null)
							data[accessor] = `${dataObj.records[record][val.accessor]}`
						else data[accessor] = ""
					})

					loadedData.push(data)
				}
				setData(loadedData)
				setLoadingTable(false)
			},
			[columns]
		)

		// const downloadData = useCallback(
		// 	(dataObj: any) => {
		// 		const loadedData: any = []
		// 		for (const record in dataObj.records) {
		// 			const tempData: any = {}
		// 			Object.values(columns).forEach(val => {
		// 				if (!val.disableExport) {
		// 					const { accessor } = val
		// 					const { Header } = val
		// 					if (dataObj.records[record][accessor] != null)
		// 						tempData[Header] = `${dataObj.records[record][val.accessor]}`
		// 				}
		// 			})

		// 			loadedData.push(tempData)
		// 		}

		// 		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(loadedData)
		// 		const csvOutput: string = XLSX.utils.sheet_to_csv(worksheet)

		// 		const fileName = heading
		// 		const type = "csv"

		// 		const dataUrl = URL.createObjectURL(
		// 			new Blob([csvOutput], { type: "text/csv" })
		// 		)
		// 		const link = document.createElement("a")
		// 		link.download = `${fileName}.${type}`
		// 		link.href = dataUrl
		// 		link.click()
		// 	},
		// 	[columns, heading]
		// )

		const fetchCurrentUserRecords = useCallback(
			(
				size: number,
				page: number,
				exportCsv: boolean,
				q: string,
				sort: string,
				filters: string
			) => {
				console.log("APIARGS==>>", apiArgs)

				setError(null)

				console.log("Filters: ", filters)

				if (exportCsv === false)
					if (apiArgs !== null) {
						fetchRecords(
							apiConfig(

								{
									page,
									size,
									exportCsv,
									q,
									sort,
									// filters
								},
								...(activateFilters ? [filters] : apiArgs),
								// ...apiArgs
							),
							transformData
						)
					} else
						fetchRecords(
							apiConfig({
								page,
								size,
								exportCsv,
								q,
								sort,
								// filters
							},
								filters
							),
							transformData
						)
				else if (apiArgs !== null)
					exportRecords(
						apiConfig({
							page,
							size,
							exportCsv,
							q,
							sort,
							// filters
						},
							...(activateFilters ? [filters] : apiArgs),
							// ...apiArgs
						),
						(dataObj) => downloadData(dataObj, columns, heading)
					)
				else
					exportRecords(
						apiConfig({
							page,
							size,
							exportCsv,
							q,
							sort,
							// filters
						},
							filters,
						),
						(dataObj) => downloadData(dataObj, columns, heading)
					)

			},
			[apiArgs, apiConfig, downloadData, exportRecords, fetchRecords, transformData, columns, heading, activateFilters]
		)

		const changeSortDirection = (reset: boolean): void => {
			if (reset || sortDirection === "") setSortDirection("asc")
			else if (sortDirection === "asc") setSortDirection("desc")
			else if (sortDirection === "desc") setSortDirection("")
		}

		const setSortColHandler = (accessor: string): void => {
			// if (accessor === "next_level") return;
			if (sortCol === accessor) changeSortDirection(false)
			else {
				setSortCol(accessor)
				changeSortDirection(true)
			}
		}



		const gotoPage = (page: number, dontfetch?: boolean): void => {
			setPageIndex(page)
			if (!dontfetch)
				fetchCurrentUserRecords(pageSize, page, false, searchQuery, sortQuery, filters)
		}

		const previousPage = (): void => {
			gotoPage(pageIndex - 1)
		}

		const nextPage = (): void => {
			gotoPage(pageIndex + 1)
		}

		const setPageSizeHandler = (size: number): void => {
			setPageSize(size)
			console.log(size)
			fetchCurrentUserRecords(size, pageIndex, false, searchQuery, sortQuery, filters)
		}

		const [canPreviousPage, setCanPreviousPage] = useState<boolean>(false)
		const [canNextPage, setCanNextPage] = useState<boolean>(false)

		useEffect(() => {
			if (pageIndex === 0) setCanPreviousPage(false)
			else setCanPreviousPage(true)

			if (pageIndex === totalPage - 1) setCanNextPage(false)
			else setCanNextPage(true)
		}, [pageIndex, totalPage])

		useEffect(() => {
			setTotalPage(Math.ceil(totalRecords / pageSize))
		}, [pageSize, totalRecords])





		useEffect(() => {
			const temp = `${sortCol} ${sortDirection}`
			if (sortDirection !== "") setSortQuery(temp)
			else setSortQuery("")
		}, [sortCol, sortDirection])

		useEffect(() => {
			console.log("Sort/Initial fetch")
			if (sortQuery || sortQuery === "") {
				if (!isFetching) {
					fetchCurrentUserRecords(pageSize, 0, false, searchQuery, sortQuery, filters)
					gotoPage(0, true)
				}
			}
		}, [sortQuery, filters]) // Do not add other dependencies

		// useEffect(() => {
		// 	console.log("Initial fetch")
		// 	fetchCurrentUserRecords(pageSize, pageIndex, false, searchQuery, sortQuery)
		// }, []) // Do not add other dependencies

		// Reloading data
		useEffect(() => {
			// console.log("Reload signal received")
			if (!isFetching && reload) {
				// console.log("Calling reload fetch")
				fetchCurrentUserRecords(pageSize, pageIndex, false, searchQuery, sortQuery, filters)

			}
			if (setReload) {
				setReload(false);
			}
		}, [reload]) // Do not add other dependencies

		// Reloading data with new apiArgs - to be used with Filters only for now. Need to be tested 
		// useEffect(() => {
		// 	if (!isFetching && apiArgs && apiArgs.length > 0) {
		// 		console.log("apiArgs Changed===> ", apiArgs)
		// 		fetchCurrentUserRecords(pageSize, pageIndex, false, searchQuery, sortQuery)
		// 	}
		// }, [apiArgs]) // Do not add other dependencies

		// Filters
		// useEffect(() => {
		// 	if (activateFilters && !isFetching && filters && filters !== '') {
		// 		console.log("Filter applied")

		// 		// Warning
		// 		// Remove line below to allow use of apiArgs again
		// 		// apiArgs = [filters]
		// 		// Since the API structure doesn't support Filters in Params yet --- using makeshift way to call API again by changing apiArgs
		// 		fetchCurrentUserRecords(pageSize, pageIndex, false, searchQuery, sortQuery, filters)
		// 	}
		// }, [filters]) // Do not add other dependencies

		useEffect(() => {
			if (fetchError) {
				if (fetchError instanceof APIRequestFailedError) {
					if (fetchError.statusCode === "201")
						setError("No Record Found")
					else if (fetchError.statusCode === "204")
						setError(fetchError.message)
					else
						handleError(fetchError)
				} else if (fetchError instanceof AuthError) {
					handleError(fetchError)
				} else {
					setError(
						"Data is not loading at the moment. Please check your connection and try again."
					)
				}
			}
		}, [fetchError, handleError])

		// NOTE: Fetch user info
		const [userInfo, setUserInfo] = useState<IUserInfo>({} as IUserInfo)
		const [userRole, setUserRole] = useState<string | null>(null)
		useEffect(() => {
			if (user) {
				const res = user?.getUserInfo()
				setUserInfo(res)
				if (res.userRole) setUserRole(res.userRole.role)
			}
		}, [user])

		const searchInputChangeHandler: ChangeEventHandler<HTMLInputElement> = e => {
			setSearchQuery(e.target.value)

			if (e.target.value === "" && searchResults === true) {
				setPageIndex(0)
				fetchCurrentUserRecords(pageSize, 0, false, e.target.value, sortQuery, filters)
				setSearchResults(false)
			}

			// if (timeout) {
			// 	clearTimeout(timeout)
			// 	console.log("timeout cancelled");
			// }
			// timeout = setTimeout(() => {
			// 	console.log("timeout triggered");
			// 	if (!isFetching) {
			// 		setPageIndex(0);
			// 		fetchCurrentUserRecords(pageSize, 0, false, e.target.value, sortQuery);
			// 	}
			// }, 1500)
		}

		const searchHandler: any = () => {
			// if (timeout) {
			// 	clearTimeout(timeout)
			// 	console.log("timeout cancelled");
			// }
			if (searchQuery !== "") {
				console.log("Search fetch")
				setPageIndex(0)
				fetchCurrentUserRecords(pageSize, 0, false, searchQuery, sortQuery, filters)
				setSearchResults(true)
			}
		}

		const exportHandler: MouseEventHandler<HTMLButtonElement> = () => {
			console.log("Export fetch")
			fetchCurrentUserRecords(pageSize, pageIndex, true, searchQuery, sortQuery, filters)
		}

		// useEffect(() => {
		// 	//if (heading === "Licensed Software without TAGS") {
		// 	// handleError(new APIRequestFailedError("req failed", 500));
		// 	// handleError(new AuthError("Authorization error"))
		// 	//}
		// }, [])




		return (
			<div>
				{activateFilters && <div>
					{FilterComponent ? <FilterComponent setFilterObj={setFilters} loading={isFetching} error={error} />
						: <p>Please disable Filters or provide a Filter Component</p>
					}
				</div>}

				<div className={`${`${tableBorder ? "dds__border dds__px-4" : ""} dds__py-3 `}`}>
					{!props.disableHeading && (
						<>
							{Icon && <Icon />}
							<h4
								className="dds__h4 dds__d-inline dds__ml-3 dds__pt-0"
								style={{ color: "var(--blue-800)" }}
							>
								{heading}
							</h4>
							<p style={{ fontSize: 16, color: "#6666", letterSpacing: 0 }}>
								{props.subHeading && props.subHeading}
							</p>
						</>
					)}

					{/* {!props.disableToolbar && (
					<TableToolbar
						searchInputChangeHandler={searchInputChangeHandler}
						searchHandler={searchHandler}
						exportHandler={exportHandler}
						isExporting={isExporting}
						searchOnly={!!props.searchOnly}
						searchHeader={props.searchHeader}
						isFetching={isFetching}
						isCmplx={isCmplx}
						onCrunchClick={() => setIsCmplx(!isCmplx)}
					/>
				)} */}

					{/* {isFetching && !error && (
					<div className="dds__mt-3 dds__mx-auto dds__text-center">
						<LoadingIndicator />
						<h4 className="dds_h4">Fetching</h4>
					</div>
				)}
				{loadingTable && !isFetching && !error && (
					<div className="dds__mt-3 dds__mx-auto dds__text-center">
						<LoadingIndicator />
						<h4 className="dds_h4">Loading</h4>
					</div>
				)} */}
					{/* {!isFetching && !loadingTable && !error && ( */}
					<div>
						<Table
							data={data}
							columns={columns}
							skipStateReset={false} // leave it like this everywhere - will be required for editing
							activateExportCurrentView={false}
							activateSearch={activateSearch}
							serverSideSearch // if yes, pass search Handler
							searchInputChangeHandler={searchInputChangeHandler}
							searchHandler={searchHandler}
							searchOnly={!!props.searchOnly}
							searchHeader={props.searchHeader}
							isFetching={isFetching || loadingTable}
							fetchError={error}

							activateExportAllData

							serverSideExport // if yes, pass exportHandler and isExporting
							exportHandler={exportHandler}
							isExporting={isExporting}


							canExpand={canExpand}
							expandProps={expandPropsExtended}
							ExpandComponent={ExpandComponent}
							defaultComplex={isCmplx}
							disableToolbar={props.disableToolbar}
							disablePagination
							serverSideSort // if yes, pass setSortColHandler, sortCol and sortDirection
							setSortColHandler={setSortColHandler}
							sortCol={sortCol}
							sortDirection={sortDirection}
							instanceProps={instancePropsExtended}
							disableColumnFunctions={props.disableColumnFunctions}
							disableHeaderRow={props.disableHeaderRow}

						/>

						{!isFetching && !loadingTable && !error && <div className="dds__mt-3">
							<Pagination
								totalPages={totalPage}
								pageIndex={pageIndex}
								gotoPage={gotoPage}
								canPreviousPage={canPreviousPage}
								canNextPage={canNextPage}
								previousPage={previousPage}
								nextPage={nextPage}
								pageCount={totalPage}
								pageSize={pageSize}
								setPageSize={setPageSizeHandler}
								compactPagination={
									props.compactPagination ? props.compactPagination : false
								}
							/>
						</div>}
					</div>
					{/* )} */}

					{/* {error &&
					!(
						props.searchOnly &&
						error ===
						"Data is not loading at the moment. Please check your connection and try again."
					) && (
						<div className="dds__mt-3 dds__mx-auto dds__text-center">
							<h4 className="dds_h4">{error}</h4>
						</div>
					)} */}
				</div>
			</div>
		)
	}

export default ServerTable
