import React, { useEffect } from "react";
import { ColumnInstance, Filters } from "react-table";
import { loadURLSVGs } from "@dds-uicore/all";
import { IconButton } from "./TableStyles";

const ActiveFilterBar: React.FC<{
	setFilter: any;
	filterState: Filters<object>;
	allColumns: ColumnInstance<object>[];
}> = props => {
	// console.log(props.allColumns);

	const handleDelete = (columnId: string) => {
		// console.log(columnId)
		props.setFilter(columnId, undefined);
	};

	useEffect(() => {
		loadURLSVGs(
			["https://uicore.dellcdn.com/1.6.0/svgs/dds__close-cir.svg"],
			false
		);
	}, []);
	let flag = 0;
	const filterChips = props.allColumns.map(col => {
		if (col.filterValue) {
			flag = 1;
			return (
				<span className="dds__p-2 dds__border dds__rounded">
					<b>{col.Header}: </b>
					{typeof col.filterValue === "string"
						? col.filterValue
						: `${col.filterValue[0]} to ${col.filterValue[1]}`}
					<IconButton
						onClick={handleDelete.bind(this, col.id)}
						className="dds__mt-1 dds__icon dds__icon--close-cir"
					/>
				</span>
			);
		} return null;
	});
	return (
		flag === 1 ? (
			<div className="dds__pb-2 dds__my-2">Active Filters: {filterChips}</div>
		) : null
	);
};

export default ActiveFilterBar