import { Fragment } from "react";
import { CardWrapper, ExpirationText, Title } from "./Styles";
import ProgressBar from "../../../Common/ProgressBar/ProgressBar";
import BulletedList from "../../BulletedList/BulletedList";
import useRedirectHandler from "../../Common/useRedirectHandler";
import { passwordReset } from "../../constants";
import { useDashboardAPIs } from "../../DashboardPage/useDashboardAPIs";
import { usePasswordManagementData } from "./usePasswordManagementData";
import LoadingSpinner from "../../../Common/LoadingSpinner";

const User: React.FC = () => {
    const { redirectHandler } = useRedirectHandler();
    const { data: metaData } = useDashboardAPIs();
    const { data: passwordData, isLoading, error } = usePasswordManagementData();

    const { ariaLabel, ...buttonInfo } = metaData.url[passwordReset];

    const passwordRules = [
        "Minimum of 9 characters",
        "Do not use any of your previous 10 passwords while creating new password",
        "Must contain atleast one number : (0,9)",
        "Must contain atleast one lowercase : (a-z)",
        "Must contain atleast one uppercase :(A-Z)",
        "Must contain atleast one special character !@#%^*()_+-=[]{};':|,.>/?~$",
    ];

    return (
        <Fragment>
            <div className="dds__d-flex" style={{ gap: "24px", flexWrap: "wrap" }}>
                <CardWrapper className="dds__border dds__elevation-2" bgColor={"#fff"}>
                    <div>
                        <Title>Password Expiration</Title>
                        {isLoading && <LoadingSpinner size="lg"></LoadingSpinner>}

                        {!isLoading && !error && (
                            <div>
                                <div className="dds__d-flex dds__justify-content-between">
                                    <ExpirationText>You password is expiring in</ExpirationText>
                                    <ExpirationText className="dds__text-right">
                                        {passwordData.remainingDays} days
                                    </ExpirationText>
                                </div>
                                <ProgressBar
                                    showPercentage={false}
                                    progress={
                                        (passwordData.remainingDays /
                                            (passwordData.totalDays + 1)) *
                                        100
                                    }
                                    textColor={"white"}
                                    bgColor={"#ececed"}
                                    fgColor={"#006bbd"}
                                />
                            </div>
                        )}
                        {!isLoading && error && (
                            <div>
                                <p>Something went wrong</p>
                            </div>
                        )}
                        <button
                            aria-label="Reset Password"
                            role="button"
                            className="dds__button dds__button--sm dds__button--primary  dds__mt-3"
                            onClick={() => redirectHandler(buttonInfo)}
                            aria-labelledby={"Open " + passwordReset + " dashboard in a new tab"}
                        >
                            {buttonInfo.name}
                            <i
                                aria-hidden="true"
                                className="dds__icon dds__icon--arrow-right dds__button__icon--end"
                            />
                        </button>
                    </div>
                </CardWrapper>
                <CardWrapper className="dds__border dds__elevation-2" bgColor={"#eeee"}>
                    <Title>Tips for creating a secure password</Title>
                    <div>
                        <BulletedList items={passwordRules}></BulletedList>
                    </div>
                </CardWrapper>
            </div>
        </Fragment>
    );
};

export default User;
