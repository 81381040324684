import { useUserInfo } from "../../../store/auth/UserInfoContext";
// import useAnnouncementsAPI from "../AnnouncementBanner/useAnnouncementsAPI";
import { useUserImageAPI } from "../DashboardBanner/useUserImageAPI";
import useDowntimeMessageAPI from "./useDowntimeMessageAPI";
import useMetaAPI from "./useMetaAPI";
import useRearrangeAPI from "./useRearrangeAPI";

export const useDashboardAPIs = () => {
	const user = useUserInfo().getUserInfo();
	const {data:downtimeMessage} = useDowntimeMessageAPI();
	const { isLoading: isMetaDataLoading, error: dataError, data } = useMetaAPI();
	const { mutate: rearrangeFav } = useRearrangeAPI(true); // Rearrange fav cards API
	const { mutate: rearrangeAll } = useRearrangeAPI(false); // Rearrange all cards API
	const {} = useUserImageAPI(user.badgeId); // prefetch user image URL
	// const {} = useAnnouncementsAPI(); // prefetch announcements
	return { isMetaDataLoading, dataError, data, rearrangeFav, rearrangeAll, downtimeMessage };
};
