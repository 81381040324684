import { useState } from "react";
import ReactTooltip from "react-tooltip";
import { IconButton } from "../../../../Table/TableStyles";
import EditAdminModal from "../../AdminModal/EditAdminModal";
import { useRegions } from "../../ManageUsersPage/RegionContext";

export type accessDetailsType = {
	[country: string]: {
		name: string,
		stockroom: string,
		country: string
	}[]
}

const EditAction = ({ row, setData }) => {

	const { regions } = useRegions();

	const [show, setShow] = useState(false);

	let employeeDetails = {
		admname: row.values.admname,
		email: row.values.email,
		rolename: row.values.rolename,
		accesstype: row.values.accesstype,
		roleid: row.values.roleid,
		ntid: row.values.ntid
	}

	type defaultStockroomsData = { stockroom: string, country: string }[]

	let defaultData: defaultStockroomsData = row.values.stockrooms
	let accessDetails: accessDetailsType = {};

	defaultData.forEach(selectedStockroom => {
		regions.forEach(region => {
			region.stockrooms.forEach(stockroomData => {
				if (stockroomData.stockroom === selectedStockroom.stockroom && stockroomData.country === selectedStockroom.country) {
					if (accessDetails[region.country] === undefined)
						accessDetails[region.country] = [];

					accessDetails[region.country].push({
						name: stockroomData.name,
						stockroom: stockroomData.stockroom,
						country: stockroomData.country
					})
				}
			});
		});
	});


	return <>
		<IconButton
			className="dds__button dds__button--secondary"
			data-for="editTicketTip"
			data-tip="Edit"
			style={{ marginTop: 0, marginBottom: 0 }}
			onClick={() => { setShow(true) }}
			aria-label="Edit admin access"
		>
			<i style={{ fontSize: '1.1rem' }} className="dds__icon dds__icon--pencil" aria-hidden="true" />
			<ReactTooltip id="editTicketTip" />

		</IconButton>
		{show && <EditAdminModal employeeDetails={employeeDetails} accessDetails={accessDetails} handleClose={() => { setShow(false) }} mode='edit'></EditAdminModal>}
	</>
}
export default EditAction