import { useCustomQuery } from "../../../hooks/useCustomQuery";
import { queryKeys } from "../../../react-query/constants";
import { myAssetsConfig } from "../API";
import columns from "../DataModel";

const useMyAssetsData = (active: boolean) => {
	console.log("Myassets API", active);
	return useCustomQuery([queryKeys.myassets], myAssetsConfig(), {
		select: assetDataObj => {
			const loadedAssetData: any = [];
			for (const asset in assetDataObj?.myAssetsList) {
				const data: any = {};
				Object.values(columns).forEach(val => {
					const { accessor } = val;
					if (assetDataObj.myAssetsList[asset][accessor] != null)
						data[accessor] = `${
							assetDataObj.myAssetsList[asset][val.accessor]
						}`;
					else data[accessor] = "";
				});

				loadedAssetData.push(data);
			}

			return loadedAssetData;
		},
		enabled: active,
	});
};

export default useMyAssetsData;
