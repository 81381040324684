import axios, { AxiosResponse } from "axios";
import { useState, useCallback, useEffect } from "react";
import { useAlert } from "react-alert";
import { APIRequestFailedError, AuthError } from "../ErrorStates";
import { downloadBlob } from "../utils/downloadData";
import { useAnalytics } from "../store/analytics/AnalyticsContext";
import { eventsInfo } from "../store/analytics/eventsInfo";
import { useIsAuthenticated } from "@azure/msal-react";
import { useAuth } from "./useAuth";

const apikey = process.env.REACT_APP_L7_APIKEY;

const useAxios = (
	unsecure?: boolean
): {
	isLoading: boolean;
	error: Error | null;
	sendRequest: (requestConfig: any, applyData: any) => Promise<void>;
} => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<Error | null>(null);
	const [controller, setController] = useState<AbortController>();
	const analytics = useAnalytics();

	const alert = useAlert();
	const auth = useAuth();
	const isAuthenticated = useIsAuthenticated();

	useEffect(() => () => controller && controller.abort(), [controller]);

	const sendRequest = useCallback(
		async (requestConfig, applyData) => {
			const startTime = Date.now();
			try {
				setIsLoading(true);
				setError(null);
				const ctrl = new AbortController();
				setController(ctrl);
				let headers = requestConfig.headers ? requestConfig.headers : {};
				if (unsecure || isAuthenticated) {
					const token = await auth.getAccessToken();
					if (!unsecure) {
						if(requestConfig.url.toLowerCase().includes(".anp2.pcf.dell.com")){
							headers = {
								...headers,
								Authorization: `Bearer ${token!.replace(/\n|\r/g, "")}`, // added for PCF URLs
							};
						}else
							headers = {
								...headers,
								jwt_token: `${token!.replace(/\n|\r/g, "")}`, // For L7 URLs
								apikey: apikey, // For L7 URLs
							};

					}
				} 
				else {
					alert.show("Session expired, redirecting to login page", {
						timeout: 1000,
						type: "info",
						onClose: () => {
							auth.login();
						},
					});
					return;
				}
				const response: AxiosResponse<any> =
					requestConfig.method.toLowerCase() === "get"
						? await axios.get(requestConfig.url, {
								headers,
								signal: ctrl.signal,
						  })
						: await axios.post(
								requestConfig.url,
								requestConfig.body ? JSON.stringify(requestConfig.body) : null,
								{
									headers,
									signal: ctrl.signal,
								}
						  );
				// log Event for Success
				analytics?.addEventToQueue({
					...eventsInfo["API Call"](),
					status_code: response.status,
					status_message:
						response.status !== 200
							? "error: " + response.statusText
							: "success",
					additional_payload: {
						pathname: new URL(requestConfig.url).pathname,
						baseurl: new URL(requestConfig.url).origin,
						method: requestConfig.method,
					},
					response_time: startTime !== 0 ? Date.now() - startTime : 0,
				});
				if (response.status !== 200) {
					if (response.status === 401)
						throw new AuthError("Session Expired, Please login again!");
					else
						throw new APIRequestFailedError(
							response.status,
							response.statusText
						);
				}
				const { data } = response;
				if (
					response.headers["content-type"] !== "application/json" &&
					response.headers["content-disposition"]
				) {
					console.log("Creating blob");
					const blob: Blob = new Blob([data], {
						type: response.headers["content-type"],
					});
					const filename: string = response.headers["content-disposition"]
						.split("filename=")[1]
						.trim();
					console.log(blob);
					downloadBlob(blob, filename);
				} else if (data.code)
					if (data.code === 200) {
						applyData(data);
					} else if (data.code === 401) {
						// Add somesort of exception for user_info
						throw new AuthError("Session Expired, Please login again!");
					} else {
						throw new APIRequestFailedError(data.code, data.message);
					}
				else applyData(data);
			} catch (err: any) {
				console.log("Axios err ", err);
				if (err.response)
					analytics?.addEventToQueue({
						...eventsInfo["API Call"](),
						status_code: err.response.status,
						status_message:
							err.response.status !== 200
								? "error: " + err.response?.statusText
								: "success",
						additional_payload: {
							pathname: new URL(requestConfig.url).pathname,
							baseurl: new URL(requestConfig.url).origin,
							method: requestConfig.method,
						},
						response_time: startTime !== 0 ? Date.now() - startTime : 0,
					});
				setError(err); // err201 -> No records found, Normal Error, APIReq -> Unknown reasons, AuthError -> unauthorised
				if (err instanceof AuthError) {
					// auth?.setSessionExpired(true);
					console.log("session expired");
				}
			}
			setIsLoading(false);
		},
		[alert, auth, unsecure]
	);

	return {
		isLoading,
		error,
		sendRequest,
	};
};

export default useAxios;
