import React from "react";

import BreadCrumb from "../components/Layout/BreadCrumb/BreadCrumb";
import { Main } from "./styles";
import Header from "../components/Layout/Header/Header";
import Footer from "../components/Layout/Footer/Footer";


const DefaultLayout: React.FC<{
	children: JSX.Element;
	crumbs: any[];
}> = ({ children, crumbs }) => (
	<div className="dds__template--productivity">
		<Header enableHamMenu={false} />

		<Main >
			<div className='dds__container--compact'>
				<BreadCrumb crumbs={crumbs} />
				{children}
			</div>
		</Main>
		<Footer />
	</div>
);

export default DefaultLayout;
