import { useCustomQuery } from "../../../hooks/useCustomQuery";
import { queryKeys } from "../../../react-query/constants";

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const frictionlessIntuneUserConfig = (): object => ({
	url: baseUrl + "/api/Fetch/v1/frictionlessIntuneUser",
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

const useFrictionlessIntuneAPI = () => {
	return useCustomQuery(
		[queryKeys.BannerIntune],
		frictionlessIntuneUserConfig(), {
		select: (data) => {
			return data.records;
			// return {
			// 	frictionlessuser: false,
			// 	intuneStatus: true,
			// 	intuneDevices: [
			// 		{
			// 			"model": "Latitude 5520",
			// 			"servicetag": "1PMKMG3"
			// 		},
			// 		{
			// 			"model": "Latitude 5520",
			// 			"servicetag": "1PMKMG3"
			// 		}
				
			// 	]
			//   }
		}
	});
}

export default useFrictionlessIntuneAPI