import React, { useEffect, useState } from "react";
import { CardContent, CardTitle, CardWrapper } from "./styles";
import { Dropdown } from "@dds/components";
import { MobileDeviceFilterObject, useMobileDevicesLeaderFilters } from "../MobileDevicesOrgContext";
import CountryDropdown from "./Dropdowns/CountryDropdown";
import DevicesDropdown from "./Dropdowns/DevicesDropdown";
import CarrierDropdown from "./Dropdowns/CarrierDropdown";
import AppliedMobileFilterBar from "./AppliedFilterBar/AppliedMobileFilterBar";


const FilterCard: React.FC<{
	applyFilterObj: React.Dispatch<any>;
	loading: boolean;
	error: Error | null;
}> = ({ applyFilterObj, loading, error }) => {
	// Initializing and tracking dropdown controllers
	const [dropdownList, setDropdownList] = useState<any[]>([]);
	useEffect(() => {
		if (dropdownList.length < 3) {
			document.querySelectorAll('[data-dds="dropdown"]').forEach(element => {
				let el = new Dropdown(element);
				setDropdownList(list => [...list, el])
			});
			console.log("checking init")
		}
	}, []);

	const {
		isLoadingFilters: isLoading,
		filterList: filterList,
	} = useMobileDevicesLeaderFilters();

	const [selectedFilterObj, setSelectedFilterObj] = useState<MobileDeviceFilterObject>({} as MobileDeviceFilterObject);

	useEffect(() => {
		console.log("Test here", filterList)
		if (filterList && dropdownList.length === 3) {
			console.log("DefaultFilter")
			loadDefaultFilterList();
		}

	}, [dropdownList, filterList])


	const loadDefaultFilterList = () => {
		console.log("checking loading default")
		let temp: string[] = []

		let filterResult = {
			device: [""],
			carrier: [""],
			country: [""],
		};
		setSelectedFilterObj(filterResult);
		applyFilterObj(filterResult);

	}


	useEffect(() => {
		if (dropdownList.length === 3) {
			function handleDropdown(e: any) {
				// console.log("Event trigger", e);
				let device: string[] = dropdownList[0].getSelection();
				let carrier: string[] = dropdownList[1].getSelection();
				let country: string[] = dropdownList[2].getSelection();

				let selectedDevice: string[] = [];
				selectedDevice = selectedDevice.concat(device);

				let selectedCarrier: string[] = [];
				selectedCarrier = selectedCarrier.concat(carrier);

				let selectedCountry: string[] = [];
				selectedCountry = selectedCountry.concat(country);

				if (selectedDevice.length === 0) {
					selectedDevice = selectedDevice.concat([""]);
				}
				if (selectedCarrier.length === 0) {
					selectedCarrier = selectedCarrier.concat([""]);
				}
				if (selectedCountry.length === 0) {
					selectedCountry = selectedCountry.concat([""]);
				}


				let filterResult: MobileDeviceFilterObject = {
					device: selectedDevice,
					carrier: selectedCarrier,
					country: selectedCountry,
				};
				setSelectedFilterObj(filterResult);
				console.log("Filter Data", filterResult)

			}

			addEventListener("ddsDropdownSelectionChangeEvent", handleDropdown);
			return function cleanupListener() {
				removeEventListener('ddsDropdownSelectionChangeEvent', handleDropdown)
			}
		}
	}, [dropdownList, selectedFilterObj]);

	const resetFilters = () => {
		if (dropdownList.length === 3) {
			for (let i = 0; i < 3; i++) {
				dropdownList[i].clearSelection();
			}
			loadDefaultFilterList();
		}
	}

	const ApplyFilterResult = () => {
		console.log("Filter Selected", selectedFilterObj)
		applyFilterObj(selectedFilterObj);
	};
	const isDisabled = (): boolean => {
		return loading
	}

	return (
		<>
			<CardWrapper>
				<CardTitle>Filters</CardTitle>
				{!isLoading && filterList &&
					< CardContent >
						<div className="filter-select">
							<DevicesDropdown device={filterList.device} />
						</div>
						<div className="filter-select">
							<CarrierDropdown carrier={filterList.carrier} />
						</div>
						<div className="filter-select">
							<CountryDropdown country={filterList.country} />
						</div>
						<div className="btn-apply">
							<button
								disabled={isDisabled()}
								type="button"
								className="dds__button dds__button--primary dds__button--sm"
								id="btn_apply"
								onClick={() => ApplyFilterResult()}
							>
								Apply
							</button>
						</div>
					</CardContent>
				}
				{dropdownList.length === 3 && <AppliedMobileFilterBar dropdownList={dropdownList} resetFilters={resetFilters} />}
			</CardWrapper >
		</>
	);
};

export default FilterCard;


