import { useCustomQuery } from '../../../../hooks/useCustomQuery';
import { queryKeys } from '../../../../react-query/constants';
import { overallAssetDetailConfig } from '../../API';

export const useStatCardData = () => {
    return useCustomQuery([queryKeys.overallassetdetails], overallAssetDetailConfig(),
        {
            select: (assetDataObj) => {
                const temp = assetDataObj.payload[0]
                const loadedAssetData: any = [temp['overalltotalassets'], temp['overallbufunded'], temp['overallitfunded'], temp['overallnotfunded']];
                return loadedAssetData;
            }
        });
}