/* eslint-disable import/prefer-default-export */
const tempBaseUATURL = "https://trackitrestservices.ausvdc02.pcf.dell.com";
const tempBaseProdURL = "https://trackitrestservices.ausmpc.pcf.dell.com";

const baseUrl = tempBaseUATURL;

const fetchDeliveryReportSummary = "/api/fetch/v1/DeliveryReportSummary";
export const fetchDeliveryReportSummaryConfig = (
	data: {
		endDate: string;
		startDate: string;
	}
):object => ({
		url: baseUrl + fetchDeliveryReportSummary,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: data,
	});
