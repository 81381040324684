import { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import DDSModal from "../../../Common/Modalv2/DDSModal";
import { IconButton } from "../../../Table/TableStyles";
import CreateEditAdminModal from "../AdminModal/CreateEditAdminModal";

export type accessDetailsType = {
	[country: string]: {
		name: string,
		stockroom: string,
		country: string
	}[]
}

const EditAction = ({ row }) => {

	const accessDetails = {
		dellSites: row.values.dellSites
	};
	const employeeDetails = {
		ntid: row.values.ntid,
		name: row.values.name,
		email: row.values.email,
		badgeid: row.values.badgeid,
		admin: row.values.admin,
		superadmin: row.values.superadmin,
	}

	let modalInstanceRef = useRef<any>();


	const [show, setShow] = useState(false);
	const openModal = () => {
		setShow(true);
	};

	useEffect(() => {
		if (show) {
			if (modalInstanceRef.current) {
				modalInstanceRef.current.open();
			}
		} else {
			// if (modalInstanceRef.current) {
			// 	modalInstanceRef.current.close();
			// }
		}
	}, [show])


	const closeModal = () => {
		console.log("Close modal")
		setShow(false);
	}
	console.log("Creating with emp details and access details", employeeDetails, accessDetails)

	return <>
		<IconButton
			className="dds__button dds__button--secondary"
			data-for="editTicketTip"
			data-tip="Edit"
			style={{ marginTop: 0, marginBottom: 0 }}
			onClick={openModal}
			aria-label="Edit admin access"
		>
			<i style={{ fontSize: '1.1rem' }} className="dds__icon dds__icon--pencil" aria-hidden="true" />
			<ReactTooltip id="editTicketTip" />

		</IconButton>
		{show && <CreateEditAdminModal handleClose={closeModal} accessDetails={accessDetails} employeeDetails={employeeDetails} mode='edit' modalInstanceRef={modalInstanceRef} />}
		{/* {show && <DDSModal handleClose={() => { setShow(false) }} mode='edit'></DDSModal>} */}
	</>
}
export default EditAction