import React, { useEffect, useRef, useState } from 'react'
import { Search } from "@dds/components";

export const useSearch = () => {
	const [searchText, setSearchText] = useState<string>("");
    const searchInstanceRef = useRef<any>(null);
    const searchRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
        searchInstanceRef.current = new Search(searchRef.current, {
            srSearchButtonText: "Search button",
            srClearButtonText: "Clear search button",
        });
        searchRef.current?.addEventListener("ddsSearchClearEvent", () => {
            setSearchText("");
        });
        () => {
            searchInstanceRef.current?.dispose();
        };
    }, []);

	return {
		searchText, setSearchText, searchRef
	}
}
