import { useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom"
import { SideNav } from "@dds/components"
import { useAnalytics } from "../../../store/analytics/AnalyticsContext";
import { eventsInfo } from "../../../store/analytics/eventsInfo";

export const SideNavigation = ({ portalName, links }) => {
	const sidenavRef = useRef<any>(null);
	useEffect(() => {
		// const element: any = document.getElementById("side-nav");
		// sidenavRef.current = new SideNav(element, { fixed: false, expanded: false });
		new SideNav(sidenavRef.current, { fixed: false, expanded: false })
		const handleResize = () => {
			if (window.innerWidth < 480) {
				sidenavRef.current!.inert = true;
			} else {
				sidenavRef.current.inert = false;
			}
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		}

	}, [])
	return (
		<nav>
			<div className="dds__side-nav__wrapper" ref={sidenavRef} data-dds="side-nav" id="side-nav" style={{ zIndex: 19 }}>
				<section className="dds__side-nav" aria-label="Main Navigation">
					<ul className="dds__side-nav__menu">
						{links.map(link => {
							return <LinkItem key={link.path} portalName={portalName} path={link.path} name={link.name} iconClass={link.iconClass} cascadingLink={link.cascadingLink} />
						})}
					</ul>
				</section>
				<div className="dds__side-nav__toggle">
					<button type="button" aria-label="collapse side navigation">
						<i className="dds__icon dds__icon--chevron-left"></i>
					</button>
					<button type="button" aria-label="expand side navigation">
						<i className="dds__icon dds__icon--chevron-right"></i>
					</button>
				</div>
			</div>
		</nav>
	)
}

export const LinkItem = ({ path, name, iconClass, cascadingLink, portalName, key }) => {
	const currentPath = window.location.pathname;
	const selectedCondition = cascadingLink ? currentPath.includes(path) : currentPath === path

	const { addEventToQueue } = useAnalytics();

	const logEvent = useCallback(() => {
		const additional_payload = {
			name: name,
			path: path
		}
		console.log("side nav " + portalName)
		console.log("SideNav", name, path)
		addEventToQueue({
			...eventsInfo["side nav " + portalName](),
			additional_payload: additional_payload
		})
	}, [addEventToQueue, name, path, portalName]);
	return <li key={key} className={selectedCondition ? "dds__side-nav__item dds__side-nav__item--selected" : "dds__side-nav__item"}>
		<Link to={path} onClick={logEvent}>
			<i aria-hidden="true" className={`dds__icon ${iconClass}`}></i>
			<span className="name-link"> {name} </span>
		</Link>
	</li>
}