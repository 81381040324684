import { objectToQuery } from "../../utils/querycreator";

const baseUrl = process.env.REACT_APP_BASE_URL;
const profileImageUrl = process.env.REACT_APP_PROFILE_IMAGE_URL;

export const metaConfig = (): object => {
	return {
		url: baseUrl + "/api/Fetch/v2/Cards",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const favCardConfig = (favourite: boolean): object => {
	return {
		url: `${baseUrl}/api/Add/v1/FavoriteCard?favorite=${favourite}`,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const rearrangeCardConfig = (isFavoriteCards: boolean): object => {
	return {
		url: `${baseUrl}/api/Add/v1/RearrangeCard?isFavoriteCards=${isFavoriteCards}`,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const fetchUserProfileImage = (badgeId: string) => ({
	// url: profileImageUrl + `?search_item=${badgeId}`,
	url: baseUrl + "/api/Fetch/v1/orglyProfilePicture",
	method: "GET",
	headers: {
		apikey: process.env.REACT_APP_PROFILE_IMAGE_API_KEY,
	},
	responseType: "arraybuffer",
});


export const fetchDowntimeMessage = () => {
	return {
		url: baseUrl + "/api/Fetch/v1/Downtime",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
}