import React from 'react'
import DropdownComponent from '../../../../Common/DropdownComponent';

const AssetConditionDropdown = ({ assetDropdownList }) => {
	return (
		<DropdownComponent
			label='Asset Condition'
			PopupList={
				<ul
					className="dds__dropdown__list"
					role="listbox"
					tabIndex={-1}
				>
					{assetDropdownList.length > 0 && (
						<>
							{assetDropdownList.map(item => (
								<li
									key={item.conditionid}
									className="dds__dropdown__item"
									role="none"
								>
									<button
										className="dds__dropdown__item-option"
										role="option"
										data-selected="false"
										tabIndex={-1}
									>
										<span className="dds__dropdown__item-label">
											{item.conditionvalue}
										</span>
									</button>
								</li>
							))}
						</>
					)}
				</ul>
			}
		/>
	)
}
export default AssetConditionDropdown;