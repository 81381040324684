
import Card from '../../Card/Card';
import { cardsTheme, passwordReset } from '../../constants';
import { userTab } from '../../DashboardPage/useMetaAPI';
import { usePasswordManagementData } from './usePasswordManagementData';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import { useState, useEffect } from 'react';
import { useDashboardAPIs } from '../../DashboardPage/useDashboardAPIs';
// import useRedirectHandler from "../../../Dashboardv3/Common/useRedirectHandler";
import { TriggerAction, createTrigger } from '../../Common/TriggerAction';
import DDSDrawer from '../../Drawer/DDSDrawer';
import DDSTabs, { TabsData } from '../../../Common/Tabs/DDSTabs';
import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import User from '../Password Management/User';


const PasswordManagementCard: React.FC<{
    favouriteStatus: boolean, description: any, tabsMetaData: userTab, actionRequired: boolean
}> = ({
    favouriteStatus,
    description,
    tabsMetaData,
    actionRequired
}) => {
        const { isLoading: isUserLoading, error: userError, data: userData } = usePasswordManagementData();
        const { isOrg } = useDashboardContext();
        const { data: metaData } = useDashboardAPIs();
        const { getUserRole } = useUserInfo();
        const role = getUserRole(passwordReset);
        const [message, setMessage] = useState("Reset Password")

        useEffect(() => {
            if (!isOrg) {
                if (metaData && metaData.data[passwordReset]) {
                    setMessage(metaData.data[passwordReset]);
                }
            }
        }, [metaData, isOrg]);

        useEffect(() => {
            if (!isOrg && !isUserLoading && !userError) {
                let temp = "Reset Password"
                if (userData)
                    temp = userData.message;
                setMessage(temp);
            }
        }, [isUserLoading, userData, userError, isOrg]);

        const tabDetails: TabsData = [{
            hidden: !tabsMetaData.user || !role.user,
            name: tabsMetaData.user,
            iconClass: "dds__icon--user",
            active: isOrg,
            content: User,
            footerButtons: []
        }]

        const triggerID = createTrigger(passwordReset)
        return <Card
            themeColor={cardsTheme[passwordReset].themeColor}
            name={passwordReset}
            ddsIconClass={cardsTheme[passwordReset].ddsIconClass}
            isFav={favouriteStatus}
            idName={passwordReset}
            description={description}
            isLoading={(message === "Reset Password" && !userError)}
            actionRequired={actionRequired}
        >
            <TriggerAction triggerID={triggerID}>
                {message}
            </TriggerAction>
            <DDSDrawer triggerID={triggerID} cardName={passwordReset}>
                <DDSTabs tabsMetaData={tabsMetaData} idName={passwordReset} tabsData={tabDetails} salt={passwordReset} />
            </DDSDrawer>

        </Card>


    }

export default PasswordManagementCard