import React from 'react'
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';

const FloatingPillColumn: React.FC<{ value: string, color?: string, column: any, row: any }> = ({ value, row, column, ...props }) => {

	let dellSites = row.values.dellSites;

	let displayList: { [key: string]: boolean } = {}

	dellSites.forEach(element => {
		displayList[element.siteName] = true;
	});

	const id = `${column.id}_${row.id}_tip`

	const total = Object.keys(displayList).length;
	const listComponent = <>
		{Object.keys(displayList).map(e => <span key={e}>{e}<br /></span>)}
	</>
	return (
		<div className="dds__w-100">
			<p
				style={{ display: "inline", color: "var(--blue-800)" }}
			>
				{Object.keys(displayList)[0]}

			</p>
			{total > 1 && <><p
				className="dds__d-inline dds__px-2"
				data-for={id}
				data-tip={ReactDOMServer.renderToStaticMarkup(listComponent)}
				style={{
					background: 'var(--orange-300)',
					borderRadius: '16px',
					textAlign: 'center',
					float: 'right'
				}}
			>
				+{total - 1} more
			</p>
				<ReactTooltip html id={id} />
			</>}

		</div>)
}

export default FloatingPillColumn