import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";
import { fetchPCVersionDetails } from "../API";

export const usePcVersionAPI = () => {
	return useCustomQuery([queryKeys.PCVersionCard], fetchPCVersionDetails(), {
		select: data => {
			
			return data.result;
		},
	});
};
/*
data = {
				"result": [
				{
					"model": "Latitude 5520",
					"host": "W118KBKMG3",
					"os": "Win11",
					"osBuildName": "2209/22H2",
					"isHdjAzure": "Modern",
					"serviceType": "Self Service",
					"alert": false,
					"actionItem": "Your device is already on the most current OS."
				},
				{
					"model": "Latitude 5521",
					"host": "W118KBKMG5",
					"os": "Win10",
					"osBuildName": "2209/22H2",
					"isHdjAzure": "Modern",
					"serviceType": "Self Service",
					"alert": true,
					"actionItem": "Please request a USB image key here: <a href = \"https://dell.service-now.com/esc?id=sc_cat_item&sys_id=e9b1e8f8130da640ec74d2228144b0cd\" rel=\"noopener noreferrer\" target = \"_blank\">Operating System Reinstallation/Reimage - Team Member Center (service-now.com)</a> and promptly reimage the device"
				},
				{
					"model": "Latitude 5522",
					"host": "W118KBKMG4",
					"os": "Win7",
					"osBuildName": "2209/22H2",
					"isHdjAzure": "Modern",
					"serviceType": "Self Service",
					"alert": true,
					"actionItem": "A self-service remote reimage option is available for this device.  Please review and follow the instructions found on the Win11 SharePoint page here: <a href = \"https://dell.sharepoint.com/sites/Windows11\" rel=\"noopener noreferrer\" target=\"_blank\">Windows 11 - Home (sharepoint.com) </a>"
				}
				]
			};
*/