import { CardContnet } from "./styles";
import LoadingSpinner from "../../Common/LoadingSpinner";
export type CardConfiguration = {
	icon: JSX.Element,
	category: number,
	title: string,
	disabled?: boolean
}

const Card: React.FC<{
	ListItem: CardConfiguration,
	setSelectedCategory: React.Dispatch<React.SetStateAction<number>>,
	selected: boolean,
	isLoadingTable: boolean,
	count: string | number,
	isLoadingCards: boolean,
	onHover: any
}> = ({ ListItem, setSelectedCategory, selected, isLoadingTable, count, isLoadingCards, onHover }) => {
	const onCardClick = cate => {
		if (!ListItem.disabled && !isLoadingTable && !isLoadingCards)
			setSelectedCategory(cate);
	};
	// As the user can click on the card to filter elements based on the category we need to make it accessible by keyboard
	// So we need to add a tabIndex to the card and when tab is pressed we need to add a focus outline to the card
	return (
		<>
			<CardContnet
				key={ListItem.category}
				onClick={() => onCardClick(ListItem.category)}
				selected={selected}
				disabled={ListItem.disabled ? true : false}
				onMouseEnter={ListItem.disabled ? null : onHover}
				tabIndex={ListItem.disabled ? -1 : 0}
				aria-label={"View " + ListItem.title}
				role="button"
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						onCardClick(ListItem.category)
					}
				}}

			>
				<div className="card-title">
					<span className="card-icon">{ListItem.icon}</span>
					<span className="title"> {ListItem.title}</span>
				</div>
				<div className="card-count">
					<span className="count">{isLoadingCards || (selected && isLoadingTable) ? <span className=""><LoadingSpinner size='md' /></span> : count}</span>
				</div>
			</CardContnet>
		</>
	);
};

export default Card;
