
import Card from '../../Card/Card';
import { cardsTheme, mobiledevices } from '../../constants';
import { userTab } from '../../DashboardPage/useMetaAPI';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import { useState, useEffect } from 'react';
import { useDashboardAPIs } from '../../DashboardPage/useDashboardAPIs';
import useMobileDevicesStatsData from '../../../MobileDevices/useMobileDevicesStatsData';
import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import DDSTabs, { TabsData } from '../../../Common/Tabs/DDSTabs';
import Leader from '../Mobile Devices Card/Leader/Leader';
import { TriggerAction, createTrigger } from '../../Common/TriggerAction';
import DDSDrawer from '../../Drawer/DDSDrawer';

const MobileDevicesCard: React.FC<{
    favouriteStatus: boolean, description: any, tabsMetaData: userTab, actionRequired: boolean
}> = ({
    favouriteStatus,
    description,
    tabsMetaData,
    actionRequired
}) => {
        const { isOrg } = useDashboardContext();
        const { isLoading: isLeaderLoading, error: leaderError, data: leaderData } = useMobileDevicesStatsData({
            device: [""],
            carrier: [""],
            country: [""]
        });
        const { getUserRole } = useUserInfo();
        const role = getUserRole(mobiledevices);
        const { data: metaData } = useDashboardAPIs();
        const [message, setMessage] = useState("View Details")

        useEffect(() => {
            if (!isOrg) {
                if (metaData && metaData.data[mobiledevices]) {
                    setMessage(metaData.data[mobiledevices]);
                }
            }
        }, [metaData, isOrg]);

        useEffect(() => {
            if (isOrg && !isLeaderLoading && !leaderError) {
                let temp = "No active devices in your org"
                if (leaderData.result.total > 0)
                    temp = `${leaderData.result.total} active devices in your org`;
                setMessage(temp);
            }
        }, [isLeaderLoading, leaderData, leaderError]);

        const tabDetails: TabsData = [{
            hidden: !tabsMetaData.leader || !role.leader,
            name: tabsMetaData.leader,
            active: isOrg,
            iconClass: "dds__icon--user-group",
            content: Leader,
            footerButtons: [
                {
                    name: "Visit Dashboard",
                    path: "/mobiledevices/myorg",
                    external: false,
                    ariaLabel: "Open " + mobiledevices + " Organization page"
                }
            ]
        }]
        const triggerID = createTrigger(mobiledevices)
        return <Card
            themeColor={cardsTheme[mobiledevices].themeColor}
            name={mobiledevices}
            ddsIconClass={cardsTheme[mobiledevices].ddsIconClass}
            isFav={favouriteStatus}
            idName={mobiledevices}
            description={description}
            isLoading={isOrg ? isLeaderLoading : (message === "View Details")}
            actionRequired={actionRequired}
        >
            <TriggerAction triggerID={triggerID}>
                {message}
            </TriggerAction>
            <DDSDrawer triggerID={triggerID} cardName={mobiledevices}>
                <DDSTabs tabsMetaData={tabsMetaData} idName={mobiledevices} tabsData={tabDetails} salt={mobiledevices} />
            </DDSDrawer>
        </Card>
    }

export default MobileDevicesCard