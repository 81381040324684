import React, { useEffect, useState } from 'react'
import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import DDSTabs, { TabsData } from '../../../Common/Tabs/DDSTabs';
import Card from '../../Card/Card';
import { createTrigger, TriggerAction } from '../../Common/TriggerAction';
import { useDashboardAPIs } from '../../DashboardPage/useDashboardAPIs';
import { userTab } from '../../DashboardPage/useMetaAPI';
import DDSDrawer from '../../Drawer/DDSDrawer';
import { pcversion, cardsTheme, passwordReset } from '../../constants';
import User from './User';
import { usePcVersionAPI } from './usePcVersionAPI';

const PCVersionCard: React.FC<{
	favouriteStatus: boolean,
	description: any,
	tabsMetaData: userTab,
	actionRequired: boolean
}> = ({
	favouriteStatus,
	description,
	tabsMetaData,
	actionRequired
}) => {
		const { isLoading: isUserLoading, error: userError, data: userData } = usePcVersionAPI();
		const { getUserRole } = useUserInfo();
		const { isOrg } = useDashboardContext();

		const role = getUserRole(pcversion);

		const { data: metaData } = useDashboardAPIs();
		const [message, setMessage] = useState("View Details")

		useEffect(() => {
			if (!isOrg) {
				if (metaData && metaData.data[pcversion]) {
					setMessage(metaData.data[pcversion]);
				}
			}
		}, [metaData, isOrg]);

		// useEffect(() => {
		// 	if (!isOrg && !isUserLoading && !userError) {
		// 		setMessage(userData.message);
		// 		// Add logic to update query data with message 
		// 	}
		// }, [isUserLoading, userData, userError, isOrg]);

		const tabDetails: TabsData = [{
			hidden: !tabsMetaData.user || !role.user,
			name: tabsMetaData.user,
			iconClass: "dds__icon--user",
			active: !isOrg,
			content: User,
			footerButtons: [

			]
		}]
		const triggerID = createTrigger(pcversion)


		return <Card
			themeColor={cardsTheme[pcversion].themeColor}
			name={
				pcversion
			}
			ddsIconClass={cardsTheme[pcversion].ddsIconClass}
			isFav={favouriteStatus}
			idName={pcversion}
			description={description}
			isLoading={isUserLoading}
			actionRequired={actionRequired}
		>
			<TriggerAction triggerID={triggerID}>
				{message}
			</TriggerAction>
			<DDSDrawer triggerID={triggerID} cardName={pcversion}>
				<DDSTabs tabsMetaData={tabsMetaData} idName={pcversion} tabsData={tabDetails} salt={pcversion} />
			</DDSDrawer>
		</Card>
	}

export default PCVersionCard