import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";
import { fetchEolUserConfig } from "../API";

export const useEOLData = (enabled: boolean = true) => {
	return useCustomQuery([queryKeys.eoluser], fetchEolUserConfig(), {
		select: data => {
			let temp: any = [];
			if (data.result.total > 0) {
				temp = data.result.productList.sort(
					(b, a) => b.endoflifeInDays - a.endoflifeInDays
				);
			}
			// temp.admin = data.result.isLeader;
			// let x = {
			// 	model: 'Latitude 1120',
			// 	servicetag: 'Dummy',
			// 	endoflifeInDays: 30
			// }
			// return [...temp, x, x, x];
			return temp;
		},
		enabled: enabled,
	});
};
