import React, { useEffect } from "react";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";

const Callback: React.FC = () => {
	const { inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const history = useHistory();

	useEffect(() => { // redirect to homepage if the user came directly to callback
		if (isAuthenticated && inProgress == InteractionStatus.None) {
			history.push("/");
		}
	}, [isAuthenticated, inProgress]);
	return (
		<div className="dds__mt-5">
			<LoadingSpinner size="md" label="Authorizing..." />
		</div>
	);
}
export default Callback