import React from "react";
// import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AuthError, PageNotFoundError } from "../../ErrorStates";
// import { useAuth } from "../../store/auth/AuthContext";
import { ButtonGroup, Header } from "./Fallback";
import { Wrapper } from "./Styles";
import Troubleshooting from "./TroubleShooting";

// eslint-disable-next-line import/prefer-default-export
export const PageNotFound: React.FC = () => {
	const error = new PageNotFoundError();

	// const isAuthenticated = useIsAuthenticated();
	// const {inProgress} = useMsal();

	// if (!isAuthenticated && !inProgress) {
	// 	throw new AuthError("Session expired")
	// }

	return (
		<Wrapper>
			<Header title={error.title} message={error.message} />
			{/* <button onClick={resetErrorBoundary}>Try again</button> */}
			<ButtonGroup gotohome />
			<Troubleshooting />
		</Wrapper>
	);
};
