import React, { useEffect, useRef, useState } from 'react'

import { v4 } from 'uuid'

const MediumThreshold: React.FC<{
	mode: 'new' | 'edit',
	MediumThresholdInput: string,
	setMediumThresholdInput: React.Dispatch<React.SetStateAction<string>>
	initialize?: boolean
}> = ({
	mode,
	MediumThresholdInput,
	setMediumThresholdInput,
	initialize = false,
}) => {
    // Telemetry Measures input change handler
		const onMediumThresholdInputChange = (event) => {
			event.preventDefault();
			setMediumThresholdInput(event.target.value)
        }
		return(
			<div className="dds__input-text__container">
    <label htmlFor="MediumThreshold-field" className="dds__label">{mode === 'edit' ? 'MediumThreshold' : ' Medium Threshold'}</label>
    <div className="dds__input-text__wrapper">
        <input
            type="text"
            className="dds__input-text" style={{
                width: "100%",
                borderRadius:"14px"
            }}
            maxLength={100}
            name="MediumThreshold-field"
            id="MediumThreshold-field"
            required
            aria-labelledby="MediumThreshold-label"
            onChange={onMediumThresholdInputChange}
            value={MediumThresholdInput}
        />
    </div>
</div>
		)
}
export  default MediumThreshold