import { useState,useRef,useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { IconButton } from "../../../../Table/TableStyles";
import EditMeasuresModal from "../../MeasuresModal/EditMeasuresModal";


const EditAction = ({ row}) => {

    let MeasuresDetails = {
		telemetry_measures: row.values.telemetry_measures,
		sub_measure: row.values.sub_measure,
		low_threshold: row.values.low_threshold,
		medium_threshold: row.values.medium_threshold,
		high_threshold: row.values.high_threshold,
		measure_Values: row.values.measure_Values,
		id:row.values.id,
	}

	const [show, setShow] = useState(false);
    const openModal = () => {
		setShow(true);
	};
    useEffect(() => {
		if (show) {
			if (modalInstanceRef.current) {
				modalInstanceRef.current.open();
			}
		} else {
			// if (modalInstanceRef.current) {
			// 	modalInstanceRef.current.close();
			// }
		}
	}, [show])
    const closeModal = () => {
		console.log("Close modal")
		setShow(false);
	}
	console.log("Creating withMeasure details",MeasuresDetails)

    let modalInstanceRef = useRef<any>();

    return <>
    <IconButton
        className="dds__button dds__button--secondary"
        data-for="editTicketTip"
        data-tip="Edit"
        style={{ marginTop: 0, marginBottom: 0 }}
        onClick={openModal}
        aria-label="Edit MeasureS"
    >
        <i style={{ fontSize: '1.1rem' }} className="dds__icon dds__icon--pencil" aria-hidden="true" />
        <ReactTooltip id="editTicketTip" />

    </IconButton>
    {show && <EditMeasuresModal  handleClose={closeModal} MeasuresDetails={MeasuresDetails} mode='edit'modalInstanceRef={modalInstanceRef} />}
</>
}
export default EditAction