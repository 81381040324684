import { objectToQuery } from "../../../utils/querycreator";

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const fetchAnonymousUserInfo = "/api/Fetch/v1/AnonymousUserInfo";
export const fetchAnonymousUserInfoConfig = (
	// token: string,
	data: {
		ntId: string;
	}
): object => ({
	url: baseUrl + fetchAnonymousUserInfo,
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
	body: data,
});

export const fetchCountries = "/api/Fetch/v1/Countries";
export const fetchCountriesConfig = (): object => ({
	url: baseUrl + fetchCountries,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

export const fetchProductive = "/api/Fetch/v1/Productive";
export const fetchProductiveConfig = (): object => ({
	url: baseUrl + fetchProductive, // Change URL
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

export const fetchBySiteConfig = (
	params: {
		page?: number
		size?: number
		q?: string
		exportCsv?: boolean
		sort: string
	},
	filters: {
		endDate: string,
		isDateRange: boolean,
		searchAll: string,
		site: string,
		startDate: string
	}

) => {
	let endpoint = "/api/Fetch/v1/RecordBySite"

	return {
		url: baseUrl + endpoint + "?" + objectToQuery(params),
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: filters,
	}
}

export const fetchStatus = "/api/Fetch/v1/Status";
export const fetchStatusConfig = (): object => ({
	url: baseUrl + fetchStatus, // change url
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

export const ApproveAdminAccess = "/api/Fetch/v1/ApproveAdminAccess";
export const ApproveAdminAccessConfig = (
	token: string,
	data: {
		admin: string;
		arrCity: [
			{
				cityname: string;
				countryname: string;
			}
		];
		superadmin: string;
		userid: string;
	}
): object => ({
	url: baseUrl + ApproveAdminAccess,
	method: "POST",
	headers: {
		Authorization: `${token}`,
		"Content-Type": "application/json",
	},
	body: data,
});

export const UpdateRecordEditable = "/api/Update/v1/RecordEditable";
export const UpdateRecordEditableConfig = (
	data: {
		comments: string;
		dispatch_pickup_eta: string;
		productive: string;
		request_item_number: string;
		serial_number: string;
		shiporsite: string;
		snow_city: string | null;
		snow_country: string | null;
		status: string;
		task_number: string;
		tracking: string;
		updatedby: string;
		updatedon: string;
	}
): object => ({
	url: baseUrl + UpdateRecordEditable,
	method: "PUT",
	// mode: "no-cors",
	headers: {
		"Content-Type": "application/json",
	},
	body: data,
});

export const updateRecordEditableConfig2 = (): object => ({
	url: baseUrl + UpdateRecordEditable,
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const fetchUserRecords = "/api/Fetch/v1/UserRecords";
export const fetchUserRecordsConfig = (): object => ({
	url: baseUrl + fetchUserRecords,
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

const fetchUserRecordByReference = "/api/Fetch/v1/UserRecordByReference";
export const fetchUserRecordByReferenceConfig = (
	data: {
		reference_number: string;
	}
): object => ({
	url: baseUrl + fetchUserRecordByReference,
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
	body: data,
});

export const getARTAdminsList = (): object => ({
	url: baseUrl + "/api/Access/v1/AllAdminList",
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});

export const createARTAdmin = (): object => ({
	url: baseUrl + "/api/Access/v1/AddAdminAccess",
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});

export const deleteARTAdminConfig = (ntId: string) => {
	return {
		url: baseUrl + "/api/Access/v1/DeleteAdminAccess?" + objectToQuery({ ntId: ntId }),
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	}
}

export const updateARTAdmin = (): object => ({
	url: baseUrl + "/api/Access/v1/UpdateAdminAccess",
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
	// body: body
	// body: {
	// 	admin: string, // 0 or 1
	// 	dellSites: [
	// 		{
	// 			isDefault: boolean,
	// 			siteName: string
	// 		}
	// 	],
	// 	ntid: string,
	// 	superadmin: string // 0 or 1
	// }
});
