import React from 'react'
import DropdownComponent from '../../../../Common/DropdownComponent'

const SubStatusDropdown = ({ subStatusList }) => {
	return (
		<DropdownComponent
			selection='multiple'
			inputDisabled={subStatusList.length === 0}
			PopupList={
				<ul
					className="dds__dropdown__list"
					role="listbox"
					tabIndex={-1}
				>
					{subStatusList.length > 0 && (
						<>
							{subStatusList.map(item => (
								<li
									key={item}
									className="dds__dropdown__item"
									role="none"
								>
									<button
										className="dds__dropdown__item-option"
										role="option"
										data-selected="false"
										tabIndex={-1}
									>
										<span className="dds__dropdown__item-label">
											{item}
										</span>
									</button>
								</li>
							))}
						</>
					)}
				</ul>
			} label={'Sub Status'} />
	)
}

export default SubStatusDropdown