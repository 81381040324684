import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import useMyAssetsData from '../../../AssetInventory/MyAssets/useMyAssetsData';
import DDSTabs, { TabsData } from '../../../Common/Tabs/DDSTabs';
import Card from '../../Card/Card';
import { createTrigger, TriggerAction } from '../../Common/TriggerAction';
import { cardsTheme, inventory } from '../../constants';
import DDSDrawer from '../../Drawer/DDSDrawer';
import { userTab } from '../../DashboardPage/useMetaAPI';
import Admin from './Admin/Admin';
import Leader from './Leader/Leader';
import User from './User';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import useTrackerLeaderData from './Leader/useTrackerLeaderData';
import { useDashboardAPIs } from '../../DashboardPage/useDashboardAPIs';
import { useEffect, useState } from 'react';

const AssetInventoryCard: React.FC<{
	favouriteStatus: boolean, description: any, tabsMetaData: userTab, actionRequired: boolean
}> = ({
	favouriteStatus,
	description,
	tabsMetaData,
	actionRequired
}) => {
		const { isOrg } = useDashboardContext();
		const { isLoading: isUserLoading, error: userError, data: userData } = useMyAssetsData(!isOrg);
		const { isLoading: isLeaderLoading, error: leaderError, data: leaderData } = useTrackerLeaderData(isOrg);

		const { getUserRole } = useUserInfo();
		const role = getUserRole(inventory);

		const { data: metaData } = useDashboardAPIs();
		const [message, setMessage] = useState("View Details")

		useEffect(() => {
			if (!isOrg) {
				if (metaData && metaData.data[inventory]) {
					setMessage(metaData.data[inventory]);
				}
			}
		}, [metaData, isOrg]);

		useEffect(() => {
			if (isOrg && !isLeaderLoading && !leaderError) {
				setMessage(leaderData[0].allAssetsCount + ' Active Assets found in your org');
			} else if (!isOrg && !isUserLoading && !userError) {
				let temp = "No Active Assets found"
				if (userData.length > 0)
					temp = userData.length + ' Active Assets found';
				setMessage(temp);
				// Add logic to update query data with message 

			}
		}, [isUserLoading, isLeaderLoading, userData, leaderData, userError, leaderError, isOrg]);


		const tabDetails: TabsData = [{
			hidden: !tabsMetaData.user || !role.user,
			name: tabsMetaData.user,
			iconClass: "dds__icon--user",
			content: User,
			active: !isOrg,
			footerButtons: [
				{
					name: "Visit Dashboard",
					path: "/assetinventory",
					external: false,
					ariaLabel: "Open " + inventory + " User page"
				}
			]
		},
		{
			hidden: !tabsMetaData.leader || !role.leader,
			name: tabsMetaData.leader,
			iconClass: "dds__icon--user-group",
			content: Leader,
			active: isOrg,
			footerButtons: [
				{
					name: "Visit Dashboard",
					path: "/assetinventory/myorg",
					external: false,
					ariaLabel: "Open " + inventory + " Organization page"
				}
			]
		}, {
			hidden: !tabsMetaData.admin || !role.admin,
			name: tabsMetaData.admin,
			iconClass: "custom_admin_icon",
			content: Admin,
			active: false,
			footerButtons: [
				{
					name: "Visit Dashboard",
					path: "/assetinventory/admin",
					external: false,
					ariaLabel: "Open " + inventory + " Admin page"
				}
			]
		}
		]
		const triggerID = createTrigger(inventory)



		return <Card
			themeColor={cardsTheme[inventory].themeColor}
			name={inventory}
			ddsIconClass={cardsTheme[inventory].ddsIconClass}
			isFav={favouriteStatus}
			idName={inventory}
			description={description}
			isLoading={isOrg ? isLeaderLoading : (message === "View Details" && !userError)}
			actionRequired={actionRequired}
		>
			<TriggerAction triggerID={triggerID}>
				{message}
			</TriggerAction>
			<DDSDrawer triggerID={triggerID} cardName={inventory}>
				<DDSTabs tabsMetaData={tabsMetaData} idName={inventory} tabsData={tabDetails} salt={inventory} />
			</DDSDrawer>
		</Card>
	}

export default AssetInventoryCard