import { createContext, useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { APIRequestFailedError } from "../../../ErrorStates";
import LoadingSpinner from "../../Common/LoadingSpinner";
import useMyAssetsData from "./useMyAssetsData";

const MyAssetContext = createContext<any>(null);

export const MyAssetsContextProvider = ({ children }) => {
	const [error, setError] = useState<string>();
	const handleError = useErrorHandler();
	const {
		isLoading: isDataLoading,
		isFetching,
		error: dataError,
		data
	} = useMyAssetsData(true);


	useEffect(() => {
		if (dataError) {
			if (dataError instanceof APIRequestFailedError) {
				// if (dataError.statusCode !== "201") {
				handleError(dataError);
				// } 
				// else {
				// 	setError("No Record Found.");
				// }
			} else {
				setError(
					"Data is not loading at the moment. Please check your connection and try again."
				);
			}
		}
	}, [dataError, handleError]);

	return (
		<MyAssetContext.Provider value={{
			data
		}}>
			{isDataLoading && !error && (
				<div
					className="dds__mx-auto dds__text-center"
					style={{
						marginTop: 150,
					}}
				>
					<LoadingSpinner label="Fetching" />
				</div>
			)}
			{!isDataLoading && !dataError && children}
			{dataError && (
				<p
					className="dds__mx-auto dds__text-center"
					style={{
						marginTop: 150,
					}}
				>
					{error}
				</p>
			)}
		</MyAssetContext.Provider>

	)

}
export const useMyAssetsAPI = () => {
	const context = useContext(MyAssetContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}