export const dateFormat = (date: Date, format?: "dd-mm-yyyy" | "yyyy-mm-dd") => {
	let month: string | number = date.getMonth() + 1;
	const year = date.getFullYear();
	let day: string | number = date.getDate();
	if (month < 10) month = `0${  month}`;
	if (day < 10) day = `0${  day}`;
	if (format === "dd-mm-yyyy") return `${day  }-${  month  }-${  year}`;
	if (format === "yyyy-mm-dd") return `${year  }-${  month  }-${  day}`;
	return `${day  }-${  month  }-${  year}`;
};
export const createDate = (dateState: string) => {
	const temp = dateState.split("-");
	return new Date(parseInt(temp[2]), parseInt(temp[1]) - 1, parseInt(temp[0]));
};
