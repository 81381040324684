import styled, { keyframes } from "styled-components";

export const IconButtonWrapper = styled.button<{}>`
:hover {
	opacity: 0.8;
	background-color: transparent !important;
}
:active, :focus {
	background-color: transparent !important;
	opacity: 1;
}
`

const rotateAnimation = keyframes`
	0% {  opacity: 0.3;}
 	50% {  opacity: 1; }
	100% {  opacity: 0.3; }
`

export const CardWrapper = styled.div<{ isLoading, actionRequired }>`
	height: 100%;
	border-radius: 24px;
	background: white;
	text-align: center;	

	:hover {
		box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.1);
		transform: scale(1.005);
		transition: box-shadow 0.3s ease-in-out;
		.card-border{
			// Hide the border when the card is hovered
			display: none;
		}
	}

	.card-border {
		display: ${props => props.isLoading || props.actionRequired ? 'block' : 'none'};
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		background: transparent;
		border-radius: 24px;
		border: ${props => props.actionRequired ? '2px solid var(--red-600)' : props.isLoading ? '2px solid var(--blue-600)' : 'none'};
		animation-name: ${rotateAnimation};
		animation-duration: 1s;
		animation-iteration-count: ${props => props.actionRequired ? '1' : 'infinite'};
		animation-timing-function: linear;
	}
	.action-border {
		border: };
	}
	

	.handle {
		cursor: move;
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;
	}
`;
export const CardHeader = styled.div<{ backgroundColor }>`
	background: ${props => props.backgroundColor};
	height: 60px;
	border-bottom: 1px solid #e5e5e5;
	border-radius: 24px 24px 0 0;
	/* .user-is-tabbing .handle {
		outline: none;
		box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #00468b;
		border-radius: 2px;
	} */

	.handle {
		cursor: move;
		
	}
`;