import React, { useEffect, useState, useContext} from "react";
import DDSModal from "../../../Common/Modalv2/DDSModal";
import { useCustomMutation } from "../../../../hooks/useCustomQuery";
import { useAlert } from "react-alert";
import TelemetryMeasuresBox from "./ModalForm/InputFields/TelemetryMeasuresBox";
import SubMeasuresBox from "./ModalForm/InputFields/SubMeasureBox";
import HighThreshold from "./ModalForm/InputFields/HighThreshold";
import MediumThreshold from "./ModalForm/InputFields/MediumThreshold";
import LowThreshold from "./ModalForm/InputFields/LowThreshold";
import MeasureValues from "./ModalForm/InputFields/MeasureValue";
import LoadingSpinner from "../../../Common/LoadingSpinner";
import "./FormStyles.css";
import { createTelemetryMeasures, updateTelemetryMeasures } from '../TelemetryAPI';
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../../react-query/constants";
import {TableContext} from "../TableContext";

const EditMeasuresModal: React.FC<{
	mode: "new" | "edit";
	handleClose: any;
	modalInstanceRef: React.MutableRefObject<any>;
	MeasuresDetails?: {
		telemetry_measures: string;
		sub_measure: string;
		low_threshold: string;
		medium_threshold: string;
		high_threshold: string;
		measure_Values: string;
		id: number;
	};
}> = ({ mode, modalInstanceRef, MeasuresDetails, handleClose }) => {
	const alert = useAlert();
	const queryClient = useQueryClient();
	const { refetchData,setMappedData } = useContext(TableContext);

	const [MeasureInput, setMeasureInput] = useState(
		MeasuresDetails?.telemetry_measures || ""
	);
	const [SubMeasureInput, setSubMeasureInput] = useState(
		MeasuresDetails?.sub_measure || ""
	);
	const [HighThresholdInput, setHighThresholdInput] = useState(
		MeasuresDetails?.high_threshold || ""
	);
	const [MediumThresholdInput, setMediumThresholdInput] = useState(
		MeasuresDetails?.medium_threshold || ""
	);
	const [LowThresholdInput, setLowThresholdInput] = useState(
		MeasuresDetails?.low_threshold || ""
	);
	const [selectedMeasureValue, setSelectedMeasureValue] =
		useState(MeasuresDetails?.measure_Values || "");
		
	// const refetchData = () => {
	// 	queryClient.invalidateQueries([queryKeys.TelemetryMeasuresList]);
	// };
	  // Function to sanitize and extract numeric values from a string
	//   const sanitizeValue = (input: string): number => {
	// 	const numericValue = parseFloat(input.replace(/[^\d.-]/g, ''));
	// 	return isNaN(numericValue) ? 0 : numericValue;
	// };
	
		// Function to sanitize threshold values
		const sanitizeThreshold = (value: string | undefined) => {
			if (!value) return '';
			return value.replace(/[^\d.]/g, ''); // Replace non-numeric characters except dot
		};
	const saveSuccessHandler = (data: any) => {
		alert.show("Saved", {
			type: "success",
			timeout: 5000,
		});
		modalInstanceRef.current.close();
    
		const sanitizedHighThreshold = HighThresholdInput !== '' ? sanitizeThreshold(HighThresholdInput) : '';
        const sanitizedMediumThreshold = MediumThresholdInput !== '' ? sanitizeThreshold(MediumThresholdInput) : '';
        const sanitizedLowThreshold = LowThresholdInput !== '' ? sanitizeThreshold(LowThresholdInput) : '';
  
		  // Construct the updated measure object with all fields
		  const updatedMeasure = {
			  telemetry_measures: MeasureInput,
			  sub_measure: SubMeasureInput,
			  low_threshold: sanitizedLowThreshold !== '' ? `${sanitizedLowThreshold}${selectedMeasureValue}` : '',
			  medium_threshold: sanitizedMediumThreshold !== '' ? `${sanitizedMediumThreshold}${selectedMeasureValue}` : '',
			  high_threshold: sanitizedHighThreshold !== '' ? `${sanitizedHighThreshold}${selectedMeasureValue}` : '',
			  measure_Values: selectedMeasureValue,
			  id: data.id // Make sure to include the ID in case it's changed on the server
		  };
  
		  // Update mappedData with the new measure object
		  setMappedData((prevData) => {
			  // Clone the array to avoid mutating state directly
			  const updatedData = [...prevData];
			  
			  // Find the index of the existing measure in mappedData
			  const existingIndex = updatedData.findIndex(item => item.id === data.id);
			  
			  // If found, update the measure object; otherwise, add to the end
			  if (existingIndex !== -1) {
				  updatedData[existingIndex] = { ...updatedData[existingIndex], ...updatedMeasure };
			  } else {
				  updatedData.push(updatedMeasure);
			  }
			  
			 // Sort by telemetry_measures value (case-insensitive)
			updatedData.sort((a, b) => a.telemetry_measures.localeCompare(b.telemetry_measures, 'en', { sensitivity: 'base' }));

  
			  return updatedData;
		  });
    
		// queryClient.setQueryData([queryKeys.TelemetryMeasuresList], data);
		refetchData();
	};

	const { mutate: saveAPI, isLoading: isSaving } = useCustomMutation(
		createTelemetryMeasures(),
		{
			onSuccess: saveSuccessHandler,
			onError: error => {
				console.error(error);
				alert.show("Error Occurred while saving, please try again", {
					type: "error",
					timeout: 5000,
				});
			},
		}
	);

	const { mutate: updateAPI, isLoading: isUpdating } = useCustomMutation(
		updateTelemetryMeasures(),
		{
			onSuccess: saveSuccessHandler,
			onError: error => {
				console.error(error);
				alert.show("Error Occurred while updating, please try again", {
					type: "error",
					timeout: 5000,
				});
			},
		}
	);

	const updateDetails = () => {

		// Function to sanitize threshold values
		const sanitizeThreshold = (value: string | undefined) => {
			if (!value) return '';
			return value.replace(/[^\d.]/g, ''); // Replace non-numeric characters except dot
		};
	
		// Sanitize threshold values
		const sanitizedLowThreshold = sanitizeThreshold(LowThresholdInput);
		const sanitizedMediumThreshold = sanitizeThreshold(MediumThresholdInput);
		const sanitizedHighThreshold = sanitizeThreshold(HighThresholdInput);
	
		const requestBody = {
			telemetryMeasures: MeasureInput,
			subMeasures: SubMeasureInput,
			measureValues: selectedMeasureValue,
			lowThreshold: sanitizedLowThreshold,
			mediumThreshold: sanitizedMediumThreshold,
			highThreshold: sanitizedHighThreshold,
			id: MeasuresDetails?.id,
		};

		const saveObject = {
			...requestBody,
		};

		if (mode === "new") {
			saveAPI(saveObject);
		} else {
			updateAPI(saveObject);
		}
	};
   
	
	
	const CancelDetails = () => {
		modalInstanceRef.current.close();
	};

	const [showError, setShowError] = useState<boolean>(false);

	useEffect(() => {
		if (isSaving || isUpdating) {
			setShowError(false);
		}
	}, [isSaving, isUpdating]);
	
	return (
		<DDSModal
			themeColor={"white"}
			iconClass={"dds__icon--diagnostic"}
			modalInstanceRef={modalInstanceRef}
			classes={"dds__rounded dds__modal--lg"}
			id={
				mode === "new"
					? "AddMeasures"
					: MeasuresDetails?.telemetry_measures || ""
			}
			title={mode === "new" ? "Add Measures" : "Edit Measures"}
			disposeOnClose={true}
			footerButtons={[
				{
					class: "",
					text: "Save",
					handleFooterButtonClick: updateDetails,
				},
				{
					class: "",
					text: "Cancel",
					handleFooterButtonClick: CancelDetails,
				},
			]}
			onClose={handleClose}
		>
			<div
				className="dds__d-flex dds__flex-wrap dds__align-items-start"
				style={{ gap: "1rem", flex: "4", minWidth: "600px" }}
			>
				<div
					data-dds="form"
					id="form-id"
					className="dds__form"
					onChange={e => e.preventDefault()}
				>
					{!(isSaving || isUpdating) && (
						<fieldset className="dds__form__section">
							<div className="dds__row dds__mb-3">
								<div className="dds__col--2 dds__col--sm-3">
									<div className="dds__form__field">
										<div className="dds__input-text__container">
											<TelemetryMeasuresBox
												mode={mode}
												MeasureInput={MeasureInput}
												setMeasureInput={setMeasureInput}
												initialize={mode === "new"}
											/>
										</div>
									</div>
								</div>
								<div className="dds__col--2 dds__col--sm-3">
									<div className="dds__form__field">
										<div className="dds__input-text__container">
											<SubMeasuresBox
												mode={mode}
												SubMeasureInput={SubMeasureInput}
												setSubMeasureInput={setSubMeasureInput}
												initialize={mode === "new"}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="dds__row dds__mb-3">
								<div className="dds__col--2 dds__col--sm-3">
									<div className="dds__form__field">
										<div className="dds__input-text__container">
											<MeasureValues
												mode={mode}
												selectedMeasureValue={selectedMeasureValue}
												setSelectedMeasureValue={setSelectedMeasureValue}
												initialize={mode === "new"}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="thresholds-container dds__col--1 dds__col--sm-6">
								<div className="dds__form__field">
									<div className="dds__input-text__container">
										<HighThreshold
											mode={mode}
											HighThresholdInput={HighThresholdInput}
											setHighThresholdInput={setHighThresholdInput}
											initialize={mode === "new"}
										/>
									</div>
								</div>
								<div className="dds__form__field">
									<div className="dds__input-text__container">
										<MediumThreshold
											mode={mode}
											MediumThresholdInput={MediumThresholdInput}
											setMediumThresholdInput={setMediumThresholdInput}
											initialize={mode === "new"}
										/>
									</div>
								</div>
								<div className="dds__form__field">
									<div className="dds__input-text__container">
										<LowThreshold
											mode={mode}
											LowThresholdInput={LowThresholdInput}
											setLowThresholdInput={setLowThresholdInput}
											initialize={mode === "new"}
										/>
									</div>
								</div>
							</div>
							{showError && (
								<p style={{ color: "red" }}>
									Please fill all the required fields
								</p>
							)}
						</fieldset>
					)}
					<br />
					{(isSaving || isUpdating) && (
						<LoadingSpinner size="sm" label="Saving" />
					)}
				</div>
			</div>
		</DDSModal>
	);
};

export default EditMeasuresModal;
