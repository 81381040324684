/* eslint-disable no-underscore-dangle */
import React, { useEffect } from "react";
// CSS order matters
import "./DDS1 Specific Components/Table.scss"
import './App.scss';
import { Route, useHistory } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback } from "../components/Fallback/Fallback";
import AuthenticatedApp from "./AuthenticatedApp/AuthenticatedApp";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import "../Assets/Custom Icons/CustomIcons.css"
import LoadingSpinner from "../components/Common/LoadingSpinner";
import './ddsfix.css'
import { AnalyticsProvider } from "../store/analytics/AnalyticsContext";
import { UserInfoProvider } from "../store/auth/UserInfoContext";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useAuth } from "../hooks/useAuth";
import { logoutRoute } from "./Routes/fixedRoutes";
import getCrumbs from "./GetCrumbs";

const App: React.FC = props => {
  const auth = useAuth();
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();

  useEffect(() => {
    console.log("load", isAuthenticated, inProgress);
    // check if path is not /logout
    if (!isAuthenticated && (inProgress == "none") && window.location.pathname !== "/logout") {
      sessionStorage.setItem('load_start_time', new Date().getTime().toString())
      auth.login();
    }
    if (isAuthenticated && window.location.pathname == "/logout") {
      auth.logout();
    }
  }, [isAuthenticated, inProgress]);


  return (
    <>
      <AuthenticatedTemplate>
        <ErrorBoundary FallbackComponent={Fallback}>
          <ErrorBoundary FallbackComponent={Fallback}>
            <AnalyticsProvider>
              <UserInfoProvider>
                <AuthenticatedApp />
              </UserInfoProvider>
            </AnalyticsProvider>
            {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={true} />}
          </ErrorBoundary>
        </ErrorBoundary>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {inProgress == InteractionStatus.Login && <div className="dds__mt-5">
          <LoadingSpinner size="md" label="Authenticating..." />
        </div>}
        {inProgress == InteractionStatus.Logout && <div className="dds__mt-5">
          <LoadingSpinner size="md" label="Signing Out..." />
        </div>}
        {inProgress == InteractionStatus.None &&
          <Route
            key={logoutRoute.path} // using path as key instead of index
            path={logoutRoute.path}
            exact={logoutRoute.exact} // TODO:  Refactor below component code
            // eslint-disable-next-line react/no-unstable-nested-components
            component={(props: any) => {
              return (
                <logoutRoute.layout {...props} crumbs={getCrumbs(props, [logoutRoute])}>
                  <logoutRoute.component {...props} />
                </logoutRoute.layout>
              );
            }}
          />
        }

      </UnauthenticatedTemplate>
    </>
  )
};

export default App;
