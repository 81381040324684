export const TriggerAction = ({ triggerID, children }) => {
	return (
		<button type="button" className="dds__button dds__button--tertiary" id={triggerID}>
			{children}
		</button>
	)
}


export const createTrigger = (name: string) => {
	return `drawer-${name.replace(/\s/g, "")}`
}

