import styled from "styled-components";

export const PageTitle = styled.div`
	color: #fff;
	padding-bottom: 15px;
	font-weight: 600;
	font-size: 24px;
`;

export const FilterCardWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	min-height: 120px;
	flex-wrap: wrap;
`;

export const CardContnet = styled.div<{ selected: boolean, disabled: boolean }>`
	min-height: 120px;
	min-width: 200px;
	width: 15%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	background-color: ${props => props.selected ? '#F5F6F7' : '#ffffff'};
	border-radius: 24px;
	border: ${props => props.selected ? '2px solid rgba(79, 116, 228, 1)' : '1px solid #D5DCE1'};
	padding: 24px;
	cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
	margin-right: 16px;
	margin-bottom: 16px;
	align-content: center;

	// Add focus state outline and make it clearlyt visible
	&:focus {
		outline: 2px solid #007bff;		
	}


	:hover{
		box-shadow: ${props => props.disabled ? 0 : '0px 3.6507456302642822px 2.2241404056549072px 0px rgba(36, 42, 48, 0.0253)'};
		box-shadow: ${props => props.disabled ? 0 : '0px 8.744236946105957px 5.309579372406006px 0px rgba(36, 42, 48, 0.0364)'};
		box-shadow: ${props => props.disabled ? 0 : '0px 16.188283920288086px 9.783900260925293px 0px rgba(36, 42, 48, 0.045)'};
		box-shadow: ${props => props.disabled ? 0 : '0px 27.863855361938477px 16.727041244506836px 0px rgba(36, 42, 48, 0.0536)'};
		box-shadow: ${props => props.disabled ? 0 : '0px 48.94049072265625px 29.069475173950195px 0px rgba(36, 42, 48, 0.0647)'};
	}

	// &:last-child {
	// 	margin-right: 0px;
	// }

	.card-title {
		margin-bottom: 16px;
		text-align: center;
		height: 24px;
		span {
			display: inline-block;
			img {
				height: 20px;
			}
			&.title {
				padding-left: 10px;
			}
		}
	}
	.card-count {
		text-align: center;
		.count {
			font-weight: bold;
			font-size: 24px;
		}
	}
`;
