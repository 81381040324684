import {
	Logoutbackground,
	LogoutBody,
	LogoutContentWrapper,
} from "./LayoutStyles";
import log_out_bg from "../Assets/log_out_bg.svg";
import { useHistory } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const LogoutComponent: React.FC<{}> = ({}) => {
	const history = useHistory();
	const auth = useAuth();
	const clickLogout = () => {
		history.push("/");
		auth.login();
	};
	return (
		<div style={{ backgroundImage: log_out_bg }}>
			<LogoutBody>
				<LogoutContentWrapper>
					<i className="dds__icon dds__icon--log-in icon_lg"></i>
					<h3 data-dds="text-area" className="txt_header">
						You signed out of your account
					</h3>
					<h4 data-dds="text-area" className="txt_sub_header">
						It's a good idea to close all browser windows.
					</h4>

					<button
						className="dds__button dds_button--primary dds__mx-2 dds__md-2"
						type="button"
						onClick={clickLogout}
					>
						Login
						<i className="dds__icon dds__icon--log-in dds__button__icon--end"></i>
					</button>
				</LogoutContentWrapper>
				<Logoutbackground
					style={{ backgroundImage: `url(${log_out_bg})` }}
				></Logoutbackground>
			</LogoutBody>
		</div>
	);
};

export default LogoutComponent;
