import { UseTableCellProps } from "react-table";
import { IconButtonWrapper } from "../Dashboardv3/Card/Styles";
import { Asset } from "./DataModel";

export const ExpandColumnController = {
	// Expander Cell
	Header: () => null, // No header - will change to 3 sub headers
	id: "expander",
	Cell: ({ row }: UseTableCellProps<Asset>) => (
		<span {...row.getToggleRowExpandedProps()}>
			{row.isExpanded ? (
				<IconButtonWrapper
					aria-label='Collapse row'
					role={'button'}
					// aria-label="Toggle favourite"
					className="dds__button dds__button--secondary dds__button--md  dds__border-0 dds__p-0"
				>
					<i
						style={{
							color: "var(--blue-600)",
							fontSize: "1.2rem",
						}}
						className={` dds__icon dds__icon--chevron-up`}
					/>

				</IconButtonWrapper>
				// <img
				// 	style={{ width: "80%" }}
				// 	src={dds_arrow_tri_down}
				// 	alt="Collapse"
				// />
			) : (
				<IconButtonWrapper
					aria-label='Expand row'
					role={'button'}
					// aria-label="Toggle favourite"
					className="dds__button dds__button--secondary dds__button--md  dds__border-0 dds__p-0"
				>
					<i
						style={{
							color: "var(--blue-600)",
							fontSize: "1.2rem",
						}}
						className={` dds__icon dds__icon--chevron-down`}
					/>

				</IconButtonWrapper>
				// <img
				// 	style={{ width: "80%" }}
				// 	src={dds_arrow_tri_right}
				// 	alt="Expand"
				// />
			)}
		</span>
	),
	disableFilters: true,
	disableResizing: true,
	width: 20,
}