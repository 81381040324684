import { useCustomQuery } from '../../hooks/useCustomQuery';
import { queryKeys } from '../../react-query/constants';
import { fetchRefreshDateConfig } from './API';

const useRefreshDateApi = () => {
	return useCustomQuery(
		[queryKeys.cvlrefresh],
		fetchRefreshDateConfig(), {
		select: (data) => data?.updateDate?.lastupdatedate
	});
}

export default useRefreshDateApi