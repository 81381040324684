import ReactTooltip from "react-tooltip"

const DefaultCell: React.FC<{ value: string, color?: string, column: any, row: any }> = ({ value, color = "var(--blue-800)", ...props }) => {
	return <div style={{
		display: 'flex',
		alignItems: 'center',
		height: '100%'
	}}>
		<p
			style={{ display: "inline", color: color, wordBreak: 'break-word' }}
			data-for={`${props.column.id}_${props.row.id}_tip`}
			data-tip={value}
		>
			{value}
		</p>
		<ReactTooltip id={`${props.column.id}_${props.row.id}_tip`} />
	</div>
}

export default DefaultCell