import DeleteAction from "./DeleteAction";
import EditAction from "./EditAction";
const ActionColumn = ({ value, row }) => {
    return (
		<div>
			<EditAction row={row} />
			<DeleteAction row={row} />
		</div>

	)
}

export default ActionColumn