import { useEffect } from "react";
import { PageTitle } from "../Admin/styles";
import { MyAssetsContextProvider } from "./MyAssetContext";
import MyAssetTable from "./MyAssetTable";

const MyAssetsPage: React.FC = () => {
	useEffect(() => {
		document.title = "Asset Inventory | My Assets"
	}, [])
	return (
		<div className="dds__container-fluid dds__px-0">
			<PageTitle>My Tagged Assets</PageTitle>
			<MyAssetsContextProvider>
				<MyAssetTable />
			</MyAssetsContextProvider>
		</div>
	);
};

export default MyAssetsPage;
