
interface IAccordionHeadingWrapperProps {
	triggerId: string;
	contentId: string;
	alert?: boolean;
	children: any;
}

const AccordianHeadingWrapper: React.FC<IAccordionHeadingWrapperProps> = ({
	triggerId,
	contentId,
	alert = false,
	children,
}) => {
	return (
		<h5 className="dds__accordion__heading">
			<button
				type="button"
				id={triggerId}
				className="dds__accordion__button"
				aria-expanded="false"
				aria-controls={contentId}
				style={{
					color: alert ? '#BB2A33' : 'initial'
				}}
			>
				{children}
			</button>
		</h5>
	);
};
export default AccordianHeadingWrapper