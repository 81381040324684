import { ColumnInstance } from "react-table";

const FiltersAdjust: React.FC<{
	allColumns: ColumnInstance<object>[];
}> = ({ allColumns }) => (
	<>
		{allColumns.map(column => column.canFilter ? (
			<div className="dds__m-2">
				<label className="dds__font-weight-bold">{column.Header} :</label>
				<span>{column.render("Filter")}</span>
			</div>
		) : null)}
	</>
);

// const FiltersAdjust:React.FC<{
// 	hideFilTool:() => void
// 	allColumns: ColumnInstance<Object>[],
// }> = (props) => {
// 	return(
// 		<Modal onClose={props.hideFilTool}>
// 			{props.allColumns.map(column => {
// 				return column.canFilter ? (
// 					<div className="dds__m-2">
// 						<label className="dds__font-weight-bold">{column.Header} :</label>
// 						<span>{column.render('Filter')}</span>
// 					</div>
// 				) : null
// 			})}
// 		</Modal>
// 	)
// }

export default FiltersAdjust;
