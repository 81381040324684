import { useCustomQuery } from "../../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../../react-query/constants";
import { adminAssetCountConfig } from "../../../../AssetInventory/API";

export const useInventoryCardAdminData = (enabled: boolean = true) =>
	useCustomQuery([queryKeys.inventoryadmin], adminAssetCountConfig(), {
		select: data => {
			const result = data.result;
			// const result = {
			// 	newAssetsCount: 20,
			// 	usedAssetsCount: 100,
			// 	buFundedAssetsCount: 80,
			// 	itFundedAssetsCount: 40
			// }
			const dataInStock = [
				{
					name: "New Devices",
					value: result["newAssetsCount"],
				},
				{
					name: "Used Devices",
					value: result["usedAssetsCount"],
				},
			];

			const dataAssignedDevices = [
				{
					name: "Business Funded",
					value: result["buFundedAssetsCount"],
				},
				{
					name: "IT Funded",
					value: result["itFundedAssetsCount"],
				},
			];

			return {
				dataInStock: dataInStock,
				dataAssignedDevices: dataAssignedDevices,
			};
		},
		enabled: enabled,
	});
