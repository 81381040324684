import React from "react";

const DateRangePicker: React.FC<{
	edRef: React.MutableRefObject<any>;
	sdRef: React.MutableRefObject<any>;
}> = ({ sdRef, edRef }) => {
	return (
		<>
			<div
				ref={sdRef}
				className="dds__date-picker "
				data-dds="date-picker"
				id="start-date-picker"
			>
				<label
					className=" dds__date-picker__label"
					htmlFor="date-picker-start-date"
				>
					Start Date:{" "}
				</label>
				<div className=" dds__date-picker__wrapper">
					<input
						style={{}}
						className=" dds__date-picker__input"
						id="date-picker-start-date"
						type="text"
					/>
					<div
						id="date-picker-start-date-feedback"
						className="dds__date-picker__invalid-feedback"
					></div>
				</div>
			</div>
			{/* End Date - Date Selector */}
			<div
				ref={edRef}
				className="dds__date-picker "
				data-dds="date-picker"
				id="end-date-picker"
			>
				<label
					className=" dds__date-picker__label"
					htmlFor="date-picker-end-date"
				>
					End Date:{" "}
				</label>
				<div className=" dds__date-picker__wrapper">
					<input
						style={{}}
						className="dds__date-picker__input"
						id="date-picker-end-date"
						type="text"
					/>
					<div
						id="date-picker-end-date-feedback"
						className="dds__date-picker__invalid-feedback"
					></div>
				</div>
			</div>
		</>
	);
};

export default DateRangePicker;
