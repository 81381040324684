import styled from "styled-components";

export const StyledTroubleshooting = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 32px;

	h4 {
	font-size: 1.5rem;
	color: #616161;
	}

	ol {
	font-size: 1.2rem;
	color: #757575;
	text-align: left;
	}
`;

export const StyledHeader = styled.div`
	h1 {
	color: var(--red-700);
	}

	h2 {
	color: var(--gray-800);
	}
`;

export const ButtonGroupContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 32px;

	button {
	& + button {
		margin-left: 16px;
	}
	}
`;

export const Wrapper = styled.main`
	display: flex;
	flex-direction: column;
	align-items: center; 
	justify-content: center;
	height: 100vh;
	text-align: center;
`