import { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom';
import { CVLLeaderboardContext, useCardData } from '../../components/VulnerabilityPortal/leaderboard/CVLLeaderboardContext';
import fixedroutes from '../Routes/fixedRoutes';
import getCrumbs from '../GetCrumbs';
import cvlLeaderboardRoutes from '../Routes/leaderboardRoutes';
import routes from '../Routes/routers';
import inventoryRoutes from '../Routes/inventoryRoutes';
import { MyAdminContext, useSetFilterStore } from '../../components/AssetInventory/Admin/AdminContext';
import { useAnalytics } from '../../store/analytics/AnalyticsContext';
import { eventsInfo } from '../../store/analytics/eventsInfo';
import { DashboardContextProvider } from '../../store/dashboard/DashboardContext';
import mobileDevicesRoutes from '../Routes/mobileDevicesRoutes';
import { MobileDeviceLeaderContext, useSetMobileFilterStore } from '../../components/MobileDevices/Org/MobileDevicesOrgContext';

const AuthenticatedApp = () => {
	const cardStore = useCardData();

	const filterStore = useSetFilterStore();
	const mobileFilterStore = useSetMobileFilterStore();

	const { addEventToQueue } = useAnalytics();

	useEffect(() => {
		let response_time = 0;
		console.log(sessionStorage.getItem("load_start_time"));
		if (sessionStorage.getItem("load_start_time")) {
			response_time =
				new Date().getTime() -
				Number(sessionStorage.getItem("load_start_time"));
			sessionStorage.removeItem("load_start_time");
			response_time /= 1000;
		}
		addEventToQueue({
			...eventsInfo.firstLoad(),
			response_time: response_time
		});
	}, [])


	const allRoutes = [...routes, ...cvlLeaderboardRoutes, ...inventoryRoutes, ...fixedroutes, ...mobileDevicesRoutes]
	return (
		<DashboardContextProvider>
			<div className="app-container">
				{/* {check404()} */}
				{/* <BrowserRouter> */}
				<Switch>
					{[...routes].map((route: any) => (
						<Route
							key={route.path} // using path as key instead of index
							path={route.path}
							exact={route.exact} // TODO:  Refactor below component code
							// eslint-disable-next-line react/no-unstable-nested-components
							component={(props: any) => {
								return (
									<route.layout {...props} crumbs={getCrumbs(props, allRoutes)}>
										<route.component {...props} />
									</route.layout>
								);
							}}
						/>
					))}
					{cvlLeaderboardRoutes.map((route: any) => (
						<Route
							key={route.path} // using path as key instead of index
							path={route.path}
							exact={route.exact} // TODO:  Refactor below component code
							// eslint-disable-next-line react/no-unstable-nested-components
							component={(props: any) => {

								return (
									<route.layout {...props} crumbs={getCrumbs(props, allRoutes)}>
										<CVLLeaderboardContext.Provider value={cardStore}>
											<route.component {...props} />
										</CVLLeaderboardContext.Provider>

									</route.layout>
								);
							}}
						/>
					))}
					{inventoryRoutes.map((route: any) => (
						<Route
							key={route.path} // using path as key instead of index
							path={route.path}
							exact={route.exact} // TODO:  Refactor below component code
							// eslint-disable-next-line react/no-unstable-nested-components
							component={(props: any) => {

								return (
									<route.layout {...props} crumbs={getCrumbs(props, allRoutes)}>
										<MyAdminContext.Provider value={filterStore}>
											<route.component {...props} />
										</MyAdminContext.Provider>
									</route.layout>
								);
							}}
						/>
					))}
					{mobileDevicesRoutes.map((route: any) => (
						<Route
							key={route.path} // using path as key instead of index
							path={route.path}
							exact={route.exact} // TODO:  Refactor below component code
							// eslint-disable-next-line react/no-unstable-nested-components
							component={(props: any) => {

								return (
									<route.layout {...props} crumbs={getCrumbs(props, allRoutes)}>
										<MobileDeviceLeaderContext.Provider value={mobileFilterStore}>
											<route.component {...props} />
										</MobileDeviceLeaderContext.Provider>
									</route.layout>
								);
							}}
						/>
					))}
					{fixedroutes.map((route: any) => (
						<Route
							key={route.path} // using path as key instead of index
							path={route.path}
							exact={route.exact} // TODO:  Refactor below component code
							// eslint-disable-next-line react/no-unstable-nested-components
							component={(props: any) => {
								return (
									<route.layout {...props} crumbs={getCrumbs(props, allRoutes)}>
										<route.component {...props} />
									</route.layout>
								);
							}}
						/>
					))}
				</Switch>

				{/* </BrowserRouter> */}
			</div>
		</DashboardContextProvider>
	)
}
export default AuthenticatedApp;