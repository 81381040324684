import { useCustomQuery } from "../../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../../react-query/constants";
import { orgAssetsCountConfig } from "../../../../AssetInventory/API";

const useTrackerLeaderData = (active: boolean = true) => {
	return useCustomQuery([queryKeys.orgcount], orgAssetsCountConfig(), {
		select: (
			data
		): {
			allAssetsCount: number;
			itFundedAssetsCount: number;
			buFundedAssetsCount: number;
			newAssetsCount: number;
			usedAssetsCount: number;
		}[] => {
			// data = { "status": "Success", "code": 200, "message": "Org Assets count fetched successfully", "result": { "allAssetsCount": 28, "itFundedAssetsCount": 10, "buFundedAssetsCount": 18, "newAssetsCount": 17, "usedAssetsCount": 11 } }
			let temp: {
				allAssetsCount: number;
				itFundedAssetsCount: number;
				buFundedAssetsCount: number;
				newAssetsCount: number;
				usedAssetsCount: number;
			}[] = [];
			temp.push(data.result);
			return temp;
		},
		enabled: active,
	});
};

export default useTrackerLeaderData;
