import { useEffect } from 'react'
import ModalHeader from '../../AssetInventory/ManageUsers/AdminModal/ModalHeader'
import Modal from '../../Common/Modal/Modal'
import { Form } from '@dds/components'
import { useForm } from 'react-hook-form'
import { addCvlAdminConfig } from './API'
import { useCustomMutation } from '../../../hooks/useCustomQuery'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from '../../../react-query/constants'
import { useAlert } from 'react-alert'

const CVLCreateAdminModal = ({ handleClose }) => {
	const alert = useAlert();

	useEffect(() => {
		const element = document.getElementById("create-admin-form");
		const api = new Form(element);
	}, [])

	const { mutate, isLoading } = useCustomMutation(addCvlAdminConfig(), {});
	const queryClient = useQueryClient()

	const onSubmit = (data) => {
		mutate({
			isSuperadmin: data.role === 'superadmin' ? true : false,
			userid: data.ntid
		}, {
			onSuccess: (data, variables) => {
				console.log(data, variables)
				queryClient.invalidateQueries([queryKeys.cvladminlist])
				alert.show(`Successfully added  ${variables.userid}  as ${variables.isSuperadmin ? 'a superadmin' : 'an admin'}`, {
					type: 'success',
					timeout: 5000,

				})
			},
			onError() {

				alert.show("Unknown error occurred while adding user. Please check whether the entered NT ID is correct", {
					type: 'error',
					timeout: 0,
				})
			},
		})
	}

	const { register, handleSubmit, formState: { errors } } = useForm();

	return (
		<Modal onClose={isLoading ? () => { } : handleClose} style={{
			height: 'auto',
			overflowX: 'hidden'
		}}>
			{/* Modal Header */}
			<div className="dds__position-fixed dds__w-100" style={{
				top: 0,
			}}>
				<ModalHeader mode={"new"} handleClose={isLoading ? () => { } : handleClose} />
			</div>
			<div style={{
				marginTop: '65px',
				marginBottom: '25px',
			}}>
				<form data-dds="form" className="dds__form dds__container" id="create-admin-form" onSubmit={handleSubmit(onSubmit)}>
					<fieldset className="dds__form__section">
						<div className="dds__row">
							<div className="dds__col--2">
								<div className="dds__input-text__container">
									<label id="text-input-label-576209465" htmlFor="text-input-control-name-576209465">NT ID</label>
									<div className="dds__input-text__wrapper">
										<input
											type="text"
											className="dds__input-text"
											// name="text-input-control-name-576209465"
											id="text-input-control-576209465"
											aria-labelledby="text-input-label-576209465 text-input-helper-576209465"
											{...register("ntid", { required: true, maxLength: 30, minLength: 1 })}


										/>
										{errors.ntid?.type === 'required' && <div id="text-input-error-712795795" style={{
											color: '#ce1126',
											fontSize: '.875rem',
											fontWeight: 400,
											lineHeight: '1.25rem',
											width: '100%'
										}}>
											NT ID is required
										</div>}

										<small id="text-input-helper-576209465" className="dds__input-text__helper"></small>
										<div id="text-input-error-576209465" className="dds__invalid-feedback">Enter a NT ID to continue</div>
									</div>
								</div>
							</div>
						</div>
						<div className="dds__row">
							<div className="dds__col--2">
								<fieldset {...register("role", { required: true })} className="dds__fieldset dds__fieldset--inline dds__radio-button-group" role="radiogroup">
									<legend>Permission</legend>

									<div className="dds__radio-button">
										<input
											className="dds__radio-button__input"
											type="radio"
											// name="name-535101291"
											id="radio-button-control-172502926"
											value="admin"
											{...register("role")}
											defaultChecked

										/>
										<label className="dds__radio-button__label" id="radio-button-label-172502926" htmlFor="radio-button-control-172502926">
											Admin
										</label>
									</div>
									<div className="dds__radio-button">
										<input
											className="dds__radio-button__input"
											type="radio"
											// name="name-535101291"
											id="radio-button-control-271178404"
											value="superadmin"
											{...register("role")}
										/>
										<label className="dds__radio-button__label" id="radio-button-label-271178404" htmlFor="radio-button-control-271178404">
											Superadmin
										</label>
									</div>
									<div id="" className="dds__invalid-feedback"></div>
								</fieldset>
							</div>
						</div>

					</fieldset>
					<div >
						<button disabled={isLoading} className="dds__button dds__mr-3" type="submit">Create</button>
						<button disabled={isLoading} className="dds__button dds__button--secondary" type="button" onClick={handleClose}>Cancel</button>
					</div>
				</form>
			</div>


		</Modal >
	)
}

export default CVLCreateAdminModal