import { useEffect, useRef } from 'react'
import { IconButton } from '../../../../Table/TableStyles'
import { useRegions } from '../../ManageUsersPage/RegionContext'
import SearchUserBox from './InputFields/SearchUserBox'
import useRows from './useRows'
import useSaveData from './useSaveData'
import ModalFooter from './ModalFooter'
import useDropdown from './useDropdown';
import { ModalBody } from '../Styles'
import RoleDropdown from './InputFields/RoleDropdown'
import CountryDropdown from './InputFields/CountryDropdown'
import StockroomDropdown from './InputFields/StockroomDropdown'
import LoadingSpinner from '../../../../Common/LoadingSpinner'
import { useUserInfo } from '../../../../../store/auth/UserInfoContext'

const ModalForm = ({ mode, overflow, handleClose, ...props }) => {
	const { regions } = useRegions();
	console.log("EmployeeDetails", props.employeeDetails)
	const { isSaving, saveData } = useSaveData(mode, handleClose)

	// track rows
	const { rows, setRows, addRow, deleteRow, rowsLeft } = useRows()

	const {
		handleCountryChange,
		loadData,
		ispreloading,
		getData,
		handleRoleChange,
		checkDropdown,
		disableSearch,
		setDisableSearch,
		searchInput,
		setSearchInput,
		role,
		checkDisabledStockroomDropdown,
		setExtraEmployeeInformation
	} = useDropdown(rows, setRows)
	const disabled = useRef<boolean>(false);
	const userNTID = useUserInfo().getUserInfo().ntId;
	useEffect(() => {
		if (mode === 'edit') {
			loadData(props.employeeDetails, props.accessDetails)
			disabled.current = (userNTID + "").toLowerCase() === (props.employeeDetails['ntid'] + "").toLowerCase()
		}
	}, [])


	const divScrollRef = useRef<any>();

	// autoscroll to the newly added row
	useEffect(() => {
		if (rows.operation === "add" && divScrollRef.current)
			divScrollRef.current.scrollIntoView({ behavior: "smooth" });
	}, [rows])

	const saveHandler = () => {
		saveData({ mode, ...getData() })
	}

	return (
		<>
			{/* Modal Body */}
			<ModalBody overflow={overflow}>
				<div id="admin-form"
					className="dds__container-fluid"
					style={{
						width: '99%'
					}}>
					<div className='dds__row'>

						<div className="dds__col-1">
							<SearchUserBox mode={mode}
								searchInput={searchInput}
								setSearchInput={setSearchInput}
								setExtraEmployeeInformation={setExtraEmployeeInformation}
								disableSearch={disableSearch}
								setDisableSearch={setDisableSearch} />
						</div>
						<div className="dds__col-1">
							{(mode === 'new' || role.rolename) && <RoleDropdown
								handleRoleChange={handleRoleChange}
								checkDropdown={checkDropdown}
								role={role}
								disabled={disabled.current}
							/>}

						</div>
					</div>
					{ispreloading && <div className="dds__mt-5"><LoadingSpinner size='md' /></div>}
					{!ispreloading && [...Array(rows.value)].map((e, rowIndex) => <div className='dds__row dds__mt-3' key={rowIndex}>
						<div className='dds__col--md-5'>
							<CountryDropdown
								handleCountryChange={handleCountryChange}
								regions={regions}
								checkDropdown={checkDropdown}
								rowIndex={rowIndex}
							/>
						</div>

						<div className="dds__col--md-5">
							<StockroomDropdown regions={regions}
								checkDropdown={checkDropdown}
								rowIndex={rowIndex}
								inputDisabled={checkDisabledStockroomDropdown(rowIndex)}
							/>

						</div>
						{(rowIndex + 1 === rows.value) ?
							((rowsLeft > 0 && (rowIndex + 1 < regions.length)) ? <div className='dds__col--md-2 dds__pt-4 dds__mt-2'>
								<IconButton
									className="dds__button dds__button--secondary"
									onClick={(e) => {
										e.preventDefault()
										addRow(rowIndex)
									}}
									ref={divScrollRef}
								>
									<i style={{ fontSize: '1.8rem' }} className="dds__icon dds__icon--add-cir" aria-hidden="true" />
								</IconButton>
							</div> : "") :
							<div className='dds__col--md-2 dds__pt-4 dds__mt-2'>
								<IconButton
									className="dds__button dds__button--secondary"
									onClick={(e) => {
										e.preventDefault();
										deleteRow(rowIndex)
									}}
								>
									<i style={{ fontSize: '1.8rem' }} className="dds__icon dds__icon--trash" aria-hidden="true" />
								</IconButton>
							</div>}

					</div>)}

				</div>
			</ModalBody>
			{/* Modal Footer */}
			<ModalFooter
				saveHandler={saveHandler}
				disableSearch={disableSearch && role.roleid}
				handleClose={handleClose}
				isSaving={isSaving} />

		</>
	)
}

export default ModalForm