import LoadingSpinner from '../../../../Common/LoadingSpinner'

const ModalFooter = ({ saveHandler, disableSearch, handleClose, isSaving }) => {

	const dataReadyToSave = () => disableSearch && !isSaving
	return (
		<div style={{
			bottom: 0,
			right: 0,
			backgroundColor: 'white',
			zIndex: 2

		}}
			className='dds__position-fixed dds__p-3 dds__w-100'>
			<button
				type="button"
				onClick={saveHandler}
				className="dds__button dds__button--primary"
				disabled={!dataReadyToSave()}
				style={{
					float: 'right'
				}}
			>
				{!isSaving && <span>Save</span>}
				{isSaving && <span><LoadingSpinner size='md' /></span>}
			</button>
			<button
				type="button"
				onClick={handleClose}
				className="dds__button dds__button--secondary dds__mr-3"
				disabled={isSaving}
				style={{
					float: 'right'
				}}
			>
				Cancel
			</button>


		</div>
	)
}

export default ModalFooter
