import React from 'react'
import { useCustomQuery } from '../../../hooks/useCustomQuery';
import { queryKeys } from '../../../react-query/constants';
import { fetchDowntimeMessage } from '../API';

const useDowntimeMessageAPI = () => {
	return useCustomQuery([queryKeys.downtimeMessage],
		fetchDowntimeMessage(),
		{
			select: (data) => {

				return data.title;
			},
		}
	);
}

export default useDowntimeMessageAPI