import styled from "styled-components";

export const ToolbarWrapper = styled.div`
	height: 100px;
 	width: 100%;	
  	border-radius: 24px;
	background: #E5F8FF;
`

export const ToolbarLink = styled.a<{ isActive: boolean }>`
	text-decoration: none !important;
  	color: ${(props) =>
		props.isActive ? '#17319E' : 'var(--gray-900)'} !important;
`

export const ToolbarIcon = styled.i`
	vertical-align: middle;
	font-size: 1.3rem;
`