import React, { useEffect } from "react";
import { Carousel } from "@dds-uicore/all";
import "../../../Assets/Custom Icons/CustomIcons.css";
import "../../../App/DDS1 Specific Components/Carousel.scss";
import { CarouselWrapper } from "./Styles";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { useAnalytics } from "../../../store/analytics/AnalyticsContext";
import { eventsInfo } from "../../../store/analytics/eventsInfo";

// Announcement Carousel Component
const AnnouncementCarousel: React.FC<{
  data: any;
  isLoading: boolean;
  isError: boolean;
}> = ({ data, isLoading, isError }) => {

  const { addEventToQueue } = useAnalytics();
  // Initializing Carousel
  useEffect(() => {
    // event listener removed as it logged the information twice

    // const logCarouselSlideEvent = (e: any) => {
    //   console.log(e);
    // }
    [].forEach.call(
      document.querySelectorAll('[data-ride="dds__carousel"]'),
      (element: any) => {
        // element.addEventListener("uicCarouselSlideEvent", logCarouselSlideEvent);
        new Carousel(element);
      }
    );

    // return () => {
    //   removeEventListener("uicCarouselSlideEvent", logCarouselSlideEvent);
    // }
  }); // Initializing using ref or ID doesn't work in DDS-uicore


  const logEvent = (slide: number) => {
    addEventToQueue(
      {
        ...eventsInfo.announcementCarouselChange(),
        additional_payload: {
          slide: slide
        }
      })
    console.log(slide);
  }

  // Need empty dependency array - issue related to DDS-uicore
  return (
    <div>
      {isLoading && !isError ? (
        <LoadingSpinner />
      ) : (
        <CarouselWrapper
          id="banner-carousel-no-arrows"
          className="dds__carousel"
          data-ride="dds__carousel"
          data-interval="0"
          data-pause="true"
        >
          <div className="dds__carousel-inner">
            {data &&
              data.records.map(
                (record: { title: string; content: string }, index: number) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={`dds__carousel-item ${index === 0 && " dds__active"
                      }`}
                  >
                    <h6 className="dds__h6" >
                      {record.title}
                    </h6>
                    <p className="dds__body-3">
                      {record.content}
                    </p>
                  </div>
                )
              )}
          </div>
          <ol className="dds__carousel-indicators">
            {data.records.map(
              (record: { title: string; content: string }, index: number) => (
                <li
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  data-target="#banner-carousel-no-arrows"
                  data-slide-to={index}
                  className={`${index === 0 && "dds__active"}`}
                  onClick={() => { logEvent(index) }}
                />
              )
            )}
          </ol>
        </CarouselWrapper>
      )}
    </div>
  );
};

export default AnnouncementCarousel;
