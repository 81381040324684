import gif from '../../../Assets/gifs/noaction_white.gif'

const DummyActionCard: React.FC = () => {
	return (
		<div style={{
			width: 444,
			height: 300,
			border: '1px solid #B6B6B6',
			borderRadius: 24,
			color: '#247554'
		}} className='dds__d-flex dds__justify-content-center dds__flex-column dds__align-items-center'>
			<img src={gif} alt="Awesome" style={{ width: 240 }} />
			<strong className="dds__body-2">
				Awesome!
			</strong>
			<p className='dds__body-2'>You do not need to take any action.</p>

		</div>
	)
}

export default DummyActionCard