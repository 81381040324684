import LoadingSpinner from '../../../Common/LoadingSpinner';
import { StatCardWrapper } from './StatCardWrapper';

type StatCardProps = {
    apiFunction: any,
    enablePercentage?: boolean,
    columnName?: string[],
}

export const StatCard = (props: StatCardProps) => {
    const { data: statCardData, isLoading: statCardisLoading, isFetching: statCardisFetching } = props.apiFunction();

    const displayData = (index: number) => {
        if (props.enablePercentage) {
            if (index === 0)
                return ""
            else {
                return " (" + (statCardData[index] * 100 / statCardData[0]).toFixed(1) + "%)"
            }
        }
        else
            return ""
    }

    const getValidData = (index: number) => {
        if (statCardData)
            return statCardData[index] + displayData(index)
        else
            return "--"
    }

    const getValidColumn = (index: number) => {
        if (props.columnName) {
            return props.columnName[index]
        }
        else {
            return ""
        }
    }

    return (statCardisFetching) ? ((
        <div
            className="dds__mx-auto dds__text-center"
            style={{
                marginTop: 150,
            }}
        >
            <LoadingSpinner label="Fetching" />
        </div>
    )) : (
        <div className="dds__d-flex  dds__flex-wrap dds__flex-align-center dds__justify-content-end" >
            <StatCardWrapper>
                <table className="statCardTable dds__mb-4">
                    <thead>
                        <tr>
                            <th className="statCardElement firstColStat"><strong className='dds__body-2'>{getValidColumn(0) || "Overall Total Assets"}</strong> </th>
                            <th className="statCardElement"><strong className='dds__body-2'>{getValidColumn(1) || "Overall Business Funded"}</strong></th>
                            <th className="statCardElement"><strong className='dds__body-2'>{getValidColumn(2) || "Overall IT Funded"}</strong></th>
                            <th className="statCardElement lastColStat"><strong className='dds__body-2'>{getValidColumn(3) || "Overall Not Funded"}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td id={getValidColumn(0) || "Overall Total Assets"} className="statCardElement dds__body-2">{getValidData(0)}</td>
                            <td id={getValidColumn(1) || "Overall Business Funded"} className="statCardElement dds__body-2">{getValidData(1)}</td>
                            <td id={getValidColumn(2) || "Overall IT Funded"} className="statCardElement dds__body-2">{getValidData(2)}</td>
                            <td id={getValidColumn(3) || "Overall Not Funded"} className="statCardElement dds__body-2">{getValidData(3)}</td>
                        </tr>
                    </tbody>
                </table>
            </StatCardWrapper>
        </div>
    )
}