import { ColumnInstance } from "react-table";

const ColumnShowAdjust: React.FC<{
	allColumns: ColumnInstance<Object>[];
}> = props => (
	<>
		{props.allColumns.map(column => {
			if (column.id !== "expander")
				return (
					<span key={column.id}>
						<label style={{ margin: "10px" }}>
							<input name={column.Header} type="checkbox" {...column.getToggleHiddenProps()} />{" "}
							{column.Header}
							{/* {console.log(column)} */}
						</label>
					</span>
				);
			return null;
		})}
	</>
);

// const ColumnShowAdjust:React.FC<{
// 	hideColTool:() => void
// 	allColumns: ColumnInstance<Object>[],
// }> = (props) => {
// 	return(
// 		<Modal onClose = { props.hideColTool } >
// 		{
// 			props.allColumns.map(column => {
// 				if (column.id !== "expander")
// 					return <span key={column.id}>
// 						<label style={{ margin: "10px" }}>
// 							<input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
// 							{column.Header}
// 							{/* {console.log(column)} */}
// 						</label>
// 					</span>
// 				return null;
// 			})
// 		}
// 		</Modal >
// 	)
// }

export default ColumnShowAdjust;
