import { QueryClient } from "@tanstack/react-query";

function queryErrorHandler(error: unknown): void {
	// error is type unknown because in js, anything can be an error (e.g. throw(5))
	// alert(error);
	console.log("Query caught this error: ",error)
	// const title =
	//   error instanceof Error ? error.message : 'error connecting to server';
  
	// // prevent duplicate toasts
	// toast.closeAll();
	// toast({ title, status: 'error', variant: 'subtle', isClosable: true });
  }

export function generateQueryClient(): QueryClient {
	return new QueryClient({
	  defaultOptions: {
		queries: {
		  onError: queryErrorHandler,
		  staleTime: 2*60*1000, // 10 minutes 600000
		  cacheTime: 15*60*1000, // default cacheTime is 5 minutes; doesn't make sense for staleTime to exceed cacheTime
		  refetchOnMount: true,
		  refetchOnWindowFocus: false,
		  refetchOnReconnect: true,
		},
		mutations: {
		  onError: queryErrorHandler,
		},
	  },
	});
  }

  
export const queryClient = generateQueryClient();