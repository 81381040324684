import { useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { useAlert } from "react-alert";
import ReactTooltip from "react-tooltip";
import { useCustomMutation } from "../../../hooks/useCustomQuery";
import { queryKeys } from "../../../react-query/constants";
import { useUserInfo } from "../../../store/auth/UserInfoContext";
import LoadingSpinner from "../../Common/LoadingSpinner";
import DDSModal from "../../Common/Modalv2/DDSModal";
import { IconButton } from "../../Table/TableStyles";
import { deleteCvlAdminConfig } from "./API";

const DeleteAction = ({ row }) => {
	const alert = useAlert();

	const { mutate, isLoading: isDeleting } = useCustomMutation(deleteCvlAdminConfig(row.values.ntid), {})
	const queryClient = useQueryClient();
	let modalInstanceRef = useRef<any>();

	const openConfirmationModal = () => {
		modalInstanceRef.current.open();
	};
	const closeConfirmationModal = () => {
		modalInstanceRef.current.close();
	};

	const deleteSuccessHandler = () => {
		queryClient.setQueryData([queryKeys.cvladminlist], (oldAdminData: any) => {
			let newAdminData: any[] = [];
			oldAdminData.admins.forEach(admin => {
				if (admin.ntid !== row.values.ntid)
					newAdminData.push(admin)
			})
			return { admins: newAdminData };
		})
		alert.show(`${row.values.name} - User successfully removed from the admin group`, {
			type: 'success',
			timeout: 5000,
		})
	}

	const deleteErrorHandler = () => {
		alert.show(`${row.values.name} - Unknown error occurred while removing user`, {
			type: 'error',
			timeout: 5000,
		})
	}

	const deleteRecordHandler = () => {
		mutate({}, {
			onSuccess: deleteSuccessHandler,
			onError: deleteErrorHandler
		})
		closeConfirmationModal();
	}
	const userNTID = useUserInfo().getUserInfo().ntId;
	if ((userNTID + "").toLowerCase() === (row.values['ntid'] + "").toLowerCase())
		return <></>

	return <><IconButton
		className="dds__button dds__button--secondary"
		data-for="deleteTicketTip"
		data-tip="Delete"
		style={{ marginTop: '0.5rem', marginBottom: 0, }}
		onClick={openConfirmationModal}
		disabled={isDeleting}
		role="button"
		aria-label="delete record"

	>
		{isDeleting && <LoadingSpinner size='sm' />}
		{!isDeleting && <i style={{ fontSize: '1.1rem', color: 'red' }} className="dds__icon dds__icon--trash" aria-hidden="true" />}
		<ReactTooltip id="deleteTicketTip" />

	</IconButton>
		<DDSModal
			themeColor={"black"}
			iconClass={"dds__icon--trash"}
			modalInstanceRef={modalInstanceRef}
			classes={"dds__rounded"}
			id={"infoModal"}
			title={"Confirm Deletion"}
			footerButtons={[
				{
					class: "dds__button--secondary",
					text: "Cancel",
					handleFooterButtonClick: closeConfirmationModal,
				},
				{
					class: "dds__button--destructive",
					text: "Confirm Delete",
					handleFooterButtonClick: deleteRecordHandler,
				},
			]}
		>
			<div
				style={{
					color: "var(--gray-800)",
				}}
			>
				Are you sure you want to remove {row.values.name} (NT ID:{" "}
				{row.values.ntid}) from the admin group. This action can not be
				undone.
			</div>
		</DDSModal>
	</>
}
export default DeleteAction
