import VulnerabilityLeaderboard from "../../components/VulnerabilityPortal/leaderboard/VulnerabilityLeaderboard";
import CvlLayout from "../../Layouts/CvlLayout";

 const cvlLeaderboardRoutes = [
	{
		path: "/vulnerability-portal/leaderboard",
		layout: CvlLayout,
		exact: true,
		name: "Leaderboard - L3",
		component: VulnerabilityLeaderboard,
	},
	{
		path: "/vulnerability-portal/leaderboard/:l3_manager",
		layout: CvlLayout,
		exact: true,
		name: "L4",
		component: VulnerabilityLeaderboard,
	},
	{
		path: "/vulnerability-portal/leaderboard/:l3_manager/:l4_manager",
		layout: CvlLayout,
		exact: true,
		name: "L5",
		component: VulnerabilityLeaderboard,
	},
	{
		path: "/vulnerability-portal/leaderboard/:l3_manager/:l4_manager/:l5_manager",
		layout: CvlLayout,
		exact: true,
		name: "L6",
		component: VulnerabilityLeaderboard,
	},
];
export default cvlLeaderboardRoutes;