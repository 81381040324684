import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import useAxios from "../../../hooks/useAxios";
import { cvlStatCountConfig, fetchLeaderboardFilterList } from "./API";
import { useCustomQuery } from "../../../hooks/useCustomQuery";
import { queryKeys } from "../../../react-query/constants";


export const CVLLeaderboardContext = createContext<any>(null);

export const useCardData = () => {
	const [cardStatistic, setCardStatistic] = useState<{
		allCVLCount: number,
		allProductCount: number,
		unassignedCVLCount: number
	}>();

	const { data: originalFilterList, isLoading: isoriginalFilterListLoading } = useCustomQuery([queryKeys.originalFilterList], fetchLeaderboardFilterList(), {
		staleTime: Infinity,
		select: (data) => data.result
	});
	const [selectedFilters, setSelectedFilters] = useState<any>(null);




	const { pathname } = window.location;

	const {
		isLoading: isCardsLoading,
		error: cardDataError,
		sendRequest: fetchCardData,
	} = useAxios();
	const fetchData = () => {
		if (!cardStatistic)
			fetchCardData(cvlStatCountConfig(), (dataObj) => {
				setCardStatistic(dataObj.result)
			})
	}
	useEffect(() => {
		if (/leaderboard/i.test(pathname) && /vulnerability-portal/i.test(pathname))
			fetchData();
	}, [pathname])
	return {
		isCardsLoading: isCardsLoading,
		cardDataError: cardDataError,
		cardStatistic: cardStatistic,
		originalFilterList: originalFilterList,
		isoriginalFilterListLoading: isoriginalFilterListLoading,
		selectedFilters: selectedFilters,
		setSelectedFilters: setSelectedFilters
	}
}
export default useCardData;


// export const CVLLeaderboardContextProvider = ({ children }) => {
// 	const [cardStatistic, setCardStatistic] = useState<{
// 		allCVLCount: number,
// 		allProductCount: number,
// 		unassignedCVLCount: number
// 	}>();

// 	const {
// 		isLoading: isCardsLoading,
// 		error: cardDataError,
// 		sendRequest: fetchCardData,
// 	} = useAxios();

// 	useEffect(() => {
// 		// if (!cardStatistic)
// 		fetchCardData(cvlStatCountConfig(), (dataObj) => {
// 			setCardStatistic(dataObj.result)
// 		})
// 	}, [])
// 	return <CVLLeaderboardContext.Provider value={{
// 		isCardsLoading: isCardsLoading,
// 		cardDataError: cardDataError,
// 		cardStatistic: cardStatistic
// 	}}>
// 		{children}
// 	</CVLLeaderboardContext.Provider>
// }
export const useCVLLeaderboardCardData = () => {
	const context = useContext(CVLLeaderboardContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}