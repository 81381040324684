/* eslint-disable react/destructuring-assignment */
import React, { ChangeEventHandler, Fragment, MouseEventHandler, useEffect, useMemo, useState } from "react";
import {
	useTable,
	useSortBy,
	useFilters,
	usePagination,
	useExpanded,
	useFlexLayout,
	useResizeColumns,
	useGlobalFilter,
} from "react-table";
import { useExportData } from "react-table-plugins";

import Papa from "papaparse";
import { dateBetweenFilterFn, DefaultColumnFilter } from "./Filters";
import TableToolbar from "./Toolbar/TableToolbar";
import { IconButton, Styles } from "./TableStyles";
import ActiveFilterBar from "./ActiveFilterBar";

import { ReactComponent as Unsorted } from "../../Assets/Unsorted.svg";
import { ReactComponent as SortAsc } from "../../Assets/Sort asc.svg";
import { ReactComponent as SortDesc } from "../../Assets/Sort desc.svg";
import { Pagination } from "./Pagination";
import ReactTooltip from "react-tooltip";
import LoadingSpinner from "../Common/LoadingSpinner";

// import { loadURLSVGs } from "@dds-uicore/all";

const getStyles = (props: any, align = "left", overflow = "hidden"): any[] => [
	props,
	{
		style: {
			justifyContent: align === "right" ? "flex-end" : "flex-start",
			alignItems: "flex-start",
			display: "flex",
			overflow,
		},
	},
];

const headerProps = (props: any, { column }: any): any[] =>
	getStyles(props, column.align, column.overflow);

const cellProps = (props: any, { cell }: any): any[] =>
	getStyles(props, cell.column.align, cell.column.overflow);



const Table: React.FC<{
	data: any[];
	columns: any[];
	activateFilters?: boolean;

	serverSideExport?: boolean;
	exportHandler?: MouseEventHandler<HTMLButtonElement>
	isExporting?: boolean

	activateExportAllData?: boolean;
	activateExportCurrentView?: boolean;
	skipStateReset: boolean;
	instanceProps?: object;
	expandProps?: object;
	disableToolbar?: boolean;
	activateSearch?: boolean;
	defaultComplex?: boolean;
	canExpand?: boolean;
	ExpandComponent?: React.FC<any>;
	serverSideSort?: boolean;
	sortCol?: string;
	sortDirection?: string;
	// changeSortDirection?: (reset:boolean) => void,
	setSortColHandler?: (id: string) => void;

	disablePagination?: boolean // Display all the rows if this is true

	serverSideSearch?: boolean
	searchInputChangeHandler?: ChangeEventHandler<HTMLInputElement>
	searchHandler?: any
	isFetching?: boolean
	searchHeader?: string
	searchOnly?: boolean

	fetchError?: any
	disableColumnFunctions?: boolean
	disableHeaderRow?: boolean
}> = props => {

	// const data = useMemo(() => props.data, [props.data]);
	const columns = useMemo(() => props.columns, [props.columns]);

	// const [data, setData] = React.useState(props.data)
	// const [originalData] = React.useState(data)

	// const [!props.skipStateReset,, set!props.skipStateReset,] = React.useState(false)

	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
			// When using the useFlexLayout:
			minWidth: 40, // minWidth is only used as a limit for resizing
			width: 100, // width is used for both the flex-basis and flex-grow
			maxWidth: 400, // maxWidth is only used as a limit for resizing
		}),
		[]
	);
	const filterTypes = useMemo(
		() => ({
			dateBetween: dateBetweenFilterFn,
			// text: (rows, id, filterValue) => {
			// 	return rows.filter(row => {
			// 		const rowValue = row.values[id];
			// 		return rowValue !== undefined
			// 			? String(rowValue)
			// 				.toLowerCase()
			// 				.startsWith(String(filterValue).toLowerCase())
			// 			: true;
			// 	});
			// }
		}),
		[]
	);

	const getExportFileBlob = ({ columns, data, fileType, fileName }): Blob | false => {
		if (fileType === "csv") {
			// CSV example
			const headerNames = columns.map(col => col.exportValue);
			const csvString = Papa.unparse({ fields: headerNames, data });
			return new Blob([csvString], { type: "text/csv" });
		}
		// else if (fileType === "xlsx") {
		//   // XLSX example

		//   const header = columns.map((c) => c.exportValue);
		//   const compatibleData = data.map((row) => {
		// 	const obj = {};
		// 	header.forEach((col, index) => {
		// 	  obj[col] = row[index];
		// 	});
		// 	return obj;
		//   });

		//   let wb = XLSX.utils.book_new();
		//   let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
		// 	header,
		//   });
		//   XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
		//   XLSX.writeFile(wb, `${fileName}.xlsx`);

		//   // Returning false as downloading of file is already taken care of
		//   return false;
		// }
		// //PDF example
		// if (fileType === "pdf") {
		//   const headerNames = columns.map((column) => column.exportValue);
		//   const doc = new JsPDF();
		//   doc.autoTable({
		// 	head: [headerNames],
		// 	body: data,
		// 	margin: { top: 20 },
		// 	styles: {
		// 	  minCellHeight: 9,
		// 	  halign: "left",
		// 	  valign: "center",
		// 	  fontSize: 11,
		// 	},
		//   });
		//   doc.save(`${fileName}.pdf`);

		//   return false;
		// }

		// Other formats goes here
		return false;
	}

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		visibleColumns,
		page, // Instead of using 'rows', we'll use page,
		// which has only the rows for the active page

		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,

		allColumns,
		getToggleHideAllColumnsProps,

		state: { pageIndex, pageSize, ...state },
		resetResizing,
		preGlobalFilteredRows,
		setGlobalFilter,
		setFilter,
		exportData,
	} = useTable(
		{
			columns,
			data: props.data,
			defaultColumn,
			getExportFileBlob,
			// filterTypes,
			initialState: {
				pageIndex: 0,
				hiddenColumns: columns
					.filter((col: any) => col.show === false)
					.map(col => col.id || col.accessor) as any,
			},
			// use the !props.skipStateReset, option to disable page resetting temporarily
			autoResetPage: !props.skipStateReset,
			// autoResetPage: !props.skipStateReset,,
			autoResetExpanded: !props.skipStateReset,
			autoResetGroupBy: !props.skipStateReset,
			autoResetSelectedRows: !props.skipStateReset,
			autoResetSortBy: !props.skipStateReset,
			autoResetFilters: !props.skipStateReset,
			autoResetRowState: !props.skipStateReset,

			...props.instanceProps,

			// updateStatusCell: props.updateStatusCell,

			// statusList,
		},
		useFlexLayout,
		useResizeColumns,
		useFilters,
		useGlobalFilter,
		useSortBy,
		useExpanded,
		usePagination,
		useExportData
	);

	const tableClasses = "dds__table"; // dds__table-cmplx //dds__table-hover dds__table-striped
	const [cmplxTable, setcmplxTable] = useState(
		!!props.defaultComplex
	);



	const cmplxHandler = (): void => {
		setcmplxTable(prevState => !prevState);

	};

	useEffect(() => { // Added external control through default complex prop value, to be used when not using internal table toolbar
		setcmplxTable(!!props.defaultComplex);
	}, [props.defaultComplex])


	// useEffect(() => {
	// 	if (props.defaultPageSize) // For server side pagination or setting default page size
	// 		setPageSize(props.defaultPageSize);
	// }, [props.defaultPageSize, setPageSize])




	//Disable Export if noRecordFound or Data is Fetched
	//Another Check for rows length is due to client Table component
	const disableExportNull = props.fetchError || props.isFetching || rows.length === 0;

	return (
		<>
			{!props.disableToolbar && (
				<TableToolbar
					preGlobalFilteredRows={preGlobalFilteredRows}
					globalFilter={state.globalFilter}
					setGlobalFilter={setGlobalFilter}
					allColumns={allColumns}
					resetResizing={resetResizing}
					onCrunchClick={cmplxHandler}
					isCmplx={cmplxTable}
					filterState={state.filters}
					activateFilters={!!props.activateFilters}
					exportData={exportData}
					activateExportCurrentView={
						!!props.activateExportCurrentView
					}
					activateExportAllData={!!props.activateExportAllData}
					activateSearch={!!props.activateSearch}

					serverSideExport={!!props.serverSideExport}
					exportHandler={props.exportHandler}
					isExporting={props.isExporting}

					serverSideSearch={!!props.serverSideSearch}
					searchInputChangeHandler={props.searchInputChangeHandler}
					searchHandler={props.searchHandler}
					searchOnly={!!props.searchOnly}
					searchHeader={props.searchHeader}
					isFetching={props.isFetching}
					disableExportNull={disableExportNull}

					disableColumnFunctions={props.disableColumnFunctions}
				/>
			)}

			{!props.disableToolbar && props.activateFilters && (
				<ActiveFilterBar
					filterState={state.filters}
					setFilter={setFilter}
					allColumns={allColumns}
				/>
			)}

			{props.isFetching && !props.fetchError && (
				<div className="dds__mt-3 dds__mx-auto dds__text-center">
					<LoadingSpinner label="Fetching" />
					{/* <h4 className="dds_h4">Fetching</h4> */}
				</div>
			)}
			{props.fetchError &&
				!(
					props.searchOnly &&
					props.fetchError ===
					"Data is not loading at the moment. Please check your connection and try again."
				) && (
					<div className="dds__mt-3 dds__mx-auto dds__text-center">
						<h4 className="dds_h4" id="noRecordFound">{props.fetchError}</h4>
					</div>
				)}


			{!props.isFetching && !props.fetchError && (
				<Styles>
					{preGlobalFilteredRows.length > 0 ? <table
						{...getTableProps()}
						className={
							cmplxTable
								? `table ${tableClasses} dds__table-cmplx`
								: `table ${tableClasses}`
						}
					>
						{!props.disableHeaderRow && 
						<thead>
							{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()} className="tr">
									{headerGroup.headers.map(column => (
										<th {...column.getHeaderProps(headerProps)} className="th">
											<div className="dds__container-fluid dds__p-0">
												{column.canGroupBy ? (
													// If the column can be grouped, let's add a toggle
													<span {...column.getGroupByToggleProps()}>
														{column.isGrouped ? "🛑 " : "👊 "}
													</span>
												) : null}

												{/* Add a sort direction indicator */}
												{!props.serverSideSort && (
													<span {...column.getSortByToggleProps()}>
														<div className="dds__row">
															<div className={`${column.canSort ? "dds__col--sm-4" : "dds__col-2"}`}
																style={{
																	overflow: 'hidden',
																	textOverflow: 'ellipsis'
																}}
																data-for={`${column.id}_tip`}
																data-tip={column.render("Header")}
															>
																{column.render("Header")}{" "}
															</div>
															{column.canSort &&
																<div className="dds__col--sm-2">{
																	column.isSorted ? (
																		column.isSortedDesc ? (
																			<SortDesc style={{ width: "10px" }} />
																		) : (
																			<SortAsc style={{ width: "10px" }} />
																		)
																	) : (
																		<Unsorted style={{ width: "10px", color: 'white' }} />
																	)
																}
																</div>}
															{/* Use column.getResizerProps to hook up the events correctly */}
														</div>
													</span>
												)}
												{props.serverSideSort && (
													<span
														role="button"
														onClick={() => {
															column.canSort && props.setSortColHandler &&
																props.setSortColHandler(column.id);
														}}
													>
														<div className="dds__row">
															<div className={`${column.canSort ? "dds__col--sm-5" : "dds__col-2"}`}
																style={{
																	overflow: 'hidden',
																	textOverflow: 'ellipsis'
																}}
																data-for={`${column.id}_tip`}
																data-tip={column.render("Header")}
															>
																{column.render("Header")}{" "}
															</div>
															{column.canSort &&
																<div className="dds__col--sm-1">{

																	props.sortCol === column.id ? (
																		<>
																			{props.sortDirection === "desc" && (
																				<SortDesc style={{ width: "10px" }} />
																			)}
																			{props.sortDirection === "asc" && (
																				<SortAsc style={{ width: "10px" }} />
																			)}
																			{props.sortDirection === "" && (
																				<Unsorted style={{ width: "10px" }} />
																			)}
																		</>
																	) : (
																		<Unsorted style={{ width: "10px" }} />
																	)
																}
																</div>
															}
														</div>
														{/* Use column.getResizerProps to hook up the events correctly */}
													</span>
												)}

											</div>

											{/* <div>
										{column.canFilter ? column.render('Filter') : null}
									</div> */}
											<ReactTooltip id={`${column.id}_tip`} />
											{column.canResize ? (
												<div
													{...column.getResizerProps()}
													className={`resizer ${column.isResizing ? "isResizing" : ""
														}`}
												/>
											) : null}
										</th>
									))}
								</tr>
							))}
						</thead>}
						<tbody {...getTableBodyProps()} className="tbody">
							{props.disablePagination ? rows.map(row => {
								prepareRow(row);
								return (
									<Fragment key={row.getRowProps().key + "rowset"}>
										{/* <div style={{ border: '1px solid green' }}> */}
										<tr className="tr" {...row.getRowProps()}>
											{row.cells.map(cell => {
												// console.log(cell.getCellProps(cellProps));
												return <td
													className="td"
													{...cell.getCellProps(cellProps)}
												// style={{
												// 	// overflow: cell.column.overflow?cell.column.overflow: "initial"
												// }}
												>
													{cell.render("Cell")}
												</td>
											}
											)}
										</tr>
										{/* </div> */}

										{row.isExpanded && props.canExpand ? (
											<tr key={row.getRowProps().key + "expanded"}>
												<div>
													{/* {renderExpandComponent({ row })} */}
													{props.ExpandComponent ? (
														<props.ExpandComponent
															expandProps={props.expandProps}
															row={row}
															columns={columns}
														/>
													) : (
														row
													)}
												</div>
											</tr>
										) : null}
									</Fragment>
								);
							}) : page.map(row => {
								prepareRow(row);
								return (
									<Fragment key={row.getRowProps().key + "rowset"}>
										{/* <div style={{ border: '1px solid green' }}> */}
										<tr className="tr" {...row.getRowProps()}>
											{row.cells.map(cell => <td className="td" {...cell.getCellProps(cellProps)}>
												{cell.render("Cell")}
											</td>
											)}
										</tr>
										{/* </div> */}

										{row.isExpanded && props.canExpand ? (
											<tr key={row.getRowProps().key + "expanded"}>
												<div>
													{/* {renderExpandComponent({ row })} */}
													{props.ExpandComponent ? (
														<props.ExpandComponent
															expandProps={props.expandProps}
															row={row}
															columns={columns}
														/>
													) : (
														row
													)}
												</div>
											</tr>
										) : null}
									</Fragment>
								);
							})}
						</tbody>
					</table> :
						<p style={{
							margin: 'auto',
							textAlign: 'center',
							fontSize: 18
						}}>No Record Found
						</p>}
				</Styles>
			)
			}


			{/* State to control whether server side or client side */}
			{!props.disablePagination && <Pagination
				pageOptions={pageOptions}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				canPreviousPage={canPreviousPage}
				canNextPage={canNextPage}
				previousPage={previousPage}
				nextPage={nextPage}
				pageCount={pageCount}
				page={page}
				setPageSize={setPageSize}
				pageSize={pageSize}
			/>}

			{/* <pre>
				<h4>Debugger</h4>
				<code>{JSON.stringify(state, null, 2)}</code>
			</pre> */}
		</>
	);
};

export default Table