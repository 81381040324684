const baseUrl = process.env.REACT_APP_EOL_BASE_URL

export const logDelllyticsEvents = () => {
	let endpoint = baseUrl + "/api/Add/v1/DellLyticsLogs"
	let method = 'POST'
	return {
		url: endpoint,
		method,
		headers: {
			"Content-Type": "application/json",
		},
		
	}
}