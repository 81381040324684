import * as XLSX from "xlsx"

export const downloadData = 
	(dataObj: any, columns:any[], filename:string) => {
		const loadedData: any = []
		for (const record in dataObj.records) {
			const tempData: any = {}
			Object.values(columns).forEach(val => {
				if (!val.disableExport) {
					const { accessor } = val
					const { Header } = val
					if (dataObj.records[record][accessor] != null)
						tempData[Header] = `${dataObj.records[record][val.accessor]}`
				}
			})

			loadedData.push(tempData)
		}

		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(loadedData)
		const csvOutput: string = XLSX.utils.sheet_to_csv(worksheet)

		const fileName = filename
		const type = "csv"

		console.log('csv output local', csvOutput)
		downloadBlob(new Blob([csvOutput], { type: "text/csv" }), `${fileName}.${type}`)
	}

	export const downloadBlob = 
	(blob: Blob, filename) => {
		const dataUrl = URL.createObjectURL(blob)
		const link = document.createElement("a")
		link.download = filename
		link.href = dataUrl
		link.click()
	}