import React from 'react'
import { useAlert } from 'react-alert';
import { useCustomQuery } from '../../../hooks/useCustomQuery';
import { queryKeys } from '../../../react-query/constants';
import { fetchStatusConfig } from '../API/api';

const useATStatusListAPI = () => {
	const alert = useAlert();
	return useCustomQuery([queryKeys.ATStatusList], fetchStatusConfig(), {
		select: (list) => {
			const temp: string[] = [];
			// eslint-disable-next-line camelcase
			list.statusList.forEach((status: { status_name: string }) => {
				temp.push(status.status_name);
			});
			return temp;
		},
		onError: () => {
			alert.show(
				"Error occurred while fetching Status options:\n Editing Status won't be available. Please contact admin or try refreshing the page",
				{
					timeout: 0, // custom timeout just for this one alert
					type: "error",
				}
			);
		}
	});
}

export default useATStatusListAPI