const NoRecordFound = () => {
	return (
		<div className="dds__d-flex dds__justify-content-center dds__text-center dds__body-2">
			<div className='dds__px-5'>
				<div>
					<i className="dds__icon dds__icon--alert-notice " style={{
						fontSize: '4.5rem',
						color: 'var(--blue-800)'
					}}></i>
				</div>
				<p className="dds__px-3 dds__py-1 dds__subtitle-2" style={{
					borderRadius: 24,
					background: '#6E6E6E',
					color: 'white'
				}}>No record found</p>
			</div>
		</div>
	)
}

export default NoRecordFound