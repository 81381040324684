import { createContext, useMemo, useContext, useEffect } from 'react'
import { IUserInfo, IUserRole, IUserRoles } from '../../common/modules';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { queryKeys } from '../../react-query/constants';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const userinfoConfig = () => {
	const fetchUserInfoEndpoint = "/api/Fetch/v1/UserInfo";
	return {
		url: BASE_URL + fetchUserInfoEndpoint,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	}
}

const UserInfoContext = createContext<any>(null);

const UserInfoProvider = (props) => {

	let userProfile = {} as IUserInfo;

	// const userInfo = {
	// 	"userName": "Dev Kathuria",
	// 	"legalName": "DEV KATHURIA",
	// 	"ntId": "dev_kathuria",
	// 	"badgeId": "1248838",
	// 	"email": "dev.kathuria@dell.com",
	// 	"city": "",
	// 	"state": "UP",
	// 	"country": "India",
	// 	"department": "BI-Delivery IT (India) - 2",
	// 	"imageurl": null,
	// 	"jobTitle": "Software Engineer 1 - IT",
	// 	"userRole": {
	// 		"arrApproveCity": [
	// 			{
	// 				"cityname": "Hyderabad",
	// 				"countryname": "India",
	// 				"isDefault": false
	// 			},
	// 			{
	// 				"cityname": "Chennai (ex Madras)",
	// 				"countryname": "India",
	// 				"isDefault": false
	// 			},
	// 			{
	// 				"cityname": "Kolkata",
	// 				"countryname": "India",
	// 				"isDefault": false
	// 			},
	// 			{
	// 				"cityname": "Chennai",
	// 				"countryname": "India",
	// 				"isDefault": false
	// 			},
	// 			{
	// 				"cityname": "Mumbai",
	// 				"countryname": "India",
	// 				"isDefault": false
	// 			},
	// 			{
	// 				"cityname": "Noida",
	// 				"countryname": "India",
	// 				"isDefault": false
	// 			},
	// 			{
	// 				"cityname": "New Delhi",
	// 				"countryname": "India",
	// 				"isDefault": false
	// 			},
	// 			{
	// 				"cityname": "Coimbatore",
	// 				"countryname": "India",
	// 				"isDefault": false
	// 			},
	// 			{
	// 				"cityname": "Ahmedabad",
	// 				"countryname": "India",
	// 				"isDefault": false
	// 			},
	// 			{
	// 				"cityname": "Bangalore",
	// 				"countryname": "India",
	// 				"isDefault": true
	// 			},
	// 			{
	// 				"cityname": "Pune",
	// 				"countryname": "India",
	// 				"isDefault": false
	// 			},
	// 			{
	// 				"cityname": "Remote",
	// 				"countryname": "India",
	// 				"isDefault": false
	// 			}
	// 		],
	// 		"role": "superadmin",
	// 		"clientRole": "superadmin",
	// 		"isAssetInventoryLeader": false,
	// 		"assetInventoryRole": "user",
	// 	},
	// 	"is_CVL_Leader": false,
	// 	"displayInventoryCard": true,
	// 	"region": "Asia-Pacific/Japan"
	// }
	// const isLoading = false;

	const { data: userInfo, isLoading, isError, refetch, error }
		= useCustomQuery([queryKeys.userinfo], userinfoConfig(),
			{
				select: (response) => {
					console.log(response)
					userProfile = response.user;


					return {
						...userProfile,
						is_CVL_Leader: response.is_CVL_Leader,
						displayInventoryCard: response.displayInventoryCard,
						user_role: response.user_role
					};
				}
			});
	const getUserInfo = (): IUserInfo => {
		if (userInfo)
			return userInfo;
		else
			refetch();

		return {} as IUserInfo
	}
	const getUserRole = (key: string): IUserRole => {
		if (userInfo)
			return userInfo.user_role[key];
		else
			refetch();

		return {} as IUserRole
	}
	useEffect(() => { // To handle edge cases of Errors (401, 403 are already handled globally)
		if (isError) {
			throw new Error("We are unable to fetch your information. Please try again later.")
		}
	}, [isError])
	const userInfoContextValue =
		useMemo(
			() => {
				return {
					getUserInfo,
					getUserRole
				}
			},
			[getUserInfo, getUserRole]
		)


	if (isLoading || !userInfo)
		return (
			<div className="dds__mt-5">
				<LoadingSpinner size="md" label="Fetching your information..." />
			</div>
		)

	return (
		<UserInfoContext.Provider
			// value={{}}
			value={userInfoContextValue}
			{...props} >
		</UserInfoContext.Provider>
	)
}

const useUserInfo = (): {
	getUserInfo: () => IUserInfo;
	getUserRole: (key: string) => IUserRole
} => {
	const context = useContext(UserInfoContext);
	if (!context)
		throw new Error('useUserInfo must always be used within UserInfoProvider')
	return context;
}

export { UserInfoProvider, useUserInfo };