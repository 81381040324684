import React from "react";
import { Row } from "react-table";
import { EmployeeDetails } from "./EmployeeDetails";
import { ProductDetails } from "./ProductDetails";
import { ShippingDetails } from "./ShippingDetails";

export type expandCell = {
	Header: string;
	value: string;
};
export type EditablexpandCell = {
	Header: string;
	id: string;
	value: string;
	editable?: boolean;
	editable_type: string;
	options_data?: string[];
};

export const Expand: React.FC<{
	row: Row<Object>;
	columns: any[];
	expandProps: any;
}> = ({
	row,
	columns,
	expandProps,
}) => {
		const pD: expandCell[] = [];
		const eD: expandCell[] = [];
		const sD: EditablexpandCell[] = [];
		let updatedon = "";
		let updatedby = "";
		const setData = expandProps && expandProps.setData ? expandProps.setData : () => { }

		columns.map(col => {
			const temp = {
				Header: col.Header,
				value: row.values[col.accessor],
			};
			if (col.accessor === "updatedon") updatedon = row.values[col.accessor];
			else if (col.accessor === "updatedby") updatedby = row.values[col.accessor];
			else if (col.expandCategory === "ProductDetails") {
				pD.push(temp);
			} else if (col.expandCategory === "ShippingDetails") {
				sD.push({
					...temp,
					id: col.accessor,
					editable: col.editable ? col.editable : true,
					editable_type: col["editable_type"],
					options_data: col["options_data"] ? col["options_data"] : [],
				});
			} else if (col.expandCategory === "EmployeeDetails") {
				eD.push(temp);
			}
			return null;
		});

		// console.log(row)
		return (
			<div className="dds__container-fluid dds__p-2">
				<div className="dds__row dds__m-2">
					<div className="dds__col-2 dds__col--md-4 dds__border dds__p-3">
						<ProductDetails data={pD} />
					</div>
					<div className="dds__col-2 dds__col--md-4 dds__border dds__p-3">
						<EmployeeDetails data={eD} />
					</div>
					<div className="dds__col-2 dds__col--md-4 dds__border dds__p-3">
						<ShippingDetails
							updatedby={updatedby}
							updatedon={updatedon}
							data={sD}
							row={row}
							setData={setData}
							allowEdit={expandProps.allowEdit}
						/>
					</div>
				</div>
			</div>
		);
	};
