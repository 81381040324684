import React, { useState } from 'react'
import DropdownComponent from '../../../../Common/DropdownComponent'

const CountryDropdown = ({ country }) => {

	return (
		<DropdownComponent
			selection='multiple'
			label="Country"
			PopupList={
				<ul
					className="dds__dropdown__list"
					role="listbox"
					tabIndex={-1}
				>
					{Object.keys(country).length > 0 && (
						<>
							{country.map((value, index) => (
								<li
									key={index}
									className="dds__dropdown__item"
									role="none"
								>
									<button
										className="dds__dropdown__item-option"
										role="option"
										data-selected="false"
										tabIndex={-1}
									>
										<span className="dds__dropdown__item-label">
											{value}
										</span>
									</button>
								</li>
							))}
						</>
					)}
				</ul>
			}
		/>
	)
}
export default CountryDropdown