import { useEffect } from 'react'
import ManageAdmin from './ManageAdminPage'
import { ManageCvlAdminContextProvider } from './ManageCvlAdminContext'

const ManageAdminWrapper = () => {
	useEffect(() => {
		document.title = "Security Threats | Manage Users"
	}, [])
	return (
		<ManageCvlAdminContextProvider>
			<ManageAdmin />
		</ManageCvlAdminContextProvider>
	)
}

export default ManageAdminWrapper