import LoadingSpinner from "../../../Common/LoadingSpinner";
import { IconButton } from "../../../Table/TableStyles";

const StatCard: React.FC<{
	heading: string,
	count?: number,
	detailButton?: boolean,
	onClickDetailButton?: () => void,
	bgColor: string,
	isLoading: boolean,
	isError: any
}> = ({ bgColor, heading, count, detailButton = false, onClickDetailButton = () => { }, isLoading, isError }) => {


	return <div className="dds__d-flex dds__p-2 dds__text-white dds__h-100" style={{
		background: bgColor,
		textAlign: 'center',
		color: 'white',
		minHeight: 100
	}}>
		<div className='dds__flex-grow-1'>
			{!isLoading && <h5 className="dds__h5 dds__text-white">{heading}</h5>}

			{isLoading && !isError && <LoadingSpinner size="md" />}

			{!isLoading && !isError && <p className=" dds__body-1 dds__mb-0 dds__text-white dds__font-weight-bold">
				{count}
			</p>}

			{isError && <h1 className="dds__h3 dds__mb-0" style={{
				color: 'red'
			}}>- - -</h1>}
		</div>
		{detailButton && !isLoading && <div className='dds__flex-row dds__align-self-center' style={{
			// position: 'absolute',
			// right: 30,
			// top: 30
		}}>
			<IconButton
				onClick={onClickDetailButton}
				className=""
				data-for="showHideColTip"
				data-tip="Show Hide Columns"
			><i
					style={{ fontSize: "1.2rem", color: 'white' }}
					className="dds__icon dds__icon--arrow-cir-right"
				/></IconButton>
		</div>}
	</div>
}

export default StatCard;