const TelemetryMeasuresToolbar = ({ AddMeasuresButton }) => {
	return (
		<>
			<div className="dds__w-100">
				<div
					className="dds__w-100 dds__h-100 dds__d-inline-block"
					style={{
						verticalAlign: "middle",
						position: "relative",
					}}
				>
					<div
						className=" dds__position-absolute"
						style={{
							top: "13%",
							right: "0%",
						}}
					><div style={{marginTop:"42px"}}>
						<span
							className="dds__mr-2">
							<AddMeasuresButton />
						</span>
						</div>
					</div>
				</div>

			</div>
		</>
	);
};

export default TelemetryMeasuresToolbar;
