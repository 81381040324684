import { useEffect, useRef, useState } from "react";
import ToolbarButton from "../../AssetInventory/ManageUsers/ManageUsersPage/Toolbar/ToolbarButton";
import CreateEditAdminModal from "./AdminModal/CreateEditAdminModal";

const CreateAdminButton = () => {
	let modalInstanceRef = useRef<any>();
	const [show, setShow] = useState(false);
	const openModal = () => {
		setShow(true);
	};

	useEffect(() => {
		console.log("trigger", show, modalInstanceRef)
		if (show) {
			if (modalInstanceRef.current) {
				modalInstanceRef.current.open();
			}
		}
	}, [show])


	const closeModal = () => {
		console.log("Close modal")
		setShow(false);
	}

	return <>
		<ToolbarButton onClick={openModal} iconClass="dds__icon--user-add"
		>Create Admin</ToolbarButton>
		{show && <CreateEditAdminModal handleClose={closeModal} mode='new' modalInstanceRef={modalInstanceRef} />}
	</>
}
export default CreateAdminButton