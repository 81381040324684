import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useCustomMutation } from "../../../hooks/useCustomQuery";
import { queryKeys } from "../../../react-query/constants";
import { useUserInfo } from "../../../store/auth/UserInfoContext";
import { updateCvlAdminConfig } from "./API";
import "./rowCell.css"

const RoleCell = ({ value, row }) => {
	const alert = useAlert();

	const [selectedValue, setSelectedValue] = useState(value)

	const { mutate, isLoading } = useCustomMutation(updateCvlAdminConfig(), {});
	const queryClient = useQueryClient()

	const roleChangeHandler = (e) => {
		setSelectedValue(e.target.value)
		console.log(e.target.value)
		mutate({
			isSuperadmin: e.target.value === 'superadmin',
			userid: row.values.ntid
		}, {
			onSuccess: (data, variables) => {
				let newRole = variables.isSuperadmin ? 'superadmin' : 'admin'
				queryClient.setQueryData([queryKeys.cvladminlist], (oldAdminData: any) => {
					let newAdminData: any[] = [];
					oldAdminData.admins.forEach(admin => {
						if (admin.ntid !== row.values.ntid)
							newAdminData.push(admin)
						else
							newAdminData.push({ ...admin, role: newRole })
					})
					return { admins: newAdminData };
				})
				alert.show(`Successfully changed access role for user ${row.values.name} to ${newRole}`, {
					type: 'success',
					timeout: 5000,

				})
			},
			onError(error, variables) {
				if (variables.isSuperadmin === true)
					setSelectedValue('admin')
				else
					setSelectedValue('superadmin')
				alert.show(`Unknown error occurred while updating user role for ${row.values.name}`, {
					type: 'error',
					timeout: 5000,
				})
			},
		})
	}

	const userNTID = useUserInfo().getUserInfo().ntId;
	if ((userNTID + "").toLowerCase() === (row.values['ntid'] + "").toLowerCase())
		return <>{value.charAt(0).toUpperCase()
			+ value.slice(1)}</>

	return (<fieldset style={{
		marginTop: '0.5rem'
	}} disabled={isLoading} onChange={roleChangeHandler} aria-required="true" className="dds__fieldset dds__radio-button-group dds__fieldset--inline stacked-radio-group-mobile" role="radiogroup">
		<div className="dds__radio-button dds__radio-button--sm">
			<input className="dds__radio-button__input"
				type="radio"
				name={`admin${row.id}`}
				id={`radio-admin-control-${row.id}`}
				value="admin"
				defaultChecked={value === 'admin'}
				checked={selectedValue === 'admin'} />
			<label className="dds__radio-button__label" id={`radio-admin-label-${row.id}`} htmlFor={`radio-admin-control-${row.id}`}>Admin</label>
		</div>
		<div className="dds__radio-button dds__radio-button--sm">
			<input
				className="dds__radio-button__input"
				type="radio"
				name={`superadmin${row.id}`}
				id={`radio-superadmin-control-${row.id}`}
				value="superadmin"
				defaultChecked={value === 'superadmin'}
				checked={selectedValue === 'superadmin'}
			/>
			<label className="dds__radio-button__label" id={`radio-superadmin-label-${row.id}`} htmlFor={`radio-superadmin-control-${row.id}`}>Superadmin</label>
		</div>
	</fieldset>)
}
export default RoleCell