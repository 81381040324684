import { useCustomQuery } from '../../../hooks/useCustomQuery';
import { queryKeys } from '../../../react-query/constants';
import { useUserInfo } from '../../../store/auth/UserInfoContext';
import { metaConfig } from '../API';
import { cvl, eol, inventory, req_tracker, unauth, vdi, passwordReset, mobiledevices, pcversion } from '../constants';
export type userTab = { user: string | null, admin: string | null, superAdmin: string | null, leader: string | null }

const useMetaAPI = () => { //TODO: Remove Dummy data
	const user = useUserInfo().getUserRole(eol) // Remove this
	return useCustomQuery([queryKeys.metaData], metaConfig(),
		{
			select: (data) => {
				// console.log("Data fetched", data)
				const hidden = {};
				hidden[req_tracker] = !data.result.description[req_tracker];
				hidden[cvl[0]] = !data.result.description[cvl[0]];
				hidden[unauth] = !data.result.description[unauth];
				hidden[eol] = !data.result.description[eol];
				hidden[passwordReset] = !data.result.description[passwordReset];
				hidden[vdi] = !data.result.description[vdi];
				hidden[inventory] = !data.result.description[inventory];
				hidden[mobiledevices] = !data.result.description[mobiledevices];
				hidden[pcversion] = !data.result.description[pcversion];

				// Params for Dashboard v3 with dummy data
				let orderOrgCards: any[] = []
				if (user.leader)
					orderOrgCards = [inventory, eol, mobiledevices];

				const orderUserActions = [cvl[0], unauth, pcversion, passwordReset];
				const order = [
					pcversion, unauth, cvl[0], req_tracker, eol, inventory, vdi, passwordReset
				]
				const actionCards = {
					"Security Threats on Your Computer": true,
					"Unauthorized Software": true,
					"PC Version": true,
					"Password Management": true,
				}
				let orgCards: any = {}
				if (user.leader)
					orgCards = {
						"Asset Tracker": true,
						"End of Life Devices": true,
						"Mobile Devices": true,
					}
				const totalOrgCards = orderOrgCards.length;
				const totalUserActions = orderUserActions.length;

				const dataObject = {
					"Asset Tracker": "1 Active Asset Found", //same
					"End of Life Devices": null, // "EOL in 645 days: Details"
					"Asset Request Tracker": "1 Active Asset Request Found", // different number
					"VDI Request Management": "No VDI Request found", // same
					"Security Threats on Your Computer": "No Threats Detected: All Clear", // same
					"Unauthorized Software": "3 Unauthorized Apps: View Details", // different number
					"Password Management": "your password will expire in 82 Days",
					"PC Version": "View PC Version Details",
				}

				const url = {
					"Asset Tracker": {
						openDrawer: true,
						name: "Visit Dashboard",
						path: "/assetinventory",
						external: false,
						ariaLabel: "Open " + inventory + " User page"
					},
					"End of Life Devices": {
						openDrawer: true,
						name: "Request a device",
						path: "https://dell.service-now.com/sp?id=sc_category",
						external: true,
						ariaLabel: "Open My IT portal to place a request for a new device"
					},
					"Asset Request Tracker": {
						openDrawer: true,
						name: "Visit Dashboard",
						path: "/request-tracker",
						external: false,
						ariaLabel: "Open " + req_tracker + "  User page"
					},
					"VDI Request Management": {
						openDrawer: true,
						name: "Manage Requests",
						path: "https://vdi.dell.com",
						external: true,
						ariaLabel: "Open " + vdi + " drawer"
					},
					"Security Threats on Your Computer": {
						openDrawer: true,
						name: "Visit Dashboard",
						path: "/vulnerability-portal",
						external: false,
						ariaLabel: "Visit Vulnerability Portal"
					},
					"Unauthorized Software": {
						openDrawer: true,
						name: "Visit Dashboard",
						path: "https://unauthsoft.dell.com/",
						external: true,
						ariaLabel: "Open " + unauth + " drawer"
					},
					"Password Management": {
						openDrawer: true,
						name: "Reset Password",
						path: "https://myhelp.dell.com/",
						external: true,
						ariaLabel: "Open " + passwordReset + " drawer"
					},
					"Mobile Devices": {
						openDrawer: true,
						name: "Visit Mobile Devices Dashboard",
						path: "/mobiledevices/myorg",
						external: false,
						ariaLabel: "Open " + mobiledevices + " drawer"
					},
					"PC Version": { // card doesn't have any URL
						openDrawer: true,
						// name: "Visit PC Version Dashboard",
						// path: "/pcversion/myorg",
						// external: false,
						ariaLabel: "Open " + pcversion + " drawer"
					}

				}
				const tabs = {
					...data.result.tabs,
					"PC Version": {
						"leader": null,
						"admin": null,
						"user": "My PC",
						"superAdmin": null
					}
				}


				return {
					...data.result,
					// total: 8,
					// tabs,
					// hidden,
					// order,
					// orderOrgCards,
					// orderUserActions,
					// actionCards,
					// totalOrgCards,
					// totalUserActions,
					// orgCards,
					// data: dataObject,
					url,

				};
			},
			cacheTime: Infinity,
			staleTime: Infinity
		});
}

export default useMetaAPI