import styled from "styled-components";

export const ModalBody = styled.div.attrs((props: { overflow: string }) => props)`
	margin-top: 10%;
	overflow-Y: ${props => props.overflow};
	height: auto;
	/* height: overflow === 'auto' ? 'auto' : '100%', */
	max-height: 55vh;
	min-height: 50vh;
	/* height: 50vh; */
	margin-bottom: 100px;
`;