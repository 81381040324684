import { NumberWrapper } from './Styles'

const CardText: React.FC<{
	total: number,
	issueMessage: JSX.Element,
	congratsMessage: JSX.Element
}> = ({ total, congratsMessage, issueMessage }) => {
	return (
		<div className="dds__d-flex dds__justify-content-center dds__text-center dds__body-2">
			{total > 0 ?
				<div className='dds__p-5'>
					{issueMessage}
				</div> :
				<div className='dds__px-5'>
					<div>
						<i className="dds__icon dds__icon--thumb-up " style={{
							fontSize: '4.5rem',
							color: 'var(--green-200)'
						}}></i>
					</div>
					<h3>Congratulation!</h3>
					{congratsMessage}
				</div>}
		</div>
	)
}

export default CardText