import ReactTooltip from "react-tooltip";
import DefaultCell from "../../../Table/ReusableTableCells/DefaultCell";
import ActionColumn from "./ActionColumn/ActionColumn";
import { IconButtonWrapper } from "../../../Dashboardv3/Card/Styles";
import { UseTableCellProps } from "react-table";
const columns: any[] = [
	// {
        
		//Expander Cell
		// Header: () => null, // No header - will change to 3 sub headers
		// Header: "SL.NO.",
		// id: "index",
		// Cell: ({ row }: UseTableCellProps<any>) => (
		//     <span {...row.getToggleRowExpandedProps()}>
		//         {row.index + 1} {/* Display the index here */}
		//     </span>
		// ),
		// Cell: ({ row }: UseTableCellProps<any>) => (
		// 	<span {...row.getToggleRowExpandedProps()}>
		// 		{row.original.expandData?
		// 			(row.isExpanded ? (
		// 				<IconButtonWrapper
		// 					aria-label="Collapse row"
		// 					role="button"
		// 					className="dds__button dds__button--secondary dds__button--md dds__border-0 dds__p-0"
		// 				>
                            
		// 					<i
		// 						style={{
		// 							color: "var(--blue-600)",
		// 							fontSize: "1.2rem",
		// 						}}
		// 						className="dds__icon dds__icon--chevron-up"
		// 					/>
		// 				</IconButtonWrapper>
		// 			) : (
		// 				<IconButtonWrapper
		// 					aria-label="Expand row"
		// 					role="button"
		// 					className="dds__button dds__button--secondary dds__button--md dds__border-0 dds__p-0"
		// 				>
		// 					<i
		// 						style={{
		// 							color: "var(--blue-600)",
		// 							fontSize: "1.2rem",
		// 						}}
		// 						className="dds__icon dds__icon--chevron-down"
		// 					/>
		// 				</IconButtonWrapper>
		// 			)):null}
		// 	</span>
		// ),

	// 	disableFilters: true,
	// 	disableResizing: true,
	// 	width: 70,
        
	// },

	{ Header: "Telemetry Measures", accessor: "telemetry_measures" },
	{ Header: "Sub Measure", accessor: "sub_measure",disableSortBy: true },
	{ Header: "Low Threshold", accessor: "low_threshold",disableSortBy: true,width:20},
	{ Header: "Medium Threshold", accessor: "medium_threshold",disableSortBy: true,width:20},
	{ Header: "High Threshold", accessor: "high_threshold",disableSortBy: true,width:20},
	{
		Header: "Action",
		accessor: "action",
		disableSortBy: true,
		Cell: ActionColumn,
	},
	{
		Header: () => null,
		accessor: "measure_Values",
		disableSortBy: true,
		show: false, // Set width to 0 or null to hide it visually
 
	  },
	  {
		Header: () => null,
		accessor: "id",
		disableSortBy: true,
		show: false, // Set width to 0 or null to hide it visually
 
	  },
	// { Header:  () => null, accessor: "measureValues",disableSortBy:true,width:null},
	// // More columns...
];

export default columns;
