export const UNAUTHORIZED = "Unauthorized";

export const LOST_TOKEN = "USER NOT AUTHORIZED: TOKEN LOST/INVALID";

export class UnauthorizedError extends Error {
	constructor(message = "Access is denied. You don't have sufficient permission to access the requested resource.") {
		super(message);
	}

	title = "403: Forbidden";

	name = "unauthorized";

	statusCode = 403;
}

export class AuthError extends Error {
	constructor(message = "Session Expired, Please login again!") {
		super(message);
	}

	title = "401: Authentication Error";

	name = "Auth";

	statusCode = 401;
}

export class PageNotFoundError extends Error {
	constructor(message = "This page doesn't exist") {
		super(message);
	}

	title = "404: Page Not Found";

	name = "404";

	statusCode = 404;
}

export class APIRequestFailedError extends Error {
	constructor(statusCode: number, message = "API request failed") {
		super(message);
		this.statusCode = `${statusCode}`;
	}

	title = "API Request Failed, Please check your connection!";

	name = "Something went wrong";

	statusCode = "";
}
