import Modal from '../../../Common/Modal/Modal';
import { IconButton } from '../../../Table/TableStyles';
import ClientTable from '../../../ClientTable/ClientTable';
import ReactTooltip from 'react-tooltip';
import LoadingSpinner from '../../../Common/LoadingSpinner';
import { useEffect, useState } from 'react';

type ProductViewProp = {
    trigger: boolean;
    leaderName: string;
    setTriggerProductView: any;
    children?: any;
};

const ProductView = (props: ProductViewProp) => {
    return (props.trigger) ? (
        <Modal classNames='' onClose={() => { props.setTriggerProductView(false) }} style={{ width: '75%', left: '15%', height: '84%', focus }}>
            {<div className="dds__pt-3 dds__px-3" style={{
                position: 'sticky',
                top: 0,
                background: 'white',
                zIndex: 999
            }}>
                <div className="dds__mb-2">
                    <h3 style={{
                        color: 'var(--blue-700)'
                    }} className="dds__d-inline dds__h3 ">{'Products Data'}</h3>
                    <IconButton style={{
                        float: 'right'
                    }}
                        onClick={() => props.setTriggerProductView(false)}>
                        <i
                            style={{ fontSize: "1rem", color: 'var(--gray-600)' }}
                            className="dds__icon dds__icon--close-x"
                            tabIndex={0}
                            role="button"
                            aria-label='Close'
                        ></i>
                    </IconButton>

                </div>
            </div>}
            {props.children}
            {/* {isLoading && !isError && <div className="dds__mt-3 dds__mx-auto dds__text-center">
                <LoadingSpinner size="md" />
            </div>} */}
            {/* {!isLoading && !isError && data && <div className="dds__px-3">
                <ClientTable
                    data={data}
                    columns={leaderProductView}
                    skipStateReset={false}
                    activateExportAllData
                    activateSearch
                    activateFilters={false}
                    disablePagination
                    disableColumnFunctions
                />
            </div>} */}



        </Modal>

    ) : null;
}

export default ProductView;