import FilterChip from './FilterChip';

const AppliedMobileFilterBar = ({ dropdownList, resetFilters }) => {
	type dropdownVariable<T> = {
		controller: any,
		value: T,
		length: number
	}

	let device: dropdownVariable<string[]> = {
		controller: dropdownList[0],
		value: dropdownList[0].getSelection(),
		length: dropdownList[0].getSelection().length
	};

	let carrier: dropdownVariable<string[]> = {
		controller: dropdownList[1],
		value: dropdownList[1].getSelection(),
		length: dropdownList[1].getSelection().length
	};

	let country: dropdownVariable<string[]> = {
		controller: dropdownList[2],
		value: dropdownList[2].getSelection(),
		length: dropdownList[2].getSelection().length
	};

	const length = (device.length + carrier.length + country.length);

	return (
		<div>
			<span>
				Filter Result({length})
			</span>
			{device.length > 0 &&
				<FilterChip listItem={device.value} controller={device.controller} />
			}
			{carrier.length > 0 &&
				<FilterChip listItem={carrier.value} controller={carrier.controller} />
			}
			{country.length > 0 &&
				<FilterChip listItem={country.value} controller={country.controller} />
			}
			{length > 0 &&
				<button
					onClick={resetFilters}
					className="dds__button dds__button--tertiary dds__button--sm">
					Reset
				</button>}


		</div>
	)
}

export default AppliedMobileFilterBar