import styled, { keyframes } from "styled-components";

const SlideDown = keyframes`
	from {
	  opacity: 0;
	  transform: translateY(-3rem);
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	}
`;

export const ModalWrapper = styled.div`
	position: fixed;
	top: 10vh;
	left: 5%;
	width: 85%;
	height: 75vh;
	min-height: 250px;
	background-color: white;
	/* padding: 1rem; */
	/* border-radius: 14px; */
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
	z-index: 30;
	animation: ${SlideDown} 300ms ease-out forwards;
	overflow-y: auto;

	@media (min-width: 768px) {
		width: 40rem;
		left: calc(50% - 20rem);
	}
`;

export const BackdropWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 20;
	background-color: rgba(0, 0, 0, 0.75);
`;
