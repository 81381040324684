import React from 'react'
import { useDashboardContext } from '../../../../../store/dashboard/DashboardContext';
import LoadingSpinner from '../../../../Common/LoadingSpinner';
import { eol } from '../../../constants';
import CardError from '../../CardError';
import EOLLeaderChart from './EOLLeaderChart';
import useEolOrgData from './useEolOrgData';

const Leader: React.FC = () => {
	const { isOrg, activeTab } = useDashboardContext();
	const { isLoading, error, data } = useEolOrgData(
		(isOrg || (activeTab.category === 'org' && activeTab.name === eol))
	);

	if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
	if (error) return <CardError />; // Will have to change as per this card error
	return (
		<div className="dds__mx-4">
			<div className="dds__text-right dds__font-weight-bold" style={{
				color: 'var(--red-900)',
			}}>
				<label htmlFor='eol-value'>
					{data?.chartLabels[0][0]}
				</label>
				<span id='eol-value' style={{
					background: 'var(--red-100)',
					marginLeft: 10,
					padding: '4px 10px 4px 10px',
					borderRadius: 10
				}}>
					{data?.chartLabels[0][1]}
				</span>
			</div>
			<EOLLeaderChart data={data} />
		</div >
	)
}

export default Leader