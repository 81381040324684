
import React from "react";

const BulletedList = ({ items }) => {
    return (
        <ul className="dds__list">
            {items.map((item, index) => (
                <li key={index} style={{ marginBottom: 0 }}>
                    {item}
                </li>
            ))
            }
        </ul >
    );
};

export default BulletedList