// test
import { useCustomQuery } from '../../../../hooks/useCustomQuery';
import { queryKeys } from '../../../../react-query/constants';
import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import { fetchUserRecordsConfig } from '../../../AssetTracker/API/api';
import DDSTabs, { TabsData } from '../../../Common/Tabs/DDSTabs';
import Card from '../../Card/Card';
import { createTrigger, TriggerAction } from '../../Common/TriggerAction';
import { cardsTheme, req_tracker } from '../../constants';
import DDSDrawer from '../../Drawer/DDSDrawer';
import { userTab } from '../../DashboardPage/useMetaAPI';
import User from './User';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import { useState, useEffect } from 'react';
import { useDashboardAPIs } from '../../DashboardPage/useDashboardAPIs';

const AssetRequestCard: React.FC<{
	favouriteStatus: boolean, description: any, tabsMetaData: userTab, actionRequired: boolean
}> = ({
	favouriteStatus,
	description,
	tabsMetaData,
	actionRequired
}) => {

		const { getUserRole } = useUserInfo();
		const role = getUserRole(req_tracker);
		const { isOrg } = useDashboardContext();
		const { isLoading: isUserLoading, data: userData, error: userError } = useCustomQuery([queryKeys.ARTUserCard], fetchUserRecordsConfig());

		const { data: metaData } = useDashboardAPIs();
		const [message, setMessage] = useState("View Details")

		useEffect(() => {
			if (!isOrg) {
				if (metaData && metaData.data[req_tracker]) {
					setMessage(metaData.data[req_tracker]);
				}
			}
		}, [metaData, isOrg]);

		useEffect(() => {
			if (!isOrg && !isUserLoading && !userError) {
				let temp = "No Active Asset Request found"
				if (userData.records.length > 0)
					temp = userData.records.length + ' Active Assets Requests found';
				setMessage(temp);
				// Add logic to update query data with message 
			}
		}, [isUserLoading, userData, userError, isOrg]);


		const tabDetails: TabsData = [{
			hidden: !tabsMetaData.user || !role.user,
			name: tabsMetaData.user,
			iconClass: "dds__icon--user",
			content: User,
			active: !isOrg,
			footerButtons: [
				{
					name: "Visit Dashboard",
					path: "/request-tracker",
					external: false,
					ariaLabel: "Open " + req_tracker + "  User page"
				}
			]
		}]

		const triggerID = createTrigger(req_tracker)
		return <Card
			themeColor={cardsTheme[req_tracker].themeColor}
			name={req_tracker}
			ddsIconClass={cardsTheme[req_tracker].ddsIconClass}
			isFav={favouriteStatus}
			idName={req_tracker}
			description={description}
			isLoading={(message === "View Details" && !userError)}
			actionRequired={actionRequired}
		>
			<TriggerAction triggerID={triggerID}>
				{message}
			</TriggerAction>
			<DDSDrawer triggerID={triggerID} cardName={req_tracker}>
				<DDSTabs tabsMetaData={tabsMetaData} idName={req_tracker} tabsData={tabDetails} salt={req_tracker} />
			</DDSDrawer>

		</Card>


	}

export default AssetRequestCard