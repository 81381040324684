/* eslint-disable jsx-a11y/role-has-required-aria-props */
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useErrorHandler } from "react-error-boundary";
import ReactTooltip from "react-tooltip";
import { Dropdown } from "@dds/components";
import styled from "styled-components";
import {
	APIRequestFailedError,
	AuthError,
	LOST_TOKEN,
} from "../../ErrorStates";
import useAxios from "../../hooks/useAxios";
// import { useAuth } from "../../store/auth/AuthContext";
import { assignAdminConfig } from "./API";
import LoadingSpinner from "../Common/LoadingSpinner";
import { useAuth } from "../../hooks/useAuth";

const SvgWrapper = styled.div`
	svg {
		/* width: 100%; */
		height: 1rem;
		display: none;
		background-color: white;
		/* padding: 0; */
		color: black;
		margin-bottom: -4%;
	}
`;

// Create an editable cell renderer
const EditableSelectCell: React.FC<{
	value: string;
	row: any;
	column: any;
	tableRole: string;
	userRole: string;
	adminList: any[];
	setData: any;
}> = ({
	value: initialValue,
	row,
	column: { width, id },
	tableRole,
	userRole,
	adminList,
	setData,
}) => {
		const [admin, setAdmin] = useState<string>(initialValue);
		const auth = useAuth();
		const alert = useAlert();
		const handleError = useErrorHandler();

		const { isLoading, error: addError, sendRequest } = useAxios();

		const saveTicketSuccessHandler = (dataObj: any): void => {
			if (dataObj.assignAdmin.assign_to_ntid === "")
				alert.show("Admin removed successfully", {
					type: "success",
					timeout: 2000,
				});
			else
				alert.show("Admin assigned successfully", {
					type: "success",
					timeout: 2000,
				});

			setData(old =>
				old.map((dataRow, index) => {
					if (index === row.index) {
						return {
							...old[index],
							assignadmin: dataObj.assignAdmin.assign_to_ntid,
						};
					}
					return dataRow;
				})
			);
		};

		const adminChangeHandler: any = async (ntid, name) => {
			setAdmin(ntid);

			const accessToken = await auth?.getAccessToken();
			if (accessToken)
				sendRequest(
					assignAdminConfig(accessToken, {
						assethostname: row.values.assethostname,
						email: row.values.email,
						assign_by_ntid: "admin",
						assign_to_name: name,
						assign_to_ntid: ntid,
						product: row.values.product,
						servicetag: row.values.servicetag,
						username: row.values.username,
					}),
					saveTicketSuccessHandler
				);
			else handleError(new AuthError(LOST_TOKEN));
		};

		useEffect(() => {
			if (addError) {
				if (addError instanceof APIRequestFailedError) {
					alert.show("Server error, please try again later", {
						type: "error",
						timeout: 5000,
					});
				} else if (addError instanceof AuthError) {
					handleError(addError);
				} else {
					alert.show(
						"Connection error, please check your connection and try again",
						{
							type: "error",
							timeout: 5000,
						}
					);
				}

				if (admin !== initialValue) setAdmin(initialValue);
			}
		}, [handleError, addError, alert, admin, initialValue]);

		const dropdownRef = useRef<any>();
		const dropdownInstanceRef = useRef<any>(null);
		useEffect(() => {
			dropdownInstanceRef.current = new Dropdown(dropdownRef.current, {
				noOptionsLabel: "User not found in the admin group",
			});
			return () => {
				dropdownInstanceRef.current.dispose()
			}
		}, []);

		const cellValue = (
			<>
				<p
					style={{ display: "inline" }}
					data-for="admin_tip"
					data-tip={initialValue}
				>
					{initialValue !== "" ? initialValue : "none"}
				</p>
				<ReactTooltip id="admin_tip" />
			</>
		);
		if (userRole === "user") return cellValue;

		return (
			<>
				{isLoading && (
					<div>
						<LoadingSpinner size="md" />
					</div>
				)}
				{
					<div
						style={{
							display: isLoading ? "none" : "initial",
						}}
						className="dds__dropdown"
						data-dds="dropdown"
						id={`basic-dropdown${row.id}`}
						data-selection="single"
						ref={dropdownRef}
					>
						<SvgWrapper>
							<div className="dds__dropdown__input-container">
								{/* <label id="basic-dropdown-label" htmlFor="basic-dropdown">Dropdown label</label> */}
								<div
									className="dds__dropdown__input-wrapper"
									role="combobox"
									aria-haspopup="listbox"
									aria-controls="basic-dropdown-popup"
									aria-expanded="false"
								>
									<input
										id={`basic-dropdown${row.id}`}
										name="basic-dropdown"
										type="text"
										className="dds__dropdown__input-field dds__p-1"
										aria-labelledby="basic-dropdown-label basic-dropdown-helper"
										disabled={isLoading}
									/>
									{/* <small id={`basic-dropdown-helper${row.id}`} className="dds__input-text__helper">Helper text</small> */}
									<div
										id={`basic-dropdown-feedback${row.id}`}
										className="dds__invalid-feedback"
									/>
								</div>
							</div>
						</SvgWrapper>

						<div
							style={{ width: 250, maxHeight: 250, overflowX: "hidden" }}
							id={`basic-dropdown-popup${row.id}`}
							className="dds__dropdown__popup dds__dropdown__popup--hidden"
							role="presentation"
						>
							<ul className="dds__dropdown__list" role="listbox" tabIndex={-1}>
								{/* {initialValue === "NA" || initialValue === "" ? (
						<li className="dds__dropdown__item" role="none" key="select">
							<button type="button" className="dds__dropdown__item-option" role="option" data-selected="true" tabIndex={-1}>
								<span className="dds__dropdown__item-label">-- Select --</span>
							</button>
						</li>
					) : null} */}
								{adminList.map(admins => (
									<li
										key={admins.ntid}
										className="dds__dropdown__item"
										role="none"
									>
										<button
											style={{
												textAlign: "left",
												width: 230,
											}}
											onClick={() => adminChangeHandler(admins.ntid, admins.name)}
											type="button"
											className="dds__dropdown__item-option dds__p-2 dds__m-0"
											role="option"
											data-selected={admins.ntid === admin}
											tabIndex={-1}
										>
											<span
												className="dds__dropdown__item-label"
												style={{ fontSize: "0.7rem", textAlign: "left" }}
											>
												{admins.ntid === "" ? (
													<b>none</b>
												) : (
													`${admins.name} - ${admins.ntid}`
												)}
											</span>
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				}
			</>
		);

		// return adminList.length > 0 ? (
		// 	<select disabled={isLoading} style={{ width: width - 12 }} value={initialValue} onChange={adminChangeHandler}>
		// 		{initialValue === "NA" || initialValue === "" ? (
		// 			<option value={initialValue} key={initialValue} selected>
		// 				-- Select --
		// 			</option>
		// 		) : null}
		// 		{adminList.map(admins =>
		// 		// console.log(status)
		// 		(
		// 			<option key={admins.ntid} value={admins.ntid}>
		// 				{admins.ntid === "" ? "REMOVE ADMIN" : admins.ntid}
		// 			</option>
		// 		)
		// 		)}
		// 	</select>
		// ) : (
		// 	cellValue
		// )
	};

export default EditableSelectCell;

// return adminList.length > 0 ? (
// 	<>
// 		<input
// 			id="table-search-input"
// 			// className="dds__form-control"
// 			// style={{
// 			// 	width: '85%',
// 			// 	display: 'inline',
// 			// }}
// 			style={{ width: width - 12 }}
// 			placeholder="Select"
// 			list="admins"
// 			onChange={adminChangeHandler}
// 			value={admin}
// 		/>
// 		{/* <DataListWrapper> */}
// 		<datalist id="admins">
// 			{initialValue === "NA" || initialValue === "" ? (
// 				<option value={initialValue} key={initialValue} selected>
// 					-- Select --
// 				</option>
// 			) : null}
// 			{adminList.map(admins =>
// 			// console.log(status)
// 			(
// 				<option key={admins.ntid} value={admins.ntid}>
// 					{admins.ntid === "" ? "REMOVE ADMIN" : admins.ntid}
// 				</option>
// 			)
// 			)}
// 		</datalist>
// 		{/* </DataListWrapper> */}
// 	</>
// ) : (
// 	cellValue
// )
