import React, { useEffect, useRef, useState } from 'react'

import { v4 } from 'uuid'

const SubMeasureBox: React.FC<{
	mode: 'new' | 'edit',
	SubMeasureInput: string,
	setSubMeasureInput: React.Dispatch<React.SetStateAction<string>>
	initialize?: boolean
}> = ({
	mode,
	SubMeasureInput,
	setSubMeasureInput,
	initialize = false,
}) => {
    // Telemetry Measures input change handler
		const onSubMeasureInputChange = (event) => {
			event.preventDefault();
			setSubMeasureInput(event.target.value)
        }
		return(
			<div className="dds__input-text__container">
    <label htmlFor="SubMeasure-field" className="dds__label">{mode === 'edit' ? 'Sub Measure' : 'Sub Measure' }</label>
    <div className="dds__input-text__wrapper">
        <input
            type="text"
            className="dds__input-text" style={{
                width: "100%",
                borderRadius:"14px"

            }}
            placeholder="Enter Sub Measure"
            maxLength={100}
            name="SubMeasure-field"
            id="SubMeasure-field"
            required
            aria-labelledby="SubMeasure-label"
            onChange={onSubMeasureInputChange}
            value={SubMeasureInput}
        />
    </div>
</div>
		)
}
export  default SubMeasureBox