import { createContext, useContext, useEffect, useState } from "react";
import { activeType } from "../../components/Dashboardv3/constants";

// Dashboard Context with types
const DashboardContext = createContext<{
	active: activeType | undefined;
	setActive: React.Dispatch<React.SetStateAction<activeType>>;
	isOrg: boolean;
	activeTab: {
		name: string;
		category: string;
	};
	setActiveTab: React.Dispatch<
		React.SetStateAction<{
			name: string;
			category: string;
		}>
	>;
	activeAPICalls: number;
	addAPICall: () => void;
	removeAPICall: () => void;
	searchCards: string;
	setSearchCards: React.Dispatch<React.SetStateAction<string>>;
}>({
	active: undefined,
	setActive: () => undefined,
	isOrg: false,
	activeTab: {
		name: "",
		category: "",
	},
	setActiveTab: () => undefined,
	activeAPICalls: 0,
	addAPICall: () => undefined,
	removeAPICall: () => undefined,
	searchCards: "",
	setSearchCards: () => undefined,
});

export const DashboardContextProvider = ({ children }) => {
	// This is global active state of dashboard tabs like org, user, admin, actions etc
	const [active, setActive] = useState<activeType>("org"); // so that state changes and the UI gets updated
	const [activeAPICalls, setActiveAPICalls] = useState<number>(0);

	// Search cards state
	const [searchCards, setSearchCards] = useState<string>("");

	// function to add or remove api call count
	const addAPICall = () => {
		setActiveAPICalls(prev => prev + 1);
	};
	const removeAPICall = () => {
		setActiveAPICalls(prev => prev - 1);
	};

	const [activeTab, setActiveTab] = useState<{
		name: string;
		category: string;
	}>({
		name: "",
		category: "",
	});

	// reset search cards state when active tab changes
	useEffect(() => {
		setSearchCards("");
	}, [active]);


	const isOrg = active === "org";
	return (
		<DashboardContext.Provider
			value={{
				active,
				setActive,
				isOrg,
				activeTab,
				setActiveTab,
				activeAPICalls,
				addAPICall,
				removeAPICall,
				searchCards,
				setSearchCards,
			}}
		>
			{children}
		</DashboardContext.Provider>
	);
};

export const useDashboardContext = () => {
	const context = useContext(DashboardContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
};
