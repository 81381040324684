interface IAccordionControlsProps {
	expandLabel: string;
	collapseLabel: string;
}

const AccordianControls: React.FC<IAccordionControlsProps> = ({
	expandLabel,
	collapseLabel,
}) => {
	return (
		<div className="dds__accordion__control">
			<button
				type="button"
				className="dds__accordion__control__expand"
				aria-disabled="false"
				aria-label={expandLabel}
			>
				Expand All
			</button>
			<button
				type="button"
				className="dds__accordion__control__collapse"
				aria-disabled="false"
				aria-label={collapseLabel}
			>
				Collapse All
			</button>
		</div>
	);
};
export default AccordianControls;