import React, { FC, useEffect, useRef } from "react";
import { Tabs } from "@dds/components";
import { useAnalytics } from "../../../store/analytics/AnalyticsContext";
import { eventsInfo } from "../../../store/analytics/eventsInfo";
import { useDashboardContext } from "../../../store/dashboard/DashboardContext";
import { userTab } from "../../Dashboardv3/DashboardPage/useMetaAPI";
import useRedirectHandler from "../../Dashboardv3/Common/useRedirectHandler";

export type TabsData = {
	hidden: boolean;
	active: boolean;
	name: string | null;
	content: FC;
	iconClass: string;
	footerButtons: {
		name: string,
		path: string,
		external: boolean,
		ariaLabel: string
	}[];

}[]
const DDSTabs: React.FC<{
	tabsData: TabsData;
	salt: string;
	idName: string
	tabsMetaData: userTab
}> = ({ tabsData, tabsMetaData, salt, idName }) => {
	//const tabsRef = useRef<HTMLDivElement>(null); Removed to use ID and event listener otherwise it was not differentiating between different cards
	const tabsInstanceRef = useRef<any>(null);
	const { setActiveTab, active } = useDashboardContext();
	salt = salt.replace(/\s/g, "");

	// const history = useHistory();

	const { addEventToQueue } = useAnalytics();
	const { redirectHandler } = useRedirectHandler();

	// const redirectHandler = (buttonInfo: { name: string, path: string, external: boolean }): void => {
	// 	const additionalPayload = buttonInfo
	// 	addEventToQueue({
	// 		...eventsInfo.redirectDashboardButton(),
	// 		additional_payload: additionalPayload
	// 	})
	// 	if (buttonInfo.external)
	// 		window.open(buttonInfo.path)
	// 	else {
	// 		// Fix for Direct push from drawer element as overlay classes are not auto removed
	// 		// remove class dds__drawer__overlay--overflow-hidden from body if present
	// 		document.body.classList.remove("dds__drawer__overlay--overflow-hidden");
	// 		history.push(buttonInfo.path);
	// 	}
	// }

	const logTabSwitchEvent = (e) => {
		let index = e.detail.index;
		while (index < tabsData.length && tabsData[index].hidden) {
			index++;
		}
		if (index >= tabsData.length)
			index = 0;

		let category = "all";

		if (tabsMetaData.leader === tabsData[index].name) {
			category = "org";
		} else if (tabsMetaData.admin === tabsData[index].name) {
			category = "admin";
		}

		setActiveTab({
			name: idName,
			category
		})
		console.log("toggle " + idName + " category " + category + " tab", tabsData[index]);
		const additional_payload = tabsData[index].name
		addEventToQueue({
			...eventsInfo["toggle " + idName + " tab"](),
			additional_payload: additional_payload
		})
		console.log("Tab switch")
	}
	let init = useRef<boolean>(true);
	useEffect(() => {
		const activeTab = tabsData.findIndex((tab) => tab.active);
		if (init.current === false && tabsInstanceRef.current)
			tabsInstanceRef.current.setActiveTab(activeTab);
	}, [active]);

	useEffect(() => {
		const element = document.getElementById(idName + "tab");
		// tabsInstanceRef.current = new Tabs(tabsRef.current);
		tabsInstanceRef.current = new Tabs(element);
		console.log("toggle " + idName + " tab");
		// Find tab which has active true from props
		const activeTab = tabsData.findIndex((tab) => tab.active);
		if (tabsInstanceRef.current) {
			init.current = false;
			tabsInstanceRef.current.setActiveTab(activeTab);
		}
		console.log("Active Tab", activeTab)

		element!.addEventListener("ddsTabsOpenedEvent", logTabSwitchEvent);
		() => {
			element!.removeEventListener("ddsTabsOpenedEvent", logTabSwitchEvent);
			tabsInstanceRef.current.dispose();
		}

		// tabsRef.current!.addEventListener("ddsTabsOpenedEvent", logTabSwitchEvent);
		// () => {
		// 	tabsRef.current!.removeEventListener("ddsTabsOpenedEvent", logTabSwitchEvent);
		// }
	}, []);

	return (
		<div className="dds__tabs" data-dds="tabs" id={idName + "tab"}
		// ref={tabsRef}
		>
			<div className="dds__tabs__list-container">
				<ul
					className="dds__tabs__list dds__tabs__list--overflow"
					role="tablist"
				>
					{tabsData.map((tab, index) => {
						return (
							!tab.hidden && <li role="none" key={index}>
								<button
									id={`tab-${salt}-${index}`.replace(/\s/g, "")}
									className="dds__tabs__tab"
									role="tab"
									aria-controls={`tab-${salt}-${index}-pane`.replace(/\s/g, "")}
									aria-selected={tab.active && false ? "true" : "false"}
									tabIndex={tab.active && false ? 0 : -1}
									style={{
										color: 'var(--gray-800)'
									}}
								>
									<i
										className={`dds__icon ${tab.iconClass} dds__tabs__tab__icon dds__tabs__tab__icon--start`}
										aria-hidden="true"
									/>
									<span className="dds__tabs__tab__label" title={tab.name!}>
										{tab.name}
									</span>
								</button>
							</li>
						);
					})}
				</ul>
			</div>
			<div className="dds__tabs__pane-container">
				{tabsData.map((tab, index) => {
					return !tab.hidden && <div
						key={index}
						id={`tab-${salt}-${index}-pane`.replace(/\s/g, "")}
						className="dds__tabs__pane"
						role="tabpanel"
						tabIndex={tab.active && false ? 0 : -1}
						aria-labelledby={`tab-${salt}-${index}`.replace(/\s/g, "")}
						style={{
							marginTop: "1.5rem"
						}}
					// aria-hidden={tab.active && false ? "false" : "true"}
					>
						<div style={{
							height: 'auto'
						}}>
							<tab.content />
						</div>
						<div style={{
							position: 'absolute',
							bottom: 16,
							width: '100%'
						}}>
							<div className="dds__d-flex dds__justify-content-center dds__flex-wrap" id={`tab-footer-${salt}-${index}`}>
								{tab.footerButtons.map(buttonInfo => {
									// if button opens a external link, add approproate label

									return <button aria-label={buttonInfo.ariaLabel} role={"button"} key={buttonInfo.name} onClick={() => redirectHandler(buttonInfo)} className="dds__button dds__button--sm dds__button--primary dds__mx-2 dds__mb-2">
										{buttonInfo.name}
										<i aria-hidden="true" className="dds__icon dds__icon--arrow-right dds__button__icon--end" />
									</button>
								})}
							</div>
						</div>


					</div>;
				})}
			</div>
		</div>
	);
};

export default DDSTabs;
