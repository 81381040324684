import { useEffect, useState } from "react";
import { useRegions } from "../../ManageUsersPage/RegionContext";
import { Dropdown } from "@dds/components";
import { accessDetailsType } from "../../DataModel/ActionColumn/EditAction";

export type extraEmployeeDetails = {
	admname: string;
	accesstype: string;
	ntid: string
};

const useDropdown = (rows, setRows) => {
	type roleType = { rolename: String; roleid: number };
	const [role, setRole] = useState<roleType>({} as roleType);

	
	const [extraEmployeeInformation, setExtraEmployeeInformation] =
		useState<extraEmployeeDetails>({} as extraEmployeeDetails);

	const { regions, setRegions } = useRegions();

	// search input to search for user
	const [searchInput, setSearchInput] = useState("");

	// to disable search after selection or in edit mode
	const [disableSearch, setDisableSearch] = useState(false);

	// Role change handler
	const handleRoleChange = (role: roleType): void => {
		setRole(role);
	};

	const [ispreloading, setIspreloading] = useState(false)

	// After data fetch, set region object and autofill data if in edit mode
	const loadData = async (
		employeeDetails?: {
			admname: string;
			email: string;
			rolename: string;
			id: string;
			accesstype: string;
			roleid: number;
			ntid:string
		},
		accessDetails?: accessDetailsType
	) => {
		setIspreloading(true);
		let data = regions;

		if (employeeDetails) {
			setSearchInput(employeeDetails.email);
			setDisableSearch(true);
			setRole({
				rolename: employeeDetails.rolename.toLowerCase(),
				roleid: employeeDetails.roleid,
			});
			setExtraEmployeeInformation({
				admname: employeeDetails.admname,
				accesstype: employeeDetails.accesstype,
				ntid: employeeDetails.ntid
			})
		}
		if (accessDetails) {
			setRows({ value: Object.keys(accessDetails).length, operation: "add" });

			Object.entries(accessDetails).forEach(([selectedCountry,selectedStockroomList], index) => {
				// console.log(selectedCountry,selectedStockroomList)
				data.forEach(allRegion => {
					if(selectedCountry === allRegion.country){
						allRegion.selected = index;
						selectedStockroomList.forEach(selectedStockroom => {
							allRegion.stockrooms.forEach(allStockroom => {
								if(allStockroom.name === selectedStockroom.name)
									allStockroom.selected = true;
							});
						})
					}
				})
			})
		}
		setTimeout(() => {
			setIspreloading(false)
		}, 500); 

		setRegions(data);
	};
	// Function to fetch value from stockroom dropdown using event listeners
	const handleDropdown = (e: any) => {
		// console.log("here");
		for (let i = 0; i < regions.length; i++) {
			let stockValue: any = document.querySelector(
				`[name="stockroom-multiselect-dropdown-name${i}"]`
			);
			// console.log("stockroom", stockValue.value ? JSON.parse(stockValue.value).length : "")

			if (stockValue && stockValue.value) {
				let selectedStockrooms = JSON.parse(stockValue.value);
				console.log(selectedStockrooms);
				setRegions(old =>
					old.map(e => {
						if (i === e.selected)
							return {
								...e,
								stockrooms: e.stockrooms.map(stockroom => {
									if (selectedStockrooms.includes(stockroom.name))
										return {
											...stockroom,
											selected: true,
										};
									return {
										...stockroom,
										selected: false,
									};
								}),
							};
						return e;
					})
				);
			}
		}
	};

	// Fetch initial region data and clean event listener on component unmount
	useEffect(() => {
		// loadData();
		// fetchCountries(myAssetsConfig(), () => loadData(dummyData))
		return function cleanupListener() {
			removeEventListener("ddsDropdownSelectionChangeEvent", handleDropdown);
		};
	}, []);

	// Add new event listener after region fetch or while adding new rows
	useEffect(() => {
		addEventListener("ddsDropdownSelectionChangeEvent", handleDropdown);
	}, [rows]);

	// Initialize DDS JS for dropdown
	const initializeDropdowns = () => {
		[].forEach.call(
			document.querySelectorAll('[data-dds="dropdown"]'),
			// ESLINT AUTOFIX
			element => {
				new Dropdown(element);
			}
		);
	};

	// Initializing dropdown initially when countries list is loaded and then on every newly added row
	useEffect(() => {
		initializeDropdowns();
	}, [rows, ispreloading]);

	// To check for overflow on every change
	useEffect(() => {
		checkDropdown();
	});
	// Function to set overflow visible to make the dropdown appear outside the modal body
	const checkDropdown = (id?: string) => {
		return;
		console.log(id);
		// if (id) {
		// 	if (document.getElementById(id)?.classList.contains("dds__dropdown__popup--visible")) {
		// 		setOverflow("visible")
		// 	} else {
		// 		setOverflow("auto")
		// 	}
		// }
		// else {
		// 	let res: "auto" | "visible" = "auto";

		// 	for (let rowIndex = 0; rowIndex < rows.value; rowIndex++) {
		// 		if (document.getElementById(`country-dropdown-popup${rowIndex}`)?.classList.contains("dds__dropdown__popup--visible")) {
		// 			res = ("visible")
		// 			break;
		// 		}
		// 		if (document.getElementById(`stockroom-multiselect-dropdown-popup${rowIndex}`)?.classList.contains("dds__dropdown__popup--visible")) {
		// 			res = ("visible")
		// 			break;
		// 		}
		// 	}
		// 	if (document.getElementById(`role-dropdown-popup`)?.classList.contains("dds__dropdown__popup--visible"))
		// 		res = ("visible")

		// 	console.log("Overflow set with res")
		// 	setOverflow(res);
		// }
	};

	const handleCountryChange = (
		selectedCountry: string,
		index: number
	): void => {
		setRegions(old =>
			old.map((e, i) => {
				if (e.country === selectedCountry)
					return {
						...old[i],
						selected: index,
					};
				else if (e.selected === index)
					return {
						...old[i],
						selected: -1,
					};
				return e;
			})
		);
	};

	// This function only enables stockroom dropdown only if a country has been selected in that row
	const checkDisabledStockroomDropdown = (rowIndex: number): boolean => {
		let flag = 0;
		regions.forEach(e => {
			if (e.selected === rowIndex) flag = 1;
		});
		if (flag === 1) return false;
		return true;
	};

	// const handleStockroomChange = (selectedStockroom: string, selection: boolean, index: number): void => {
	// 	setRegions(old =>
	// 		old.map((e, i) => {
	// 			if (e.selected === index)
	// 				return {
	// 					...old[i],
	// 					selected: index,
	// 					stockrooms: old[i].stockrooms.map(stockroom => {
	// 						if (stockroom.name === selectedStockroom)
	// 							return {
	// 								...stockroom,
	// 								selected: selection
	// 							}
	// 						return stockroom
	// 					})
	// 				}
	// 			else if (e.selected === index)
	// 				return {
	// 					...old[i],
	// 					selected: -1
	// 				}
	// 			return e
	// 		})
	// 	)
	// }

	const getData = () => {
		return {
			email: searchInput,
			regions,
			...role,
			...extraEmployeeInformation,

			city:"",
			location: "",
			region: ""

		};
	};

	return {
		handleCountryChange,
		loadData,
		ispreloading,
		getData,
		handleRoleChange,
		checkDropdown,
		disableSearch,
		setDisableSearch,
		searchInput,
		setSearchInput,
		role,
		checkDisabledStockroomDropdown,
		setExtraEmployeeInformation
	};
};
export default useDropdown;
